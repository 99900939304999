import * as React from 'react';

export interface ISectionWrapperProps {
  iconClass?: string;
  contentsClass?: string;
}

export interface ISectionWrapperState {}

/**
 * The SectionWrapper is a presentational wrapper that applies the object form section format.
 */
export class SectionWrapper extends React.Component<ISectionWrapperProps, ISectionWrapperState> {
  public render(): JSX.Element {
    const contentsClass = this.props.contentsClass || 'form-group-field';
    return (
      <div className="form-group-container">
        <div className="form-group-icon">
          {this.props.iconClass && <i className={this.props.iconClass} />}
        </div>
        <div className={contentsClass}>{this.props.children}</div>
      </div>
    );
  }
}
