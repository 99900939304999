import * as _ from 'underscore';

export class TargetValueValidator {
  public static isValidString(input: string): boolean {
    const list = [
      TargetValueValidator.isMinMaxString,
      TargetValueValidator.isRangeString,
      TargetValueValidator.isRelationalString,
      TargetValueValidator.isSpecificString,
    ];
    return _.any(list, function(matcher): boolean {
      return matcher(input);
    });
  }

  public static isMinMaxString(input: string): boolean {
    const match = /^\s*(min|max|minimize|maximize|amrap|alap)\s*(\w*)$/i;
    return match.test(input);
  }

  public static isRangeString(input: string): boolean {
    const match = /^\s*(\d+(?:\.\d*)?)\s*(?:-|to)\s*(\d+(?:\.\d*)?)\s*(\w*)\s*$/i;
    return match.test(input);
  }

  public static isRelationalString(input: string): boolean {
    const match = /^(<=|>=|at least|at most|≥|≤)\s*((?:(?:\d+h)?(?:\d{1,2}m)?(?:\d{1,2}(?:\.\d+)?s))|(?:(?:\d+:)?(?:\d{1,2}:)(?:\d{1,2}(?:\.\d*)?)?)|(?:-?\d+(?:\.\d*)?)\s*(-?\w*|'|"|#|º|km\/h))\s*$/i;
    return match.test(input);
  }

  public static isSpecificString(input: string): boolean {
    const match = /^ *((?:(?:\d+h)?(?:\d{1,2}m)?(?:\d{1,2}(?:\.\d+)?s))|(?:(?:\d+:)?(?:\d{1,2}:)(?:\d{1,2}(\.\d*)?)?)|(?:-?\d+(?:\.\d*)?)\s*(?:-?\w*|'|"|#|º|km\/h)) *$/i;
    return match.test(input);
  }
}
