import { CollectionResultSet } from '../../Data/CollectionResultSet';
import { ExerciseDefinition } from '../ExerciseDefinition';
import { User } from '../User';
import { RecordCollection } from './RecordCollection';

export class DefinitionCollection extends RecordCollection<ExerciseDefinition, User> {
  public search(searchText: string): JQueryPromise<CollectionResultSet<ExerciseDefinition>> {
    const searchData =
      typeof searchText === 'string' && searchText.trim().length > 0
        ? {
            term: searchText.trim(),
          }
        : {
            most_recent: true,
          };
    const url = this._baseURL;
    this._promiseService.emptyPromise();
    return this._dataLayer.searchCollection(url, this.type, searchData);
  }
}
