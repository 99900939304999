import { ExerciseSet } from '../../Models/ExerciseSet';
import { Workout } from '../../Models/Workout';
import { ISelectionNavigationDisplay } from '../Selection/ISelectionNavigationDisplay';
import { Composer } from './Composer';
import { IComposer } from './IComposer';
import { AcceptedModels, IManager } from './IManager';

/**
 * The Manager class is responsible for managing the workout navigation process.
 */
export class Manager implements IManager {
  public composer: IComposer = new Composer();
  public viewModel: ISelectionNavigationDisplay;
  public currentIndex: number;
  public list: ExerciseSet[];

  constructor(viewModel: ISelectionNavigationDisplay) {
    this.viewModel = viewModel;
  }

  public clearNavigation(): void {
    this.currentIndex = null;
    this.list = null;
    this.updateDisplay();
  }

  public goNext(): JQueryPromise<any> {
    const newIndex = this.currentIndex + 1;
    if (newIndex >= this.list.length) {
      throw new Error('Already at end of list, cannot go to next.');
    }
    return this.goToIndex(newIndex);
  }

  public goPrevious(): JQueryPromise<any> {
    const newIndex = this.currentIndex - 1;
    if (newIndex < 0) {
      throw new Error('Already at beginning of list, cannot go to previous.');
    }
    return this.goToIndex(newIndex);
  }

  public populateList(workout: Workout): void {
    this.list = this.composer.createNavigationList(workout);
  }

  public setCurrentItem(set: AcceptedModels): void {
    if (!(set instanceof ExerciseSet)) {
      throw new Error('Can only accept Exercise Sets at this time');
    }
    const itemIndex = this.list.indexOf(set);
    if (itemIndex === -1) {
      throw new Error('Item not in list');
    }
    this.currentIndex = itemIndex;
    this.updateDisplay();
  }

  /**
   * Instructs the view model to go to provided model in the list.
   * @param index
   */
  private goToIndex(index: number): JQueryPromise<any> {
    const newModel = this.list[index];
    return this.viewModel.changeSelection(newModel);
  }

  private updateDisplay() {
    if (this.list == null) {
      this.viewModel.updatePreviousItemDisplay(null);
      this.viewModel.updateNextItemDisplay(null);
      this.viewModel.updateSetProgress('clear');
      return;
    }
    if (this.currentIndex === 0) {
      this.viewModel.updatePreviousItemDisplay(null);
    } else {
      this.viewModel.updatePreviousItemDisplay('Previous Set');
    }
    if (this.currentIndex === this.list.length - 1) {
      this.viewModel.updateNextItemDisplay(null);
    } else {
      this.viewModel.updateNextItemDisplay('Next Set');
    }
    this.viewModel.updateSetProgress(this.currentIndex, this.list.length);
  }
}
