import * as React from 'react';
import { MaximumNoteLength, MaximumWorkoutNameLength } from '../../../../ApplicationConfiguration';
import { Workout } from '../../../../Models/Workout';
import { ILocalizedComponentProps, LocalizedPureComponent } from '../../../LocalizedComponent';
import { ValueValidator } from '../../Helpers/ValueValidator';
import { DateTimePicker } from '../EditObject/DateTimePicker';
import { FormField } from '../EditObject/FormField';
import { FormTextArea } from '../EditObject/FormTextArea';
import { SectionWrapper } from '../EditObject/SectionWrapper';
import { INewWorkoutData } from './INewWorkoutData';

export interface INewWorkoutSectionProps extends ILocalizedComponentProps {
  name: string;
  plannedDate: string;
  review: string;
  instructions: string;
  copiedWorkout: Workout | null;
  updateData: (propName: keyof INewWorkoutData, newValue: string) => void;
  updateValidity: (newValue: boolean) => void;
}

export interface INewWorkoutSectionState {}

export const MAXIMUM_NAME_LENGTH = MaximumWorkoutNameLength;
export const MAXIMUM_NOTE_LENGTH = MaximumNoteLength;

export class NewWorkoutSection extends LocalizedPureComponent<
  INewWorkoutSectionProps,
  INewWorkoutSectionState
> {
  private nameValidator = ValueValidator.lengthValidator(
    1,
    MAXIMUM_NAME_LENGTH,
    () => this.props.name,
  );
  private instructionsValidator = ValueValidator.lengthValidator(
    0,
    MAXIMUM_NOTE_LENGTH,
    () => this.props.instructions,
  );
  private reviewValidator = ValueValidator.lengthValidator(
    0,
    MAXIMUM_NOTE_LENGTH,
    () => this.props.review,
  );

  public render(): JSX.Element {
    return (
      <div>
        {this.props.copiedWorkout && this.renderCopiedWorkout()}
        <SectionWrapper iconClass="workout-name-icon">
          <FormField
            isValid={this.nameValidator.isValid()}
            value={this.props.name}
            propertyName="name"
            modelName="workout"
            labelPath="object.workout.name"
            placeholder=""
            updateAction={this.updateName}
            valueError={this.nameValidator.error()}
          />
        </SectionWrapper>
        <SectionWrapper iconClass="workout-date-icon">
          <DateTimePicker value={this.props.plannedDate} onChange={this.updateDate} />
        </SectionWrapper>
        <SectionWrapper iconClass="instruction-icon">
          <FormTextArea
            value={this.props.instructions}
            valueError={this.instructionsValidator.error()}
            isValid={this.instructionsValidator.isValid()}
            updateAction={this.updateInstructions}
            placeholder=""
            labelPath="object.workout.instructions"
            modelName="workout"
            propertyName="instructions"
          />
        </SectionWrapper>
        <SectionWrapper iconClass="workout-note-icon">
          <FormTextArea
            value={this.props.review}
            valueError={this.reviewValidator.error()}
            isValid={this.reviewValidator.isValid()}
            updateAction={this.updateReview}
            placeholder=""
            labelPath="object.workout.note_content"
            modelName="workout"
            propertyName="review"
          />
        </SectionWrapper>
      </div>
    );
  }

  public componentDidUpdate(
    prevProps: Readonly<INewWorkoutSectionProps>,
    prevState: Readonly<INewWorkoutSectionState>,
    prevContext: any,
  ): void {
    this.props.updateValidity(
      this.nameValidator.isValid() &&
        this.reviewValidator.isValid() &&
        this.instructionsValidator.isValid(),
    );
  }

  private renderCopiedWorkout(): JSX.Element {
    return (
      <SectionWrapper iconClass="workout-copy-icon">
        <label>{this.localize('new_workout.copied_workout')}</label>
        {this.props.copiedWorkout.name} on{' '}
        {this.renderTimeStamp(this.props.copiedWorkout.planned_date)}
      </SectionWrapper>
    );
  }

  private renderTimeStamp(date: string): string {
    return moment(date).format('YYYY-MM-DD HH:MM');
  }

  private updateName = (newValue: string) => {
    this.props.updateData('name', newValue);
  };

  private updateDate = (newValue: string) => {
    this.props.updateData('plannedDate', newValue);
  };

  private updateInstructions = (newValue: string) => {
    this.props.updateData('instructions', newValue);
  };

  private updateReview = (newValue: string) => {
    this.props.updateData('review', newValue);
  };
}
