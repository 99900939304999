import * as _ from 'underscore';
import { TargetValueValidator } from '../../../Services/TargetValueValidator';

export interface IValueValidator {
  isValid(): boolean;
  error(): string;
}

export const durationRegex = /^ *((?:\d*\.?\d*[hms] *)|\d*:?\d*:?\d*\.?\d*|(?:\d+h)?(?:\d+m)?(?:\d+s)?|(\d+m\d+)) *$/i;
export const tempoRegex = /^ *(\d+|X) *$/i;
export const weightRegex = /^ *\d*\.?\d* *(kgs|kg|#|lbs|st|LBS)?\.* *$/i;

/**
 * The ValueValidator type is a small class that encapsulates validation logic and error string handling.
 */
export class ValueValidator<T> implements IValueValidator {
  private validationFunction: (value: T) => boolean;
  private errorString: string;
  private valueFunction: () => T;

  /**
   * Constructs the validator instance.
   * @param validationFunction - The function used to validate the value.
   * @param errorString - The error string to be returned if the value is not valid.
   * @param valueFunction - The value function used to receive the value.
   */
  public constructor(
    validationFunction: (value: T) => boolean,
    errorString: string,
    valueFunction: () => T,
  ) {
    this.validationFunction = validationFunction;
    this.valueFunction = valueFunction;
    this.errorString = errorString;
  }

  public static weightValidator(
    valueFunction: () => string,
    errorString?: string,
  ): ValueValidator<string> {
    const validFunc = (value: string) => {
      return _.isEmpty(value) || weightRegex.test(value);
    };
    errorString =
      errorString ||
      'Enter the weight of the bar. It must be higher than zero and in kilograms or pounds';
    return new ValueValidator(validFunc, errorString, valueFunction);
  }

  public static durationValidator(
    valueFunction: () => string,
    errorString?: string,
  ): ValueValidator<string> {
    const validFunc = (value: string) => durationRegex.test(value);
    errorString = errorString || 'Must be a valid duration. 1m30s, 1m30, 1:30, 90 are all valid.';
    return new ValueValidator<string>(validFunc, errorString, valueFunction);
  }

  public static rangeValidator(
    min: number,
    max: number,
    valueFunction: () => number,
    errorString?: string,
  ): ValueValidator<number> {
    const validFunc = (value: number) => value >= min && value <= max;
    errorString = errorString || `Value must be between ${min} and ${max}.`;
    return new ValueValidator<number>(validFunc, errorString, valueFunction);
  }

  public static lengthValidator(
    min: number,
    max: number,
    valueFunction: () => string,
    errorString?: string,
  ): ValueValidator<string> {
    const validFunc = (value: string) => {
      value = value || '';
      return value.length >= min && value.length <= max;
    };
    errorString = errorString || `Must be between ${min} and ${max} characters long.`;
    return new ValueValidator<string>(validFunc, errorString, valueFunction);
  }

  public static intensityValueValidator(
    valueFunction: () => string,
    errorString?: string,
  ): ValueValidator<string> {
    const regexp = /^ *((?:-?\d*\.?\d* *(?:\S+)*)|(?:\d+\.?\d*[hms])+|((?:\d{1,2}:){0,2}\d{0,2}(?:\.\d*)?)) *$/;
    const validFunc = (v: string) => regexp.test(v);
    errorString = errorString || 'Must be a numeric value followed by a unit.';
    return new ValueValidator<string>(validFunc, errorString, valueFunction);
  }

  public static tempoValueValidator(
    valueFunction: () => string,
    errorString?: string,
  ): ValueValidator<string> {
    const validFunc = (v: string) => {
      return _.isEmpty(v) || tempoRegex.test(v);
    };
    errorString = errorString || 'Must be a duration in whole seconds or X for explosive';
    return new ValueValidator(validFunc, errorString, valueFunction);
  }

  public static targetValueValidator(
    valueFunction: () => string,
    errorString?: string,
  ): ValueValidator<string> {
    const validFunc = (v: string) => TargetValueValidator.isValidString(v);
    errorString = errorString || 'Must be a valid target value.';
    return new ValueValidator(validFunc, errorString, valueFunction);
  }

  public isValid(newValue?: T): boolean {
    const evalValue = newValue || this.valueFunction();
    return this.validationFunction(evalValue);
  }

  public error(newValue?: T): string {
    return this.isValid(newValue) ? '' : this.errorString;
  }
}
