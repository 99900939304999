import * as React from 'react';
import { Workout } from '../../../../Models/Workout';
import { INewWorkoutViewModel } from '../../../../ViewModels/Panels/INewWorkoutViewModel';
import { ILocalizedComponentProps, LocalizedPureComponent } from '../../../LocalizedComponent';
import { ConfirmSection } from '../EditObject/ConfirmSection';
import { ConfirmStatus } from '../EditObject/ConfirmStatus';
import { INewWorkoutData } from './INewWorkoutData';
import { NewWorkoutGenerator } from './NewWorkoutGenerator';
import { NewWorkoutSection } from './NewWorkoutSection';

export interface INewWorkoutFormProps extends ILocalizedComponentProps {
  copiedWorkout: Workout | null;
}

export interface INewWorkoutFormActionProps {
  viewModel: INewWorkoutViewModel;
}

export interface INewWorkoutFormState extends INewWorkoutData {
  isValid: boolean;
  transactionInProgress: boolean;
}

export class NewWorkoutForm extends LocalizedPureComponent<
  INewWorkoutFormProps & INewWorkoutFormActionProps,
  INewWorkoutFormState
> {
  private dismounted: boolean = false;

  public constructor(props: INewWorkoutFormProps & INewWorkoutFormActionProps) {
    super(props);
    const newWorkoutData = this.generateNewWorkout(this.props.copiedWorkout);
    this.state = {
      isValid: true,
      ...newWorkoutData,
      transactionInProgress: false,
    };
  }

  private get confirmLabelPath(): string {
    return this.props.copiedWorkout ? 'new_workout.submit_copy' : 'new_workout.submit_new';
  }

  private get buttonStatus(): ConfirmStatus {
    if (this.state.transactionInProgress === true) {
      return ConfirmStatus.Pending;
    }
    return this.state.isValid ? ConfirmStatus.Valid : ConfirmStatus.Invalid;
  }

  public render(): JSX.Element {
    return (
      <form id="new-workout-form">
        <NewWorkoutSection
          copiedWorkout={this.props.copiedWorkout}
          review={this.state.review}
          instructions={this.state.instructions}
          plannedDate={this.state.plannedDate}
          updateData={this.updateData}
          updateValidity={this.updateValidity}
          name={this.state.name}
        />
        <ConfirmSection
          status={this.buttonStatus}
          onSubmitAction={this.handleSubmit}
          confirmLabelPath={this.confirmLabelPath}
        />
      </form>
    );
  }

  public componentWillUnmount(): void {
    this.dismounted = true;
  }

  private handleSubmit = (): void => {
    if (this.state.isValid) {
      this.setState({ transactionInProgress: true });
      const promise = this.props.viewModel.handleNewWorkoutRequest({
        instructions: this.state.instructions,
        name: this.state.name,
        note_content: this.state.review,
        planned_date: this.state.plannedDate,
        status: 'planned',
      });
      promise.always(() => {
        if (!this.dismounted) {
          this.setState({ transactionInProgress: false });
        }
      });
    }
  };

  private updateData = (propName: keyof INewWorkoutData, newValue: string): void => {
    const updateObj = {};
    (updateObj as any)[propName] = newValue;
    this.setState(updateObj);
  };

  private updateValidity = (newValue: boolean): void => {
    this.setState({ isValid: newValue });
  };

  // TODO Move to View model
  private generateNewWorkout(copiedWorkout: Workout | null): INewWorkoutData {
    return NewWorkoutGenerator.generateNewWorkout(copiedWorkout);
  }
}
