import * as log from 'loglevel';
import * as _ from 'underscore';
import { IExerciseSetHistory } from '../../../../Models/History/IExerciseSetHistory';
import { IPerformanceSpecification } from '../../../../Models/IPerformanceSpecification';
import { IExerciseSetFormatted } from './IExerciseSetFormatted';
import { IExerciseSetFormatter } from './IExerciseSetFormatter';
import { IPerformanceSpecificationFormatted } from './IPerformanceSpecificationFormatted';

/**
 * The ExerciseSetFormatter is responsible for formatting exercise sets in preparation to be rendered by a template.
 */
export class ExerciseSetFormatter implements IExerciseSetFormatter {
  public formatUnit(specification: IPerformanceSpecification): string {
    switch (specification.unit_kind) {
      case 'counting':
        return 'Count/Reps';
      case 'time':
        return 'Duration';
      default:
        return specification.unit_entry;
    }
  }

  public formatSpecification(
    specification: IPerformanceSpecification,
  ): IPerformanceSpecificationFormatted {
    return {
      actual: this.formatActual(specification),
      target: this.formatTarget(specification),
      unit: this.formatUnit(specification),
    };
  }

  public formatExerciseSet(set: IExerciseSetHistory): IExerciseSetFormatted {
    return {
      configurations: set.configurations,
      difficulty: set.difficulty,
      id: set.id,
      performanceSpecifications: this.formatSpecifications(set.performance_specifications),
      status: set.status,
    };
  }

  private formatSpecifications(
    specs: IPerformanceSpecification[],
  ): IPerformanceSpecificationFormatted[] {
    return _.map(
      specs,
      (spec: IPerformanceSpecification): IPerformanceSpecificationFormatted => {
        return this.formatSpecification(spec);
      },
    );
  }

  /**
   * Converts durations (in seconds) to human readable format.
   * @param duration
   */
  private formatDuration(duration: number): string {
    const dur = moment.duration(duration, 'seconds');
    const formatOptions: moment.IDurationFormatOptions = {};
    const fractionUnderMinute = 'm:ss.S';
    const fractionOverMinute = 'h:mm:ss.S';
    const wholeUnderMinute = 'm:ss';
    const wholeOverMinute = 'h:mm:ss';
    const hasMinutes = dur.minutes() > 0;
    formatOptions.trim = hasMinutes ? 'left' : false;
    // tslint:disable-next-line:prefer-conditional-expression
    if (dur.milliseconds() > 0) {
      formatOptions.template = hasMinutes ? fractionOverMinute : fractionUnderMinute;
    } else {
      formatOptions.template = hasMinutes ? wholeOverMinute : wholeUnderMinute;
    }
    return dur.format(formatOptions);
  }

  private getFormatFunction(specification: IPerformanceSpecification): (value: number) => string {
    switch (specification.unit_kind) {
      case 'time':
        return (value: number) => {
          if (_.isNumber(value)) {
            return this.formatDuration(value);
          } else {
            return '';
          }
        };
      default:
        return (value: number) => {
          if (_.isNumber(value)) {
            return value.toString();
          } else {
            return '';
          }
        };
    }
  }

  private formatTarget(specification: IPerformanceSpecification): string {
    const formatFunc = this.getFormatFunction(specification);
    const targetValue = formatFunc(specification.target_value);
    switch (specification.target_type) {
      case 'range':
        const lowerValue = formatFunc(specification.target_lower_value);
        return `${lowerValue} - ${targetValue}`;
      case 'greater_than':
        return `≥ ${targetValue}`;
      case 'less_than':
        return `≤ ${targetValue}`;
      case 'minimize':
        return 'Minimize';
      case 'maximize':
        return 'Maximize';
      case 'specific':
        return targetValue;
      default:
        log.error(`Unexpected Format Target Type ${specification.target_type}`);
        return '';
    }
  }

  private formatActual(specification: IPerformanceSpecification): string {
    if (_.isNull(specification.actual_value)) {
      return '';
    }
    return this.getFormatFunction(specification)(specification.actual_value);
  }
}
