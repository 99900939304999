import * as React from 'react';
import { Exercise } from '../../../../Models/Exercise';
import { ExerciseDefinition } from '../../../../Models/ExerciseDefinition';
import { ExerciseSet } from '../../../../Models/ExerciseSet';
import { Model } from '../../../../Models/Model';
import { Workout } from '../../../../Models/Workout';
import {
  IFormChangeData,
  IObjectPanelViewModel,
} from '../../../../ViewModels/Panels/IObjectPanelViewModel';
import { ILocalizedComponentProps, LocalizedComponent } from '../../../LocalizedComponent';
import { ConfirmSection } from './ConfirmSection';
import { ConfirmStatus } from './ConfirmStatus';
import { ExerciseSection } from './Exercise/ExerciseSection';
import { DefinitionSection } from './ExerciseDefinition/DefinitionSection';
import { ExerciseSetSection } from './ExerciseSet/ExerciseSetSection';
import { IContextRecords } from './IContextRecords';
import { WorkoutSection } from './Workout/WorkoutSection';

export interface IObjectFormProps extends ILocalizedComponentProps {
  model: Model;
  contextRecords: IContextRecords;
}

export interface IObjectFormActionProps {
  viewModel: IObjectPanelViewModel;
}

export interface IObjectFormState {
  isConfirmable: boolean;
  transactionInProgress: boolean;
}

/**
 * The ObjectForm is a thin wrapper that links the confirm section to the relevant section for the model provided.
 * Most of the form activity takes place within the {@link ObjectSection} concrete class for the model type, with the
 * ObjectSection being responsible for deciding whether the form is valid or not.
 */
export class ObjectForm extends LocalizedComponent<
  IObjectFormProps & IObjectFormActionProps,
  IObjectFormState
> {
  private formData: IFormChangeData[] = [];
  private dismounted: boolean = false;

  public constructor(props: IObjectFormProps & IObjectFormActionProps) {
    super(props);
    this.state = {
      isConfirmable: true,
      transactionInProgress: false,
    };
  }

  private get buttonStatus(): ConfirmStatus {
    if (this.state.transactionInProgress === true) {
      return ConfirmStatus.Pending;
    }
    return this.state.isConfirmable ? ConfirmStatus.Valid : ConfirmStatus.Invalid;
  }

  public render(): JSX.Element {
    return (
      <form id="object-form">
        {this.objectSection()}
        <ConfirmSection status={this.buttonStatus} onSubmitAction={this.handleSubmit} />
      </form>
    );
  }

  public componentWillUnmount(): void {
    this.dismounted = true;
  }

  private handleSubmit = (): void => {
    if (this.state.isConfirmable === true) {
      this.setState({ transactionInProgress: true });
      const promise = this.props.viewModel.applyChanges(this.formData);
      promise.always(() => {
        if (!this.dismounted) {
          this.setState({ transactionInProgress: false });
        }
      });
    }
  };

  private objectSection(): JSX.Element {
    if (this.props.model instanceof ExerciseDefinition) {
      return (
        <DefinitionSection
          model={this.props.model}
          updateValidity={this.updateValidity}
          updateFormData={this.updateFormData}
        />
      );
    }
    if (this.props.model instanceof ExerciseSet) {
      return (
        <ExerciseSetSection
          updateValidity={this.updateValidity}
          updateFormData={this.updateFormData}
          model={this.props.model}
          showRestPeriod={this.props.model.setGroup().siblings().length > 1}
        />
      );
    }
    if (this.props.model instanceof Exercise) {
      return (
        <ExerciseSection
          model={this.props.model}
          updateValidity={this.updateValidity}
          updateFormData={this.updateFormData}
          showBarWeight={this.props.model.exercise_definition.category === 'strength'}
          showRestBetweenExercises={this.props.model.exerciseGroup().shouldShowRestExercise()}
          showRestBetweenSets={this.props.model.exerciseGroup().shouldShowRestSet()}
        />
      );
    }
    if (this.props.model instanceof Workout) {
      return (
        <WorkoutSection
          updateValidity={this.updateValidity}
          updateFormData={this.updateFormData}
          model={this.props.model}
        />
      );
    }
  }

  private updateValidity = (newValue: boolean): void => {
    this.setState({ isConfirmable: newValue });
  };

  private updateFormData = (newValues: IFormChangeData[]): void => {
    this.formData = newValues;
  };
}
