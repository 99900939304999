import * as React from 'react';
import { ILocalizedComponentProps, LocalizedPureComponent } from '../../../LocalizedComponent';
import { INavigationSectionData } from './INavigationSectionData';

export interface INavigationSectionDataProps extends INavigationSectionData {}

export interface INavigationSectionProps extends ILocalizedComponentProps {
  data: INavigationSectionDataProps;
  onNextClick(): void;
  onPreviousClick(): void;
}

export interface INavigationSectionState {}

const enabledButton: React.CSSProperties = {
  opacity: 1,
  pointerEvents: 'auto',
};

const disabledButton: React.CSSProperties = {
  opacity: 0,
  pointerEvents: 'none',
};

export class NavigationSection extends LocalizedPureComponent<
  INavigationSectionProps,
  INavigationSectionState
> {
  public render(): JSX.Element {
    return (
      <section className="sv-navigation">
        <button
          className="xbtn xbtn-primary sv-previous-button"
          style={this.previousButtonStyle()}
          onClick={this.handlePreviousClick}
        >
          {this.props.data.previousDisplay}
        </button>
        {this.renderProgressSection()}
        <button
          className="xbtn xbtn-primary sv-next-button"
          style={this.nextButtonStyle()}
          onClick={this.handleNextClick}
        >
          {this.props.data.nextDisplay}
        </button>
      </section>
    );
  }

  private renderProgressSection(): JSX.Element {
    if (
      typeof this.props.data.currentPosition === 'number' &&
      typeof this.props.data.numberOfSets === 'number'
    ) {
      return <div className="sv-progress-text">{this.renderProgressIndicator()}</div>;
    }
    return <div className="sv-progress-text" style={{ opacity: 0 }} />;
  }

  private handleNextClick = (): void => {
    this.props.onNextClick();
  };
  private handlePreviousClick = (): void => {
    this.props.onPreviousClick();
  };

  private renderProgressIndicator(): string {
    return `${this.props.data.currentPosition + 1} / ${this.props.data.numberOfSets}`;
  }

  private previousButtonStyle() {
    return this.props.data.previousDisplay ? enabledButton : disabledButton;
  }

  private nextButtonStyle() {
    return this.props.data.nextDisplay ? enabledButton : disabledButton;
  }
}
