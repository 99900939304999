import { DataLayer } from '../../Data/DataLayer';
import { IDataLayer } from '../../Data/IDataLayer';
import { IPromiseService, PromiseService } from '../../Services/PromiseService';

export type CollectionTypes = ModelType | 'exercise-history' | 'definition-history';

export abstract class Collection<TParent, T> {
  public _baseURL: string;
  public type: CollectionTypes;
  protected _dataLayer: IDataLayer;
  protected _parentObject: TParent;
  protected _promiseService: IPromiseService;
  protected _moreURL: string;

  constructor(
    baseUrl: string,
    dataLayer: DataLayer,
    parentObject: TParent,
    type: CollectionTypes,
    promiseService: PromiseService,
  ) {
    this._baseURL = baseUrl;
    this._dataLayer = dataLayer;
    this._parentObject = parentObject;
    this.type = type;
    this._promiseService = promiseService;
    if (typeof this._baseURL !== 'string') {
      throw new Error('Collection:BaseURL is not a string');
    }
    this._moreURL = this._baseURL;
  }

  /**
   * Loads first set or more memebrs. See {@link Collection.more}.
   * @returns {JQueryPromise<T[]>}
   */
  public load(): JQueryPromise<T[]> {
    return this.more();
  }

  /**
   * Returns a promise resolving with all of the currently loaded members.
   * If none are loaded, returns a promise resolving with the first set if applicable.
   * @returns {any} Promise resolving with the initial set of members.
   */
  public all(): JQueryPromise<T[]> {
    if (this.initialDataLoaded()) {
      return this._promiseService.emptyPromise().resolve(this.members());
    } else {
      return this.more();
    }
  }

  public isMoreAvailable(): boolean {
    return this._moreURL != null;
  }

  public initialDataLoaded(): boolean {
    return this._moreURL !== this._baseURL;
  }

  /**
   * Loads more members.
   * Subclasses should override with applicable loading logic.
   */
  public abstract more(): JQueryPromise<T[]>;

  /**
   * Reloads the collection.
   * Subclasses should override with applicable reloading logic.
   */
  public abstract reload(): JQueryPromise<T[]>;

  /**
   * Returns the currently loaded members.
   */
  public abstract members(): T[];

  protected abstract updateCollection(collectionSet: any): void;
}
