/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Vendor Extensions and Imports
import 'moment-duration-format';
import RunExtensions from '../application/Extension/index';

RunExtensions();

// ApplicationService is the root object.
import { ApplicationService } from '../application/Core/ApplicationService';
import { CarouselManager } from '../application/home/CarouselManager';
import { UserProfileController } from '../application/Views/UserProfile/UserProfileController';
// noinspection JSUnusedGlobalSymbols
export const AppService = ApplicationService;
// noinspection JSUnusedGlobalSymbols
export const UserProfile = UserProfileController;
// noinspection JSUnusedGlobalSymbols
export const Carousel = CarouselManager;

declare const process: {
  env: {
    NODE_ENV: string;
  };
};

// Uncomment Below to Implement React Performance Logging
//
// import * as ReactPerf from 'react-addons-perf';
// // noinspection JSUnusedGlobalSymbols
// export const Perf = ReactPerf;

// Uncomment for detecting avoidable re-renders.
// import * as React from 'react';
//
// declare function require(name: string);
//
// if (process.env.NODE_ENV !== 'production') {
//     const {whyDidYouUpdate} = require('why-did-you-update');
//     whyDidYouUpdate(React, {exclude: /^Velocity/});
// }
