import * as React from 'react';
import { IPerformanceSpecification } from '../../../../Models/IPerformanceSpecification';
import { ILocalizedComponentProps, LocalizedPureComponent } from '../../../LocalizedComponent';
import { IncrementerControl } from './IncrementerControl';
import { IncrementTypes } from './IncrementTypes';
import { SpecificationsList } from './SpecificationsList';
import './SpecificationsSection.scss';

export interface ISpecificationsSectionProps extends ILocalizedComponentProps {
  specifications: IPerformanceSpecification[];
  selectedIndex: number;
  selectedValue: 'actual' | 'target' | null;
  onSelect: (index: number, value: 'actual' | 'target') => void;
  isActualSelectable: boolean;
  incrementerValue: string | number;
  onIncrementerChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onIncrementerAction: (incrementAction: IncrementTypes) => void;
  onIncrementerBlur: () => void;
  canDecrement: boolean;
  canIncrement: boolean;
  specificationControlLabel: string;
}

export interface ISpecificationsSectionState {}

const specificationContainerClass = 'sv-specifications-editor';
const specificationLabelClass = 'sv-specification-name';
const specificationInputClass = 'sv-specification-value';
const sectionTitlePath = 'object.exercise_set.performance_specifications';

export class SpecificationsSection extends LocalizedPureComponent<
  ISpecificationsSectionProps,
  ISpecificationsSectionState
> {
  public render(): JSX.Element {
    return (
      <div className="msv-section msvSpecificationsSection">
        <div className="msvSectionTitle">{this.localize(sectionTitlePath)}</div>
        <SpecificationsList
          specifications={this.props.specifications}
          selectedIndex={this.props.selectedIndex}
          selectedValue={this.props.selectedValue}
          onSelect={this.props.onSelect}
          actualsSelectable={this.props.isActualSelectable}
        />
        <IncrementerControl
          currentValue={this.props.incrementerValue}
          onChange={this.props.onIncrementerChange}
          onIncrementClick={this.props.onIncrementerAction}
          decrementPermitted={this.props.canDecrement}
          isEnabled={this.props.selectedValue != null}
          incrementPermitted={this.props.canIncrement}
          inputClass={specificationInputClass}
          labelClass={specificationLabelClass}
          containerClass={specificationContainerClass}
          label={this.props.specificationControlLabel}
          onBlurOrSubmit={this.props.onIncrementerBlur}
        />
      </div>
    );
  }
}
