export enum PanelTypes {
  Workout,
  WorkoutList,
  NewWorkout,
  DefinitionList,
  DefinitionSelection,
  History,
  Guide,
  Object,
}
