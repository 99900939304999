import * as React from 'react';
import { ILocalizedComponentProps, LocalizedPureComponent } from '../../../LocalizedComponent';
import { CategoryElement } from '../Shared/CategoryElement';

export interface IDefinitionAttributesProps extends ILocalizedComponentProps {
  category: DefinitionCategory;
  implement: string;
  sided: boolean;
}

export interface IDefinitionAttributesState {}

export class DefinitionAttributes extends LocalizedPureComponent<
  IDefinitionAttributesProps,
  IDefinitionAttributesState
> {
  public render(): JSX.Element {
    return (
      <div className="sv-horizontal-group sv-definition-attributes msv-section">
        <div>
          <CategoryElement category={this.props.category} />
        </div>
        <div>
          <div className="sv-definition-implement">{this.getImplementTitle()}</div>
          <span className="sv-label-text">
            {this.localize('object.exercise_definition.implement')}
          </span>
        </div>
        <div>
          <div className="sv-definition-sided">{this.getSidedTitle()}</div>
          <span className="sv-label-text">{this.localize('object.exercise_definition.sided')}</span>
        </div>
      </div>
    );
  }

  private getImplementTitle(): string {
    const config: any = this.localize('configuration.exercise_definitions.implements');
    return config[this.props.implement].title;
  }

  private getSidedTitle(): string {
    const config: any = this.localize('configuration.exercise_definitions.sided');
    return this.props.sided ? config.true.title : config.false.title;
  }
}
