import { DataCollection } from './Collections/DataCollection';
import { IExerciseDefinition } from './IExerciseDefinition';
import { Model } from './Model';

export class ExerciseDefinition extends Model implements IExerciseDefinition {
  public note_content: string;
  public name: string;
  public exercise_definition_uuid: string;
  public category: DefinitionCategory;
  public implement: string;
  public sided: boolean;
  public links: {
    self: string;
    history: string;
  };

  public user_id: number;
  public updated_at: string;
  public created_at: string;
  private _historyCollection: any;

  public history(): DataCollection<ExerciseDefinition, any> {
    let _ref;
    // tslint:disable-next-line:no-conditional-assignment
    return (_ref = this._historyCollection) != null
      ? _ref
      : (this._historyCollection = new DataCollection(
          this.links.history,
          this.dataStore(),
          this,
          'definition-history',
          this.promiseService(),
        ));
  }

  public canBeDeleted() {
    const promise = this.promiseService().emptyPromise();
    this.history()
      .reload()
      .done(resultSet => {
        if (resultSet.length === 0) {
          return promise.resolve();
        } else {
          return promise.reject();
        }
      });
    return promise;
  }

  public isDeletePermitted(): JQueryPromise<boolean> {
    const promise = this.promiseService().emptyPromise();
    const historyPromise = this.history().reload();
    historyPromise.done(function(resultSet) {
      return promise.resolve(resultSet.length === 0);
    });
    historyPromise.fail(function() {
      return promise.reject();
    });
    return promise;
  }
}
