import * as React from 'react';

export interface ISelectionEntryProps {
  id: string;
  category: string;
  name: string;
  previouslyUsed: boolean;
  currentlySelected: boolean;
  selectionEvent: (newId: string) => void;
}

export interface ISelectionEntryState {}

export class SelectionEntry extends React.PureComponent<
  ISelectionEntryProps,
  ISelectionEntryState
> {
  public render(): JSX.Element {
    let classes =
      'exercise-definition-selection-item exercise_definition exercise-definition-format';
    if (this.props.currentlySelected) {
      classes = classes + ' selected-definition';
    }
    return (
      <li
        className={classes}
        data-object-id={this.props.id}
        data-record="exercise_definition"
        data-category={this.props.category}
        onClick={() => this.props.selectionEvent(this.props.id)}
      >
        <div className="ed-status-header">
          <div className="difficulty">
            <i />
            <span />
          </div>
        </div>
        <div className="ed-primary-header">
          <div className="definition-name">
            {this.props.previouslyUsed && <i className="definition-ownership" />}
            {this.props.name}
          </div>
        </div>
        <div className="ed-second-header ed-select-exercise">
          <div className="content">
            <i />
            <span />
          </div>
        </div>
      </li>
    );
  }
}
