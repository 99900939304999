import * as React from 'react';
import { IExerciseDefinition } from '../../Models/IExerciseDefinition';
import { ILocalizedComponentProps, LocalizedPureComponent } from '../LocalizedComponent';
import { IWorkoutCopyFormDelegate } from './IWorkoutCopyFormDelegate';
import { MapResult } from './MapResult';
import { WorkoutCopyDialog } from './WorkoutCopyDialog';

export enum CopyState {
  NotStarted,
  InProgress,
  Complete,
}

export interface IWorkoutCopyContainerProps extends ILocalizedComponentProps {
  workoutName: string;
  workoutDate: string;
  mappingRequired: MapResult[];
  searchResults: IExerciseDefinition[];
  searchEntry: string;
  copyState: CopyState;
  delegate: IWorkoutCopyFormDelegate;
  selectedMapping: MapResult;
  selectedDefinition: IExerciseDefinition | null;
}

export interface IWorkoutCopyContainerState {}

const START_COPY_TEXT_PATH = 'workout_links.show.start_copy_text';
const START_COPY_BUTTON_LABEL_PATH = 'workout_links.show.start_copy_button';

export class WorkoutCopyContainer extends LocalizedPureComponent<
  IWorkoutCopyContainerProps,
  IWorkoutCopyContainerState
> {
  public render(): JSX.Element {
    switch (this.props.copyState) {
      case CopyState.NotStarted:
        return this.renderNotStarted();
      case CopyState.InProgress:
        return (
          <WorkoutCopyDialog
            workoutName={this.props.workoutName}
            workoutDate={this.props.workoutDate}
            selectedMapping={this.props.selectedMapping}
            searchEntry={this.props.searchEntry}
            selectedDefinition={this.props.selectedDefinition}
            delegate={this.props.delegate}
            mappingRequired={this.props.mappingRequired}
            searchResults={this.props.searchResults}
          />
        );
      case CopyState.Complete:
      default:
        throw new Error("I Don't know what to do here");
    }
  }

  private handleStartCopyClick = () => {
    this.props.delegate.startCopying();
  };

  private renderNotStarted(): JSX.Element {
    return (
      <div className="copy-section" id="copySection">
        <p className="text-center">{this.localize(START_COPY_TEXT_PATH)}</p>
        <p className="text-center">
          <button onClick={this.handleStartCopyClick} className="xbtn xbtn-primary workout-copy">
            {this.localize(START_COPY_BUTTON_LABEL_PATH)}
          </button>
        </p>
      </div>
    );
  }
}
