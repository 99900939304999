import * as React from 'react';
import { ILocalizedComponentProps, LocalizedPureComponent } from '../../../LocalizedComponent';

export interface ICategoryElementProps extends ILocalizedComponentProps {
  category: DefinitionCategory;
}

export interface ICategoryElementState {}

const CategoryGroupPath = 'configuration.exercise_definitions.categories';
const CategoryLabelPath = 'object.exercise_definition.category';

export class CategoryElement extends LocalizedPureComponent<
  ICategoryElementProps,
  ICategoryElementState
> {
  public render(): JSX.Element {
    const iconClass = `category-${this.props.category}-icon`;
    return (
      <div>
        <div className="sv-definition-category-container">
          <i className={iconClass} />
          <span className="sv-definition-category">{this.getTitle()}</span>
        </div>
        <span className="sv-label-text">{this.localize(CategoryLabelPath)}</span>
      </div>
    );
  }

  private getTitle(): string {
    const value = this.context.getValue(CategoryGroupPath);
    return (value as any)[this.props.category].title;
  }
}
