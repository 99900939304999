import * as React from 'react';
import { ExerciseGroup } from '../../../../Models/ExerciseGroup';

export interface IExerciseGroupEntryProps {
  exerciseGroup: ExerciseGroup;
  /**
   * Indicates whether this is the exercise group being moved.
   */
  isBeingMoved: boolean;
  /**
   * Handles request to move to this group. Will not be called if this group is being moved.
   * @param {ExerciseGroup} exerciseGroup
   */
  moveToAction: (exerciseGroup: ExerciseGroup) => void;
  /**
   * Zero-indexed position value for this group.
   */
  position: number;
}

export interface IExerciseGroupEntryState {}

export class ExerciseGroupEntry extends React.PureComponent<
  IExerciseGroupEntryProps,
  IExerciseGroupEntryState
> {
  public render(): JSX.Element {
    return (
      <li className={this.entryClass} onClick={this.handleClick}>
        <div className="groupMovement-position">{this.props.position + 1}</div>
        <div className="groupMovement-exercises">{this.renderExercises()}</div>
      </li>
    );
  }

  private handleClick = (): void => {
    if (!this.props.isBeingMoved) {
      this.props.moveToAction(this.props.exerciseGroup);
    }
  };

  private get entryClass(): string {
    return this.props.isBeingMoved
      ? 'groupMovement-entry groupMovement-entry-active'
      : 'groupMovement-entry';
  }

  private renderExercises(): JSX.Element[] {
    const exercises = this.props.exerciseGroup.exercises();
    return exercises.map(exercise => {
      return (
        <div className="groupMovement-exercise" key={exercise.id}>
          {exercise.exerciseDefinition().name}
        </div>
      );
    });
  }
}
