import * as React from 'react';
import * as _ from 'underscore';
import { Exercise } from '../../../../Models/Exercise';
import { ExerciseSet } from '../../../../Models/ExerciseSet';
import { IExerciseHistory } from '../../../../Models/History/IExerciseHistory';
import { IPlateItem, IPlateSet } from '../../../../Models/IExerciseSet';
import { IPerformanceSpecification } from '../../../../Models/IPerformanceSpecification';
import { SetGroup } from '../../../../Models/SetGroup';
import convertConfiguration from '../../../Utilities/ConvertConfiguration';
import { ExerciseSetFormatter } from '../../Helpers/History/ExerciseSetFormatter';
import { IConfigurationEntry } from '../../Panels/DefinitionSelection/IConfigurationEntry';
import { HistoryContainer } from '../History/HistoryContainer';
import { PredictionProvider } from '../PredictionProvider';
import * as AnimationDefaults from '../Shared/AnimationDefaults';
import { CommandsSection } from '../Shared/CommandsSection';
import { InstructionsContainer } from '../Shared/InstructionsContainer';
import { RadioGroupControl } from '../Shared/RadioGroupControl';
import { ReadOnlyContainer } from '../Shared/ReadOnlyContainer';
import { ISelectionProps, ISelectionState, Selection } from '../Shared/Selection';
import { Title } from '../Shared/Title';
import { IncrementTypes } from './IncrementTypes';
import { IntensitySection, IPlateData } from './IntensitySection';
import { LastUpdateTimer } from './LastUpdateTimer';
import { SpecificationsSection } from './SpecificationsSection';
import { ValueIncrementer } from './ValueIncrementer';

export type SpecificationSelection = 'actual' | 'target' | null;

export interface IExerciseSetSelectionProps extends ISelectionProps<ExerciseSet> {
  history: IExerciseHistory[];
  setIndex: number | false;
  historyButtonVisible: boolean;
  advancedModeEnabled: boolean;
  isMobile: boolean;
}

export interface IExerciseSetSelectionState extends ISelectionState {
  selectedSpecificationIndex: number | null;
  selectedSpecificationValue: SpecificationSelection;
  specificationInputValue: string;
  intensityInputValue: string;
}

const instructionsLabelPath = 'object.exercise.instructions';
const reminderLabelPath = 'object.exercise_definition.note_content';
const deleteWarningPath = 'mobile_selection_view.delete_set_confirm.warning';
const deleteConfirmPath = 'mobile_selection_view.delete_set_confirm.confirm';
const deleteCancelPath = 'mobile_selection_view.delete_set_confirm.cancel';
const difficultyOptionsPath = 'configuration.exercise_set.difficulty';

interface ISpecificationSelectionState {
  selectedSpecificationIndex: number;
  selectedSpecificationValue: SpecificationSelection;
  specificationInputValue: string;
}

const unselectedState: ISpecificationSelectionState = {
  selectedSpecificationIndex: null,
  selectedSpecificationValue: null,
  specificationInputValue: '',
};

const advancedEnterAnimation = {
  animation: 'transition.slideDownIn',
  display: 'flex',
  duration: AnimationDefaults.defaults.smallAnimationDuration,
};

const advancedLeaveAnimation = {
  animation: 'transition.slideUpOut',
  duration: AnimationDefaults.defaults.smallAnimationDuration,
};

export class ExerciseSetSelection extends Selection<
  ExerciseSet,
  IExerciseSetSelectionProps,
  IExerciseSetSelectionState
> {
  private predictionProvider = new PredictionProvider();
  private formatter = new ExerciseSetFormatter();
  private difficultyOptions: IConfigurationEntry[];
  private specificationInputValue: number;
  private lastStatus: ModelStatus;

  public constructor(props: IExerciseSetSelectionProps) {
    super(props);
    const firstConfig = props.model.configurations[0];
    const inputValue = (firstConfig && firstConfig.value && firstConfig.value.toString()) || '';
    this.state = {
      deleteVisible: false,
      intensityInputValue: inputValue,
      selectedSpecificationIndex: null,
      selectedSpecificationValue: null,
      specificationInputValue: '',
    };
    this.lastStatus = props.model.status;
  }

  public componentWillReceiveProps(
    nextProps: Readonly<IExerciseSetSelectionProps>,
    nextContext: any,
  ): void {
    // State should change only if the props are actually changing.
    if (
      !_.isEqual(nextProps.model, this.props.model) ||
      this.recordUpdatedAt !== nextProps.model.updated_at
    ) {
      const newSpecState = this.newSpecificationState(
        nextProps.model.performance_specifications,
        nextProps.model.status,
      );
      const firstConfig = nextProps.model.configurations[0];
      const inputValue = (firstConfig && firstConfig.value && firstConfig.value.toString()) || '';
      const newResults = _.assign({}, newSpecState, { intensityInputValue: inputValue });
      this.setState(newResults);
      this.lastStatus = nextProps.model.status;
    }
  }

  public render(): JSX.Element {
    if (this.props.model.parent() == null) {
      return null;
    }
    const exerciseName = this.props.model.exercise().exerciseDefinition().name;
    return (
      <div>
        <div className="sv-section">
          <Title labelClass="sv-exercise-name" title={exerciseName} />
        </div>
        <IntensitySection
          currentValue={this.state.intensityInputValue}
          incrementPermitted={this.canIncrementIntensity(IncrementTypes.Increment)}
          decrementPermitted={this.canIncrementIntensity(IncrementTypes.Decrement)}
          onIncrementClick={this.handleIncrementIntensityClick}
          tempoData={this.exercise().tempo}
          onChange={this.handleIntensityChange}
          secondaryConfigurations={this.props.model.secondaryConfigurations()}
          prediction={this.predictionProvider.predictForSet(this.props.model)}
          intensityValueLabel={this.intensityLabel()}
          plateData={this.getPlates()}
          onIncrementerBlur={this.handleIntensityBlur}
        />
        <RadioGroupControl
          options={this.getDifficultyOptions()}
          currentValue={this.props.model.difficulty}
          updateAction={this.setDifficulty}
          groupName="set_group"
          propertyName="difficulty"
        />
        <SpecificationsSection
          specifications={this.props.model.performance_specifications}
          onIncrementerBlur={this.onSpecificationBlur}
          onIncrementerAction={this.onSpecificationIncrementerClick}
          selectedIndex={this.state.selectedSpecificationIndex}
          selectedValue={this.state.selectedSpecificationValue}
          specificationControlLabel={this.getSpecificationLabel()}
          onSelect={this.onSelectSpecification}
          isActualSelectable={this.getIsActualSelectable()}
          incrementerValue={this.state.specificationInputValue}
          canIncrement={this.canIncrementSpecification(IncrementTypes.Increment)}
          canDecrement={this.canIncrementSpecification(IncrementTypes.Decrement)}
          onIncrementerChange={this.onSpecificationIncremeterChange}
        />
        {!_.isEmpty(this.exercise().instructions) && (
          <div className="msv-section">
            <InstructionsContainer
              label={this.localize(instructionsLabelPath)}
              contents={this.exercise().instructions}
              inputClass="sv-exercise-instructions"
            />
          </div>
        )}

        {!_.isEmpty(this.exercise().definition_note) && (
          <div className="msv-section">
            <ReadOnlyContainer
              inputClass="sv-exercise-reminder"
              labelClass="sv-reminder-container"
              label={this.localize(reminderLabelPath)}
              contents={this.exercise().definition_note}
            />
          </div>
        )}

        <CommandsSection
          viewModel={this.props.viewModel}
          deletePromptVisible={this.state.deleteVisible}
          deleteDeniedVisible={false}
          updateDeleteVisibility={this.updateDeleteVisibility}
          executeDelete={this.executeDelete}
          deleteWarning={this.localize(deleteWarningPath)}
          deleteConfirm={this.localize(deleteConfirmPath)}
          deleteCancel={this.localize(deleteCancelPath)}
        >
          <button className="xbtn xbtn-primary sv-copy-set" onClick={this.handleCopySet}>
            {this.localize('mobile_selection_view.copy_set')}
          </button>
          {this.props.isMobile && (
            <button className="xbtn xbtn-primary sv-history" onClick={this.handleHistoryClick}>
              {this.localize('mobile_selection_view.show_history')}
            </button>
          )}
          {this.props.advancedModeEnabled && (
            <button
              className="xbtn xbtn-primary sv-add-segment"
              onClick={this.handleAddSegmentClick}
            >
              {this.localize('mobile_selection_view.add_segment')}
            </button>
          )}
          {this.hasMultipleSegments() && this.props.advancedModeEnabled && (
            <button
              className="xbtn xbtn-primary sv-delete-segment"
              onClick={this.handleDeleteSegmentClick}
            >
              {this.localize('mobile_selection_view.delete_segment')}
            </button>
          )}
        </CommandsSection>
        {this.props.isMobile && this.props.history && (
          <HistoryContainer highlightPosition={this.props.setIndex} history={this.props.history} />
        )}
        <LastUpdateTimer lastUpdatedAt={this.props.model.updated_at} />
      </div>
    );
  }

  private exercise(): Exercise {
    return this.props.model.exercise();
  }

  private setGroup(): SetGroup {
    return this.props.model.setGroup();
  }

  private hasMultipleSegments(): boolean {
    return this.setGroup().children().length > 1;
  }

  // <editor-fold desc="Intensity Section Methods">
  private intensityLabel(): string {
    const firstConfig = this.props.model.configurations[0];
    if (firstConfig == null) {
      return '';
    }
    const intensityName = firstConfig.name || 'Intensity';
    return `${firstConfig.unit_entry} ${intensityName}`.trim();
  }

  private canIncrementIntensity(type: IncrementTypes): boolean {
    const allowed = ValueIncrementer.allowedChanges(this.props.model.configurations[0]);
    return _.contains(allowed, type);
  }

  private handleIncrementIntensityClick = (type: IncrementTypes): void => {
    const newValue = ValueIncrementer.valueForIncrement(this.props.model.configurations[0], type);
    this.props.viewModel.updateConfiguration(0, 'value', newValue);
  };

  private handleIntensityChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ intensityInputValue: event.target.value });
  };

  private handleIntensityBlur = (): void => {
    this.props.changeSpinnerVisibility(true);
    const newValue = parseFloat(this.state.intensityInputValue);
    this.props.viewModel
      .updateConfiguration(0, 'value', newValue)
      .always(() => this.props.changeSpinnerVisibility(false));
  };

  private getPlates(): IPlateData {
    return this.convertPlateDataToPlateValue(this.props.model.plates, this.exercise());
  }

  private convertPlateDataToPlateValue(set: IPlateSet, exercise: Exercise): IPlateData {
    const newObj = _.clone(set);
    if (_.isNull(newObj)) {
      return;
    }
    const plateItems = _.map(newObj, function(value: number, key: string): IPlateItem {
      return { weight: key, count: value };
    });
    const remainder = newObj.remainder;
    delete newObj.remainder;
    return {
      barWeight: exercise.effective_bar_weight,
      plates: plateItems,
      remainder,
    };
  }

  // </editor-fold>

  // <editor-fold desc="Specification Section Methods">

  private newSpecificationState(
    newPerformanceSpecs: IPerformanceSpecification[],
    newStatus: ModelStatus,
  ): ISpecificationSelectionState {
    const newSpec = newPerformanceSpecs[this.state.selectedSpecificationIndex];
    if (newSpec == null) {
      return unselectedState;
    }
    // If Last Status was Planned, New Status is Completed, last Value was Target, set to Actual
    const newSelectionValue = this.isStatusChanging(newStatus)
      ? this.defaultSelectionTargetForStatus(newStatus)
      : this.state.selectedSpecificationValue;
    const newValue = newSelectionValue === 'actual' ? newSpec.actual_value : newSpec.target_value;
    if (_.isNumber(newValue)) {
      return {
        selectedSpecificationIndex: this.state.selectedSpecificationIndex,
        selectedSpecificationValue: newSelectionValue,
        specificationInputValue: newValue.toString(),
      };
    } else {
      return unselectedState;
    }
  }

  private isStatusChanging(newStatus: ModelStatus): boolean {
    return this.lastStatus !== newStatus;
  }

  private defaultSelectionTargetForStatus(status: ModelStatus): SpecificationSelection {
    return status === 'completed' ? 'actual' : 'target';
  }

  private canIncrementSpecification(type: IncrementTypes): boolean {
    const currentSpec = this.currentSpecification();
    if (currentSpec == null) {
      return false;
    }
    const currentValue =
      this.state.selectedSpecificationValue === 'actual'
        ? currentSpec.actual_value
        : currentSpec.target_value;
    if (!_.isNumber(currentValue)) {
      return false;
    }
    return type === IncrementTypes.Increment ? true : currentValue > 1;
  }

  private getSpecificationValue(
    index: number,
    specificationSet?: IPerformanceSpecification[],
    value?: SpecificationSelection,
  ): number {
    const specSet = specificationSet || this.props.model.performance_specifications;
    const currentSpec = specSet[index];
    const specificationValue = value || this.state.selectedSpecificationValue;
    return 'actual' === specificationValue ? currentSpec.actual_value : currentSpec.target_value;
  }

  private getSpecificationLabel(): string {
    const currentSpec = this.currentSpecification();
    if (currentSpec == null) {
      return '';
    }
    const formattedTitle = this.formatter.formatUnit(currentSpec);
    return `${this.state.selectedSpecificationValue} ${formattedTitle}`;
  }

  private getIsActualSelectable(): boolean {
    return this.props.model.status === 'completed';
  }

  private currentSpecification(): IPerformanceSpecification {
    if (this.state.selectedSpecificationIndex == null) {
      return null;
    }
    return this.props.model.performance_specifications[this.state.selectedSpecificationIndex];
  }

  private onSelectSpecification = (index: number, value: SpecificationSelection): void => {
    this.specificationInputValue = this.getSpecificationValue(index, null, value);
    this.setState({
      selectedSpecificationIndex: index,
      selectedSpecificationValue: value,
      specificationInputValue: this.specificationInputValue.toString(),
    });
  };

  private onSpecificationIncrementerClick = (action: IncrementTypes): void => {
    const targetValue =
      this.state.selectedSpecificationValue === 'target' ? 'target_value' : 'actual_value';
    const incrementValue = action === IncrementTypes.Increment ? 'increment' : 'decrement';
    this.props.viewModel.incrementSpecification(
      this.state.selectedSpecificationIndex,
      targetValue,
      incrementValue,
    );
  };

  private onSpecificationIncremeterChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.specificationInputValue = parseFloat(event.target.value);
    this.setState({ specificationInputValue: event.target.value });
  };

  private onSpecificationBlur = (): void => {
    this.props.changeSpinnerVisibility(true);
    const targetProp =
      this.state.selectedSpecificationValue === 'target' ? 'target_value' : 'actual_value';
    const promise = this.props.viewModel.updateSpecification(
      this.state.selectedSpecificationIndex,
      targetProp,
      this.specificationInputValue,
    );
    promise.always(() => this.props.changeSpinnerVisibility(false));
  };

  // </editor-fold>

  private getDifficultyOptions(): IConfigurationEntry[] {
    if (this.difficultyOptions == null) {
      this.difficultyOptions = convertConfiguration(difficultyOptionsPath, this.context);
    }
    return this.difficultyOptions;
  }

  private setDifficulty = (newValue: ModelDifficulty) => {
    this.props.changeSpinnerVisibility(true);
    this.props.viewModel
      .updateDifficulty(newValue)
      .always(() => this.props.changeSpinnerVisibility(false));
  };

  private handleCopySet = (): void => {
    this.overlayPresent = true;
    this.viewModel.copySet().always(() => (this.overlayPresent = false));
  };

  private handleHistoryClick = (): void => {
    this.overlayPresent = true;
    const onfulfilled = () => (this.overlayPresent = false);
    this.viewModel.displayHistoryPanel().then(onfulfilled, onfulfilled);
  };

  private handleAddSegmentClick = (): void => {
    this.overlayPresent = true;
    this.viewModel.addSegment().always(() => (this.overlayPresent = false));
  };

  private handleDeleteSegmentClick = (): void => {
    this.overlayPresent = true;
    this.viewModel.deleteSegment().always(() => (this.overlayPresent = false));
  };
}
