import * as React from 'react';
import * as _ from 'underscore';
import { DefinitionCollection } from '../../../../Models/Collections/DefinitionCollection';
import { ExerciseDefinition } from '../../../../Models/ExerciseDefinition';
import { IDefinitionListViewModel } from '../../../../ViewModels/Panels/IDefinitionListViewModel';
import { AbstractPanelViewContainer } from '../AbstractPanelViewContainer';
import { IPanelExternalData, IPanelInternalData } from '../IPanelData';
import { IPanelHeaderActions } from '../IPanelHeaderActions';
import { IPanelHeaderCategoryFilterState } from '../IPanelHeaderCategoryFilterState';
import { IPanelViewModelProps } from '../IPanelProps';
import { PanelTypes } from '../PanelTypes';
import { IDefinitionListActionProps, IDefinitionListDataProps } from './ExerciseList';

const categories = ['strength', 'flexibility', 'endurance'];

export type ExerciseListContainerProps = IPanelViewModelProps<
  IDefinitionListDataProps & IDefinitionListActionProps,
  IDefinitionListViewModel
>;

interface IExerciseListContainerState {}

export class ExerciseListContainer
  extends AbstractPanelViewContainer<
    IDefinitionListDataProps,
    {},
    IExerciseListContainerState,
    IDefinitionListActionProps
  >
  implements IDefinitionListViewModel {
  private listenerActive = false;
  private searchText: string = '';
  private categoryFilters: IPanelHeaderCategoryFilterState = {
    endurance: false,
    flexibility: false,
    strength: false,
  };

  public static initialPanelData(panelId: string): IPanelExternalData {
    return {
      hasFocus: false,
      hasTransactionOverlay: false,
      id: panelId,
      objectId: null,
      panelType: PanelTypes.DefinitionList,
      parentPanelId: null,
      title: 'Exercises',
    };
  }

  public static initialPanelProps(
    definitionCollection: DefinitionCollection,
  ): IDefinitionListDataProps {
    return {
      collection: definitionCollection,
      definitions: [],
    };
  }

  public componentWillUnmount(): void {
    $(document).off('sr:object:delete:exercise_definition', this.handleDefinitionDelete);
  }

  public componentDidMount(): void {
    super.componentDidMount();
    $(document).on('sr:object:delete:exercise_definition', this.handleDefinitionDelete);
    this.onRefresh();
  }

  public onDismiss(): void {
    this.removePanel();
  }

  public onFilter(newFilter: IPanelHeaderCategoryFilterState): void {
    this.updateFilters(newFilter);
  }

  public onRefresh(): void {
    this.refreshList();
  }

  public onSearch(searchText: string): void {
    this.search(searchText);
  }

  public onDefinitionDelete(): void {
    this.displayDefinitions();
  }

  /*
     Assigns the new definition collection and triggers display changes.
     @param {RecordCollection} newCollection - Collection to be assigned.
     */
  // public setDefinitionCollection(newCollection) {
  //   this.definitionCollection = newCollection;
  //   return this.definitionCollection.more().done(() => {
  //     return this.displayDefinitions();
  //   });
  // }

  public displayDefinitions() {
    const filteredDefinitions = this.filterDefinitions(this.props.props.collection.members());
    const sortedDefinitions = _.sortBy(filteredDefinitions, 'name');
    this.updateContentProps({ definitions: sortedDefinitions });
    if (this.listenerActive === false) {
      this.listenerActive = true;
    }
  }

  public search(newValue: string) {
    this.searchText = newValue;
    this.displayDefinitions();
  }

  public refresh() {
    this.refreshList();
  }

  public updateFilters(newValue: IPanelHeaderCategoryFilterState) {
    this.categoryFilters = newValue;
    this.displayDefinitions();
  }

  public refreshList() {
    this.updatePanelData({ hasTransactionOverlay: true });
    return this.props.props.collection.reload().done(() => {
      this.updatePanelData({ hasTransactionOverlay: false });
      return this.displayDefinitions();
    });
  }

  public openDefinitionHistory(definitionId: string) {
    let exerciseDefinition: ExerciseDefinition | null;
    exerciseDefinition = this.modelService.getModel({
      id: definitionId,
      type: 'exercise_definition',
    }) as ExerciseDefinition | null;
    if (exerciseDefinition) {
      return this.props.panelManager.openDefinitionHistory(exerciseDefinition);
    }
  }
  public selectDefinition(definitionId: string) {
    let exerciseDefinition;
    exerciseDefinition = this.modelService.getModel({
      id: definitionId,
      type: 'exercise_definition',
    });
    if (exerciseDefinition) {
      return this.changeSelection(exerciseDefinition);
    }
  }

  public onSelect(id: string): void {
    this.selectDefinition(id);
  }

  public onOpenHistory(id: string): void {
    this.openDefinitionHistory(id);
  }

  protected actionProps(): IDefinitionListActionProps {
    return {
      historyAction: this.handleOpenHistory,
      selectAction: this.handleSelect,
    };
  }

  protected renderHeaderActions(): IPanelHeaderActions {
    return {
      onDismiss: this.handleDismiss,
      onFilter: this.handleFilter,
      onRefresh: this.handleRefresh,
      onSearch: this.handleSearch,
    };
  }

  protected panelData(): IPanelInternalData {
    return {
      additionalClasses: null,
      type: 'definition-list',
    };
  }

  private filterDefinitions(ed: ExerciseDefinition[]): ExerciseDefinition[] {
    const strength = this.categoryFilters.strength;
    const flex = this.categoryFilters.flexibility;
    const endurance = this.categoryFilters.endurance;
    const showAll = _.all([strength, flex, endurance], v => v === false);
    if (_.isEmpty(this.searchText) && showAll) {
      return ed;
    }
    return _.select(ed, w => this.includeDefinition(w));
  }

  private includeDefinition(workout: ExerciseDefinition): boolean {
    return this.matchesName(workout) && this.matchesCategories(workout);
  }

  private matchesName(ed: ExerciseDefinition): boolean {
    if (_.isEmpty(this.searchText)) {
      return true;
    }
    return ed.name.indexOf(this.searchText) >= 0;
  }

  private matchesCategories(ed: ExerciseDefinition): boolean {
    const c = this.categoryFilters;
    if (c.flexibility === c.endurance && c.endurance === c.strength) {
      return true;
    }
    const workoutCategory = ed.category;
    return _.any(categories, cat => (c as any)[cat] && workoutCategory === cat);
  }

  private handleOpenHistory = (id: string) => this.onOpenHistory(id);
  private handleDefinitionDelete = () => this.displayDefinitions();
  private handleSelect = (id: string) => this.onSelect(id);
  private handleDismiss = (): void => this.onDismiss();
  private handleFilter = (value: IPanelHeaderCategoryFilterState): void => this.onFilter(value);
  private handleSearch = (search: string): void => this.onSearch(search);
  private handleRefresh = (): void => this.onRefresh();
}
