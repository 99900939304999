import * as log from 'loglevel';
import * as React from 'react';
import { LocalizedPureComponent } from '../../LocalizedComponent';
import {
  HistoryItem,
  IHistoryItemActionProps,
  IHistoryItemDataProps,
} from '../History/HistoryItem';
import { ISelectionContainerProps } from '../MobileSelection/Container/SelectionContainer';
import {
  ExerciseList,
  IDefinitionListActionProps,
  IDefinitionListDataProps,
} from './DefinitionList/ExerciseList';
import {
  DefinitionSelectionContainer,
  IDefinitionSelectionContainerActionProps,
  IDefinitionSelectionContainerProps,
} from './DefinitionSelection/Container';
import { IObjectFormActionProps, IObjectFormProps, ObjectForm } from './EditObject/ObjectForm';
import { GuideContent, IGuideContentProps } from './Guide/GuideContent';
import { IPanelData } from './IPanelData';
import { IPanelHeaderActions } from './IPanelHeaderActions';
import { IPanelPassthroughProps, IPanelProps } from './IPanelProps';
import {
  INewWorkoutFormActionProps,
  INewWorkoutFormProps,
  NewWorkoutForm,
} from './NewWorkout/NewWorkoutForm';
import { PanelElement } from './PanelElement';
import { PanelTypes } from './PanelTypes';
import { IListProps, IWorkoutListActionProps, List as WorkoutList } from './SessionList/List';
import {
  IWorkoutContainerActionProps,
  IWorkoutContainerProps,
  WorkoutContainer,
} from './Workouts/WorkoutContainer';

export interface IPanelComponentState {}

/**
 * The PanelComponent provides handles the formatting of the panel header, overlay, and selection container.
 * The children elements contain the content itself.
 */
export class PanelComponent extends LocalizedPureComponent<
  IPanelProps<any> & IPanelPassthroughProps,
  IPanelComponentState
> {
  public componentDidMount(): void {
    if (this.props.onMount) {
      this.props.onMount();
    }
  }

  public render(): JSX.Element {
    const { data, selectionProps, headerActions } = this.props;
    const children = this.renderPanelContent(this.props);
    return this.renderElement(data, selectionProps, headerActions, children);
  }

  private renderElement(
    data: IPanelData,
    selectionProps: ISelectionContainerProps,
    headerActions: IPanelHeaderActions,
    children: JSX.Element,
  ) {
    return (
      <PanelElement
        panelId={data.id}
        objectId={data.objectId}
        panelType={data.type}
        additionalClasses={data.additionalClasses}
        hasFocus={data.hasFocus}
        title={data.title}
        selectionProps={selectionProps}
        headerActions={headerActions}
        hasTransactionOverlay={data.hasTransactionOverlay}
        isMobile={this.props.isMobile}
      >
        {children}
      </PanelElement>
    );
  }

  private renderPanelContent(panel: IPanelProps<any>): JSX.Element {
    const type = panel.data.panelType;
    const passThroughProps: IPanelPassthroughProps = { isMobile: this.props.isMobile };
    if (type === PanelTypes.Workout) {
      const wp = panel as IPanelProps<IWorkoutContainerProps, {}, IWorkoutContainerActionProps>;
      return <WorkoutContainer {...wp.props} {...wp.actionProps} {...passThroughProps} />;
    } else if (type === PanelTypes.WorkoutList) {
      const wl = panel as IPanelProps<IListProps, {}, IWorkoutListActionProps>;
      return <WorkoutList {...wl.props} {...wl.actionProps} {...passThroughProps} />;
    } else if (type === PanelTypes.DefinitionList) {
      const p = panel as IPanelProps<IDefinitionListDataProps, {}, IDefinitionListActionProps>;
      return <ExerciseList {...p.props} {...p.actionProps} {...passThroughProps} />;
    } else if (type === PanelTypes.History) {
      const p = panel as IPanelProps<IHistoryItemDataProps, {}, IHistoryItemActionProps>;
      return <HistoryItem {...p.props} {...p.actionProps} {...passThroughProps} />;
    } else if (type === PanelTypes.DefinitionSelection) {
      const p = panel as IPanelProps<
        IDefinitionSelectionContainerProps,
        {},
        IDefinitionSelectionContainerActionProps
      >;
      return <DefinitionSelectionContainer {...p.props} {...p.actionProps} {...passThroughProps} />;
    } else if (type === PanelTypes.NewWorkout) {
      const p = panel as IPanelProps<INewWorkoutFormProps, {}, INewWorkoutFormActionProps>;
      return <NewWorkoutForm {...p.props} {...p.actionProps} {...passThroughProps} />;
    } else if (type === PanelTypes.Object) {
      const p = panel as IPanelProps<IObjectFormProps, {}, IObjectFormActionProps>;
      return <ObjectForm {...p.props} {...p.actionProps} {...passThroughProps} />;
    } else if (type === PanelTypes.Guide) {
      const p = panel as IPanelProps<IGuideContentProps>;
      return <GuideContent {...p.props} {...p.actionProps} {...passThroughProps} />;
    }
  }
}
