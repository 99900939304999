import * as _ from 'underscore';
import { TargetValueValidator } from '../Services/TargetValueValidator';

export default function extendParsley() {
  window.Parsley.addValidator('lt', {
    validateString(value, requirement) {
      const reqValue = parseFloat(requirement);
      if (_.isNaN(reqValue)) {
        return true;
      }
      return parseFloat(value) < reqValue;
    },
    priority: 32,
  });
  window.Parsley.addValidator('targetValue', {
    validateString(value): boolean {
      return TargetValueValidator.isValidString(value);
    },
    priority: 32,
  });
}
