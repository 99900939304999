import * as React from 'react';

export interface IFormGroupFieldProps {
  name: string;
  id: string;
  password?: boolean;
  minLength?: number;
  maxLength?: number;
  label: string;
  required?: boolean;
  groupField?: boolean;
  errorText?: string | null;
  value: string;
  updateEveryChange?: boolean;
  onChange(newValue: string): void;
}

interface IFormGroupFieldState {
  currentValue: string;
  basisValue: string;
}

export class FormGroupField extends React.Component<IFormGroupFieldProps, IFormGroupFieldState> {
  constructor(props: IFormGroupFieldProps, context: any) {
    super(props, context);
    this.state = {
      basisValue: props.value,
      currentValue: props.value,
    };
  }

  public static getDerivedStateFromProps(
    nextProps: IFormGroupFieldProps,
    prevState: IFormGroupFieldState,
  ): Partial<IFormGroupFieldState> | null {
    if (nextProps.updateEveryChange || prevState.basisValue !== nextProps.value) {
      return {
        currentValue: nextProps.value,
        basisValue: nextProps.value,
      };
    }
    return null;
  }
  public render(): React.ReactNode {
    const { name, id, password, minLength, maxLength, label, required, groupField } = this.props;
    const divClasses = groupField ? 'form-group-field' : 'form-group';
    return (
      <div className={divClasses}>
        <label htmlFor={id}>{label}</label>
        <input
          className="form-field"
          name={name}
          id={id}
          type={password ? 'password' : 'text'}
          required={required}
          minLength={minLength}
          maxLength={maxLength}
          value={this.state.currentValue}
          onChange={this.onChange}
          onBlur={this.onBlur}
        />
      </div>
    );
  }

  private onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (this.props.updateEveryChange) {
      return this.props.onChange(event.target.value);
    }
    this.setState({ currentValue: event.target.value });
  };

  private onBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
    if (this.props.value !== this.state.currentValue) {
      this.props.onChange(this.state.currentValue);
    }
  };
}
