import * as React from 'react';
import * as _ from 'underscore';
import { ILocalizedComponentProps, LocalizedPureComponent } from '../../../LocalizedComponent';
import { CategoryElement } from '../Shared/CategoryElement';

export interface IExerciseAttributesProps extends ILocalizedComponentProps {
  category: DefinitionCategory;
  restPeriod: string | null;
  restSet: string | null;
  barWeight: string | null;
}

export interface IExerciseAttributesState {}

export class ExerciseAttributes extends LocalizedPureComponent<
  IExerciseAttributesProps,
  IExerciseAttributesState
> {
  public render(): JSX.Element {
    return (
      <div className="sv-horizontal-group msv-section">
        {this.renderCategoryElement()}
        {!_.isEmpty(this.props.restSet) && (
          <div className="sv-rest-set-container">
            <div className="sv-rest-set sv-field">{this.props.restSet}</div>
            <span className="sv-label-text">{this.localize('object.exercise_group.rest_set')}</span>
          </div>
        )}
        {!_.isEmpty(this.props.restPeriod) && (
          <div className="sv-rest-exercise-container">
            <div className="sv-rest-exercise sv-field">{this.props.restPeriod}</div>
            <span className="sv-label-text">
              {this.localize('object.exercise_group.rest_exercise')}
            </span>
          </div>
        )}
        {!_.isEmpty(this.props.barWeight) && (
          <div className="sv-bar-weight-container">
            <div className="sv-exercise-bar-weight sv-field">{this.props.barWeight}</div>
            <span className="sv-label-text">{this.localize('object.exercise.bar_weight')}</span>
          </div>
        )}
      </div>
    );
  }

  private renderCategoryElement(): JSX.Element {
    return <CategoryElement category={this.props.category} />;
  }
}
