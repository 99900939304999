import * as React from 'react';
import * as _ from 'underscore';
import { VelocityComponent } from 'velocity-react';
import { Exercise } from '../../../../Models/Exercise';
import { ExerciseDefinition } from '../../../../Models/ExerciseDefinition';
import { ExerciseGroup } from '../../../../Models/ExerciseGroup';
import { ExerciseSet } from '../../../../Models/ExerciseSet';
import { IExerciseHistory } from '../../../../Models/History/IExerciseHistory';
import { Model } from '../../../../Models/Model';
import { Workout } from '../../../../Models/Workout';
import { INewSelectionViewModel } from '../../../../ViewModels/Selection/INewSelectionViewModel';
import { ILocalizedComponentProps, LocalizedComponent } from '../../../LocalizedComponent';
import { ExerciseSelection } from '../Exercise/ExerciseSelection';
import { DefinitionSelection } from '../ExerciseDefinition/DefinitionSelection';
import { ExerciseSetSelection } from '../ExerciseSet/ExerciseSetSelection';
import { GroupMovement } from '../GroupMovement/index';
import { WorkoutSelection } from '../Workout/WorkoutSelection';
import { INavigationSectionDataProps, NavigationSection } from './NavigationSection';
import { Overlay } from './Overlay';

export interface ISelectionContainerProps extends ILocalizedComponentProps {
  navigationData: INavigationSectionDataProps;
  model: Model;
  viewModel: INewSelectionViewModel;
  history: IExerciseHistory[];
  setIndex: number | false;
  isVisible: boolean;
  updateVisibility: (newValue: boolean) => void;
}

export interface ISelectionContainerPassthroughProps {
  isMobile: boolean;
}

export interface ISelectionContainerState {
  advancedEnabled: boolean;
  overlayPresent: boolean;
}

const CLOSE_BUTTON_PATH = 'mobile_selection_view.close_button';
const ADVANCED_TOGGLE_PATH = 'mobile_selection_view.advanced_toggle';

export class SelectionContainer extends LocalizedComponent<
  ISelectionContainerProps & ISelectionContainerPassthroughProps,
  ISelectionContainerState
> {
  private directionalAnimation: VelocityComponent;
  // private navigationProps: INavigationSectionDataProps;
  private animationDirection: 'previous' | 'next' = 'next';

  public constructor(props: ISelectionContainerProps & ISelectionContainerPassthroughProps) {
    super(props);
    this.state = {
      advancedEnabled: false,
      overlayPresent: false,
    };
  }

  public set overlayPresent(newValue: boolean) {
    this.setState({ overlayPresent: newValue });
  }

  public get overlayPresent(): boolean {
    return this.state.overlayPresent;
  }

  private get contentClasses(): string {
    return this.state.overlayPresent ? 'sv-content sv-update-content' : 'sv-content';
  }

  private get hasAdvancedOption(): boolean {
    return this.props.model instanceof Exercise || this.props.model instanceof ExerciseSet;
  }

  private get animationState(): string {
    return this.props.isVisible ? 'transition.slideDownIn' : 'transition.slideUpOut';
  }

  private get animationDisplay(): string {
    return this.props.isVisible ? 'flex' : 'none';
  }

  private get historyButtonVisible(): boolean {
    return this.props.isMobile;
  }

  private get effectName(): string {
    return this.animationDirection === 'previous'
      ? 'SR.SelectionNavigatePrevious'
      : 'SR.SelectionNavigateNext';
  }

  public render(): JSX.Element {
    const modelType = this.props.model?.type;
    const modelId = this.props.model?.id;
    return (
      <VelocityComponent
        animation={this.animationState}
        duration={500}
        display={this.animationDisplay}
      >
        <VelocityComponent animation={this.effectName} ref={c => (this.directionalAnimation = c)}>
          <div
            className="sv-container"
            data-selected-record-id={modelId}
            data-selected-record-type={modelType}
          >
            <header className="sv-header">
              <div className="sv-header-text" />
              <button onClick={this.handleCloseClick} className="xbtn xbtn-primary sv-close-button">
                {this.localize(CLOSE_BUTTON_PATH)}
              </button>
            </header>
            <section className={this.contentClasses}>
              {this.props.model && this.renderSelection()}
              {this.state.overlayPresent && <Overlay />}
            </section>
            <NavigationSection
              data={this.props.navigationData}
              onNextClick={this.handleNextClick}
              onPreviousClick={this.handlePreviousClick}
            />
            {this.hasAdvancedOption && (
              <footer>
                <label className="sv-advanced-toggle">
                  <input
                    type="checkbox"
                    className="sv-advanced-toggle"
                    checked={this.state.advancedEnabled}
                    onChange={this.handleAdvancedToggleChange}
                  />
                  {this.localize(ADVANCED_TOGGLE_PATH)}
                </label>
              </footer>
            )}
          </div>
        </VelocityComponent>
      </VelocityComponent>
    );
  }

  private handleAdvancedToggleChange = (): void => {
    this.setState({ advancedEnabled: !this.state.advancedEnabled });
  };

  private handleCloseClick = (): void => {
    this.props.updateVisibility(false);
  };

  private handleSpinnerChange = (newValue: boolean): void => {
    this.overlayPresent = newValue;
  };

  private renderSelection(): JSX.Element {
    if (this.props.model instanceof Exercise) {
      return this.renderExerciseSelection();
    } else if (this.props.model instanceof ExerciseSet) {
      return this.renderExerciseSetSelection();
    } else if (this.props.model instanceof Workout) {
      return this.renderWorkoutSelection();
    } else if (this.props.model instanceof ExerciseDefinition) {
      return this.renderExerciseDefinitionSelection();
    } else if (this.props.model instanceof ExerciseGroup) {
      return this.renderExerciseGroupMovement();
    }
  }

  private renderExerciseSelection(): JSX.Element {
    return (
      <ExerciseSelection
        model={this.props.model as Exercise}
        isVisible={this.props.isVisible}
        isMobile={this.props.isMobile}
        changeSpinnerVisibility={this.handleSpinnerChange}
        isSpinnerVisible={this.overlayPresent}
        viewModel={this.props.viewModel}
        advancedModeEnabled={this.state.advancedEnabled}
        history={this.props.history}
        setIndex={this.props.setIndex}
      />
    );
  }

  private renderExerciseSetSelection(): JSX.Element {
    const esSet = this.props.model as ExerciseSet;
    return (
      <ExerciseSetSelection
        setIndex={this.props.setIndex}
        history={this.props.history}
        advancedModeEnabled={this.state.advancedEnabled}
        changeSpinnerVisibility={this.handleSpinnerChange}
        isSpinnerVisible={this.overlayPresent}
        viewModel={this.props.viewModel}
        isVisible={this.props.isVisible}
        model={esSet}
        isMobile={this.props.isMobile}
        historyButtonVisible={this.historyButtonVisible}
      />
    );
  }

  private renderExerciseDefinitionSelection(): JSX.Element {
    const ed = this.props.model as ExerciseDefinition;
    return (
      <DefinitionSelection
        isVisible={this.props.isVisible}
        viewModel={this.props.viewModel}
        historyButtonVisible={this.historyButtonVisible}
        changeSpinnerVisibility={this.handleSpinnerChange}
        isSpinnerVisible={this.overlayPresent}
        model={ed}
        history={this.props.history}
      />
    );
  }

  private renderWorkoutSelection(): JSX.Element {
    const w = this.props.model as Workout;
    return (
      <WorkoutSelection
        viewModel={this.props.viewModel}
        changeSpinnerVisibility={this.handleSpinnerChange}
        workout={w}
        isVisible={this.props.isVisible}
      />
    );
  }
  private renderExerciseGroupMovement(): JSX.Element {
    const eg = this.props.model as ExerciseGroup;
    return (
      <GroupMovement
        exerciseGroup={eg}
        viewModel={this.props.viewModel}
        changeSpinnerVisibility={this.handleSpinnerChange}
      />
    );
  }

  private handleNextClick = (): void => {
    this.animationDirection = 'next';
    this.directionalAnimation.runAnimation();
    this.props.viewModel.onNextClick();
  };

  private handlePreviousClick = (): void => {
    this.animationDirection = 'previous';
    this.directionalAnimation.runAnimation();
    this.props.viewModel.onPreviousClick();
  };
}
