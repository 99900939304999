import * as _ from 'underscore';
import { IdValue } from '../Core/GlobalVariables';
import { Model } from '../Models/Model';
import { IDataStore } from './IDataStore';

export interface IDataContainer<T extends Model> {
  [key: string]: T;
  [key: number]: T;
}

interface IModelStore {
  [key: string]: IDataContainer<Model>;
}

export class DataStore implements IDataStore {
  private dataStore: IModelStore;

  constructor() {
    this.dataStore = {};
  }

  public getData<T extends Model>(type: ModelType, id: IdValue): T {
    let type_store;
    type_store = this.getObjectClass(type);
    if (type === 'exercise_definition') {
      return type_store[id] as T;
    } else {
      if (typeof id !== 'number') {
        id = parseInt(id, 10);
      }
      return type_store[id] as T;
    }
  }

  /**
   * Gets the object class container.
   * @param type
   * @returns {any}
   */
  public getObjectClass<T extends Model>(type: ModelType): IDataContainer<T> {
    let _base;
    let _ref;
    // tslint:disable-next-line:no-conditional-assignment
    if ((_ref = (_base = this.dataStore)[type]) == null) {
      _base[type] = {};
    }
    return this.dataStore[type] as IDataContainer<T>;
  }

  /**
   * Gets the children for an object.
   * @param parentID - Parent object's ID
   * @param childType - The type of class to retrieve.
   * @param childReferenceProperty - The property containing the parent ID value.
   * @returns The requested model.
   */
  public getChildren<T extends Model, TParent>(
    parentID: string | number,
    childType: ModelType,
    childReferenceProperty: keyof TParent,
  ): T[] {
    let childrenClassObjects;
    let foundChildren;
    childrenClassObjects = this.getObjectClass<T>(childType);
    foundChildren = _.filter(childrenClassObjects, function(obj: any) {
      return obj[childReferenceProperty] === parentID;
    });
    return foundChildren;
  }

  /**
   * Unloads all objects for a given type.
   * @param type Type to unload.
   * @returns {_.List<T>}
   */
  public unloadObjectClass<T extends Model>(type: ModelType): void {
    const klass = this.getObjectClass<T>(type);
    _.each(klass, value => {
      this.unloadObject(value);
    });
  }

  /**
   * Stores a model in the DataStore. If the object already exists, updates that object.
   * @param type Type of Model being stored.
   * @param id ID for the object.
   * @param objectData The object data.
   * @returns {any}
   */
  public storeObject<T extends Model>(type: ModelType, id: IdValue, objectData: any): void {
    const existingObject = this.getData(type, id);
    if (existingObject instanceof Model) {
      existingObject.setPersistedData(objectData.data());
    } else {
      this.writeObject(type, id, objectData);
    }
  }

  /**
   * Removes a given object from the data store.
   * @param objectOrType
   * @param id
   */
  public unloadObject(objectOrType: Model | ModelType, id?: string | number): void {
    let obj;
    let objectClass;
    obj = objectOrType instanceof Model ? objectOrType : this.getData(objectOrType, id);
    $(document).trigger('sr:object:unload:' + obj.type, obj);
    objectClass = this.getObjectClass(obj.type);
    delete objectClass[obj.id];
  }

  private writeObject(type: ModelType, id: IdValue, object: any): void {
    let objectStore;
    let object_id;
    objectStore = this.getObjectClass(type);
    object_id = type === 'exercise_definition' ? id : parseInt(id as any, 10);
    return (objectStore[object_id] = object);
  }
}
