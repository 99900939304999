import * as log from 'loglevel';
import * as React from 'react';
import * as _ from 'underscore';
import { DefinitionCollection } from '../../../../Models/Collections/DefinitionCollection';
import { Exercise } from '../../../../Models/Exercise';
import { ExerciseDefinition } from '../../../../Models/ExerciseDefinition';
import { ExerciseGroup } from '../../../../Models/ExerciseGroup';
import { IExerciseHistory } from '../../../../Models/History/IExerciseHistory';
import { IExerciseHistoryBase } from '../../../../Models/History/IExerciseHistoryBase';
import { IExerciseDefinition } from '../../../../Models/IExerciseDefinition';
import { Workout } from '../../../../Models/Workout';
import { ILocalizationService } from '../../../../Services/ILocalizationService';
import { INewExerciseOptions } from '../../../../Services/ModelService';
import {
  IDefinitionSelectionViewModel,
  IExerciseQuickSetOptions,
  SelectionModes,
} from '../../../../ViewModels/Panels/IDefinitionSelectionViewModel';
import { AbstractPanelViewContainer } from '../AbstractPanelViewContainer';
import { IPanelExternalData, IPanelInternalData } from '../IPanelData';
import { IPanelHeaderActions } from '../IPanelHeaderActions';
import { IPanelViewModelProps } from '../IPanelProps';
import { PanelTypes } from '../PanelTypes';
import {
  IDefinitionSelectionContainerActionProps,
  IDefinitionSelectionContainerProps,
} from './Container';
import { DefinitionSelectionMode } from './DefinitionSelectionMode';
import { IConfigurationEntry } from './IConfigurationEntry';
import { IExistingDefinitionDataProps } from './IExistingDefinitionProps';
import { NewExerciseContentMode } from './NewExerciseConfiguration';

const queryDelay = 300;

export type DefinitionSelectionViewContainerProps = IPanelViewModelProps<
  IDefinitionSelectionContainerProps & IDefinitionSelectionContainerActionProps,
  IDefinitionSelectionViewModel
>;

export interface IDefinitionSelectionViewContainerState {}

export class DefinitionSelectionViewContainer
  extends AbstractPanelViewContainer<
    IDefinitionSelectionContainerProps,
    {},
    IDefinitionSelectionViewContainerState,
    IDefinitionSelectionContainerActionProps
  >
  implements IDefinitionSelectionViewModel {
  // private _selectedRecord: Exercise | ExerciseGroup | Workout;
  // private _definitionMode: SelectionModes;
  private selectedDefinition: string;
  // private searchText: string;
  // private currentDefinitions: ExerciseDefinition[];
  // private existingProps: IExistingDefinitionDataProps;
  // private quickSetProps: {
  //   quickSetCount: number;
  //   quickSetIntensity: string;
  //   quickSetTarget: string;
  // };
  private delay: number;

  public static newExerciseMode(definitionMode: SelectionModes): NewExerciseContentMode {
    return definitionMode !== 'change-exercise'
      ? NewExerciseContentMode.QuickSet
      : NewExerciseContentMode.NotPermitted;
  }

  public static initialPanelProps(
    localizationService: ILocalizationService,
    definitionMode: SelectionModes,
    selectedRecord: Exercise | ExerciseGroup | Workout,
  ): IDefinitionSelectionContainerProps {
    const props = this.constructReactProps(localizationService);
    return {
      existingDefinitionDataProps: props.existingProps,
      localization: localizationService,
      newDefinitionProps: props.newProps,
      newExerciseContentMode: this.newExerciseMode(definitionMode),
      previousExercise: [],
      quickSetProps: props.quickSetProps,
      selectedExercise: null,
      selectionMode: DefinitionSelectionMode.Existing,
      targetRecord: selectedRecord,
      targetType: definitionMode,
    };
  }

  public static initialPanelData(
    viewModelId: string,
    definitionMode: SelectionModes,
    parentViewModelId: string = null,
  ): IPanelExternalData {
    return {
      hasFocus: false,
      hasTransactionOverlay: false,
      id: viewModelId,
      panelType: PanelTypes.DefinitionSelection,
      parentPanelId: parentViewModelId,
      title: this.calculateTitle(definitionMode),
    };
  }

  private static calculateTitle(definitionMode: SelectionModes): string {
    switch (definitionMode) {
      case 'change-exercise':
        return 'Change Exercise';
      case 'add-exercise':
        return 'Add Super Set';
      case 'add-exercise-group':
        return 'Add Exercise';
      default:
        log.warn('Unexpected Definition Mode', definitionMode);
        return 'Select Exercise';
    }
  }

  private static constructReactProps(localizationService: ILocalizationService) {
    return {
      existingProps: this.getExistingProps(),
      newProps: {
        categories: this.prepareConfiguration(
          'configuration.exercise_definitions.categories',
          localizationService,
        ),
        implementList: this.prepareConfiguration(
          'configuration.exercise_definitions.implements',
          localizationService,
        ),
        sidedList: this.prepareConfiguration(
          'configuration.exercise_definitions.sided',
          localizationService,
        ),
      },
      quickSetProps: {
        quickSetCount: 1,
        quickSetIntensity: '45 lbs',
        quickSetTarget: '10',
      },
    };
  }

  private static getExistingProps(): IExistingDefinitionDataProps {
    return {
      definitionList: [],
      selectedDefinition: null,
    };
  }

  private static prepareConfiguration(
    path: string,
    localizationService: ILocalizationService,
  ): IConfigurationEntry[] {
    const options: {
      [key: string]: { title: string };
    } = localizationService.getValue(path) as any;
    return _.map(options, (value, key) => {
      return { key, title: value.title };
    });
  }

  public componentDidMount(): void {
    super.componentDidMount();
    this.runSearch('');
  }

  public updateContentMode(newMode: NewExerciseContentMode): void {
    this.updateContentProps({ newExerciseContentMode: newMode });
  }

  public updateMode(newMode: DefinitionSelectionMode): void {
    this.updateContentProps({ selectionMode: newMode });
    if (newMode === DefinitionSelectionMode.New) {
      this.updateContentProps({
        newExerciseContentMode: NewExerciseContentMode.QuickSet,
        previousExercise: [],
        selectedExercise: null,
      });
    }
  }

  public updateQuickSetValues(param: {
    numberOfSets: number;
    intensityValue: string;
    targetValue: string;
  }): void {
    this.updateContentProps({
      quickSetProps: {
        quickSetCount: param.numberOfSets,
        quickSetIntensity: param.intensityValue,
        quickSetTarget: param.targetValue,
      },
    });
  }

  public completeChange(
    quickSet: IExerciseQuickSetOptions,
    exerciseToCopy: IExerciseHistoryBase,
  ): JQueryPromise<any> {
    return this.submitChange(quickSet, exerciseToCopy);
  }

  public onDismiss(): void {
    this.removePanel();
  }

  /**
   * Changes the selected definition.
   * @param {String, null} newDefinitionId - The new definition to be selected. If null, deselects.
   */
  public changeSelectedDefinition(newDefinitionId: string) {
    this.selectedDefinition = newDefinitionId;
    this.populateQuickSets(newDefinitionId);
    this.updateSelection(newDefinitionId);
  }

  public submitChange(
    quickSetOptions: IExerciseQuickSetOptions,
    exerciseToCopy: IExerciseHistoryBase,
  ): JQueryPromise<any> {
    if (this.props.props.targetType === 'change-exercise') {
      return this.changeExercise();
    } else if (this.props.props.targetType === 'add-exercise') {
      return this.createExercise(quickSetOptions, exerciseToCopy);
    } else if (this.props.props.targetType === 'add-exercise-group') {
      return this.createExerciseGroup(quickSetOptions, exerciseToCopy);
    }
  }

  /**
   * Submits request to create a new definition with the supplied data.
   * @param definitionData - Data object for new
   * exercise definition.
   * @param quickSetOptions
   */
  public createDefinition(
    definitionData: IExerciseDefinition,
    quickSetOptions?: IExerciseQuickSetOptions,
  ): JQueryPromise<any> {
    const promise = this.modelService.addDefinition(
      this.props.appService.currentUser,
      definitionData,
    );
    return promise.done((result: ExerciseDefinition) => {
      this.selectedDefinition = result.id as string;
      return this.submitChange(quickSetOptions, null);
    });
  }

  public runSearch(searchText: string): JQueryPromise<any> {
    let promise;
    promise = this.userExerciseDefinitions().search(searchText);
    return promise.done(results => {
      return this.displayDefinitions(results.newObjects);
    });
  }

  public selectPreviousExercise(ex: IExerciseHistoryBase): void {
    this.updateContentProps({
      newExerciseContentMode: NewExerciseContentMode.CopyPrevious,
      selectedExercise: ex,
    });
  }

  public selectNoPreviousExercise(): void {
    this.updateContentProps({
      selectedExercise: null,
    });
  }

  public onSelectDefinition(definitionId: string): void {
    this.changeSelectedDefinition(definitionId);
  }

  public onSearchDefinition(searchText: string): void {
    this.handleSearchAction(searchText);
  }

  protected actionProps(): IDefinitionSelectionContainerActionProps {
    return {
      existingDefinitionActionProps: {
        onChangeSearchAction: this.handleSearchChange,
        onSelectDefinitionAction: this.handleSelectDefinition,
      },
      viewModel: this,
    };
  }

  protected panelData(): IPanelInternalData {
    return {
      additionalClasses: null,
      type: 'definition-selection',
    };
  }

  protected renderHeaderActions(): IPanelHeaderActions {
    return {
      onDismiss: this.handleDismiss,
    };
  }

  private handleDismiss = (): void => {
    this.onDismiss();
  };

  private changeExercise(): JQueryPromise<any> {
    let promise;
    (this.props.props.targetRecord as Exercise).exercise_definition_uuid = this.selectedDefinition;
    promise = this.props.props.targetRecord.save();
    return promise.done(() => {
      this.props.completePromise.resolve();
      this.removePanel();
    });
  }

  private updateSelection(newSelectionId: string) {
    const def = this.findDefinitionFromExisting(newSelectionId);
    const clonedExisting = _.clone(this.props.props.existingDefinitionDataProps);
    const isChanging = clonedExisting.selectedDefinition !== def;
    if (isChanging) {
      clonedExisting.selectedDefinition = def;
      this.selectNoPreviousExercise();
      this.updateContentProps({ existingDefinitionDataProps: clonedExisting });
      this.updatePreviousExercise(def);
    }
  }

  private updatePreviousExercise(definition: ExerciseDefinition): void {
    this.updateContentProps({ previousExercise: [] });
    if (definition != null) {
      definition
        .history()
        .more()
        .then((ex: IExerciseHistory[]) => {
          this.updateContentProps({
            previousExercise: definition
              .history()
              .members()
              .slice(0, 3),
          });
        });
    }
  }

  private createExerciseGroup(
    quickSetOptions: IExerciseQuickSetOptions,
    toCopy: IExerciseHistoryBase,
  ): JQueryPromise<any> {
    let promise;
    const selectedRecord = this.props.props.targetRecord;
    const newOptions = this.exerciseCreateOptions(quickSetOptions, toCopy);
    if (!(selectedRecord instanceof Workout)) {
      log.error('Non Workout Object Provided', selectedRecord);
      return this.props.appService.promiseService.rejectedPromise();
    } else {
      promise = this.modelService.addExerciseGroup(
        selectedRecord,
        this.selectedDefinition,
        newOptions,
      );
      return promise.done(() => {
        this.props.completePromise.resolve();
        this.removePanel();
      });
    }
  }

  private exerciseCreateOptions(
    quickSetOptions: IExerciseQuickSetOptions,
    toCopy: IExerciseHistoryBase,
  ): INewExerciseOptions {
    if (quickSetOptions) {
      return { quick_set_attributes: quickSetOptions };
    } else if (toCopy) {
      return { previous_exercise_id: toCopy.id };
    }
  }

  private createExercise(
    quickSetOptions: IExerciseQuickSetOptions,
    exerciseToCopy: IExerciseHistoryBase,
  ): JQueryPromise<any> {
    let promise;
    const newOptions = this.exerciseCreateOptions(quickSetOptions, exerciseToCopy);
    const selectedRecord = this.props.props.targetRecord;
    if (!(selectedRecord instanceof ExerciseGroup)) {
      log.error('Non Exercise Group Object Provided', selectedRecord);
      return this.props.appService.promiseService.rejectedPromise();
    }
    promise = this.modelService.addExercise(selectedRecord, this.selectedDefinition, newOptions);
    return promise.done(() => {
      this.props.completePromise.resolve();
      this.removePanel();
    });
  }

  private displayDefinitions(definitions: ExerciseDefinition[]) {
    let selectionExistsInNewSearch;
    const clonedExisting = _.clone(this.props.props.existingDefinitionDataProps);
    clonedExisting.definitionList = definitions;
    this.updateContentProps({ existingDefinitionDataProps: clonedExisting });
    selectionExistsInNewSearch = _.any(definitions, definition => {
      return definition.id === this.selectedDefinition;
    });
    if (selectionExistsInNewSearch) {
      return this.changeSelectedDefinition(this.selectedDefinition);
    } else {
      return this.changeSelectedDefinition(null);
    }
  }

  private userExerciseDefinitions(): DefinitionCollection {
    return this.props.appService.currentUser.exercise_definitions();
  }

  private populateQuickSets(newDefinitionId: string) {
    if (newDefinitionId == null) {
      return;
    }
    const existingDef = this.findDefinitionFromExisting(newDefinitionId);
    const quickSetValues: {
      numberOfSets: number;
      targetValue: string;
      intensityValue: string;
    } = this.calculateQuickSetValues(existingDef.category);
    this.updateQuickSetValues(quickSetValues);
  }

  private findDefinitionFromExisting(newDefinitionId: string): ExerciseDefinition | null {
    const iterator: _.ListIterator<ExerciseDefinition, boolean> = function(
      def: ExerciseDefinition,
    ): boolean {
      return def.id === newDefinitionId;
    };
    return _.find(this.props.props.existingDefinitionDataProps.definitionList, iterator);
  }

  /**
   * Calculates quick set values for a given category.
   * @param category
   * @returns Quick Set values to provide.
   */
  private calculateQuickSetValues(
    category: DefinitionCategory,
  ): { numberOfSets: number; targetValue: string; intensityValue: string } {
    switch (category) {
      case 'strength':
        return {
          intensityValue: '45 lbs',
          numberOfSets: 3,
          targetValue: '10',
        };
      case 'endurance':
        return {
          intensityValue: '10 km/h',
          numberOfSets: 1,
          targetValue: '10:00',
        };
      case 'flexibility':
        return {
          intensityValue: '',
          numberOfSets: 1,
          targetValue: '1:00',
        };
      case null:
      case 'no_category':
        return {
          intensityValue: null,
          numberOfSets: 3,
          targetValue: null,
        };
      default:
        log.error('Invalid Category Provided', category);
        return {
          intensityValue: null,
          numberOfSets: 3,
          targetValue: null,
        };
    }
  }

  private handleSearchAction = (newSearchText: string): void => {
    this.handleSearchChange(newSearchText);
  };

  private handleSearchChange = (event: string): void => {
    let inputString: string;
    let queryFunc;
    if (this.delay) {
      window.clearTimeout(this.delay);
    }
    inputString = event;
    queryFunc = () => {
      return this.runSearch(inputString);
    };
    this.delay = window.setTimeout(queryFunc, queryDelay);
  };

  private handleSelectDefinition = (definitionId: string): void => {
    this.onSelectDefinition(definitionId);
  };
}
