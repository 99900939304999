import * as React from 'react';
import { IExerciseHistoryBase } from '../../../../Models/History/IExerciseHistoryBase';
import { ILocalizedComponentProps, LocalizedPureComponent } from '../../../LocalizedComponent';
import { IExerciseHistoryFormatted } from '../../Helpers/History/IExerciseHistoryFormatted';
import { IExerciseDisplayClasses, IExerciseHistoryDisplayClasses } from '../../History/Exercise';
import { SetGroup } from '../../History/SetGroup';

export interface IPreviousExerciseProps extends ILocalizedComponentProps {
  exercise: IExerciseHistoryFormatted;
  isSelected: boolean;
  displayClasses: IExerciseHistoryDisplayClasses;
  selectExercise: (ex: IExerciseHistoryBase) => void;
}

export interface IPreviousExerciseState {}

export class PreviousExercise extends LocalizedPureComponent<
  IPreviousExerciseProps,
  IPreviousExerciseState
> {
  private get selectExerciseContent(): JSX.Element {
    if (this.props.isSelected) {
      return <i className="fa fa-check fa-fw" />;
    } else {
      return <i className="fa fa-square-o fa-fw" />;
    }
  }

  private get displayClasses(): IExerciseDisplayClasses {
    return this.props.displayClasses.exercise;
  }
  public render(): JSX.Element {
    const exercise = this.props.exercise;
    const tempo = exercise.tempo;
    return (
      <div
        className={this.displayClasses.exerciseEntry}
        data-object-id={exercise.id}
        data-workout-id={exercise.workout_id}
        key={exercise.id}
        onClick={this.handleSelectExercise}
      >
        <div className={this.displayClasses.exerciseData}>
          <div className={this.displayClasses.exerciseTitle}>
            <div className={this.displayClasses.workoutName}>{exercise.workout_name}</div>
            <div className={this.displayClasses.plannedDate}>
              <span>
                <i />
              </span>{' '}
              {this.renderRelativeDate(exercise.date)}
            </div>
          </div>
          <div className={this.displayClasses.exerciseHeader}>
            {tempo.concentric && (
              <div className="tempo">
                <span>
                  <i className="exercise-tempo-icon" />
                </span>
                {tempo.concentric}/{tempo.pause}/{tempo.eccentric}/{tempo.rest}
              </div>
            )}
            {exercise.rest_set && (
              <div className={this.displayClasses.restSetContainer}>
                <span>
                  <i className="exercise-group-rest-set-icon" />
                </span>{' '}
                {exercise.rest_set}
              </div>
            )}
            {exercise.note_content && (
              <div className={this.displayClasses.reviewContainer}>
                <span>
                  <i className="exercise-note-content-icon" />
                </span>{' '}
                {exercise.note_content}
              </div>
            )}
            {exercise.super_set_exercises && exercise.super_set_exercises.length > 0 && (
              <div className={this.displayClasses.superSetExerciseContainer}>
                <span>
                  <i />
                  Super-set{' '}
                </span>{' '}
                {exercise.super_set_exercises}
              </div>
            )}
          </div>
          <div className={this.displayClasses.setGroupsContainer}>{this.renderSetGroups()}</div>
        </div>
        <div className="prevExerciseSelector-button">{this.selectExerciseContent}</div>
      </div>
    );
  }

  private handleSelectExercise = (): void => {
    this.props.selectExercise(this.props.exercise);
  };

  private renderSetGroups(): JSX.Element[] {
    return this.props.exercise.set_groups.map((sg, index) => {
      return (
        <SetGroup
          displayClasses={this.props.displayClasses}
          key={sg.id}
          position={index}
          setGroup={sg}
          highlightPosition={null}
        />
      );
    });
  }

  private renderRelativeDate(date: Date): string {
    return moment(date)
      .fromNow()
      .toTitleCase();
  }
}
