import { IServerResult } from '../IServerResult';
import { ServerRequestHandler } from './ServerRequestHandler';

export class RequestHandlerData<T> extends ServerRequestHandler<T> {
  public doneRequest(response: IServerResult<T>) {
    let parsedResponse;
    parsedResponse = this._responseParser.parse(response);
    return this._promise.resolve(parsedResponse);
  }
}
