import * as React from 'react';

export interface IFixedGroupProps {}

export interface IFixedGroupState {}

export class FixedGroup extends React.PureComponent<IFixedGroupProps, IFixedGroupState> {
  public render(): JSX.Element {
    return (
      <ul className="menu-misc-items menu">
        <li className="menu-item log-out">
          <a rel="nofollow" data-method="delete" href="/users/sign_out">
            <div className="menu-item-icon">
              <i />
            </div>
            <span>Log Out</span>
          </a>
        </li>
        <li className="menu-item profile">
          <a href="/user">
            <div className="menu-item-icon">
              <i />
            </div>
            <span>Settings</span>
          </a>
        </li>
      </ul>
    );
  }
}
