import { IExerciseDisplayClasses } from './Exercise';
import { IExerciseSetDisplayClasses } from './ExerciseSet';
import { ISetGroupDisplayClasses } from './SetGroup';

export const exerciseDisplayClasses: IExerciseDisplayClasses = {
  exerciseData: 'hpv-exercise-data',
  exerciseEntry: 'hpv-exercise',
  exerciseHeader: 'hpv-exercise-header',
  exerciseOpenButton: 'hpv-exercise-open',
  exerciseTitle: 'hpv-exercise-title',
  plannedDate: 'hpv-planned-date',
  restSetContainer: 'hpv-rest-set',
  reviewContainer: 'hpv-review',
  setGroupsContainer: 'hpv-set-gropus',
  superSetExerciseContainer: 'hpv-super-set-exercises',
  workoutName: 'hpv-workout-name',
};

export const exerciseSetDisplayClasses: IExerciseSetDisplayClasses = {
  difficultyContainer: 'hpv-difficulty',
  exerciseSetContainer: 'hpv-exercise-set',
  intensityNameItem: 'hpv-intensity-name',
  intensityValueContainer: 'hpv-intensity-value',
  intensityValueItem: 'hpv-intensity-value',
  parameterEntry: 'hpv-parameter',
  parametersContainer: 'hpv-parameters',
  primaryValuesContainer: 'hpv-primary-values',
  specActual: 'hpv-spec-actual',
  specTarget: 'hpv-spec-target',
  specUnit: 'hpv-spec-unit',
  specificationEntry: 'hpv-specification',
  specificationsContainer: 'hpv-specifications',
};

export const setGroupDisplayClasses: ISetGroupDisplayClasses = {
  exerciseSetsContainer: 'hpv-exercise-sets',
  setDifficultyContainer: 'hpv-set-difficulty',
  setDifficultyState: 'hpv-set-difficulty-',
  setGroupEntry: 'hpv-set-group',
  setGroupHighlight: 'hpv-set-highlight',
  setPosition: 'hpv-set-position',
};

export const historyDisplayClasses = {
  exercise: exerciseDisplayClasses,
  exerciseSet: exerciseSetDisplayClasses,
  setGroup: setGroupDisplayClasses,
};

export default historyDisplayClasses;
