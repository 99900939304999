import * as React from 'react';
import { IPerformanceSpecification } from '../../../../../Models/IPerformanceSpecification';
import { ILocalizedComponentProps, LocalizedPureComponent } from '../../../../LocalizedComponent';
import { DataEntryMode } from './DataEntryMode';
import { IConfigurationObject } from './IConfigurationObject';
import { SpecificationFieldSet } from './SpecificationFieldSet';

export interface ISpecificationFieldSectionProps extends ILocalizedComponentProps {
  specifications: { object: Readonly<IPerformanceSpecification>; isValid: boolean }[];
  updateSpecification: (newValue: IPerformanceSpecification, index: number) => void;
  removeSpecification: (index: number) => void;
  updateValidity: (isValid: boolean, index: number) => void;
  addSpecification: () => void;
  canAddSpecification: boolean;
  isActualPermitted: boolean;
  configObject: IConfigurationObject;
  updateMode: (newMode: DataEntryMode) => void;
  mode: DataEntryMode;
}

export interface ISpecificationFieldSectionState {}

export class SpecificationFieldSection extends LocalizedPureComponent<
  ISpecificationFieldSectionProps,
  ISpecificationFieldSectionState
> {
  public render(): JSX.Element {
    return (
      <div className="exercise-set-specifications">
        <div className="form-group-container">
          <div className="form-group-icon">
            <i className="exercise-set-planned-length-icon" />
          </div>
          <div className="form-group-field">
            <label>{this.localize('object.exercise_set.performance_specifications')}</label>
            <div className="exercise-set-specifications-fields">{this.renderSpecifications()}</div>
            {this.props.canAddSpecification && (
              <div
                className="standard-button add-specification"
                onClick={this.props.addSpecification}
                id="object-panel-add-specification"
              >
                <i className="icon-add" />
                {this.localize('object.exercise_set.add_specification')}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  private renderSpecifications(): JSX.Element[] {
    return this.props.specifications.map((wrapper, index) => {
      const spec = wrapper.object;
      return (
        <SpecificationFieldSet
          index={index}
          key={index}
          updateValidity={this.props.updateValidity}
          specification={spec}
          isActualPermitted={this.props.isActualPermitted}
          configurationObject={this.props.configObject}
          updateSpecification={this.props.updateSpecification}
          removeSpecification={this.props.removeSpecification}
          configurationMode={this.props.mode}
        />
      );
    });
  }
}
