import { ModelFragment } from '../Core/GlobalVariables';
import { IDataLayer } from '../Data/IDataLayer';
import { DiffService } from '../Services/DiffService';
import { PromiseService } from '../Services/PromiseService';
import { Exercise } from './Exercise';
import { ExerciseDefinition } from './ExerciseDefinition';
import { ExerciseGroup } from './ExerciseGroup';
import { ExerciseSet } from './ExerciseSet';
import { Model } from './Model';
import { SetGroup } from './SetGroup';
import { User } from './User';
import { Workout } from './Workout';

/**
 * Generates a model object
 * @param dataObj
 * @returns {any}
 * @constructor
 */
export function Constructor<T extends Model>(
  dataObj: ModelFragment,
): new (
  dataObj: any,
  dataLayer: IDataLayer,
  diffService: DiffService,
  promiseService: PromiseService,
) => T {
  switch (dataObj.type) {
    case 'workout':
      return Workout as any;
    case 'exercise_group':
      return ExerciseGroup as any;
    case 'exercise':
      return Exercise as any;
    case 'set_group':
      return SetGroup as any;
    case 'exercise_set':
      return ExerciseSet as any;
    case 'exercise_definition':
      return ExerciseDefinition as any;
    case 'user':
      return User as any;
    default:
      throw new Error(`Unanticipated Type: ${dataObj.type}`);
  }
}

export function Factory<T extends Model>(
  dataObj: any,
  dataLayer: IDataLayer,
  diffService: DiffService,
  promiseService: PromiseService,
): T {
  let klass;
  klass = Constructor(dataObj);
  return new klass(dataObj, dataLayer, diffService, promiseService) as any;
}
