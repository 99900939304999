import * as log from 'loglevel';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { AjaxInterface } from '../Data/Ajax/AjaxInterface';
import { DataLayer } from '../Data/DataLayer';
import { DataStore } from '../Data/DataStore';
import { IDataLayer } from '../Data/IDataLayer';
import { ServerManager } from '../Data/ServerManager';
import { User } from '../Models/User';
import { DiffService } from '../Services/DiffService';
import { IModelService } from '../Services/IModelService';
import { LocalizationService } from '../Services/LocalizationService';
import { ModelService } from '../Services/ModelService';
import { PromiseService } from '../Services/PromiseService';
import { ViewService } from '../Services/ViewService';
import { IPanelStateManager } from '../ViewModels/IPanelStateManager';
import { ApplicationRoot } from '../Views/Journal/ApplicationRoot';
import { ThinMenuView } from '../Views/ThinMenuView';
import { WorkoutCopyViewModel } from '../Views/WorkoutCopy/WorkoutCopyViewModel';
import { IApplicationInitiator } from './IApplicationInitiator';
import { IApplicationService } from './IApplicationService';
import { ISweatRecord } from './ISweatRecord';

declare global {
  // tslint:disable-next-line:interface-name
  interface Window {
    moment: any;
    SR: ISweatRecord;
  }
}

/**
 * The ApplicationService type is responsible for instantiating services.
 */
export class ApplicationService implements IApplicationService, IApplicationInitiator {
  public static sharedService: IApplicationService & IApplicationInitiator;

  public currentUser: User;
  public localizationService: LocalizationService;
  public modelService: IModelService;
  public promiseService: PromiseService;
  public dataLayer: IDataLayer;
  private diffService: DiffService;
  private thinMenuView: ThinMenuView;
  private _coreServicesActivated: boolean;
  private _workoutServicesActivated: boolean;
  private viewService: ViewService;
  private stateManager: IPanelStateManager;

  public static getService(): IApplicationService {
    if (this.sharedService == null) {
      this.sharedService = new ApplicationService();
      this.sharedService.startCoreServices();
    }
    return this.sharedService;
  }

  public startCoreServices(): void {
    if (this._coreServicesActivated === true) {
      return;
    }
    if (window.SR == null) {
      window.SR = { applicationService: this };
    }
    this.thinMenuView = new ThinMenuView(null, $(), null);
    this.promiseService = new PromiseService();
    this.localizationService = new LocalizationService(this.promiseService);
    window.SR.promiseService = this.promiseService;
    window.SR.localizationService = this.localizationService;
    this._coreServicesActivated = true;
  }

  /**
   * Starts workout services.
   */
  public startWorkoutServices(): void {
    if (this._workoutServicesActivated === true) {
      log.trace('ApplicationService.startWorkoutServices - calling startAppRoot again');
      this.startApplicationRoot();
      return;
    }
    log.trace('ApplicationService.startWorkoutServices - starting');
    const SR = window.SR;
    window.SR.dataStore = new DataStore();
    this.diffService = new DiffService();
    window.SR.diffService = this.diffService;
    window.SR.modelService = new ModelService(window.SR.dataStore, this);
    this.modelService = window.SR.modelService;
    window.SR.dataStore._modelService = SR.modelService;
    this.viewService = new ViewService($, this, this.promiseService, this.localizationService);
    window.SR.viewService = this.viewService;
    SR.ajaxInterface = new AjaxInterface($);
    SR.serverManager = new ServerManager(SR.ajaxInterface);
    SR.dataLayer = new DataLayer(SR.serverManager, SR.dataStore, this);
    this.dataLayer = SR.dataLayer;
    this._workoutServicesActivated = true;
    this.localizationService.localizationReady().done(() => {
      this.startApplicationRoot();
    });
  }

  /**
   * Starts the workout copy service.
   * @param workoutUrl - The URL of the workout to be copied.
   */
  public startCopyServices(workoutUrl: string): void {
    const component = document.getElementById('reactLocation');
    ReactDOM.render(
      <WorkoutCopyViewModel
        viewService={this.viewService}
        application={this}
        workoutUrl={workoutUrl}
      />,
      component,
    );
  }

  private startApplicationRoot(): void {
    if (document.body.className.indexOf('workouts') >= 0) {
      log.debug('ApplicationService.startApplicationRoot - starting');
      const key = new Date().toISOString();
      ReactDOM.render(
        <ApplicationRoot appService={this} localization={this.localizationService} key={key} />,
        $('#app-root')[0],
      );
    } else {
      log.warn('ApplicationService.startApplicationRoot - failed');
    }
  }
}
