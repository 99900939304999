import { Model } from '../../Models/Model';
import { IModelService } from '../../Services/IModelService';
import { IDataLayer } from '../IDataLayer';
import { IResponseParser } from './IResponseParser';

interface ICompoundDocument<T extends Model> {
  data: T;
  included: Model[];
  links: {
    self: string;
  };
}

export class CompoundDocumentParser<T extends Model> implements IResponseParser<T> {
  private modelService: IModelService;
  private dataLayer: IDataLayer;

  public constructor(modelService: IModelService, dataLayer: IDataLayer) {
    this.modelService = modelService;
    this.dataLayer = dataLayer;
  }

  public parse(response: any): T {
    const responseDoc = response as ICompoundDocument<T>;
    const obj = this.modelService.createObject<T>(responseDoc.data);
    if (responseDoc.included) {
      responseDoc.included.forEach(value => {
        const newObj = this.modelService.createObject(value);
        this.dataLayer.storeModel(newObj);
      });
    }
    return obj;
  }
}
