import * as React from 'react';

export interface INoteLabelProps {
  label: string;
  contents: string;
  inputClass: string;
}

export interface INoteLabelState {}

export class InstructionsContainer extends React.PureComponent<INoteLabelProps, INoteLabelState> {
  public render(): JSX.Element {
    const classes = `sv-field ${this.props.inputClass}`;
    return (
      <label className="sv-textbox sv-instructions-container">
        <span className="sv-label-text">{this.props.label}</span>
        <div className={classes}>{this.props.contents}</div>
      </label>
    );
  }
}
