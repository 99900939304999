import * as React from 'react';
import { Workout } from '../../../../Models/Workout';
import { FormatDate } from '../../../Utilities/FormatDate';

interface IItemProps {
  status: string;
  id: string | number;
  largestCategory: string;
  name: string;
  plannedDate: string;
  workout: Workout;
  onSelect: (workout: Workout) => void;
  onOpen: (workout: Workout) => Promise<any>;
}

interface IItemState {
  isOpening: boolean;
}

// noinspection TsLint
require('./Item.scss');

export class Item extends React.PureComponent<IItemProps, IItemState> {
  public constructor(props: IItemProps, context?: any) {
    super(props, context);
    this.state = {
      isOpening: false,
    };
  }

  public render(): JSX.Element {
    return (
      <div
        className="workout-list-item workout"
        data-current-status={this.props.status}
        data-object-id={this.props.id}
        data-record="workout"
        onClick={this.handleSelectClick}
        data-category={this.props.largestCategory}
      >
        <div className="wl-status-header">
          <div>
            <i />
            <span />
          </div>
        </div>
        <div className="w-primary-header">
          <div className="workout-name">{this.props.name}</div>
          <div className="planned-date workoutItem-plannedDate">
            {this.renderDate(this.props.plannedDate)}
          </div>
        </div>
        <div className="w-second-header" onClick={this.handleOpenClick}>
          {this.buttonContent()}
        </div>
      </div>
    );
  }

  private buttonContent(): JSX.Element {
    if (this.state.isOpening) {
      return <i className="fa fa-spin fa-spinner" />;
    } else {
      return (
        <div className="content">
          <i />
          <span />
        </div>
      );
    }
  }

  private handleOpenClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (!this.state.isOpening) {
      this.setState({ isOpening: true });
      const promise = this.props.onOpen(this.props.workout);
      const reaction = () => this.setState({ isOpening: false });
      promise.then(reaction, reaction);
    }
  };

  private handleSelectClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    this.props.onSelect(this.props.workout);
  };

  private renderDate(date: string): string {
    return FormatDate.relative(date);
  }
}
