export function randomUUID(): string {
  const results = generateNumbers();
  return results.join('');
}

export default randomUUID;

function generateNumbers(count = 10): number[] {
  let _results;
  _results = [];
  for (let interval = 1; interval <= 10; interval++) {
    _results.push(createRandomNumber(0, 9));
  }
  return _results;
}

function createRandomNumber(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}
