import * as React from 'react';
import RandomUUID from '../../../../Extension/RandomUUID';
import { ILocalizedComponentProps, LocalizedPureComponent } from '../../../LocalizedComponent';

export interface IFormFieldProps extends ILocalizedComponentProps {
  value: string;
  valueError: string | null;
  modelName: string;
  propertyName: string;
  placeholder: string;
  isValid: boolean;
  labelPath: string;
  updateAction: (newValue: string) => void;
}

export interface IFormFieldState {}

export class FormField extends LocalizedPureComponent<IFormFieldProps, IFormFieldState> {
  private UUID: string = RandomUUID();

  public render(): JSX.Element {
    const inputClass = this.props.isValid ? `form-field` : `form-field form-field-error`;
    const inputName = `${this.props.modelName}[${this.props.propertyName}]`;
    const inputId = `${inputName}-${this.UUID}`;
    return (
      <div>
        <label htmlFor={inputId}>{this.localize(this.props.labelPath)}</label>
        <input
          className={inputClass}
          name={inputName}
          type="text"
          value={this.props.value}
          onChange={this.handleChange}
        />
        {!this.props.isValid && <div className="field-error">{this.props.valueError}</div>}
      </div>
    );
  }

  private handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.props.updateAction(event.target.value);
  };
}
