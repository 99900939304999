import * as React from 'react';
import * as _ from 'underscore';
import { IPanelHeaderActions } from './IPanelHeaderActions';
import { IPanelHeaderCategoryFilterState } from './IPanelHeaderCategoryFilterState';
import { ToggleButton } from './ToggleButton';

export interface IPanelHeaderProps {
  actions: IPanelHeaderActions;
  title: string;
}

export interface IPanelHeaderState {
  isSearching: boolean;
  searchText: string;
  categoryFilters: IPanelHeaderCategoryFilterState;
}

export class PanelHeader extends React.PureComponent<IPanelHeaderProps, IPanelHeaderState> {
  private categoryActions = {
    endurance: (): void => {
      this.updateCategoryFilter('endurance', !this.state.categoryFilters.endurance);
    },
    flexibility: (): void => {
      this.updateCategoryFilter('flexibility', !this.state.categoryFilters.flexibility);
    },
    strength: (): void => {
      this.updateCategoryFilter('strength', !this.state.categoryFilters.strength);
    },
  };

  public constructor(props: IPanelHeaderProps, context?: any) {
    super(props);
    this.state = {
      categoryFilters: {
        endurance: false,
        flexibility: false,
        strength: false,
      },
      isSearching: false,
      searchText: '',
    };
  }

  public render(): JSX.Element {
    return (
      <div className="panel-header">
        {this.state.isSearching ? this.renderHeaderSearch() : this.renderHeaderTitle()}
        {this.props.actions.onRefresh && (
          <ToggleButton
            baseClasses="panel-refresh"
            isActive={false}
            handleClick={this.props.actions.onRefresh}
          />
        )}
        {this.props.actions.onFilter && this.renderCategoryButtons()}
        {this.props.actions.onSearch && (
          <ToggleButton
            baseClasses="panel-search"
            isActive={this.state.isSearching}
            handleClick={this.handleSearchToggle}
            activeClass="active-search"
          />
        )}
        {this.props.actions.onDismiss && (
          <ToggleButton
            baseClasses="panel-dismissable"
            isActive={false}
            handleClick={this.props.actions.onDismiss}
          />
        )}
      </div>
    );
  }

  private renderHeaderTitle(): JSX.Element {
    return (
      <header className="panel-header-item">
        <span className="panel-header-content">{this.props.title}</span>
      </header>
    );
  }

  private renderHeaderSearch(): JSX.Element {
    return (
      <input
        type="text"
        name="panel-search"
        placeholder="Enter search terms here."
        className="panel-search panel-filter"
        value={this.state.searchText}
        onChange={this.handleSearchChange}
      />
    );
  }

  private updateCategoryFilter(category: DefinitionCategory, newValue: boolean) {
    const newCategory = _.clone(this.state.categoryFilters);
    (newCategory as any)[category] = newValue;
    this.setState({ categoryFilters: newCategory });
    this.props.actions.onFilter(newCategory);
  }

  private handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ searchText: e.target.value });
    this.props.actions.onSearch(e.target.value);
  };

  private renderCategoryButtons(): JSX.Element[] {
    return ['strength', 'flexibility', 'endurance'].map(category => {
      return (
        <ToggleButton
          baseClasses="panel-filter"
          key={category}
          handleClick={(this.categoryActions as any)[category]}
          filterCategory={category}
          isActive={(this.state.categoryFilters as any)[category]}
        />
      );
    });
  }

  private handleSearchToggle = (): void => {
    const searchText = this.state.isSearching ? '' : this.state.searchText;
    this.setState({ isSearching: !this.state.isSearching, searchText });
    this.props.actions.onSearch('');
  };
}
