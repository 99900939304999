import { IServerResult } from '../IServerResult';
import { ServerRequestHandler } from './ServerRequestHandler';

export class RequestHandlerCache<T> extends ServerRequestHandler<T> {
  public doneRequest(response: IServerResult<T>) {
    let parsedResponse;
    parsedResponse = this._responseParser.parse(response);
    this._objectManager.storeModel(parsedResponse);
    $(document).trigger('sr:object:update');
    return this._promise.resolve(parsedResponse);
  }
}
