import * as React from 'react';

export interface ITitleProps {
  title: string;
  labelClass: string;
}

export interface ITitleState {}

export class Title extends React.PureComponent<ITitleProps, ITitleState> {
  public render(): JSX.Element {
    const classes = `sv-title ${this.props.labelClass}`;
    return <div className={classes}>{this.props.title}</div>;
  }
}
