import * as React from 'react';
import { IExerciseDefinition } from '../../../../Models/IExerciseDefinition';
import { ILocalizedComponentProps, LocalizedPureComponent } from '../../../LocalizedComponent';
import { SelectionEntry } from './SelectionEntry';

export interface ISelectionListProps extends ILocalizedComponentProps {
  onChangeSearchAction: (newSearchText: string) => void;
  onSelectEntryAction: (definitionId: string) => void;
  definitions: IExerciseDefinition[];
  selectedDefinition: IExerciseDefinition;
  isMobile: boolean;
}

export interface ISelectionListState {}

const SEARCH_PLACEHOLDER_PATH = 'inline.search_exercise_definition';

export class SelectionList extends LocalizedPureComponent<
  ISelectionListProps,
  ISelectionListState
> {
  public render(): JSX.Element {
    return this.props.isMobile && this.props.selectedDefinition
      ? this.renderMobileSelected()
      : this.renderStandardForm();
  }

  private renderMobileSelected(): JSX.Element {
    const classes =
      'exercise-definition-selection-item exercise_definition exercise-definition-format';
    const def = this.props.selectedDefinition;
    return (
      <div
        className="existing-exercise-selector definition-selector-group selector-open"
        id="existing-exercise-selector"
      >
        <ul className="definition-search-results">
          <li
            className={classes}
            data-object-id={def.id}
            data-record="exercise_definition"
            data-category={def.category}
          >
            <div className="ed-status-header">
              <div className="difficulty">
                <i />
                <span />
              </div>
            </div>
            <div className="ed-primary-header">
              <div className="definition-name">
                {def.user_id != null && <i className="definition-ownership" />}
                {def.name}
              </div>
            </div>
            <button className="xbtn xbtn-primary" onClick={this.handleDeselect}>
              Change
            </button>
          </li>
        </ul>
      </div>
    );
  }

  private handleDeselect = (): void => {
    this.props.onSelectEntryAction(null);
  };

  private renderStandardForm(): JSX.Element {
    return (
      <div
        className="existing-exercise-selector definition-selector-group selector-open"
        id="existing-exercise-selector"
      >
        <div className="form-group">
          <input
            className="form-field"
            name="definition-name"
            type="search"
            onChange={this.handleChange}
            placeholder={this.localize(SEARCH_PLACEHOLDER_PATH)}
          />
        </div>
        <ul className="definition-search-results">{this.renderDefinitions()}</ul>
      </div>
    );
  }

  private handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.props.onChangeSearchAction(event.target.value);
  };

  private renderDefinitions(): JSX.Element[] {
    return this.props.definitions.map((def: IExerciseDefinition) => {
      const currentlySelected = this.props.selectedDefinition === def;
      return (
        <SelectionEntry
          name={def.name}
          category={def.category}
          key={def.id}
          previouslyUsed={def.user_id != null}
          id={def.id as string}
          currentlySelected={currentlySelected}
          selectionEvent={this.props.onSelectEntryAction}
        />
      );
    });
  }
}
