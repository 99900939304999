import { View } from './View';

/**
 * The ThinMenuView class handles the menu functions when the user is not signed in.
 */
export class ThinMenuView extends View<null> {
  public attachListeners() {
    $(document).on('click.thinmenuview', '.navbar-toggle[data-toggle=navbar]', e =>
      this.handleToggleClick(e),
    );
  }

  public detachListeners() {
    $(document).off('click.thinmenuview', '.navbar-toggle=[data-toggle=navbar]');
  }

  public handleToggleClick(event: JQueryEventObject) {
    const toggleTarget = $(event.currentTarget).attr('data-target');
    $(toggleTarget).toggleClass('display-contents');
  }
}
