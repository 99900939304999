import * as React from 'react';
import { IMenuEntryProps } from './IMenuEntryProps';
import { MenuEntry } from './MenuEntry';

export interface IMenuProps {
  items: IMenuEntryProps[];
  menuClass: string;
}

export interface IMenuState {}

export class MenuGroup extends React.PureComponent<IMenuProps, IMenuState> {
  public render(): JSX.Element {
    return <ul className={'menu ' + this.props.menuClass}>{this.renderItems()}</ul>;
  }

  private renderItems(): JSX.Element[] {
    return this.props.items.map(menuEntry => {
      return (
        <MenuEntry
          panelType={menuEntry.panelType}
          panelId={menuEntry.panelId}
          panelTitle={menuEntry.panelTitle}
          panelObjectId={menuEntry.panelObjectId}
          isOpen={menuEntry.isOpen}
          isDismissable={menuEntry.isDismissable}
          additionalClasses={menuEntry.additionalClasses}
          clickAction={menuEntry.clickAction}
          key={menuEntry.panelId}
          dismissAction={menuEntry.dismissAction}
          workout={menuEntry.workout}
        />
      );
    });
  }
}
