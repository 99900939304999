import * as log from 'loglevel';
import { ILocalizationService } from './ILocalizationService';
import { PromiseService } from './PromiseService';

/**
 * Class is responsible for localizing values.
 */
export class LocalizationService implements ILocalizationService {
  /**
   * Promise for the localization of the service.
   */
  public _promise: JQueryDeferred<any>;

  /**
   * Indicates whether the service has finished loading or not.
   */
  public _isLoaded: boolean;
  /**
   * Storage object for the localizations.
   */
  private localizations: any;

  constructor(promiseService: PromiseService) {
    this._promise = promiseService.emptyPromise();
  }

  /**
   * Returns whether the service has finished loading or not.
   * @returns {boolean}
   */
  public isLoaded(): boolean {
    return this._isLoaded;
  }

  /**
   * Returns a promise that resolves when the localization service is ready.
   */
  public localizationReady(): JQueryDeferred<any> {
    return this._promise;
  }

  /**
   * Sets the localization data.
   * @param data
   */
  public setData(data: any): void {
    this.localizations = data;
    this._isLoaded = true;
    this._promise.resolve();
  }

  /**
   * Returns the localization storage.
   * @returns {any}
   */
  public getData(): any {
    return this.localizations;
  }

  /**
   * Returns the value from the path specified.
   * @param path - Period delimited path of the data requested.
   * @returns {any} Localized value.
   */
  public getValue(path: string): any {
    const data = this.getData();
    return this.getNestedKey(data, path);
  }

  private getNestedKey(data: any, path: string): any {
    let key;
    let pList;
    let x;
    x = data;
    pList = path.split('.');
    // tslint:disable-next-line:prefer-for-of
    for (let index = 0; index < pList.length; index++) {
      key = pList[index];
      if (!x[key]) {
        log.warn('Unable to get Key', path, this.localizations);
        x = null;
        break;
      }
      x = x[key];
    }
    if (typeof x === 'string' || typeof x === 'object') {
      return x;
    } else {
      return 'Key Not Found - ' + path;
    }
  }
}
