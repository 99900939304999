import * as React from 'react';
import { IConfigurationEntry } from '../DefinitionSelection/IConfigurationEntry';

export interface IRadioButtonControlProps {
  options: IConfigurationEntry[];
  currentValue: string;
  updateAction: (newValue: string) => void;
  modelName: string;
  propertyName: string;
}

export interface IRadioButtonControlState {}

export class RadioButtonControl extends React.PureComponent<
  IRadioButtonControlProps,
  IRadioButtonControlState
> {
  public render(): JSX.Element {
    return <div className="radio-button-group">{this.renderButtons()}</div>;
  }

  private renderButtons(): JSX.Element[] {
    const inputName = `${this.props.modelName}[${this.props.propertyName}]`;
    return this.props.options.map(option => {
      const inputId = `${this.props.modelName}-${this.props.propertyName}-${option.key}`;
      const isChecked = this.props.currentValue === option.key;
      const inputClassName = `radio-button ${this.props.modelName}-${this.props.propertyName}-${
        option.key
      }`;
      return (
        <div className="radio-button" key={option.key}>
          <input
            type="radio"
            className={inputClassName}
            name={inputName}
            id={inputId}
            checked={isChecked}
            onChange={() => this.props.updateAction(option.key)}
            value={option.key}
          />
          <label htmlFor={inputId} className={inputClassName}>
            <i />
            <span>{option.title}</span>
          </label>
        </div>
      );
    });
  }
}
