import * as React from 'react';
import { FormatDate } from '../../../Utilities/FormatDate';

export interface IDateProps {
  date: string;
  label: string;
}

export interface IDateState {}

export class DateContainer extends React.PureComponent<IDateProps, IDateState> {
  public render(): JSX.Element {
    return (
      <label className="sv-inline-label">
        <span className="sv-label-text">{this.props.label}</span>
        <input
          type="text"
          readOnly={true}
          className="sv-workout-date sv-field"
          value={FormatDate.relative(this.props.date)}
        />
      </label>
    );
  }
}
