export function registerSlideEffect() {
  $.Velocity.RegisterEffect('SR.SelectionNavigateNext', {
    calls: [
      [{ opacity: [0, 1], translateX: -75, translateZ: 0 }, 0.5],
      [{ opacity: [1, 0], translateX: [0, 75], translateZ: 0 }, 0.5],
    ],
    defaultDuration: 600,
  });
  $.Velocity.RegisterEffect('SR.SelectionNavigatePrevious', {
    calls: [
      [{ opacity: [0, 1], translateX: 75, translateZ: 0 }, 0.5],
      [{ opacity: [1, 0], translateX: [0, -75], translateZ: 0 }, 0.5],
    ],
    defaultDuration: 600,
  });
}

export default registerSlideEffect;
