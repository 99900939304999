import * as React from 'react';
import * as _ from 'underscore';
import { IExerciseHistory } from '../../../../Models/History/IExerciseHistory';
import { IExerciseHistoryBase } from '../../../../Models/History/IExerciseHistoryBase';
import { ILocalizedComponentProps, LocalizedPureComponent } from '../../../LocalizedComponent';
import { HistoryFormatter } from '../../Helpers/History/HistoryFormatter';
import { IHistoryFormatter } from '../../Helpers/History/IHistoryFormatter';
import { IExerciseHistoryDisplayClasses } from '../../History/Exercise';
import { historyDisplayClasses } from '../../History/PanelViewClasses';
import { SectionWrapper } from '../EditObject/SectionWrapper';
import { DefinitionSelectionMode } from './DefinitionSelectionMode';
import { IQuickSetSettings } from './IQuickSetSettings';
import { PreviousExerciseSelector } from './PreviousExerciseSelector';
import { QuickSetFields } from './QuickSetFields';

export interface INewExerciseConfigurationProps extends ILocalizedComponentProps {
  quickSetProps: IQuickSetSettings;
  contentMode: NewExerciseContentMode;
  handleQuickSetUpdate: (count: number, intensity: string, target: string) => void;
  changeContentMode: (newMode: NewExerciseContentMode) => void;
  previousExercises: IExerciseHistory[];
  selectedExercise: IExerciseHistoryBase;
  selectionMode: DefinitionSelectionMode;
  selectPreviousExercise: (ex: IExerciseHistoryBase) => void;
}

export enum NewExerciseContentMode {
  Empty,
  QuickSet,
  CopyPrevious,
  NotPermitted,
}

export interface INewExerciseConfigurationState {}

const NONE_BUTTON_LABEL = 'definitions.select.new_contents.empty';
const NEW_EXERCISE_CONTENTS_TITLE = 'definitions.select.new_contents.title';

const exerciseDisplayClasses = _.clone(historyDisplayClasses.exercise);
exerciseDisplayClasses.exerciseEntry = 'hpv-exercise prevExerciseSelector-entry';

const displayClasses: IExerciseHistoryDisplayClasses = {
  exercise: exerciseDisplayClasses,
  exerciseSet: historyDisplayClasses.exerciseSet,
  setGroup: historyDisplayClasses.setGroup,
};

/**
 * The NewExerciseConfiguration class displays the forms that allow users to choose how the new
 * exercise will be configured. They will be given a choice between empty, quick setup, and copying a previous
 * exercise. See {@link NewExerciseContentMode} for choices.
 */
export class NewExerciseConfiguration extends LocalizedPureComponent<
  INewExerciseConfigurationProps,
  INewExerciseConfigurationState
> {
  private formatter: IHistoryFormatter = new HistoryFormatter();

  public render(): JSX.Element {
    return (
      <div className="newExerciseContent-section">
        <div className="definitionSelection-sectionTitle">
          {this.localize(NEW_EXERCISE_CONTENTS_TITLE)}
        </div>
        <SectionWrapper contentsClass="newExerciseContent-options">
          <div>
            <div className="newExerciseContent-option" onClick={this.switchToNone}>
              <div className="newExerciseContent-optionContent">
                {this.localize(NONE_BUTTON_LABEL)}
              </div>
              <div className="newExerciseContent-optionSelector">
                {this.emptyExerciseSelectorContents()}
              </div>
            </div>
            <QuickSetFields
              toggleEnabledState={this.switchToQuickSet}
              quickSetCount={this.props.quickSetProps.quickSetCount}
              intensityValue={this.props.quickSetProps.quickSetIntensity}
              targetValue={this.props.quickSetProps.quickSetTarget}
              updateQuickSetOptions={this.props.handleQuickSetUpdate}
              isEnabled={this.props.contentMode === NewExerciseContentMode.QuickSet}
            />
          </div>
          {this.props.selectionMode === DefinitionSelectionMode.Existing &&
            this.props.previousExercises.length > 0 && (
              <PreviousExerciseSelector
                exercises={this.formatter.formatHistory(this.props.previousExercises)}
                selectedExercise={this.props.selectedExercise}
                displayClasses={displayClasses}
                selectExercise={this.props.selectPreviousExercise}
              />
            )}
        </SectionWrapper>
      </div>
    );
  }

  private emptyExerciseSelectorContents(): JSX.Element {
    const cn =
      this.props.contentMode === NewExerciseContentMode.Empty
        ? 'newExerciseContent-optionIcon-selected'
        : 'newExerciseContent-optionIcon-notSelected';
    return <i className={cn} />;
  }

  private switchToNone = (event: React.MouseEvent<HTMLDivElement>): void => {
    this.props.changeContentMode(NewExerciseContentMode.Empty);
  };

  private switchToQuickSet = (): void => {
    this.props.changeContentMode(NewExerciseContentMode.QuickSet);
  };
}
