import * as React from 'react';
import { Exercise } from '../../../../Models/Exercise';
import { SetGroup } from '../../../../Models/SetGroup';
import { ILocalizedComponentProps, LocalizedPureComponent } from '../../../LocalizedComponent';

export interface IHoverButtonsProps extends ILocalizedComponentProps {
  model: Exercise | SetGroup;
  onCopy?: () => void;
  onDelete?: () => void;
  onAddSet?: () => void;
}

export interface IHoverButtonsState {}

export class HoverButtons extends LocalizedPureComponent<IHoverButtonsProps, IHoverButtonsState> {
  public render(): JSX.Element {
    return (
      <div className="hover-overlay">
        {this.props.onCopy && (
          <div className="hover-overlay-copy hover-overlay-icon" onClick={this.handleCopyClick}>
            {this.localize('object.set_group.copy')}
          </div>
        )}
        {this.props.onDelete && (
          <div className="hover-overlay-delete hover-overlay-icon" onClick={this.handleDeleteClick}>
            {this.localize('object.set_group.delete')}
          </div>
        )}
        {this.props.onAddSet && (
          <div className="hover-overlay-icon hover-overlay-add-set" onClick={this.handleAddClick}>
            {this.localize('object.exercise.add_set')}
          </div>
        )}
      </div>
    );
  }

  private handleCopyClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation();
    if (this.props.model instanceof SetGroup) {
      this.props.onCopy();
    }
  };

  private handleDeleteClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation();
    this.props.onDelete();
  };

  private handleAddClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation();
    if (this.props.model instanceof Exercise) {
      this.props.onAddSet();
    }
  };
}
