import * as React from 'react';
import { IGuideViewModel } from '../../../../ViewModels/GuideViewModel';
import { AbstractPanelViewContainer } from '../AbstractPanelViewContainer';
import { IPanelExternalData, IPanelInternalData } from '../IPanelData';
import { IPanelHeaderActions } from '../IPanelHeaderActions';
import { IPanelViewModelProps } from '../IPanelProps';
import { PanelTypes } from '../PanelTypes';
import { IGuideContentProps } from './GuideContent';

const GUIDE_CONTENT_PATH = '/journal/help';

export type IGuideContainerProps = IPanelViewModelProps<IGuideContentProps, IGuideViewModel>;

export interface IGuideContainerState {}

export class GuideContainer extends AbstractPanelViewContainer<
  IGuideContentProps,
  {},
  IGuideContainerState
> {
  public static initialPanelData(panelId: string): IPanelExternalData {
    return {
      hasFocus: false,
      hasTransactionOverlay: false,
      id: panelId,
      panelType: PanelTypes.Guide,
      parentPanelId: null,
      title: 'Guide',
    };
  }

  public static initialPanelProps(): IGuideContentProps {
    return { content: '' };
  }

  public componentDidMount(): void {
    super.componentDidMount();
    this.assignHelpContent();
  }

  public onDismiss(): void {
    this.removePanel();
  }

  /**
   * Loads the help content.
   */
  public assignHelpContent(): void {
    $.get({
      dataType: 'html',
      success: (a, b, c) => this.handleHelpSuccess(a, b, c),
      url: GUIDE_CONTENT_PATH,
    });
  }

  /**
   * Updates the view panel content once the request has been received.
   * @param data
   * @param statusText
   * @param request
   */
  public handleHelpSuccess(data: any, statusText: string, request: JQueryXHR) {
    this.updateContentProps({ content: data });
  }

  protected actionProps(): {} {
    return {};
  }

  protected panelData(): IPanelInternalData {
    return {
      additionalClasses: null,
      type: 'help',
    };
  }

  protected renderHeaderActions(): IPanelHeaderActions {
    return {
      onDismiss: this.handleDismiss,
    };
  }

  private handleDismiss = (): void => {
    this.onDismiss();
  };
}
