import { Model } from '../Models/Model';
import { AjaxInterface } from './Ajax/AjaxInterface';
import {
  AjaxObjectCreateJSON,
  AjaxObjectCreateScript,
  AjaxObjectDeleteJSON,
  AjaxObjectGetHTML,
  AjaxObjectGetJSON,
  AjaxObjectGetScript,
  AjaxObjectUpdateJSON,
  AjaxObjectUpdateScript,
} from './Ajax/AjaxObject';
import { IAjaxObject } from './Ajax/IAjaxObject';
import { RequestType } from './DataLayer';

/**
 * This class is responsible for handling the results of a server request.
 * It will handle both completed and failed transactions, returning a model object in the former instance.
 */
export class ServerManager {
  private ajaxInterface: AjaxInterface;

  constructor(ajaxInterface: AjaxInterface) {
    this.ajaxInterface = ajaxInterface;
  }

  /**
   * Loads the object and returns a promise when the request is complete.
   * @param {string, Model} objectOrPath - Either object or path of string.
   * @param {boolean, 'html'} scriptRequested - Whether the server should return a JS script (true), or JSON (false).
   * @param {*} dataObject - Any data object to append to the request.
   */
  public loadObject<T>(
    objectOrPath: string | T,
    scriptRequested?: RequestType,
    dataObject?: any,
  ): JQueryPromise<T> {
    let objectData: any;
    let url: string;
    if (typeof objectOrPath === 'string') {
      url = objectOrPath;
    } else if (objectOrPath instanceof Model) {
      objectData = this._getObjectData(objectOrPath);
      url = objectData.path;
    }
    const klass: any =
      scriptRequested === true
        ? AjaxObjectGetScript
        : scriptRequested === 'html'
        ? AjaxObjectGetHTML
        : AjaxObjectGetJSON;
    const ajaxObj = new klass(url, dataObject);
    return this.submitAjax(ajaxObj);
  }

  public saveObject(object: Model, scriptRequested: RequestType) {
    const objectData: any = this._getObjectData(object);
    const dataObj: any = {};
    dataObj[objectData.type] = objectData.changes;
    const klass = scriptRequested === true ? AjaxObjectUpdateScript : AjaxObjectUpdateJSON;
    const ajaxObj = new klass(objectData.path, dataObj);
    return this.submitAjax(ajaxObj);
  }

  public deleteObject(object: Model) {
    const objectData = this._getObjectData(object);
    const ajaxObj = new AjaxObjectDeleteJSON(objectData.path, null);
    return this.submitAjax(ajaxObj);
  }

  public createObject(
    dataObject: any,
    collectionURL: string,
    objectType: ModelType,
    scriptRequest: RequestType,
  ) {
    const objectData: any = {};
    objectData[objectType] = dataObject;
    const klass = scriptRequest === true ? AjaxObjectCreateScript : AjaxObjectCreateJSON;
    const ajaxObject = new klass(collectionURL, objectData);
    return this.submitAjax(ajaxObject);
  }

  private submitAjax(ajaxObject: JQueryAjaxSettings & IAjaxObject): JQueryPromise<any> {
    const promise = this.ajaxInterface.submitAjax(ajaxObject);
    promise.fail(this.handleUnauthorizedFailure);
    return promise;
  }

  private handleUnauthorizedFailure = (error: JQueryXHR) => {
    if (error.status === 401) {
      window.location.reload(true);
    }
  };

  private _getObjectData(object: any): any {
    let result;
    result = {};
    if (object.changes) {
      $.extend(result, {
        changes: object.changes(),
      });
    }
    if (object.type) {
      $.extend(result, {
        type: object.type,
      });
    }
    if (object.links && object.links.self) {
      $.extend(result, {
        path: object.links.self,
      });
    }
    return result;
  }
}
