import * as React from 'react';
import * as _ from 'underscore';
import { Model } from '../../../../Models/Model';
import { ILocalizedComponentProps, LocalizedComponent } from '../../../LocalizedComponent';

export interface IModelComponentProps<T extends Model> extends ILocalizedComponentProps {
  model: T;
}

/**
 * The ModelComponent class takes a model as a prop, and will re-render if:
 *
 * * the model's updated_at property has changed, or;
 * * any of the props or states have changed.
 *
 * This requires that all model changes trigger a change to their ancestors's updated_at property.
 */
export abstract class ModelComponent<
  T extends Model,
  TProps extends IModelComponentProps<T>,
  TState
> extends LocalizedComponent<TProps, TState> {
  private lastUpdatedTime: string;

  public constructor(props: TProps, context?: any) {
    super(props, context);
    this.lastUpdatedTime = props.model.updated_at;
  }

  public abstract render(): JSX.Element;

  public componentDidUpdate(prevProps: Readonly<TProps>, prevState: Readonly<TState>): void {
    this.lastUpdatedTime = this.props.model.updated_at;
  }

  public shouldComponentUpdate(nextProps: Readonly<TProps>, nextState: Readonly<TState>): boolean {
    return (
      nextProps.model.updated_at !== this.lastUpdatedTime ||
      !_.isEqual(this.state, nextState) ||
      !_.isEqual(this.props, nextProps)
    );
  }
}
