import * as React from 'react';
import { IConfiguration } from '../../../../../Models/IConfiguration';
import { ILocalizedComponentProps, LocalizedPureComponent } from '../../../../LocalizedComponent';
import { ConfigurationFieldSet } from './ConfigurationFieldSet';
import { DataEntryMode } from './DataEntryMode';
import { IConfigurationObject } from './IConfigurationObject';

export interface IConfigurationSectionProps extends ILocalizedComponentProps {
  parameters: { object: IConfiguration; isValid: boolean }[];
  removeParameter: (index: number) => void;
  updateParameter: (newValue: IConfiguration, index: number) => void;
  addParameter: () => void;
  configObject: IConfigurationObject;
  canAddParameter: boolean;
  updateValidity: (index: number, isValid: boolean) => void;
  mode: DataEntryMode;
}

export interface IConfigurationSectionState {}

export class ConfigurationSection extends LocalizedPureComponent<
  IConfigurationSectionProps,
  IConfigurationSectionState
> {
  public render(): JSX.Element {
    const intensityParameters = this.props.parameters.map((paramWrapper, index: number) => {
      const param = paramWrapper.object;
      return (
        <ConfigurationFieldSet
          configuration={param}
          key={index}
          remove={this.props.removeParameter}
          update={this.props.updateParameter}
          configurationMode={this.props.mode}
          updateValidity={this.props.updateValidity}
          configurationObject={this.props.configObject}
          index={index}
        />
      );
    });
    return (
      <div className="exercise-set-intensity-parameters">
        <div className="form-group-container">
          <div className="form-group-icon">
            <i className="exercise-set-intensity-icon" />
          </div>
          <div className="form-group-field">
            <label>{this.localize('object.exercise_set.intensity_parameters')}</label>
            <div className="exercise-set-intensity-parameter-fields">{intensityParameters}</div>
            {this.props.canAddParameter && (
              <div
                className="standard-button add-parameter"
                onClick={this.props.addParameter}
                data-help="edit_panel.object_form.exercise_set.intensity_parameter_add"
                id="object-panel-add-parameter"
              >
                <i className="icon-add" />
                {this.localize('object.exercise_set.add_parameter')}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
