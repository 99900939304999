import * as React from 'react';
import * as _ from 'underscore';
import { ExerciseDefinition } from '../../../../../Models/ExerciseDefinition';
import { IFormChangeData } from '../../../../../ViewModels/Panels/IObjectPanelViewModel';
import ConvertConfiguration from '../../../../Utilities/ConvertConfiguration';
import { ValueValidator } from '../../../Helpers/ValueValidator';
import { IConfigurationEntry } from '../../DefinitionSelection/IConfigurationEntry';
import { ImplementSelector } from '../../DefinitionSelection/ImplementSelector';
import { FormField } from '../FormField';
import { IObjectSectionProps, ObjectSection } from '../ObjectSection';
import { RadioButtonControl } from '../RadioButtonControl';
import { SectionWrapper } from '../SectionWrapper';

export interface IDefinitionSectionProps extends IObjectSectionProps<ExerciseDefinition> {}

export interface IDefinitionSectionState {
  name: string;
  nameError: string | null;
  nameIsValid: boolean;
  category: DefinitionCategory;
  implement: string;
  sided: boolean;
  note_content: string;
}

const categoryOptionsPath = 'configuration.exercise_definitions.categories';
const implementOptionsPath = 'configuration.exercise_definitions.implements';
const sidedOptionsPath = 'configuration.exercise_definitions.sided';
const updateProperties = ['name', 'category', 'implement', 'sided', 'note_content'];

export class DefinitionSection extends ObjectSection<
  ExerciseDefinition,
  IDefinitionSectionProps,
  IDefinitionSectionState
> {
  private categoryOptions: IConfigurationEntry[];
  private _implementOptions: IConfigurationEntry[];
  private _sidedOptions: IConfigurationEntry[];
  private lengthValidator = ValueValidator.lengthValidator(1, 140, () => this.state.name);

  public constructor(props: IDefinitionSectionProps) {
    super(props);
    const model = props.model;
    this.state = {
      category: model.category,
      implement: model.implement,
      name: model.name,
      nameError: null,
      nameIsValid: true,
      note_content: model.note_content,
      sided: model.sided,
    };
  }

  private get sidedString(): string {
    return this.state.sided === true ? 'true' : 'false';
  }

  private get sidedChoices(): IConfigurationEntry[] {
    if (this._sidedOptions == null) {
      this._sidedOptions = ConvertConfiguration(sidedOptionsPath, this.context);
    }
    return this._sidedOptions;
  }

  private get implements(): IConfigurationEntry[] {
    if (this._implementOptions == null) {
      this._implementOptions = ConvertConfiguration(implementOptionsPath, this.context);
    }
    return this._implementOptions;
  }

  private get categories(): IConfigurationEntry[] {
    if (this.categoryOptions == null) {
      this.categoryOptions = ConvertConfiguration(categoryOptionsPath, this.context);
    }
    return this.categoryOptions;
  }

  public render(): JSX.Element {
    return (
      <div>
        <SectionWrapper iconClass="workout-name-icon">
          <FormField
            propertyName="name"
            modelName="exercise_definition"
            updateAction={this.updateName}
            value={this.state.name}
            isValid={this.lengthValidator.isValid()}
            labelPath="object.exercise_definition.name"
            valueError={this.lengthValidator.error()}
            placeholder={this.localize('object.exercise_definition.name_placeholder')}
          />
        </SectionWrapper>
        <SectionWrapper>
          <RadioButtonControl
            options={this.categories}
            currentValue={this.state.category}
            updateAction={this.updateCategory}
            modelName="exercise_definition"
            propertyName="category"
          />
        </SectionWrapper>
        <ImplementSelector
          options={this.implements}
          currentValue={this.state.implement}
          sectionLabel={this.localize('object.exercise_definition.implement')}
          updateAction={this.updateImplement}
        />
        <SectionWrapper>
          <RadioButtonControl
            options={this.sidedChoices}
            currentValue={this.sidedString}
            propertyName="sided"
            updateAction={this.updateSided}
            modelName="exercise_definition"
          />
        </SectionWrapper>
        <SectionWrapper>
          <label htmlFor="exercise_definition_note_content">
            {this.localize('object.exercise_definition.note_content')}
          </label>
          <textarea
            id="exercise_definition_note_content"
            onChange={this.updateReminder}
            value={this.state.note_content}
          />
        </SectionWrapper>
      </div>
    );
  }

  protected updateFormData(): void {
    const updateData: IFormChangeData[] = _.map(updateProperties, value => {
      return {
        attribute: value,
        type: 'exercise_definition',
        value: (this.state as any)[value],
      };
    });
    this.props.updateFormData(updateData);
    this.props.updateValidity(this.lengthValidator.isValid());
  }

  private updateImplement = (newValue: { implement: string }): void => {
    this.setState({ implement: newValue.implement });
  };

  private updateCategory = (newValue: DefinitionCategory): void => {
    this.setState({ category: newValue });
  };

  private updateName = (newValue: string): void => {
    newValue = newValue.slice(0, 2000);
    this.setState({ name: newValue });
    const isValid = this.lengthValidator.isValid(newValue);
    this.props.updateValidity(isValid);
  };

  private updateSided = (newValue: string): void => {
    const assignValue = newValue === 'true';
    this.setState({ sided: assignValue });
  };

  private updateReminder = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    this.setState({ note_content: event.target.value });
  };
}
