import * as React from 'react';
import * as _ from 'underscore';
import { IPerformanceSpecification } from '../../../../../Models/IPerformanceSpecification';
import { ILocalizedComponentProps, LocalizedPureComponent } from '../../../../LocalizedComponent';
import { IConfigurationEntry } from '../../DefinitionSelection/IConfigurationEntry';
import { DataEntryMode } from './DataEntryMode';
import { IConfigurationObject } from './IConfigurationObject';
import { NumericField } from './NumericField';

export interface ISpecificationFieldSetProps extends ILocalizedComponentProps {
  specification: IPerformanceSpecification;
  index: number;
  configurationObject: IConfigurationObject;
  updateSpecification: (newValue: IPerformanceSpecification, index: number) => void;
  removeSpecification: (index: number) => void;
  updateValidity: (isValid: boolean, index: number) => void;
  configurationMode: DataEntryMode;
  isActualPermitted: boolean;
}

export interface ISpecificationFieldSetState {}

export class SpecificationFieldSet extends LocalizedPureComponent<
  ISpecificationFieldSetProps,
  ISpecificationFieldSetState
> {
  private get errorText(): string {
    const targetLowerWrongError = this.isTargetLowerValid
      ? null
      : 'Lower value must be below upper value.';
    const targetWrongError = this.isTargetValid ? null : 'Target value must be a number.';
    const actualWrongError = this.isActualValid ? null : 'Actual value must be a number.';
    return _.compact([targetLowerWrongError, targetWrongError, actualWrongError]).join(' ');
  }

  private get isActualValid(): boolean {
    return (
      !this.props.isActualPermitted ||
      (this.props.isActualPermitted && _.isNumber(this.props.specification.actual_value))
    );
  }

  private get isTargetValid(): boolean {
    return (
      !this.targetTypeAllowsTargetValue(this.props.specification.target_type) ||
      _.isNumber(this.props.specification.target_value)
    );
  }

  private get isTargetLowerValid(): boolean {
    return (
      !(this.props.specification.target_type === 'range') ||
      (this.props.specification.target_lower_value < this.props.specification.target_value &&
        _.isNumber(this.props.specification.target_lower_value))
    );
  }
  public render(): JSX.Element {
    return (
      <div className="performance-specification error-group">
        {this.props.configurationMode === DataEntryMode.Configuration
          ? this.renderConfigurationMode()
          : this.renderEntryMode()}
        <div className="error-container">{this.errorText}</div>
      </div>
    );
  }

  public componentDidUpdate(
    prevProps: Readonly<ISpecificationFieldSetProps>,
    prevState: Readonly<ISpecificationFieldSetState>,
    prevContext: any,
  ): void {
    const actualIsValid = this.isActualValid;
    const targetLowerIsValid = this.isTargetLowerValid;
    const targetValid = this.isTargetValid;
    this.props.updateValidity(
      _.all([actualIsValid, targetLowerIsValid, targetValid]),
      this.props.index,
    );
  }

  protected localize(path: string): string {
    return this.context.getValue(`specifications.${path}`);
  }

  private renderConfigurationMode(): JSX.Element {
    return (
      <div className="specification-field-group horizontal-group">
        <div className="form-horizontal-container specification-config">
          {this.renderUnitKind()}
          <label>{this.localize('unit_kind')}</label>
        </div>
        {this.renderUnitEntrySection()}
        <div className="form-horizontal-container specification-config">
          {this.renderTargetTypeInput()}
          <label>{this.localize('target_type')}</label>
        </div>
        <div className="standard-button remove-specification" onClick={this.handleRemoveClick}>
          <i className="icon-cancel" />
        </div>
      </div>
    );
  }

  private renderEntryMode(): JSX.Element {
    const interimText = 'and';
    const targetType = _.find(
      this.props.configurationObject.targetTypes,
      value => value.key === this.props.specification.target_type,
    );
    const targetTypeText = targetType.title;
    let specificationUnit: string;
    if (this.props.specification.unit_kind === 'custom') {
      specificationUnit = this.props.specification.unit_entry;
    } else {
      const unitEntries = this.props.configurationObject.unitEntries[
        this.props.specification.unit_kind
      ];
      const unitEntryText = _.find(
        unitEntries,
        value => value.key === this.props.specification.unit_entry,
      );
      specificationUnit = unitEntryText.title;
    }
    return (
      <div className="specification-field-group horizontal-group">
        <div className="form-horizontal-container specification-entry target-type">
          {targetTypeText}
        </div>
        {this.renderTargetLower()}
        {this.props.specification.target_type === 'range' ? (
          <div className="form-horizontal-container specification-entry target-type-interim">
            {interimText}
          </div>
        ) : null}
        {this.renderTarget()}
        <div className="form-horizontal-container specification-entry specification-unit">
          {specificationUnit}
        </div>
        {this.props.isActualPermitted && this.renderActual()}
        {this.props.isActualPermitted && (
          <div className="form-horizontal-container specification-entry specification-unit">
            {specificationUnit}
          </div>
        )}
        <div className="standard-button remove-specification" onClick={this.handleRemoveClick}>
          <i className="icon-cancel" />
        </div>
      </div>
    );
  }

  private renderUnitKind(): JSX.Element {
    const unitKinds = this.renderConfigurationOptions(this.props.configurationObject.unitKinds);
    return (
      <select
        name="exercise_set[performance_specifications][][unit_kind]"
        onChange={this.handleUnitKindChange}
        className="exercise-set-specification-unit-kind"
        value={this.props.specification.unit_kind}
      >
        {unitKinds}
      </select>
    );
  }

  private renderUnitEntrySection(): JSX.Element {
    const unitEntries = this.renderUnitEntries();
    if (unitEntries == null) {
      return null;
    }
    return (
      <div className="form-horizontal-container specification-config">
        {unitEntries}
        <label>{this.localize('unit_entry')}</label>
      </div>
    );
  }

  private renderUnitEntries(): JSX.Element {
    if (this.props.specification.unit_kind === 'custom') {
      return (
        <input
          className="form-field exercise-set-specification-unit-entry-custom"
          name="exercise_set[performance_specifications][][unit_entry]"
          onChange={this.handleUnitEntryChange}
          value={this.props.specification.unit_entry}
        />
      );
    }
    const unitEntries = this.props.configurationObject.unitEntries[
      this.props.specification.unit_kind
    ];
    const unitEntryOptions = this.renderConfigurationOptions(unitEntries);
    if (unitEntries.length <= 1) {
      return null;
    }
    return (
      <select
        name="exercise_set[performance_specifications][][unit_entry]"
        onChange={this.handleUnitEntryChange}
        value={this.props.specification.unit_entry}
        className="exercise-set-specification-unit-entry-standard"
      >
        {unitEntryOptions}
      </select>
    );
  }

  private renderTargetTypeInput(): JSX.Element {
    const targetTypes = this.renderConfigurationOptions(this.props.configurationObject.targetTypes);
    return (
      <select
        name="exercise_set[performance_specifications][][target_type]"
        value={this.props.specification.target_type}
        onChange={this.handleTargetTypeChange}
        className="exercise-set-specification-target-type"
      >
        {targetTypes}
      </select>
    );
  }

  private renderTargetLower(): JSX.Element {
    const placeholder = this.localize('target_lower');
    if (this.props.specification.target_type !== 'range') {
      return null;
    }
    return (
      <div className="form-horizontal-container specification-entry">
        <NumericField
          className="exercise-set-specification-target-lower-value form-field"
          updateValue={this.handleTargetLowerChange}
          name="exercise_set[performance_specifications][][target_lower_value]"
          value={this.props.specification.target_lower_value}
        />
        <label>{placeholder}</label>
      </div>
    );
  }

  private renderTarget(): JSX.Element {
    const targetType = this.props.specification.target_type;
    if (targetType === 'minimize' || targetType === 'maximize') {
      return null;
    }
    return (
      <div className="form-horizontal-container specification-entry">
        <NumericField
          className="exercise-set-specification-target-value form-field"
          updateValue={this.handleTargetChange}
          name="exercise_set[performance_specifications][][target_value]"
          value={this.props.specification.target_value}
        />
        <label>{this.localize('target_value')}</label>
      </div>
    );
  }

  private renderActual(): JSX.Element {
    if (!this.props.isActualPermitted) {
      return null;
    }
    return (
      <div className="form-horizontal-container specification-entry">
        <NumericField
          className="exercise-set-specification-actual-value form-field"
          updateValue={this.handleActualChange}
          name="exercise_set[performance_specifications][][actual_value]"
          value={this.props.specification.actual_value}
        />
        <label>{this.localize('actual_value')}</label>
      </div>
    );
  }

  // noinspection JSMethodCanBeStatic
  private renderConfigurationOptions(optionSet: IConfigurationEntry[]): JSX.Element[] {
    return optionSet.map(entry => {
      return (
        <option label={entry.title} value={entry.key} key={entry.key}>
          {entry.title}
        </option>
      );
    });
  }

  private handleRemoveClick = (): void => {
    this.props.removeSpecification(this.props.index);
  };

  private handleUnitKindChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    this.updateSpecification('unit_kind', event.target.value);
  };

  private handleUnitEntryChange = (
    event: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement>,
  ): void => {
    this.updateSpecification('unit_entry', event.target.value);
  };

  private handleTargetTypeChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    this.updateSpecification('target_type', event.target.value);
  };

  private handleTargetLowerChange = (newValue: number): void => {
    this.updateSpecification('target_lower_value', newValue);
  };

  private handleTargetChange = (newValue: number): void => {
    this.updateSpecification('target_value', newValue);
  };
  private handleActualChange = (newValue: number): void => {
    this.updateSpecification('actual_value', newValue);
  };

  private updateSpecification(propertyChanged: keyof IPerformanceSpecification, newValue: any) {
    const newSpecification = _.clone(this.props.specification);
    (newSpecification[propertyChanged] as any) = newValue;
    this.coerceSpec(newSpecification);
    this.props.updateSpecification(newSpecification, this.props.index);
  }

  /**
   * Updates the specification with valid values if inconsistent.
   * @param spec
   */
  private coerceSpec(spec: IPerformanceSpecification) {
    // tslint:disable-next-line:prefer-conditional-expression
    if (spec.target_type === 'range') {
      spec.target_lower_value = spec.target_lower_value || 0;
    } else {
      spec.target_lower_value = null;
    }
    // tslint:disable-next-line:prefer-conditional-expression
    if (this.targetTypeAllowsTargetValue(spec.target_type)) {
      spec.target_value = spec.target_value || 0;
    } else {
      spec.target_value = null;
    }
    // tslint:disable-next-line:prefer-conditional-expression
    if (this.props.isActualPermitted) {
      spec.actual_value = spec.actual_value || 0;
    } else {
      spec.actual_value = null;
    }
    if (spec.unit_kind !== 'custom') {
      spec.unit_entry = this.coerceUnitEntry(spec.unit_kind, spec.unit_entry);
    }
  }

  private coerceUnitEntry(newKind: string, currentEntry: string): string {
    const permittedValues = this.props.configurationObject.unitEntries[newKind];
    // If Current Entry in permitted Values keys, return as-is. Otherwise, return first kind.
    const isPermitted = _.any(permittedValues, entry => entry.key === currentEntry);
    return isPermitted ? currentEntry : permittedValues[0].key;
  }

  private targetTypeAllowsTargetValue(targetType: SpecificationTargetType) {
    return targetType !== 'maximize' && targetType !== 'minimize';
  }
}
