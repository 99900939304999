import * as log from 'loglevel';
import * as React from 'react';
import { Workout } from '../../../../Models/Workout';
import { INewWorkoutViewModel } from '../../../../ViewModels/Panels/INewWorkoutViewModel';
import { AbstractPanelViewContainer } from '../AbstractPanelViewContainer';
import { IPanelExternalData, IPanelInternalData } from '../IPanelData';
import { IPanelHeaderActions } from '../IPanelHeaderActions';
import { IPanelViewModelProps } from '../IPanelProps';
import { PanelTypes } from '../PanelTypes';
import { INewWorkoutFormActionProps, INewWorkoutFormProps } from './NewWorkoutForm';

export type NewWorkoutContainerProps = IPanelViewModelProps<
  INewWorkoutFormProps & INewWorkoutFormActionProps,
  INewWorkoutViewModel
>;

interface INewWorkoutContainerState {}

export class NewWorkoutContainer
  extends AbstractPanelViewContainer<
    INewWorkoutFormProps,
    {},
    INewWorkoutContainerState,
    INewWorkoutFormActionProps
  >
  implements INewWorkoutViewModel {
  public static initialPanelData(
    panelId: string,
    parentViewModelId: string | null = null,
  ): IPanelExternalData {
    return {
      hasFocus: false,
      hasTransactionOverlay: false,
      id: panelId,
      panelType: PanelTypes.NewWorkout,
      parentPanelId: parentViewModelId,
      title: 'New Session',
    };
  }

  public static initialPanelProps(workout: Workout): INewWorkoutFormProps {
    return {
      copiedWorkout: workout,
    };
  }
  public closeNewWorkoutPanel(): void {
    this.removePanel();
  }

  /**
   * Handles the new workout event when provided with workout data from the model.
   * @param {{name: string, planned_date: string, status: ['planned', 'completed'], notes: string}} workoutData -
   *   Workout data object.
   */
  public handleNewWorkoutRequest(workoutData: any): JQueryPromise<any> {
    const promise = this.modelService.addWorkout(
      this.props.appService.currentUser,
      workoutData,
      this.props.props.copiedWorkout,
    );
    promise.done((result: Workout) => {
      this.props.panelManager.openWorkout(result);
      this.props.completePromise.resolve();
      this.removePanel();
    });
    return promise.fail(error => {
      return log.error(error);
    });
  }

  protected actionProps(): INewWorkoutFormActionProps {
    return {
      viewModel: this,
    };
  }

  protected panelData(): IPanelInternalData {
    return {
      additionalClasses: null,
      type: 'new-workout',
    };
  }

  protected renderHeaderActions(): IPanelHeaderActions {
    return {
      onDismiss: this.onDismiss,
    };
  }

  private onDismiss = (): void => {
    this.closeNewWorkoutPanel();
  };
}
