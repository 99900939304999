import * as React from 'react';
import { IMenuEntryProps } from './IMenuEntryProps';

export interface IMenuEntryState {}

export class MenuEntry extends React.PureComponent<IMenuEntryProps, IMenuEntryState> {
  public render(): JSX.Element {
    let title: JSX.Element;
    if (this.props.workout) {
      title = (
        <div className="menu-item-text">
          <div className="workout-name">{this.props.workout.name}</div>
        </div>
      );
    } else {
      title = <div className="menu-item-text">{this.props.panelTitle}</div>;
    }
    return (
      <li
        className={'menu-item menu-panel-item ' + this.props.additionalClasses}
        data-panel={this.props.panelId}
        data-panel-type={this.props.panelType}
        data-panel-object-id={this.props.panelObjectId}
        onClick={this.props.clickAction}
      >
        <div className="menu-item-icon">
          <i />
        </div>
        {title}
        {this.props.isOpen &&
          this.props.isDismissable && (
            <div className="menu-item-dismiss" onClick={this.props.dismissAction}>
              <button className="menu-panel-dismiss" />
            </div>
          )}
      </li>
    );
  }
}
