import * as moment from 'moment';
import { IWorkoutProps } from '../../../../Models/IWorkoutProps';
import { INewWorkoutData } from './INewWorkoutData';

export class NewWorkoutGenerator {
  public static generateNewWorkout(copiedWorkout: IWorkoutProps | null): INewWorkoutData {
    const moment2 = NewWorkoutGenerator.generateNewTime();
    return copiedWorkout
      ? this.copyWorkout(copiedWorkout)
      : {
          instructions: '',
          name: 'New Workout',
          plannedDate: moment2.toISOString(),
          review: '',
        };
  }

  private static generateNewTime(): moment.Moment {
    return moment()
      .startOf('hour')
      .add(1, 'hour');
  }

  private static copyWorkout(copiedWorkout: IWorkoutProps): INewWorkoutData {
    const parsedDate = moment(copiedWorkout.planned_date);
    const date: moment.Moment = parsedDate.add(7, 'days');
    const plannedDate = date.isBefore() ? this.generateNewTime().toISOString() : date.toISOString();
    return {
      instructions: copiedWorkout.instructions,
      name: copiedWorkout.name,
      plannedDate,
      review: '',
    };
  }
}
