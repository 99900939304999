import * as _ from 'underscore';
import { IPredictionSet } from '../../../Models/ExerciseSet';
import { IExerciseSet } from '../../../Models/IExerciseSet';
import { IPerformanceSpecification } from '../../../Models/IPerformanceSpecification';
import { IPredictionProvider } from './IPredictionProvider';

/**
 * The PredictionProvider takes a target with a supplied prediction set and returns the prediction text.
 */
export class PredictionProvider implements IPredictionProvider {
  public predictForSet(exerciseSet: IExerciseSet): string {
    return this.predict(exerciseSet.performance_specifications, exerciseSet.estimated_weights);
  }

  /**
   * Generates a prediction set.
   * @param specifications The performance specifications to be predicted for.
   * @param predictionSet The prediction set to generate the prediction from.
   */
  public predict(
    specifications: IPerformanceSpecification[],
    predictionSet: IPredictionSet,
  ): string {
    if (specifications.length !== 1) {
      return '';
    }
    const specification = specifications[0];
    if (specification.unit_kind !== 'counting') {
      return '';
    }
    if (specification.target_type === 'specific') {
      return this.predictForRep(predictionSet, specification.target_value);
    } else if (specification.target_type === 'range') {
      const lowerPrediction = this.predictForRep(predictionSet, specification.target_lower_value);
      const upperPrediction = this.predictForRep(predictionSet, specification.target_value);
      if (_.isString(lowerPrediction) && _.isString(upperPrediction)) {
        return `${lowerPrediction} - ${upperPrediction}`;
      } else {
        return '';
      }
    }
    return '';
  }

  //noinspection JSMethodCanBeStatic
  /**
   * Returns the prediction for a specific rep.
   * @param predictionSet
   * @param repCount
   * @returns {any}
   */
  public predictForRep(predictionSet: IPredictionSet, repCount: number): string {
    return predictionSet[repCount];
  }
}
