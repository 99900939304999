import * as _ from 'underscore';
import { Exercise } from './Exercise';
import { IConfiguration } from './IConfiguration';
import { IExerciseSet, IPlateSet } from './IExerciseSet';
import { IPerformanceSpecification } from './IPerformanceSpecification';
import { IWorkoutDescendant } from './IWorkoutDescendant';
import { Model } from './Model';
import { SetGroup } from './SetGroup';
import { Workout } from './Workout';

export interface IPredictionSet {
  [key: number]: string;
}

export class ExerciseSet extends Model implements IExerciseSet, IWorkoutDescendant {
  public _parentType: ModelType = 'set_group';
  public _parentReferenceProperty = 'set_group_id';

  public set_group_id: number;
  public position: number;
  public performance_specifications: IPerformanceSpecification[];
  public configurations: IConfiguration[];
  public plates: IPlateSet;
  public estimated_weights: IPredictionSet;
  public status: ModelStatus;
  public difficulty: ModelDifficulty;

  public secondaryConfigurations(): IConfiguration[] {
    return this.configurations.slice(1);
  }

  public validate() {
    const changes = _.pick(this.changes(), 'difficulty', 'status');
    const changeKeys = _.keys(changes);
    if (changeKeys.length !== 1) {
      return true;
    }
    if (changeKeys[0] === 'status') {
      if (changes.status === 'completed' && this.difficulty === 'not_set') {
        this.difficulty = 'medium';
      } else if (changes.status === 'planned' && this.difficulty !== 'not_set') {
        this.difficulty = 'not_set';
      }
    }
    if (changeKeys[0] === 'difficulty') {
      if (changes.difficulty !== 'not_set' && this.status === 'planned') {
        return (this.status = 'completed');
      } else if (changes.difficulty === 'not_set' && this.status !== 'planned') {
        return (this.status = 'planned');
      }
    }
  }

  public workout(): Workout {
    return this.setGroup().workout();
  }

  public exercise(): Exercise {
    return this.setGroup().parent() as Exercise;
  }

  public setGroup(): SetGroup {
    return this.parent() as any;
  }
}
