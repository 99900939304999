import * as React from 'react';
import { ILocalizedComponentProps, LocalizedPureComponent } from '../../../LocalizedComponent';

export interface IQuickSetFormProps extends ILocalizedComponentProps {
  toggleEnabledState: () => void;
  quickSetCount: number;
  intensityValue: string;
  targetValue: string;
  /**
   * Callback used to update form with the new quick set options.
   * @param setCount
   * @param intensityValue
   * @param targetValue
   */
  updateQuickSetOptions: (setCount: number, intensityValue: string, targetValue: string) => void;
  isEnabled: boolean;
}

export interface IQuickSetFormState {
  isValid: boolean;
}

const NEW_EXERCISE_QUICK_SET_TITLE = 'definitions.select.new_contents.quick_sets';

export class QuickSetFields extends LocalizedPureComponent<IQuickSetFormProps, IQuickSetFormState> {
  private targetInput: HTMLInputElement;
  private intensityInput: HTMLInputElement;
  private quickSetInput: HTMLInputElement;

  public render(): JSX.Element {
    return (
      <div className="newExerciseContent-option" onClick={this.props.toggleEnabledState}>
        <div className="newExerciseContent-optionContent">
          <span>{this.localize(NEW_EXERCISE_QUICK_SET_TITLE)}</span>

          {this.props.isEnabled && (
            <div className="definition-selector-quick-sets-fields">
              <label>
                <input
                  type="number"
                  name="numberOfSets"
                  ref={input => (this.quickSetInput = input)}
                  className="form-field"
                  value={this.props.quickSetCount}
                  onChange={this.handleInputChange}
                />
                <span>{this.localize('definitions.select.quick_sets_count')}</span>
              </label>
              <label>
                <input
                  type="text"
                  name="intensityValue"
                  className="form-field"
                  ref={input => (this.intensityInput = input)}
                  value={this.props.intensityValue}
                  onChange={this.handleInputChange}
                />
                <span>{this.localize('definitions.select.intensity_value')}</span>
              </label>
              <label>
                <input
                  type="text"
                  name="targetValue"
                  className="form-field"
                  value={this.props.targetValue}
                  ref={input => (this.targetInput = input)}
                  onChange={this.handleInputChange}
                />
                <span>{this.localize('definitions.select.target_value')}</span>
              </label>
            </div>
          )}
        </div>
        <div className="newExerciseContent-optionSelector">{this.selectorContents()}</div>
      </div>
    );
  }

  private handleInputChange = () => {
    this.props.updateQuickSetOptions(
      this.quickSetInput.valueAsNumber,
      this.intensityInput.value,
      this.targetInput.value,
    );
  };

  private selectorContents(): JSX.Element {
    const cn = this.props.isEnabled
      ? 'newExerciseContent-optionIcon-selected'
      : 'newExerciseContent-optionIcon-notSelected';
    return <i className={cn} />;
  }
}
