import * as _ from 'underscore';
import { Exercise } from './Exercise';
import { IWorkoutDescendant } from './IWorkoutDescendant';
import { Model } from './Model';
import { Workout } from './Workout';

export class ExerciseGroup extends Model implements IWorkoutDescendant {
  public _childType: ModelType = 'exercise';
  public _childReferenceProperty = 'exercise_group_id';
  public _childCollectionName = 'exercises';
  public _parentType: ModelType = 'workout';
  public _parentReferenceProperty = 'workout_id';
  public rest_set: string;
  public rest_exercise: string;
  /**
   * The position value can be updated by the client. When increasing the position value, any items before
   * its new position (including the item currently at that position) are decremented.
   */
  public position: number;

  public shouldShowRestSet(): boolean {
    let childrenArray;
    childrenArray = this.children();
    return _.any(childrenArray, function(exercise: Exercise) {
      return exercise.children().length > 1;
    });
  }

  public shouldShowRestExercise(): boolean {
    return this.children().length > 1;
  }

  public workout(): Workout {
    return (this.parent() as any) as Workout;
  }

  public exercises(): Exercise[] {
    return this.children() as Exercise[];
  }
}
