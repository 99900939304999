import { Workout } from '../../Models/Workout';
import { ILocalizationService } from '../../Services/ILocalizationService';

export interface IWorkoutListGroup {
  workouts: Workout[];
  type: string;
  period: string;
  position: number;
  readonly title: string;
}

// tslint:disable-next-line:max-classes-per-file
export class WorkoutListGroup implements IWorkoutListGroup {
  public workouts: Workout[];
  public type: string;
  public period: string;
  public position: number;
  public localizationService: ILocalizationService;

  public momentValue(): number {
    let momentValue: moment.Moment;
    if (this.period === 'future') {
      momentValue = moment().endOf('year');
    } else if (this.period === 'next-week') {
      momentValue = moment()
        .add(1, 'week')
        .endOf('week');
    } else if (this.period === 'this-week') {
      momentValue = moment().endOf('week');
    } else if (this.period === 'this-month') {
      momentValue = moment().startOf('month');
    } else if (this.period === 'last-month') {
      momentValue = moment()
        .startOf('month')
        .subtract(1, 'month');
    } else {
      momentValue = moment(this.period, 'YYYY-MM');
    }
    return momentValue.valueOf();
  }

  get title(): string {
    if (this.type === 'relative') {
      return this.localizationService.getValue('timeframes.' + this.period);
    } else {
      return moment(this.period, 'YYYY-MM').format('MMMM YYYY');
    }
  }
}
