import { Model } from '../../Models/Model';
import { IServerResult } from '../IServerResult';
import { ServerRequestHandler } from './ServerRequestHandler';

export class RequestHandlerDelete<T extends Model> extends ServerRequestHandler<T> {
  private _deleteObject: Model;

  public doneRequest(response: IServerResult<T>) {
    this._objectManager.deleteData(this._deleteObject);
    if (this._responseParser) {
      this._responseParser.parse(response);
    }
    $(document).trigger('sr:object:update');
    return this._promise.resolve();
  }

  public deleteObject(deleteObject: T) {
    this._deleteObject = deleteObject;
  }
}
