import * as React from 'react';
import { IPerformanceSpecification } from '../../../../Models/IPerformanceSpecification';
import { ILocalizedComponentProps, LocalizedPureComponent } from '../../../LocalizedComponent';
import { ExerciseSetFormatter } from '../../Helpers/History/ExerciseSetFormatter';
import { IExerciseSetFormatter } from '../../Helpers/History/IExerciseSetFormatter';
import { SpecificationEntry } from './SpecificationEntry';

export interface ISpecificationsListProps extends ILocalizedComponentProps {
  specifications: IPerformanceSpecification[];
  selectedIndex: number | null;
  selectedValue: 'actual' | 'target' | null;
  actualsSelectable: boolean;
  onSelect: (index: number, selectedValue: 'actual' | 'target') => void;
}

export interface ISpecificationsListState {}

export class SpecificationsList extends LocalizedPureComponent<
  ISpecificationsListProps,
  ISpecificationsListState
> {
  private formatter: IExerciseSetFormatter = new ExerciseSetFormatter();

  public render(): JSX.Element {
    return (
      <div className="sv-specifications-section">
        <header className="sv-parameter-header">
          <div className="sv-parameter-target">{this.localize('specifications.target_value')}</div>
          <div />
          <div className="sv-parameter-actual">{this.localize('specifications.actual_value')} </div>
        </header>
        {this.renderSpecificationEntries()}
      </div>
    );
  }

  private renderSpecificationEntries(): JSX.Element[] {
    return this.props.specifications.map(
      (specification: IPerformanceSpecification, index: number) => {
        const formattedSet = this.formatter.formatSpecification(specification);
        return (
          <SpecificationEntry
            isSelected={this.getSelectedValue(index)}
            targetValue={formattedSet.target}
            actualValue={formattedSet.actual}
            unit={formattedSet.unit}
            targetSelectable={this.isTargetSelectable(specification)}
            actualSelectable={this.props.actualsSelectable}
            specificationIndex={index}
            selectAction={this.props.onSelect}
            key={index}
          />
        );
      },
    );
  }

  private getSelectedValue(index: number): 'actual' | 'target' | null {
    if (this.props.selectedIndex !== index) {
      return null;
    }
    return this.props.selectedValue;
  }

  /**
   * Determines whether the passed specification's target value can be selected.
   * @param specification
   * @returns {boolean}
   */
  private isTargetSelectable(specification: IPerformanceSpecification): boolean {
    switch (specification.target_type) {
      case 'range':
      case 'specific':
      case 'greater_than':
      case 'less_than':
        return true;
      default:
        return false;
    }
  }
}
