import * as React from 'react';
import { IExerciseDefinition } from '../../Models/IExerciseDefinition';
import { ILocalizedComponentProps, LocalizedPureComponent } from '../LocalizedComponent';
import { ImportDefinitionRow } from './ImportDefinitionRow';
import { IWorkoutCopyFormDelegate } from './IWorkoutCopyFormDelegate';
import { MapResult } from './MapResult';

export interface IMappingDialogProps extends ILocalizedComponentProps {
  mappings: MapResult[];
  searchResults: IExerciseDefinition[];
  delegate: IWorkoutCopyFormDelegate;
  selectedDefinition: IExerciseDefinition;
  searchEntry: string;
  selectedItem: MapResult | null;
}

export interface IMappingDialogState {}

const MAPPING_TITLE_PATH = 'copy_workout_link.mapping_title';

export class MappingDialog extends LocalizedPureComponent<
  IMappingDialogProps,
  IMappingDialogState
> {
  public render(): JSX.Element {
    return (
      <div className="form-group-container">
        <div className="form-group-icon">
          <i className="exercise-definition-icon" />
        </div>
        <div className="form-group-field">
          <div>
            <strong>{this.localize(MAPPING_TITLE_PATH)}</strong>
          </div>
          {this.renderRows()}
        </div>
      </div>
    );
  }

  private renderRows(): JSX.Element[] {
    return this.props.mappings.map(mapResult => {
      const isSelected = mapResult === this.props.selectedItem;
      const searchResults = isSelected ? this.props.searchResults : [];
      return (
        <ImportDefinitionRow
          mapResult={mapResult}
          isSelected={isSelected}
          searchResults={searchResults}
          delegate={this.props.delegate}
          selectedDefinition={this.props.selectedDefinition}
          searchEntry={this.props.searchEntry}
          key={mapResult.origin_uid}
        />
      );
    });
  }
}
