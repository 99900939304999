import { DataCollection } from './Collections/DataCollection';
import { ExerciseDefinition } from './ExerciseDefinition';
import { ExerciseGroup } from './ExerciseGroup';
import { IExerciseHistory } from './History/IExerciseHistory';
import { IExercise, IExerciseDefinitionStub } from './IExercise';
import { IExerciseTempo } from './IExerciseTempo';
import { IWorkoutDescendant } from './IWorkoutDescendant';
import { Model } from './Model';
import { SetGroup } from './SetGroup';
import { Workout } from './Workout';

export class Exercise extends Model implements IWorkoutDescendant, IExercise {
  public instructions: string;
  public note_content: string;
  public definition_note: string;
  public bar_weight: string;
  public effective_bar_weight: string;
  public status: ModelStatus;
  public exercise_definition_uuid: string;
  public exercise_definition: IExerciseDefinitionStub;
  public eccentric: string;
  public concentric: string;
  public rest: string;
  public pause: string;
  public position: number;
  public links: {
    self: string;
    history: string;
  };
  public exercise_group_id: string;
  public _childType: ModelType = 'set_group';
  public _childReferenceProperty = 'exercise_id';
  public _childCollectionName = 'set_groups';
  public _parentType: ModelType = 'exercise_group';
  public readonly _parentReferenceProperty: 'exercise_group_id' = 'exercise_group_id';
  protected _historyCollection: DataCollection<Exercise, IExerciseHistory>;

  public get tempo(): IExerciseTempo {
    return {
      concentric: this.concentric,
      eccentric: this.eccentric,
      pause: this.pause,
      rest: this.rest,
    };
  }

  public parent(): ExerciseGroup {
    if (this._parentType) {
      return this.dataStore().getData<ExerciseGroup>(
        this._parentType,
        this[this._parentReferenceProperty],
      );
    }
  }

  public history(): DataCollection<Exercise, IExerciseHistory> {
    if (this._historyCollection == null) {
      this._historyCollection = new DataCollection<Exercise, IExerciseHistory>(
        this.links.history,
        this.dataStore(),
        this,
        'exercise-history',
        this.promiseService(),
      );
    }
    return this._historyCollection;
  }

  public workout(): Workout {
    return this.parent().workout();
  }

  public exerciseGroup(): ExerciseGroup {
    return this.parent() as ExerciseGroup;
  }

  public exerciseDefinition(): ExerciseDefinition {
    return this.dataStore().getData<ExerciseDefinition>(
      'exercise_definition',
      this.exercise_definition_uuid,
    );
  }

  /**
   * Returns a position-ordered collection of set groups.
   * @returns {SetGroup[]}
   */
  public setGroups(): SetGroup[] {
    return this.children() as SetGroup[];
  }

  protected updateData(dataObj: any) {
    let changeDiff;
    changeDiff = this.calculateDiff(this.getSavedData(), dataObj);
    $.extend(this, dataObj);
    this.triggerUpdate(changeDiff);
    if (this.changes().exercise_definition_uuid != null) {
      return this.history().clear();
    }
  }
}
