import * as React from 'react';
import { ILocalizedComponentProps, LocalizedPureComponent } from '../../../../LocalizedComponent';
import { DataEntryMode } from './DataEntryMode';

export interface IModePickerProps extends ILocalizedComponentProps {
  updateMode: (newMode: DataEntryMode) => void;
  mode: DataEntryMode;
}

export interface IModePickerState {}

const TITLE_PATH = 'edit_panel.object_form.exercise_set.display_mode.title';

export class ModePicker extends LocalizedPureComponent<IModePickerProps, IModePickerState> {
  public render(): JSX.Element {
    return (
      <div className="form-group-container">
        <div className="form-group-icon" />
        <div className="form-group-field">
          <label>{this.localize(TITLE_PATH)}</label>
          <div className="radio-group">
            <label>
              <input
                type="radio"
                value="input"
                name="entry_mode"
                onChange={this.changeToInputMode}
                checked={this.props.mode === DataEntryMode.Input}
                className="config-mode-radio"
              />
              <span>
                {this.localize('edit_panel.object_form.exercise_set.display_mode.data_entry')}
              </span>
            </label>
            <label>
              <input
                type="radio"
                value="configure"
                name="entry_mode"
                className="config-mode-radio"
                onChange={this.changeToConfigureMode}
                checked={this.props.mode === DataEntryMode.Configuration}
              />
              <span>
                {this.localize('edit_panel.object_form.exercise_set.display_mode.config')}
              </span>
            </label>
          </div>
        </div>
      </div>
    );
  }

  private changeToInputMode = (): void => {
    this.props.updateMode(DataEntryMode.Input);
  };

  private changeToConfigureMode = (): void => {
    this.props.updateMode(DataEntryMode.Configuration);
  };
}
