import * as React from 'react';
import { IExerciseHistoryFormatted } from '../Helpers/History/IExerciseHistoryFormatted';
import { IExerciseSetDisplayClasses } from './ExerciseSet';
import { ISetGroupDisplayClasses, SetGroup } from './SetGroup';

export interface IExerciseHistoryDisplayClasses {
  exercise: IExerciseDisplayClasses;
  setGroup: ISetGroupDisplayClasses;
  exerciseSet: IExerciseSetDisplayClasses;
}

export interface IExerciseProps {
  exercise: IExerciseHistoryFormatted;
  highlightPosition: number | null;
  displayClasses: IExerciseHistoryDisplayClasses;
  openWorkoutFromUrl?: (workoutUrl: string) => Promise<any>;
}

export interface IExerciseDisplayClasses {
  exerciseEntry: string;
  exerciseData: string;
  exerciseTitle: string;
  workoutName: string;
  plannedDate: string;
  exerciseHeader: string;
  restSetContainer: string;
  reviewContainer: string;
  superSetExerciseContainer: string;
  setGroupsContainer: string;
  exerciseOpenButton: string;
}

export interface IExerciseState {
  isOpening: boolean;
}

export class Exercise extends React.PureComponent<IExerciseProps, IExerciseState> {
  public constructor(props: IExerciseProps, context?: any) {
    super(props, context);
    this.state = {
      isOpening: false,
    };
  }

  public render(): JSX.Element {
    const exercise = this.props.exercise;
    const setGroups = exercise.set_groups.map((group, index) => {
      return (
        <SetGroup
          highlightPosition={this.props.highlightPosition}
          displayClasses={this.props.displayClasses}
          setGroup={group}
          position={index}
          key={group.id}
        />
      );
    });
    const workoutUrl = `/Journal/workouts/${exercise.workout_id}`;
    const tempo = exercise.tempo;
    return (
      <div
        className={this.displayClasses().exerciseEntry}
        data-object-id={exercise.id}
        data-workout-url={workoutUrl}
        data-workout-id={exercise.workout_id}
        key={exercise.id}
      >
        <div className={this.displayClasses().exerciseData}>
          <div className={this.displayClasses().exerciseTitle}>
            <div className={this.displayClasses().workoutName}>{exercise.workout_name}</div>
            <div className={this.displayClasses().plannedDate}>
              <span>
                <i />
              </span>{' '}
              {this.renderRelativeDate(exercise.date)}
            </div>
          </div>
          <div className={this.displayClasses().exerciseHeader}>
            {tempo.concentric && (
              <div className="tempo">
                <span>
                  <i className="exercise-tempo-icon" />
                </span>
                {tempo.concentric}/{tempo.pause}/{tempo.eccentric}/{tempo.rest}
              </div>
            )}
            {exercise.rest_set && (
              <div className={this.displayClasses().restSetContainer}>
                <span>
                  <i className="exercise-group-rest-set-icon" />
                </span>{' '}
                {exercise.rest_set}
              </div>
            )}
            {exercise.note_content && (
              <div className={this.displayClasses().reviewContainer}>
                <span>
                  <i className="exercise-note-content-icon" />
                </span>{' '}
                {exercise.note_content}
              </div>
            )}
            {exercise.super_set_exercises && exercise.super_set_exercises.length > 0 && (
              <div className={this.displayClasses().superSetExerciseContainer}>
                <span>
                  <i />
                  Super-set{' '}
                </span>{' '}
                {exercise.super_set_exercises}
              </div>
            )}
          </div>
          <div className={this.displayClasses().setGroupsContainer}>{setGroups}</div>
        </div>
        {this.props.openWorkoutFromUrl && (
          <div
            className={this.displayClasses().exerciseOpenButton}
            onClick={this.handleOpenExercise}
          >
            <div>{this.openExerciseContent()}</div>
          </div>
        )}
      </div>
    );
  }

  private openExerciseContent(): JSX.Element {
    if (this.state.isOpening) {
      return <i className="fa fa-spin fa-spinner" />;
    } else {
      return <i className="hpv-openExerciseButtonIcon" />;
    }
  }

  private handleOpenExercise = (): void => {
    if (this.props.openWorkoutFromUrl && !this.state.isOpening) {
      this.setState({ isOpening: true });
      const promise = this.props.openWorkoutFromUrl(this.props.exercise.workout_url);
      const react = () => this.setState({ isOpening: false });
      promise.then(react, react);
    }
  };

  private displayClasses(): IExerciseDisplayClasses {
    return this.props.displayClasses.exercise;
  }

  private renderRelativeDate(date: Date): string {
    return moment(date)
      .fromNow()
      .toTitleCase();
  }
}
