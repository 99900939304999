import { IAjaxObject } from './IAjaxObject';

/**
 * AjaxInterface abstracts AJAX calls.
 */
export class AjaxInterface {
  private library: JQueryStatic;

  public constructor(jqueryStatic: JQueryStatic) {
    this.library = jqueryStatic;
  }

  public submitAjax(
    requestObject: JQueryAjaxSettings & IAjaxObject,
  ): JQueryXHR | JQueryPromise<any> {
    if (requestObject.isValid()) {
      return this.library.ajax(requestObject);
    } else {
      return $.Deferred().reject();
    }
  }
}
