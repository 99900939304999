import * as React from 'react';
import { ILocalizedComponentProps, LocalizedPureComponent } from '../../../LocalizedComponent';

export interface IWorkoutShareProps extends ILocalizedComponentProps {
  shareStatus: 'public' | 'private';
  shareUrl: string;
  updateShareStatus: (newValue: 'public' | 'private') => void;
}

export interface IWorkoutShareState {}

export class WorkoutShare extends LocalizedPureComponent<IWorkoutShareProps, IWorkoutShareState> {
  public render(): JSX.Element {
    const buttonStatusClass =
      this.props.shareStatus == 'public' ? 'sharing-public' : 'sharing-private';
    const buttonClass = `sharing xbtn xbtn-xs xbtn-primary ${buttonStatusClass}`;
    return (
      <div className="panel-share-content">
        <div>
          <i className="fa fa-link fa-fw" />
          <a href={this.props.shareUrl} target="_blank" className="form-field">
            {this.sharePath}
          </a>
        </div>
        <button className={buttonClass} onClick={this.handleClick}>
          <i />
          <span>{this.props.shareStatus === 'public' ? 'Public' : 'Private'}</span>
        </button>
      </div>
    );
  }

  private handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    const newStatus = this.props.shareStatus === 'public' ? 'private' : 'public';
    this.props.updateShareStatus(newStatus);
  };

  private get sharePath(): string {
    const shareUrl = this.props.shareUrl;
    return (
      '/' +
      shareUrl
        .split('/')
        .slice(3)
        .join('/')
    );
  }
}
