import * as React from 'react';
import * as _ from 'underscore';
import { ILocalizedComponentProps, LocalizedPureComponent } from '../../LocalizedComponent';
import { HistoryItemContainer, IHistoryItemContainerProps } from '../History/HistoryItemContainer';
import {
  ExerciseListContainer,
  ExerciseListContainerProps,
} from './DefinitionList/ExerciseListContainer';
import {
  DefinitionSelectionViewContainer,
  DefinitionSelectionViewContainerProps,
} from './DefinitionSelection/DefinitionSelectionViewContainer';
import { IObjectFormContainerProps, ObjectFormContainer } from './EditObject/ObjectFormContainer';
import { GuideContainer, IGuideContainerProps } from './Guide/GuideContainer';
import { IPanelPassthroughProps, IPanelViewModelProps } from './IPanelProps';
import { NewWorkoutContainer, NewWorkoutContainerProps } from './NewWorkout/NewWorkoutContainer';
import { PanelTypes } from './PanelTypes';
import {
  IWorkoutListContainerProps,
  WorkoutListContainer,
} from './SessionList/WorkoutListContainer';
import {
  IWorkoutViewModelComponentProps,
  WorkoutViewModelComponent,
} from './Workouts/WorkoutViewModelComponent';
import Dictionary = _.Dictionary;

export interface IPanelContainerProps extends ILocalizedComponentProps, IPanelPassthroughProps {
  panels: Dictionary<IPanelViewModelProps<any, any>>;
}

export interface IPanelContainerState {}

/**
 * The PanelContainer contains all of the panels.
 */
export class PanelContainer extends LocalizedPureComponent<
  IPanelContainerProps,
  IPanelContainerState
> {
  public render(): JSX.Element {
    return <div className="panel-container">{this.renderPanels()}</div>;
  }

  private renderPanels(): JSX.Element[] {
    const panelKeys = _.keys(this.props.panels);
    const panels = _.map(panelKeys, key => this.props.panels[key]);
    const sortedPanels = _.sortBy(panels, panel => panel.position);
    const passThroughProps: IPanelPassthroughProps = { isMobile: this.props.isMobile };
    return _.map(sortedPanels, (panel: IPanelViewModelProps<any, any>) => {
      const panelType = panel.data.panelType;
      const key = panel.data.id;
      switch (panelType) {
        case PanelTypes.Workout:
          const workoutProps = (panel as any) as IWorkoutViewModelComponentProps;
          return <WorkoutViewModelComponent {...workoutProps} key={key} {...passThroughProps} />;
        case PanelTypes.WorkoutList:
          const workoutListProps = (panel as any) as IWorkoutListContainerProps;
          return <WorkoutListContainer {...workoutListProps} key={key} {...passThroughProps} />;
        case PanelTypes.NewWorkout:
          const panelProps = (panel as any) as NewWorkoutContainerProps;
          return <NewWorkoutContainer {...panelProps} key={key} {...passThroughProps} />;
        case PanelTypes.DefinitionSelection:
          const definitionSelectionProps = (panel as any) as DefinitionSelectionViewContainerProps;
          return (
            <DefinitionSelectionViewContainer
              {...definitionSelectionProps}
              key={key}
              {...passThroughProps}
            />
          );
        case PanelTypes.History:
          const historyProps = (panel as any) as IHistoryItemContainerProps;
          return <HistoryItemContainer key={key} {...historyProps} {...passThroughProps} />;
        case PanelTypes.Guide:
          const guideProps = (panel as any) as IGuideContainerProps;
          return <GuideContainer key={key} {...guideProps} {...passThroughProps} />;
        case PanelTypes.Object:
          const objectProps = (panel as any) as IObjectFormContainerProps;
          return <ObjectFormContainer {...objectProps} key={key} {...passThroughProps} />;
        case PanelTypes.DefinitionList:
          const definitionListProps = (panel as any) as ExerciseListContainerProps;
          return <ExerciseListContainer {...definitionListProps} key={key} {...passThroughProps} />;
      }
    });
  }
}
