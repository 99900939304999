import * as React from 'react';
import { IConfiguration } from '../../../Models/IConfiguration';
import { IExerciseSetFormatted } from '../Helpers/History/IExerciseSetFormatted';
import { IPerformanceSpecificationFormatted } from '../Helpers/History/IPerformanceSpecificationFormatted';

export interface IExerciseSetProps {
  exerciseSet: IExerciseSetFormatted;
  displayClasses: IExerciseSetDisplayClasses;
}

export interface IExerciseSetState {}

export interface IExerciseSetDisplayClasses {
  exerciseSetContainer: string;
  intensityNameItem: string;
  intensityValueContainer: string;
  intensityValueItem: string;
  parameterEntry: string;
  parametersContainer: string;
  primaryValuesContainer: string;
  difficultyContainer: string;
  specActual: string;
  specTarget: string;
  specUnit: string;
  specificationEntry: string;
  specificationsContainer: string;
}

export class ExerciseSet extends React.PureComponent<IExerciseSetProps, IExerciseSetState> {
  public render(): JSX.Element {
    const set = this.props.exerciseSet;
    const performanceSpecs = set.performanceSpecifications.map((spec, index) => {
      return this.renderPerformanceSpecifications(spec, index);
    });
    const difficultyClassName = `difficulty-${set.difficulty}-icon`;
    const secondaryConfigs = set.configurations.slice(1).map((param, index) => {
      return this.renderParameter(param, index);
    });
    let intensityValue: string;
    let intensityName: string;
    const firstConfig = set.configurations[0];
    if (firstConfig) {
      intensityName = firstConfig.name;
      intensityValue = `${firstConfig.value} ${firstConfig.unit_entry}`;
    } else {
      intensityName = '';
      intensityValue = '';
    }
    return (
      <div className={this.props.displayClasses.exerciseSetContainer}>
        <div className={this.props.displayClasses.primaryValuesContainer}>
          <div className={this.props.displayClasses.difficultyContainer}>
            <i className={difficultyClassName} />
          </div>
          <div className={this.props.displayClasses.intensityValueContainer}>
            <div className={this.props.displayClasses.intensityValueItem}>{intensityValue}</div>
            <div className={this.props.displayClasses.intensityNameItem}>{intensityName}</div>
          </div>
          <div className={this.props.displayClasses.specificationsContainer}>
            {performanceSpecs}
          </div>
        </div>
        <ul className={this.props.displayClasses.parametersContainer}>{secondaryConfigs}</ul>
      </div>
    );
  }

  private renderPerformanceSpecifications(
    set: IPerformanceSpecificationFormatted,
    index: number,
  ): JSX.Element {
    return (
      <div className={this.props.displayClasses.specificationEntry} key={index}>
        <div className={this.props.displayClasses.specTarget}>{set.target}</div>
        <div className={this.props.displayClasses.specUnit}>{set.unit}</div>
        <div className={this.props.displayClasses.specActual}>{set.actual}</div>
      </div>
    );
  }

  private renderParameter(param: IConfiguration, index: number): JSX.Element {
    return (
      <li className="hpv-parameter" key={index}>
        {param.value} {param.unit_entry} {param.name}
      </li>
    );
  }
}
