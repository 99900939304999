import { IExerciseDisplayClasses } from '../../History/Exercise';
import { IExerciseSetDisplayClasses } from '../../History/ExerciseSet';
import { ISetGroupDisplayClasses } from '../../History/SetGroup';

export const exerciseDisplayClasses: IExerciseDisplayClasses = {
  exerciseEntry: 'seh-exercise',
  exerciseData: 'seh-exercise-data',
  exerciseTitle: 'seh-exercise-title',
  workoutName: 'seh-workout-name',
  plannedDate: 'seh-planned-date',
  exerciseHeader: 'seh-exercise-header',
  restSetContainer: 'seh-rest-set',
  reviewContainer: 'seh-review',
  superSetExerciseContainer: 'seh-super-set-exercises',
  setGroupsContainer: 'seh-set-gropus',
  exerciseOpenButton: 'seh-exercise-open',
};

export const exerciseSetDisplayClasses: IExerciseSetDisplayClasses = {
  difficultyContainer: 'seh-difficulty',
  exerciseSetContainer: 'seh-exercise-set',
  intensityNameItem: 'seh-intensity-name',
  intensityValueContainer: 'seh-intensity-value',
  intensityValueItem: 'seh-intensity-value',
  parameterEntry: 'seh-parameter',
  parametersContainer: 'seh-parameters',
  primaryValuesContainer: 'seh-primary-values',
  specActual: 'seh-spec-actual',
  specTarget: 'seh-spec-target',
  specUnit: 'seh-spec-unit',
  specificationEntry: 'seh-specification',
  specificationsContainer: 'seh-specifications',
};

export const setGroupDisplayClasses: ISetGroupDisplayClasses = {
  exerciseSetsContainer: 'seh-exercise-sets',
  setDifficultyContainer: 'seh-set-difficulty',
  setDifficultyState: 'seh-set-difficulty-',
  setGroupEntry: 'seh-set-group',
  setGroupHighlight: 'seh-set-highlight',
  setPosition: 'seh-set-position',
};

const classes = {
  exercise: exerciseDisplayClasses,
  exerciseSet: exerciseSetDisplayClasses,
  setGroup: setGroupDisplayClasses,
};

export default classes;
