import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { IApplicationService } from '../../Core/IApplicationService';
import { User } from '../../Models/User';
import { LocalizationContext } from '../LocalizedComponent';
import { ButtonOptions } from '../UserProfileView';
import { UserProfileAttributes } from './UserProfileAttributes';
import { UserProfileForm } from './UserProfileForm';

export interface IUserProfileControllerProps {
  application: IApplicationService;
}

interface IUserProfileControllerState {
  submitStatus: ButtonOptions;
  rest_set: string;
  rest_exercise: string;
  barbell_weight: string;
  dumbbell_weight: string;
  olympic_barbell_weight: string;
  localized: boolean;
}

export class UserProfileController extends React.Component<
  IUserProfileControllerProps,
  IUserProfileControllerState
> {
  constructor(props: IUserProfileControllerProps, context: any) {
    super(props, context);
    this.state = {
      localized: false,
      submitStatus: ButtonOptions.Ready,
      rest_exercise: '',
      rest_set: '',
      barbell_weight: '',
      dumbbell_weight: '',
      olympic_barbell_weight: '',
    };
  }

  public static initiateForm(appService: IApplicationService): void {
    ReactDOM.render(
      <UserProfileController application={appService} />,
      document.getElementById('user-profile-form-container'),
    );
  }

  public render(): React.ReactNode {
    if (!this.state.localized) {
      return null;
    }
    const {
      submitStatus,
      rest_set,
      rest_exercise,
      barbell_weight,
      dumbbell_weight,
      olympic_barbell_weight,
    } = this.state;
    return (
      <LocalizationContext.Provider value={this.props.application.localizationService}>
        <UserProfileForm
          disableSubmitButton={submitStatus !== ButtonOptions.Ready}
          userRestSet={rest_set}
          userRestExercise={rest_exercise}
          userBarbellWeight={barbell_weight}
          userDumbbellWeight={dumbbell_weight}
          userOlympicBarbellWeight={olympic_barbell_weight}
          onUpdateField={this.handleModelUpdate}
          onPasswordSubmit={this.handleChangePassword}
        />
      </LocalizationContext.Provider>
    );
  }

  public componentDidMount(): void {
    this.props.application.localizationService.localizationReady().then(() => {
      this.setState({ localized: true });
    });
    this.props.application.currentUser.reload().then(() => {
      this.updateUserData(this.props.application.currentUser);
    });
  }

  public user(): User {
    return this.props.application.currentUser;
  }

  /**
   * Submits the requested change and returns a promise resolving when the transaction is complete.
   * @param attributeName
   * @param newValue
   */
  public updateModelAttribute(attributeName: keyof User, newValue: string): JQueryPromise<User> {
    const user = this.user();
    (user[attributeName] as any) = newValue;
    return user.save().always(e => this.updateUserData(this.user()));
  }

  /**
   * Attempts to change the password.
   * @param currentPassword
   * @param newPassword
   * @param passwordConfirm
   */
  private handleChangePassword = (
    currentPassword: string,
    newPassword: string,
    passwordConfirm: string,
  ): void => {
    const user = this.user();
    this.setState({ submitStatus: ButtonOptions.Pending });
    user
      .save({
        current_password: currentPassword,
        password: newPassword,
        password_confirmation: passwordConfirm,
      })
      .always(() => {
        this.setState({ submitStatus: ButtonOptions.Ready });
      });
  };

  private updateUserData(user: User): void {
    const {
      rest_set,
      rest_exercise,
      barbell_weight,
      olympic_barbell_weight,
      dumbbell_weight,
    } = user;
    this.setState({
      barbell_weight: barbell_weight || '',
      dumbbell_weight: dumbbell_weight || '',
      olympic_barbell_weight: olympic_barbell_weight || '',
      rest_exercise: rest_exercise || '',
      rest_set: rest_set || '',
    });
  }

  private handleModelUpdate = (attribute: UserProfileAttributes, newValue: string): void => {
    this.updateModelAttribute(attribute, newValue);
  };

  private handleSubmit = (): void => {};
}
