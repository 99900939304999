import { IServerCollectionResult } from '../IServerCollectionResult';
import { ServerCollectionHandler } from './ServerCollectionHandler';

/**
 * Parsers the request as a model but does not store the data in the data layer.
 */
export class RequestHandlerSearchCollection<T> extends ServerCollectionHandler<T> {
  public doneRequest(response: IServerCollectionResult<T>) {
    let parsedResponse;
    parsedResponse = this._responseParser.parse(response);
    return this._promise.resolve(parsedResponse);
  }
}
