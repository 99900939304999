import * as React from 'react';
import * as _ from 'underscore';
import { IExerciseTempo } from '../../../../../Models/IExerciseTempo';
import { ILocalizedComponentProps, LocalizedPureComponent } from '../../../../LocalizedComponent';
import { TempoField } from './TempoField';

export interface ITempoComponentProps extends ILocalizedComponentProps {
  eccentric: string;
  concentric: string;
  rest: string;
  pause: string;
  eccentricValid: boolean;
  concentricValid: boolean;
  pauseValid: boolean;
  restValid: boolean;
  updateValue: (newValue: IExerciseTempo) => void;
}

export interface ITempoComponentState {}

export class TempoComponent extends LocalizedPureComponent<
  ITempoComponentProps,
  ITempoComponentState
> {
  public render(): JSX.Element {
    return (
      <div className="horizontal-group">
        <TempoField
          value={this.props.eccentric}
          propertyName="eccentric"
          labelPath="object.exercise.tempo.eccentric.title"
          updateValue={this.updateEccentric}
          isValid={this.props.eccentricValid}
        />
        <TempoField
          value={this.props.concentric}
          propertyName="concentric"
          labelPath="object.exercise.tempo.concentric.title"
          updateValue={this.updateConcentric}
          isValid={this.props.concentricValid}
        />
        <TempoField
          value={this.props.pause}
          propertyName="pause"
          labelPath="object.exercise.tempo.pause.title"
          updateValue={this.updatePause}
          isValid={this.props.pauseValid}
        />
        <TempoField
          value={this.props.rest}
          propertyName="rest"
          labelPath="object.exercise.tempo.rest.title"
          updateValue={this.updateRest}
          isValid={this.props.restValid}
        />
      </div>
    );
  }

  private updateEccentric = (newValue: string) => {
    this.updateValue(newValue, 'eccentric');
  };

  private updateConcentric = (newValue: string) => {
    this.updateValue(newValue, 'concentric');
  };

  private updateRest = (newValue: string) => {
    this.updateValue(newValue, 'rest');
  };

  private updatePause = (newValue: string) => {
    this.updateValue(newValue, 'pause');
  };

  private updateValue(newValue: string, propertyName: keyof IExerciseTempo): void {
    const partial: Dictionary<string> = {};
    partial[propertyName] = newValue;
    const updateObject = _.assign(this.getTempoData(), partial);
    this.props.updateValue(updateObject);
  }

  private getTempoData(): IExerciseTempo {
    return {
      concentric: this.props.concentric,
      eccentric: this.props.eccentric,
      pause: this.props.pause,
      rest: this.props.rest,
    };
  }
}
