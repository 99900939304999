import * as React from 'react';
import { ILocalizedComponentProps, LocalizedPureComponent } from '../../../LocalizedComponent';
import { ConfirmStatus } from './ConfirmStatus';

export interface IConfirmSectionProps extends ILocalizedComponentProps {
  status: ConfirmStatus;
  onSubmitAction: () => void;
  confirmLabelPath?: string;
}

export interface IConfirmSectionState {}

export class ConfirmSection extends LocalizedPureComponent<
  IConfirmSectionProps,
  IConfirmSectionState
> {
  public render(): JSX.Element {
    const buttonClasses = this.buttonClasses;
    return (
      <div className="form-group-container form-confirm-container">
        <button
          className={buttonClasses}
          disabled={this.props.status !== ConfirmStatus.Valid}
          onClick={this.handleSubmitClick}
          type="submit"
          name="object-form-submit"
        >
          {this.buttonContents}
        </button>
      </div>
    );
  }

  private get buttonClasses(): string {
    switch (this.props.status) {
      case ConfirmStatus.Invalid:
        return 'confirm-button disabled';
      case ConfirmStatus.Pending:
        return 'confirm-button confirm-button-pending disabled';
      case ConfirmStatus.Valid:
        return 'confirm-button';
    }
  }

  private get buttonContents(): JSX.Element | string {
    if (this.props.status !== ConfirmStatus.Pending) {
      return this.localize(this.buttonLabel);
    }
    return <i className="fa fa-spinner fa-spin" />;
  }

  private get buttonLabel(): string {
    return this.props.confirmLabelPath || 'object.general.submit';
  }

  private handleSubmitClick = (event: React.FormEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onSubmitAction();
  };
}
