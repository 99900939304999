import * as React from 'react';
import { IConfiguration } from '../../../../Models/IConfiguration';

// noinspection TsLint
require('./IntensityParameterList.scss');

export interface IIntensityParameterListProps {
  parameters: IConfiguration[];
}

export interface IIntensityParameterListState {}

export class IntensityParameterList extends React.PureComponent<
  IIntensityParameterListProps,
  IIntensityParameterListState
> {
  public render(): JSX.Element {
    return <ul className="msvIntensityParameterList">{this.renderList()}</ul>;
  }

  private renderList(): JSX.Element[] {
    return this.props.parameters.map((parameter, index: number) => {
      return (
        <li className="msvIntensityParameter" key={index}>
          {parameter.value} {parameter.unit_entry} {parameter.name}
        </li>
      );
    });
  }
}
