import * as React from 'react';
import { Workout } from '../../../../Models/Workout';
import { ILocalizationService } from '../../../../Services/ILocalizationService';
import { IWorkoutHoverChangeDelegate } from '../../../../ViewModels/Panels/IWorkoutViewModel';
import { LocalizedComponent } from '../../../LocalizedComponent';
import { FormatDate } from '../../../Utilities/FormatDate';
import { IPanelPassthroughProps } from '../IPanelProps';
import { ExerciseGroupContainer } from './ExerciseGroupContainer';
import { WorkoutShare } from './WorkoutShare';

export interface IWorkoutContainerProps {
  model: Workout;
  localization: ILocalizationService;
}

export interface IWorkoutContainerActionProps {
  delegate: IWorkoutHoverChangeDelegate;
}

/**
 * The WorkoutContainer renders the contents of the workout. It inherits from LocalizedComponent (as opposed to
 * {@link ModelComponent}) because the {@link Workout.updated_at} property is not updated when children are loaded.
 */
export class WorkoutContainer extends LocalizedComponent<
  IWorkoutContainerProps & IWorkoutContainerActionProps & IPanelPassthroughProps,
  object
> {
  public render(): JSX.Element {
    const workout = this.props.model;
    return (
      <div
        className="workout group_base"
        data-current-status={workout.status}
        data-object-id={workout.id}
        data-record="workout"
      >
        {this.renderHeader()}
        <div className="exercise_groups">{this.renderExerciseGroups()}</div>
        <div className="next-action">
          <button
            className="add-exercise ia-action"
            data-action="add-exercise"
            onClick={this.handleAddExercise}
          >
            <div className="command-icon">
              <div className="difficulty">
                <i />
                <span />
              </div>
            </div>
            <div className="main-section">
              <i />
              {this.localize('inline.add_exercise')}
            </div>
          </button>
        </div>
      </div>
    );
  }

  private handleAddExercise = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    this.props.delegate.addExercise();
  };

  private handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    this.props.delegate.selectModel(this.props.model);
  };

  private updateShareValue = (newValue: 'public' | 'private'): void => {
    this.props.delegate.updateShareStatus(newValue);
  };

  private renderHeader(): JSX.Element {
    const workout = this.props.model;
    return (
      <div className="workout-header" onClick={this.handleClick}>
        <div className="w-primary-header">
          <div className="main-data">
            <header>
              <i className="workout-name" /> {workout.name}
            </header>
            <div className="attributes">
              <div className="item planned-date">
                <span>
                  <i />
                </span>
                {FormatDate.relative(workout.planned_date)}
              </div>
            </div>
          </div>
          {workout.note_content && (
            <div className="item note-content">
              <span>
                <i />
              </span>
              <span className="content">{workout.note_content}</span>
            </div>
          )}
          {workout.instructions && (
            <div className="item instructions">
              <span>
                <i className="instruction-icon" />
              </span>
              <span className="content">{workout.instructions}</span>
            </div>
          )}
          <WorkoutShare
            shareStatus={workout.privacy_status}
            shareUrl={workout.links.sharing}
            updateShareStatus={this.updateShareValue}
          />
        </div>
        <div className="w-status-header">
          <div className="difficulty">
            <i />
            <span />
          </div>
        </div>
      </div>
    );
  }

  private renderExerciseGroups(): JSX.Element[] {
    return this.props.model.exerciseGroups().map((eg, index: number) => {
      return (
        <ExerciseGroupContainer
          model={eg}
          key={eg.id}
          isMobile={this.props.isMobile}
          position={index}
          delegate={this.props.delegate}
        />
      );
    });
  }
}
