import * as log from 'loglevel';
import * as React from 'react';
import { ModelFragment } from '../../../../Core/GlobalVariables';
import { Exercise } from '../../../../Models/Exercise';
import { ExerciseGroup } from '../../../../Models/ExerciseGroup';
import { Model } from '../../../../Models/Model';
import { SetGroup } from '../../../../Models/SetGroup';
import { Workout, WorkoutPrivacyStatus } from '../../../../Models/Workout';
import { ILocalizationService } from '../../../../Services/ILocalizationService';
import { EventDataDelete } from '../../../../ViewModels/Panels/HistoryViewModel';
import { IWorkoutViewModelFacade } from '../../../../ViewModels/Panels/IWorkoutViewModel';
import { AbstractPanelViewContainer } from '../AbstractPanelViewContainer';
import { IPanelExternalData, IPanelInternalData } from '../IPanelData';
import { IPanelHeaderActions } from '../IPanelHeaderActions';
import { IPanelViewModelProps } from '../IPanelProps';
import { PanelTypes } from '../PanelTypes';
import { IWorkoutContainerActionProps, IWorkoutContainerProps } from './WorkoutContainer';

export type IWorkoutViewModelComponentProps = IPanelViewModelProps<
  IWorkoutContainerProps & IWorkoutContainerActionProps,
  IWorkoutViewModelFacade
>;

export interface IWorkoutViewModelComponentState {}

export class WorkoutViewModelComponent
  extends AbstractPanelViewContainer<
    IWorkoutContainerProps,
    {},
    IWorkoutViewModelComponentState,
    IWorkoutContainerActionProps
  >
  implements IWorkoutViewModelFacade {
  public static initialPanelProps(
    localizationService: ILocalizationService,
    workout: Workout,
  ): IWorkoutContainerProps {
    return {
      localization: localizationService,
      model: workout,
    };
  }

  public static initialPanelData(panelId: string, workout: Workout): IPanelExternalData {
    return {
      hasFocus: false,
      hasTransactionOverlay: true,
      id: panelId,
      objectId: workout.id.toString(),
      panelType: PanelTypes.Workout,
      parentPanelId: null,
      title: workout.name,
    };
  }

  public componentDidMount(): void {
    super.componentDidMount();
    $(document).on('sr:object:delete:workout', this.handleDelete);
    $(document).on('sr:object:update', this.handleUpdate);
    this.props.props.model
      .reload()
      .always(() => this.updatePanelData({ hasTransactionOverlay: false }));
  }

  public componentWillUnmount(): void {
    $(document).off('sr:object:delete:workout', this.handleDelete);
    $(document).off('sr:object:update', this.handleUpdate);
  }

  public closeWorkoutPanel(): void {
    this.removePanel();
  }

  // <editor-fold desc="IWorkoutHoverChangeDelegate">
  public deleteModel(model: Model): void {
    this.modelService.deleteModelById(model).always(() => this.refreshDisplay());
  }

  public addSetGroup(ex: Exercise): void {
    this.modelService.addSetGroup(ex).always(() => this.refreshDisplay());
  }

  public copySetGroup(setGroup: SetGroup): void {
    this.modelService.copySet(setGroup.exercise(), setGroup.id).always(() => this.refreshDisplay());
  }

  public addExercise(): void {
    this.startAddExercise();
  }

  public selectModel(model: Model): void {
    this.handleSelectionRequest(model.type, model.id);
  }

  // </editor-fold>

  public handleSelectionRequest(type: ModelType, id: number | string) {
    return this.displayRecord(type, id, true);
  }

  public refresh(): void {
    this.refreshWorkout();
  }

  public refreshWorkout(): JQueryPromise<Workout> {
    this.updatePanelData({ hasTransactionOverlay: true });
    return this.props.props.model.reload().always(() => {
      this.updatePanelData({ hasTransactionOverlay: false });
      this.displayWorkout(this.props.props.model);
    }) as JQueryPromise<Workout>;
  }

  public updateShareStatus(newStatus: WorkoutPrivacyStatus): JQueryPromise<Workout> {
    this.props.props.model.update({
      privacy_status: newStatus,
    });
    return this.props.props.model.save().done(() => {
      return this.refreshDisplay();
    });
  }

  public displayWorkout(workout: Workout) {
    if (workout === this.props.props.model) {
      this.updateContentProps({ model: workout });
    } else if (this.props.props.model == null) {
      this.updateContentProps({ model: workout });
    }
  }

  /**
   * Moves the set group provided before the prior set group.
   * @param {{type: string, id: number}} movedSetGroup - Set group to move.
   * @param {{type: string, id: number}, null} moveAfterGroup - Set group to that the target is being moved after.
   * If null, setgroup is being moved first.
   */
  public moveSetGroupAfter(movedSetGroup: SetGroup, moveAfterGroup: SetGroup | null) {
    let exercise: Exercise;
    let newPosition;
    let promise;
    let setGroupBefore;
    movedSetGroup = this.modelService.getModel(movedSetGroup) as SetGroup;
    exercise = movedSetGroup.parent() as Exercise;
    if (moveAfterGroup) {
      setGroupBefore = this.modelService.getModel(moveAfterGroup) as SetGroup;
    }
    newPosition = setGroupBefore ? setGroupBefore.position + 1 : 0;
    movedSetGroup.update({
      position: newPosition,
    });
    promise = movedSetGroup.save(false);
    promise.done(() => {
      return this.modelService.reloadChildrenSetGroups(exercise);
    });
    return promise.fail(error => {
      return log.error(error);
    });
  }

  /**
   * Moves the Exercise provided after the target exercise.
   * @param {{type: string, id: number}} movedExercise - Exercise to move.
   * @param {{type: string, id: number}, null} targetExercise - Exercise that the target is being moved after.
   * If null, exercise is being moved first to first.
   * @param targetParent
   */
  public moveExerciseAfter(
    movedExercise: ModelFragment,
    targetExercise: ModelFragment,
    targetParent: ModelFragment,
  ): JQueryPromise<any> {
    let isMovingGroup: boolean;
    let modelBefore;
    let movedModel;
    let movedParentModel: ExerciseGroup;
    let newPosition;
    let promise;
    let targetParentModel: any;
    movedModel = this.modelService.getModel(movedExercise);
    targetParentModel = this.modelService.getModel(targetParent);
    movedParentModel = movedModel.parent() as ExerciseGroup;
    isMovingGroup = movedParentModel !== targetParentModel;
    if (targetExercise) {
      modelBefore = this.modelService.getModel(targetExercise) as Exercise;
    }
    newPosition = modelBefore ? modelBefore.position + 1 : 0;
    if (isMovingGroup) {
      movedModel.update({
        exercise_group_id: targetParentModel.id,
      });
    }
    movedModel.update({
      position: newPosition,
    });
    promise = movedModel.save(false);
    promise.done(() => {
      this.handleExerciseTransfer(movedParentModel);
      if (isMovingGroup) {
        return this.handleExerciseTransfer(targetParentModel);
      }
    });
    return promise.fail(error => {
      return log.error(error);
    });
  }

  public handleExerciseTransfer(exerciseGroup: ExerciseGroup) {
    let promise;
    promise = this.modelService.reloadChildrenExercises(exerciseGroup);
    return promise.done(result => {
      if (result.newObjects.length === 0) {
        return exerciseGroup.delete().done(() => {});
      }
    });
  }

  public moveExerciseGroupAfter(
    movedGroup: ModelFragment,
    targetGroup: ModelFragment,
  ): JQueryPromise<any> {
    throw new Error('Needs Reimplementation');
  }

  public startAddExercise() {
    const workout: Workout = this.props.props.model;
    this.props.panelManager.openDefinitionSelectorPanel(
      workout,
      'add-exercise-group',
      this.panelId,
    );
  }

  public onDelete(event: JQueryEventObject, ed: EventDataDelete): void {
    this.checkForDelete(ed);
  }

  public onUpdate(): void {
    this.checkForUpdate();
  }
  protected actionProps(): IWorkoutContainerActionProps {
    return {
      delegate: this,
    };
  }

  protected panelData(): IPanelInternalData {
    return {
      additionalClasses: 'workouts',
      type: 'workout',
    };
  }

  protected renderHeaderActions(): IPanelHeaderActions {
    return {
      onDismiss: this.handleDismiss,
      onRefresh: this.handleRefresh,
    };
  }

  protected displayRecord(type: string, id: string | number, modalDisplay?: boolean): void {
    if (this.props.selectionProps) {
      this.props.selectionProps.viewModel.displayRecord(type, id, modalDisplay, this);
    }
  }

  private checkForUpdate(): void {
    if (this.props.props.model.name !== this.props.data.title) {
      this.updatePanelData({ title: this.props.props.model.name });
    }
  }

  private refreshDisplay(): void {
    this.updateContentProps({ model: this.props.props.model });
  }

  private checkForDelete(eventData: { object: Model }) {
    if (eventData.object === this.props.props.model) {
      this.removePanel();
    }
  }

  private handleDismiss = (): void => this.closeWorkoutPanel();
  private handleRefresh = (): void => {
    this.refreshWorkout();
  };
  private handleDelete = (event: JQueryEventObject, eventData: EventDataDelete): void =>
    this.onDelete(event, eventData);
  private handleUpdate = (): void => this.onUpdate();
}
