import * as React from 'react';
import { IExerciseHistoryBase } from '../../../../Models/History/IExerciseHistoryBase';
import { ILocalizedComponentProps, LocalizedPureComponent } from '../../../LocalizedComponent';
import { IExerciseHistoryFormatted } from '../../Helpers/History/IExerciseHistoryFormatted';
import { IExerciseHistoryDisplayClasses } from '../../History/Exercise';
import { PreviousExercise } from './PreviousExercise';

export interface IPreviousExerciseSelectorProps extends ILocalizedComponentProps {
  exercises: IExerciseHistoryFormatted[];
  selectedExercise: IExerciseHistoryBase;
  selectExercise: (exercise: IExerciseHistoryBase) => void;
  displayClasses: IExerciseHistoryDisplayClasses;
}

export interface IPreviousExerciseSelectorState {}

const COPY_EXERCISE_TITLE_PATH = 'definitions.select.new_contents.copy';

export class PreviousExerciseSelector extends LocalizedPureComponent<
  IPreviousExerciseSelectorProps,
  IPreviousExerciseSelectorState
> {
  public render(): JSX.Element {
    return (
      <div className="prevExerciseSelector-section">
        <div className="">{this.localize(COPY_EXERCISE_TITLE_PATH)}</div>
        {this.renderExercises()}
      </div>
    );
  }

  private renderExercises(): JSX.Element[] {
    return this.props.exercises.map(e => this.renderExercise(e));
  }

  private renderExercise(ex: IExerciseHistoryFormatted): JSX.Element {
    const isSelected = this.props.selectedExercise && this.props.selectedExercise.id === ex.id;
    return (
      <PreviousExercise
        exercise={ex}
        selectExercise={this.props.selectExercise}
        displayClasses={this.props.displayClasses}
        isSelected={isSelected}
        key={ex.id}
      />
    );
  }
}
