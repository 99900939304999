import Moment = moment.Moment;

export class DateTimePickerCustom {
  public static formatString = 'YYYY-MM-DD HH:mm';
  public static formatDateString = 'YYYY-MM-DD';
  public static formateTime = 'HH:mm';

  public static setTime(this: JQuery, currentTime: any, $input: JQuery) {
    // tslint:disable-next-line:no-this-assignment
    const picker = this;
    ((this as any) as JQuery)
      .find('.xdsoft_today_button')
      .on('touchend.xdsoft click.xdsoft', e =>
        DateTimePickerCustom.handleTodayClick(e, picker, $input),
      );
  }

  public static handleClose(this: JQuery, currentTime: any, $input: JQuery) {
    return $input.blur();
  }

  public static handleTodayClick(event: JQueryEventObject, picker: JQuery, $input: JQuery): void {
    const nowTime = moment();
    const quarterBeginning: number = nowTime.minutes() - (nowTime.minutes() % 15);
    const quarterHour = nowTime.startOf('hour').minutes(quarterBeginning);
    const targetString = quarterHour.format(DateTimePickerCustom.formatString);
    if ($input.val() === targetString) {
      return;
    }
    picker.setOptions({ value: targetString });
    ($input[0] as HTMLInputElement).value = targetString;
  }

  public static parseDate(date: string | Moment, format: string): Date | false {
    if (moment.isMoment(date)) {
      return date.toDate();
    }
    const d = moment(date, format);
    if (d.isValid()) {
      return d.toDate();
    } else {
      return false;
    }
  }

  public static formatDate(date: string, format: string): string {
    return moment(date).format(format);
  }

  public static options(): DateTimePicker.IOptions {
    return {
      format: DateTimePickerCustom.formatString,
      formatDate: DateTimePickerCustom.formatDateString,
      formatTime: DateTimePickerCustom.formateTime,
      inline: true,
      mask: '2999-19-39 29:59',
      onClose: DateTimePickerCustom.handleClose,
      onGenerate: DateTimePickerCustom.setTime,
      onSelectDate: DateTimePickerCustom.handleSelect,
      onSelectTime: DateTimePickerCustom.handleSelect,
      step: 15,
    };
  }

  public static handleSelect(this: JQuery, currentTime: any, $input: JQuery): void {}

  public static setDateFormatter(): void {
    $.datetimepicker.setDateFormatter({
      formatDate: DateTimePickerCustom.formatDate,
      parseDate: DateTimePickerCustom.parseDate,
    });
  }
}
