import * as React from 'react';
import { IConfigurationEntry } from './IConfigurationEntry';
import { INewDefinitionFormValues } from './NewDefinitionForm';

export interface ICategorySelectorProps {
  options: IConfigurationEntry[];
  currentSelection: string;
  updateAction: (value: Partial<INewDefinitionFormValues>) => void;
  sectionLabel: string;
  propertyName: string;
}

export interface ICategorySelectorState {}

export class CategorySelector extends React.Component<
  ICategorySelectorProps,
  ICategorySelectorState
> {
  public render(): JSX.Element {
    const categoryButtons = this.props.options.map((category, index: number) => {
      const propertyName = this.props.propertyName;
      const inputId = `definition-radio-${propertyName}-${category.key}`;
      const labelClass = `radio-button definition-${propertyName}-${category.key}`;
      const isSelected = this.props.currentSelection === category.key;
      const inputName = `exercise_definition[${propertyName}]`;
      return (
        <div className="radio-button" key={category.key}>
          <input
            type="radio"
            className="radio-button"
            name={inputName}
            id={inputId}
            checked={isSelected}
            value={category.key}
            onChange={() => this.props.updateAction({ category: category.key })}
          />
          <label htmlFor={inputId} className={labelClass}>
            <i />
            <span>{category.title}</span>
          </label>
        </div>
      );
    });

    return (
      <div className="form-group-container">
        <div className="form-group-icon" />
        <div className="form-group-field">
          <label htmlFor="category">{this.props.sectionLabel}</label>
          <div className="radio-button-group">{categoryButtons}</div>
        </div>
      </div>
    );
  }
}
