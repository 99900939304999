import * as React from 'react';
import { VelocityComponent } from 'velocity-react';
import { IPlateItem } from '../../../../Models/IExerciseSet';

export interface IPlateItemProps {
  plate: IPlateItem;
}

export interface IPlateItemState {}

const ANIMATION_NAME = 'callout.bounce';
const ANIMATION_DURATION = 500;

export class PlateItem extends React.PureComponent<IPlateItemProps, IPlateItemState> {
  private velocity: VelocityComponent;

  public render(): JSX.Element {
    const plate = this.props.plate;
    return (
      <VelocityComponent
        animation={ANIMATION_NAME}
        duration={ANIMATION_DURATION}
        ref={c => (this.velocity = c)}
      >
        <div className="sv-plate-entry">
          <div className="sv-plate-weight">{plate.weight}</div>
          <div className="sv-plate-count">&times; {plate.count}</div>
        </div>
      </VelocityComponent>
    );
  }

  public componentWillReceiveProps(nextProps: Readonly<IPlateItemProps>, nextContext: any): void {
    if (nextProps.plate.count !== this.props.plate.count) {
      this.velocity.runAnimation();
    }
  }
}
