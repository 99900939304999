import * as React from 'react';
import * as _ from 'underscore';
import { IExerciseHistory } from '../../../../Models/History/IExerciseHistory';
import { ILocalizedComponentProps, LocalizedPureComponent } from '../../../LocalizedComponent';
import { HistoryFormatter } from '../../Helpers/History/HistoryFormatter';
import { IHistoryFormatter } from '../../Helpers/History/IHistoryFormatter';
import { Exercise } from '../../History/Exercise';
import SelectionHistoryClasses from './SelectionHistoryClasses';

export interface IHistoryContainerProps extends ILocalizedComponentProps {
  history: IExerciseHistory[];
  highlightPosition: number | false;
  openWorkoutFromUrl?: (url: string) => Promise<any>;
}

export interface IHistoryContainerState {}

const SECTION_TITLE_PATH = 'mobile_selection_view.history.title';
const NEVER_DONE_PATH = 'edit_panel.exercise_history.never_done';

export class HistoryContainer extends LocalizedPureComponent<
  IHistoryContainerProps,
  IHistoryContainerState
> {
  private formatter: IHistoryFormatter = new HistoryFormatter();

  public render(): JSX.Element {
    return (
      <div className="sv-history-section msv-section">
        <div className="msvSectionTitle">{this.localize(SECTION_TITLE_PATH)}</div>
        <div className="sv-history-container">
          {_.isEmpty(this.props.history) ? this.localize(NEVER_DONE_PATH) : this.renderExercises()}
        </div>
      </div>
    );
  }

  private renderExercises(): JSX.Element[] {
    const formattedHistory = this.formatter.formatHistory(this.props.history);
    const highlightPosition: number | null =
      this.props.highlightPosition === false ? null : this.props.highlightPosition;
    return formattedHistory.map(historyEntry => {
      return (
        <Exercise
          highlightPosition={highlightPosition}
          displayClasses={SelectionHistoryClasses}
          exercise={historyEntry}
          key={historyEntry.id}
          openWorkoutFromUrl={this.props.openWorkoutFromUrl}
        />
      );
    });
  }
}
