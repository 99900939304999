import * as React from 'react';
import { ISetGroupHistoryFormatted } from '../Helpers/History/ISetGroupHistoryFormatted';
import { ExerciseSet, IExerciseSetDisplayClasses } from './ExerciseSet';

export interface ISetGroupProps {
  highlightPosition: number | null;
  setGroup: ISetGroupHistoryFormatted;
  position: number;
  displayClasses: {
    exerciseSet: IExerciseSetDisplayClasses;
    setGroup: ISetGroupDisplayClasses;
  };
}

export interface ISetGroupDisplayClasses {
  setDifficultyContainer: string;
  setDifficultyState: string;
  setPosition: string;
  exerciseSetsContainer: string;
  setGroupEntry: string;
  setGroupHighlight: string;
}

export interface ISetGroupState {}

export class SetGroup extends React.PureComponent<ISetGroupProps, ISetGroupState> {
  public render(): JSX.Element {
    const group = this.props.setGroup;
    const exerciseSets = group.exercise_sets.map(set => {
      return (
        <ExerciseSet
          exerciseSet={set}
          key={set.id}
          displayClasses={this.props.displayClasses.exerciseSet}
        />
      );
    });
    const classes = this.getSetGroupClasses(this.props.position);
    return (
      <div className={classes} key={group.id}>
        <div className={this.displayClasses().setPosition}>{this.props.position + 1}</div>
        <div className={this.displayClasses().exerciseSetsContainer}>{exerciseSets}</div>
      </div>
    );
  }

  private displayClasses(): ISetGroupDisplayClasses {
    return this.props.displayClasses.setGroup;
  }

  private getSetGroupClasses(position: number | null): string {
    if (this.props.highlightPosition === null || this.props.highlightPosition === position) {
      return `${this.displayClasses().setGroupEntry} ${this.displayClasses().setGroupHighlight}`;
    } else {
      return this.displayClasses().setGroupEntry;
    }
  }
}
