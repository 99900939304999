import * as React from 'react';

export interface INumericFieldProps {
  className: string;
  updateValue: (newValue: number) => void;
  name: string;
  value: number;
}

export interface INumericFieldState {
  valueEntry: string;
  prevValue: number;
}

export class NumericField extends React.PureComponent<INumericFieldProps, INumericFieldState> {
  public constructor(props: INumericFieldProps, context?: any) {
    super(props, context);
    const valueEntry = props.value ? props.value.toString() : '';
    this.state = { valueEntry, prevValue: props.value };
  }

  public static getDerivedStateFromProps(
    nextProps: Readonly<INumericFieldProps>,
    prevState: INumericFieldState,
  ): Partial<INumericFieldState> | null {
    if (nextProps.value !== prevState.prevValue) {
      return {
        prevValue: nextProps.value,
        valueEntry: nextProps.value ? nextProps.value.toString() : '',
      };
    }
    return null;
  }

  public render(): JSX.Element {
    return (
      <input
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        value={this.state.valueEntry}
        name={this.props.name}
        className={this.props.className}
      />
    );
  }

  private handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ valueEntry: event.target.value });
  };

  private handleBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
    const text = event.currentTarget.value;
    const updatedValue = text === '' ? null : parseFloat(text);
    this.props.updateValue(updatedValue);
  };
}
