/**
 * The CollectionResultSet is returned by collection queries made to the server.
 */
export class CollectionResultSet<T> {
  /**
   * The newly returned objects.
   */
  public newObjects: T[];
  /**
   * The URL containing other items (if available).
   */
  public moreURL: string;

  constructor(newObjects: T[], moreUrl: string) {
    this.newObjects = newObjects;
    this.moreURL = moreUrl;
  }
}
