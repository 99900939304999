import * as React from 'react';
import { DefinitionCollection } from '../../../../Models/Collections/DefinitionCollection';
import { IExerciseDefinition } from '../../../../Models/IExerciseDefinition';
import { ExerciseEntry } from './ExerciseEntry';

export interface IDefinitionListDataProps {
  definitions: IExerciseDefinition[];
  collection: DefinitionCollection;
}

export interface IDefinitionListActionProps {
  selectAction: (id: string) => void;
  historyAction: (id: string) => void;
}

export interface IExerciseListState {}

export class ExerciseList extends React.Component<
  IDefinitionListDataProps & IDefinitionListActionProps,
  IExerciseListState
> {
  public render(): JSX.Element {
    const definitions = this.props.definitions.map((definition: IExerciseDefinition) => {
      const selectAction = (event: React.MouseEvent) => {
        event.stopPropagation();
        return this.props.selectAction(definition.id as string);
      };
      const historyAction = (event: React.MouseEvent) => {
        event.stopPropagation();
        return this.props.historyAction(definition.id as string);
      };
      return (
        <ExerciseEntry
          name={definition.name}
          category={definition.category}
          noteContent={definition.note_content}
          id={definition.id as string}
          key={definition.id}
          historyAction={historyAction}
          selectAction={selectAction}
        />
      );
    });
    return <div>{definitions}</div>;
  }
}
