import * as React from 'react';

export interface IExerciseEntryProps {
  category: string;
  id: string;
  name: string;
  noteContent: string;
  historyAction: (event: React.MouseEvent) => void;
  selectAction: (event: React.MouseEvent) => void;
}

export interface IExerciseEntryState {}

export class ExerciseEntry extends React.PureComponent<IExerciseEntryProps, IExerciseEntryState> {
  public render(): JSX.Element {
    return (
      <div
        className="exercise-definition-list-item exercise_definition exercise-definition-format"
        data-object-id={this.props.id}
        data-record="exercise_definition"
        data-category={this.props.category}
        onClick={this.props.selectAction}
      >
        <div className="ed-status-header">
          <div className="difficulty">
            <i />
            <span />
          </div>
        </div>
        <div className="ed-primary-header">
          <div className="definition-name">{this.props.name}</div>
          <div className="attributes">
            <div className="note-content">{this.props.noteContent}</div>
          </div>
        </div>
        <div className="ed-second-header ed-open-history" onClick={this.props.historyAction}>
          <div className="content">
            <i />
            <span />
          </div>
        </div>
      </div>
    );
  }
}
