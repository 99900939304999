import * as _ from 'underscore';
import { Collection } from './Collection';

export class DataCollection<TParent, TResult extends { id: any }> extends Collection<
  TParent,
  TResult
> {
  private __dataStore: TResult[];

  public clear(): void {
    this._clearData();
    this._moreURL = this._baseURL;
  }

  public more(): JQueryPromise<TResult[]> {
    if (this._moreURL === undefined) {
      return this._promiseService.resolvedPromise({});
    }
    const newPromise = this._promiseService.emptyPromise();
    const dataPromise = this._dataLayer.loadDataCollection(this._moreURL);
    dataPromise.done(collectionSet => {
      this.updateCollection(collectionSet);
      newPromise.resolve(collectionSet.newObjects);
    });
    dataPromise.fail(() => {
      newPromise.reject();
    });
    return newPromise;
  }

  public reload(): JQueryPromise<TResult[]> {
    this._clearData();
    this._moreURL = this._baseURL;
    return this.more();
  }

  public members(): TResult[] {
    return this._getData();
  }

  protected updateCollection(collectionSet: any) {
    this._moreURL = collectionSet.moreURL;
    return this._addData(collectionSet.newObjects);
  }

  private _clearData(): void {
    this.__dataStore = [];
  }

  private _addData(newArray: TResult[]) {
    let intermediate;
    if (this.__dataStore == null) {
      this.__dataStore = [];
    }
    if (!(newArray instanceof Array)) {
      throw new Error('DataCollection._addData: Argument is not an array.');
    }
    intermediate = _.union(this.__dataStore, newArray);
    return (this.__dataStore = _.unique(intermediate, false, function(obj: TResult) {
      return obj.id;
    }));
  }

  private _getData(): TResult[] {
    return this.__dataStore;
  }
}
