import * as React from 'react';
import { SubmitStatus } from './SubmitStatus';

export interface ISubmitButtonProps {
  submitAction: (event: React.FormEvent<HTMLButtonElement>) => void;
  status: SubmitStatus;
  label: string;
}

export interface ISubmitButtonState {}

export class SubmitButton extends React.PureComponent<ISubmitButtonProps, ISubmitButtonState> {
  private get buttonContents(): string | JSX.Element {
    if (this.props.status !== SubmitStatus.Pending) {
      return this.props.label;
    }
    return <i className="fa fa-spinner fa-spin" />;
  }

  public render(): JSX.Element {
    let classes = 'confirm-button';
    if (this.props.status) {
      classes += ' disabled';
    }
    return (
      <button
        className={classes}
        type="submit"
        onClick={this.props.submitAction}
        name="definition-confirm"
        disabled={this.props.status !== SubmitStatus.Valid}
      >
        {this.buttonContents}
      </button>
    );
  }
}
