import * as React from 'react';
import { DateTimePickerCustom } from '../../../../Extension/DateTimePickerCustom';

export interface IDateTimePickerProps {
  value: string;
  onChange: (newValue: string) => void;
}

export interface IDateTimePickerState {}

const DATE_FORMAT = 'YYYY-MM-DD HH:mm';

export class DateTimePicker extends React.Component<IDateTimePickerProps, IDateTimePickerState> {
  private input: Element;

  public render(): JSX.Element {
    return (
      <input
        ref={input => (this.input = input)}
        type="text"
        value={this.props.value}
        name="workout[planned_date]"
        onChange={this.emptyHandleChange}
      />
    );
  }

  public componentDidMount(): void {
    $(this.input).datetimepicker(this.getOptions());
  }

  public componentWillUnmount(): void {
    $(this.input).datetimepicker('destroy');
  }

  private emptyHandleChange() {}

  private handleChange = (currentTime: Date, $input: JQuery): void => {
    this.props.onChange(moment(currentTime).toISOString());
  };

  private getOptions(): DateTimePicker.IOptions {
    const initial = DateTimePickerCustom.options();
    initial.onSelectDate = this.handleChange;
    initial.onSelectTime = this.handleChange;
    initial.value = moment(this.props.value).format(DATE_FORMAT);
    return initial;
  }
}
