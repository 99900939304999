import * as React from 'react';
import RandomNumber from '../../../../Extension/RandomUUID';
import { IConfigurationEntry } from '../../Panels/DefinitionSelection/IConfigurationEntry';

export interface IRadioGroupControlProps<T> {
  options: IConfigurationEntry[];
  currentValue: T;
  updateAction: (newValue: T) => void;
  /**
   * The group name for this radio group, usually the record type.
   */
  groupName: string;
  /**
   * The property name managed by this radio group.
   */
  propertyName: string;
}

export interface IRadioGroupControlState {}

export class RadioGroupControl<T> extends React.Component<
  IRadioGroupControlProps<T>,
  IRadioGroupControlState
> {
  private randomID: string = RandomNumber();

  public render(): JSX.Element {
    const categoryButtons = this.categoryButtons();

    return (
      <div className="sv-difficulty-form sv-horizontal-group msv-section">{categoryButtons}</div>
    );
  }

  private categoryButtons() {
    const propertyName = this.props.propertyName;
    const inputName = `${this.props.groupName}[${propertyName}]`;
    const categoryButtons = this.props.options.map((category, index: number) => {
      const inputId = `${this.props.groupName}-radio-${propertyName}-${category.key}-${
        this.randomID
      }`;
      const labelClass = `sv-radio-button sv-${this.props.groupName}-${propertyName}-${
        category.key
      }`;
      const isSelected = this.props.currentValue.toString() === category.key;
      return (
        <div className="sv-radio-button" key={category.key}>
          <input
            type="radio"
            className="sv-radio-button sv-difficulty"
            name={inputName}
            id={inputId}
            checked={isSelected}
            value={category.key}
            onChange={() => this.props.updateAction(category.key as any)}
          />
          <label htmlFor={inputId} className={labelClass}>
            <i />
            <span>{category.title}</span>
          </label>
        </div>
      );
    });
    return categoryButtons;
  }
}
