import * as React from 'react';
import { MouseEvent } from 'react';
import { VelocityTransitionGroup } from 'velocity-react';
import * as Defaults from './AnimationDefaults';

export interface INoteElementProps {
  label: string;
  contents: string;
  /**
   * Whether field is being edited or not.
   */
  isEditing: boolean;
  /**
   * Callback to update editing state.
   */
  startEditing: () => void;
  /**
   * Callback to cancel editing state.
   */
  cancelEditing: () => void;
  /**
   * Callback to bind values changes to state.
   * @param event
   */
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  /**
   * Callback to apply the changes.
   */
  applyChanges: () => void;
  /**
   * Class to be attached to the text area.
   */
  inputClass: string;

  containerClass?: string;
}

export interface INoteElementState {}

const ANIMATE_IN_OPTIONS = {
  animation: 'transition.slideRightIn',
  duration: Defaults.defaults.smallAnimationDuration,
};

const ANIMATE_OUT_OPTIONS = {
  animation: 'transition.slideRightOut',
  duration: Defaults.defaults.smallAnimationDuration,
};

export class NoteElement extends React.PureComponent<INoteElementProps, INoteElementState> {
  private textArea: HTMLTextAreaElement;

  public constructor(props: INoteElementProps, context?: any) {
    super(props, context);
  }

  public render(): JSX.Element {
    const inputClass = `${this.props.inputClass} sv-field`;
    const containerClass = this.props.containerClass || 'sv-note-content-container';
    return (
      <label className="sv-notebox msv-section">
        <span className="msvSectionTitle">{this.props.label}</span>
        <div className={containerClass}>
          <textarea
            className={inputClass}
            ref={input => (this.textArea = input)}
            onChange={this.props.onChange}
            onFocus={this.props.startEditing}
            value={this.content()}
            onClick={this.props.startEditing}
          />
          <VelocityTransitionGroup enter={ANIMATE_IN_OPTIONS} leave={ANIMATE_OUT_OPTIONS}>
            {this.props.isEditing && (
              <div className="sv-note-commands">
                <button className="sv-accept-changes xbtn xbtn-primary" onClick={this.applyChanges}>
                  <i className="icon-accept" />
                </button>
                <button
                  className="sv-cancel-changes xbtn xbtn-primary"
                  onClick={this.cancelSelection}
                >
                  <i className="icon-cancel" />
                </button>
              </div>
            )}
          </VelocityTransitionGroup>
        </div>
      </label>
    );
  }

  private content(): string {
    return this.props.contents || '';
  }

  private applyChanges = (event: React.MouseEvent<HTMLButtonElement>): void => {
    this.props.applyChanges();
  };

  private cancelSelection = (event: MouseEvent<HTMLButtonElement>): void => {
    // Needed to prevent weird edge case where removing the buttons would cause the textarea
    // to retain focus.
    event.stopPropagation();
    event.preventDefault();
    (document.activeElement as HTMLButtonElement).blur();
    this.props.cancelEditing();
  };
}
