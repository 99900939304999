import * as React from 'react';
import { FixedGroup } from './FixedGroup';
import { IMenuConfiguration } from './IMenuConfiguration';
import { MenuGroup } from './MenuGroup';

export interface IMenuProps {
  menuConfig: IMenuConfiguration;
}

export interface IMenuState {}

export class Menu extends React.PureComponent<IMenuProps, IMenuState> {
  public render(): JSX.Element {
    return (
      <div className="navbarContents" id="navbar-collapse">
        <MenuGroup menuClass={this.config.fixedClass} items={this.config.fixedItems} />
        <MenuGroup menuClass={this.config.variableClass} items={this.config.variableItems} />
        <MenuGroup menuClass={this.config.secondaryClass} items={this.config.secondaryItems} />
        <FixedGroup />
      </div>
    );
  }

  private get config(): IMenuConfiguration {
    return this.props.menuConfig;
  }
}
