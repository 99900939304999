import * as React from 'react';
import {
  ISelectionContainerProps,
  SelectionContainer,
} from '../MobileSelection/Container/SelectionContainer';
import { IPanelData } from './IPanelData';
import { IPanelHeaderActions } from './IPanelHeaderActions';
import { IPanelPassthroughProps } from './IPanelProps';
import { PanelHeader } from './PanelHeader';

export interface IPanelElementProps extends IPanelPassthroughProps {
  panelId: string;
  objectId: string;
  panelType: string;
  additionalClasses: string;
  hasFocus: boolean;
  title: string;
  selectionProps?: ISelectionContainerProps;
  headerActions: IPanelHeaderActions;
  hasTransactionOverlay: boolean;
}

export interface IPanelElementState {}

export class PanelElement extends React.Component<IPanelElementProps, IPanelElementState> {
  public static renderElement(
    data: IPanelData,
    selectionProps: ISelectionContainerProps,
    headerActions: IPanelHeaderActions,
    children: JSX.Element,
    isMobile: boolean,
  ): JSX.Element {
    return (
      <PanelElement
        panelId={data.id}
        objectId={data.objectId}
        panelType={data.type}
        additionalClasses={data.additionalClasses}
        hasFocus={data.hasFocus}
        title={data.title}
        selectionProps={selectionProps}
        headerActions={headerActions}
        hasTransactionOverlay={data.hasTransactionOverlay}
        isMobile={isMobile}
      >
        {children}
      </PanelElement>
    );
  }

  public render(): JSX.Element {
    const {
      panelId,
      objectId,
      additionalClasses,
      hasFocus,
      headerActions,
      selectionProps,
      children,
      hasTransactionOverlay,
      panelType,
      title,
      isMobile,
    } = this.props;
    const divId = `panel-${panelId}`;
    let className = `panel-item ${additionalClasses}`;
    if (hasFocus) {
      className += ' panel-item-focus';
    }
    return (
      <div
        id={divId}
        className={className}
        data-panel={panelId}
        data-panel-object-id={objectId}
        data-panel-type={panelType}
      >
        <PanelHeader actions={headerActions} title={title} />
        <div className="scroll-container">
          <div className="scroll-content">
            <div className="panel-content">{children}</div>
          </div>
        </div>
        {hasTransactionOverlay && (
          <div className="panel-overlay">
            <div className="panel-spinner-wrapper">
              <i className="fa fa-spin fa-spinner fa-3x" />
            </div>
          </div>
        )}
        {selectionProps && !isMobile && (
          <SelectionContainer {...selectionProps} isMobile={this.props.isMobile} />
        )}
      </div>
    );
  }
}
