import * as log from 'loglevel';
import { IModelService } from '../../Services/IModelService';
import { PromiseService } from '../../Services/PromiseService';
import { DataLayer } from '../DataLayer';
import { IServerCollectionResult } from '../IServerCollectionResult';
import { ICollectionResponseParser } from '../Parsers/ICollectionResponseParser';

export class ServerCollectionHandler<T> {
  protected _requestPromise: JQueryPromise<any>;
  protected _promiseService: PromiseService;
  protected _responseParser: ICollectionResponseParser<T>;
  protected _modelService: IModelService;
  protected _objectManager: DataLayer;
  protected _promise: JQueryDeferred<any>;

  constructor(
    promise: JQueryPromise<any>,
    promiseService: PromiseService,
    dataParser: ICollectionResponseParser<T>,
    modelService: IModelService,
    dataLayer: DataLayer,
  ) {
    this._requestPromise = promise;
    this._promiseService = promiseService;
    this._responseParser = dataParser;
    this._modelService = modelService;
    this._objectManager = dataLayer;
    this._promise = this._promiseService.emptyPromise();
    this._requestPromise.done(this.handleDone);
    this._requestPromise.fail(this.handleFail);
  }

  public doneRequest(response: IServerCollectionResult<T>) {
    let parsedResponse;
    parsedResponse = this._responseParser.parse(response);
    return this._promise.resolve(parsedResponse);
  }

  public failedRequest(...args: any[]) {
    log.error(args);
    return this._promise.reject;
  }

  public promise() {
    return this._promise;
  }

  private handleDone = (response: any) => {
    return this.doneRequest(response);
  };
  private handleFail = (...args: any[]) => {
    return this.failedRequest(...args);
  };
}
