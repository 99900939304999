import * as _ from 'underscore';
import { IdValue } from '../Core/GlobalVariables';
import { IApplicationService } from '../Core/IApplicationService';
import { Model } from '../Models/Model';
import { IModelService } from '../Services/IModelService';
import { PromiseService } from '../Services/PromiseService';
import { CollectionResultSet } from './CollectionResultSet';
import { DataStore, IDataContainer } from './DataStore';
import { IDataLayer } from './IDataLayer';
import { IDataStore } from './IDataStore';
import { CollectionDataParser } from './Parsers/CollectionDataParser';
import { CollectionModelParser } from './Parsers/CollectionModelParser';
import { CompoundDocumentParser } from './Parsers/CompoundDocumentParser';
import { SingleModelParser } from './Parsers/SingleModelParser';
import { RequestHandlerCache } from './RequestHandlers/RequestHandlerCache';
import { RequestHandlerData } from './RequestHandlers/RequestHandlerData';
import { RequestHandlerDelete } from './RequestHandlers/RequestHandlerDelete';
import { RequestHandlerNoData } from './RequestHandlers/RequestHandlerNoData';
import { RequestHandlerRecordCollection } from './RequestHandlers/RequestHandlerRecordCollection';
import { RequestHandlerSearchCollection } from './RequestHandlers/RequestHandlerSearchCollection';
import { ServerManager } from './ServerManager';

export type RequestType = any;

export class DataLayer implements IDataLayer {
  private _dataRequestor: ServerManager;
  private _dataStore: IDataStore;
  private appService: IApplicationService;

  constructor(
    _dataRequestor: ServerManager,
    _dataStore: DataStore,
    appService: IApplicationService,
  ) {
    this._dataRequestor = _dataRequestor;
    this._dataStore = _dataStore;
    this.appService = appService;
  }

  public deleteData(objectOrType: ModelType | Model, id?: IdValue) {
    if (objectOrType instanceof Model) {
      return this.dataStore().unloadObject(objectOrType.type, objectOrType.id);
    } else {
      return this.dataStore().unloadObject(objectOrType, id);
    }
  }

  /**
   * Returns a model from the data layer.
   * @param {ModelType} type
   * @param {IdValue} id
   * @returns {Model}
   */
  public getData<T extends Model>(type: ModelType, id: IdValue) {
    let potentialModel;
    potentialModel = arguments[0];
    if (potentialModel.type != null && potentialModel.id != null) {
      return this.dataStore().getData<T>(potentialModel.type, potentialModel.id);
    } else {
      return this.dataStore().getData<T>(type, id);
    }
  }

  public getChildrenData<T extends Model>(
    parentID: IdValue,
    childType: ModelType,
    childReferenceProperty: string,
  ): T[] {
    return this.dataStore().getChildren<T>(parentID, childType, childReferenceProperty);
  }

  public loadDataCollection(url: string) {
    const promise = this.dataRequestor().loadObject(url);
    const handler = new RequestHandlerData(
      promise,
      this.promiseService(),
      new CollectionDataParser(this.modelService()),
      this.modelService(),
      this,
    );
    return handler.promise();
  }

  public loadRecordCollection<T extends Model>(url: string) {
    const promise = this.dataRequestor().loadObject(url);
    const handler = new RequestHandlerRecordCollection<T>(
      promise,
      this.promiseService(),
      new CollectionModelParser<T>(this.modelService()),
      this.modelService(),
      this,
    );
    return handler.promise();
  }

  /*
     Refreshes the record collection (unloads, reloads).
     @param {string} url - URL to load the data from.
     @param {string} type - Model type to unload.
     */
  public refreshRecordCollection(url: string, type: ModelType) {
    if (!(typeof url === 'string')) {
      throw new Error('URL is not Provided');
    }
    if (!(typeof type === 'string')) {
      throw new Error('Type is not Provided');
    }
    // this.dataStore().unloadObjectClass(type);
    return this.loadRecordCollection(url);
  }

  /**
   * Runs a search and returns the objects in a promise.
   * @param {string} url - URL to load the data from.
   * @param {string} type - Model type to search.
   * @param {*} searchObject - Object with search terms to be parsed.
   * @return
   */
  public searchCollection(url: string, type: ModelType, searchObject: any) {
    let handler;
    let promise;
    if (!(typeof url === 'string')) {
      throw new Error('URL is not Provided');
    }
    if (!(typeof type === 'string')) {
      throw new Error('Type is not Provided');
    }
    if (!(type === 'exercise_definition')) {
      throw new Error('type is not Exercise Definition');
    }
    promise = this.dataRequestor().loadObject(url, false, searchObject);
    handler = new RequestHandlerSearchCollection(
      promise,
      this.promiseService(),
      new CollectionModelParser(this.modelService()),
      this.modelService(),
      this,
    );
    return handler.promise();
  }

  public getAllObjects<T extends Model>(type: ModelType): IDataContainer<T> {
    return this.dataStore().getObjectClass<T>(type);
  }

  public storeModel<T extends Model>(modelObject: any): void {
    const modelType = modelObject.type;
    const modelID = modelObject.id;
    this.dataStore().storeObject<T>(modelType, modelID, modelObject);
  }

  public storeArray<T extends Model>(arrayOfModels: any[]) {
    if (!(arrayOfModels instanceof Array)) {
      throw new Error('storeArray: Object passed is not an array.');
    }
    if (
      !_.all(arrayOfModels, function(supposedModel) {
        return supposedModel instanceof Model;
      })
    ) {
      throw new Error('storeArray: At least one non-model object passed.');
    }
    _.each(arrayOfModels, model => {
      this.storeModel(model);
    });
  }

  public storeCollectionResultSet<T extends Model>(collectionSet: CollectionResultSet<T>): void {
    this.storeArray(collectionSet.newObjects);
  }

  public storeData<T extends Model>(nonModelObject: any): T {
    let model;
    model = this.modelService().createObject<T>(nonModelObject);
    this.storeModel(model);
    return model;
  }

  public getObject(objectOrPath: any, scriptRequested: RequestType): JQueryPromise<any> {
    let transactionPromise;
    transactionPromise = this.dataRequestor().loadObject(objectOrPath, scriptRequested);
    return this._handleTransaction(transactionPromise, scriptRequested);
  }

  public createObject(
    dataObject: any,
    collectionPath: string,
    objectType: ModelType,
    scriptRequested: RequestType,
  ): JQueryPromise<Model> {
    let transactionPromise;
    transactionPromise = this.dataRequestor().createObject(
      dataObject,
      collectionPath,
      objectType,
      scriptRequested,
    );
    return this._handleTransaction(transactionPromise, scriptRequested);
  }

  public saveObject<T extends Model>(object: T, scriptRequested: RequestType): JQueryPromise<T> {
    let transactionPromise;
    transactionPromise = this.dataRequestor().saveObject(object, scriptRequested);
    return this._handleTransaction(transactionPromise, scriptRequested);
  }

  public deleteObject<T>(objectToDelete: Model): JQueryPromise<T> {
    let transactionPromise;
    let x;
    transactionPromise = this.dataRequestor().deleteObject(objectToDelete);
    const parser = new CompoundDocumentParser(this.modelService(), this);
    x = new RequestHandlerDelete(
      transactionPromise,
      this.promiseService(),
      parser,
      this.modelService(),
      this,
    );
    x.deleteObject(objectToDelete);
    return x.promise();
  }

  private _handlerClass(scriptRequested: RequestType): any {
    if (scriptRequested === true) {
      return RequestHandlerNoData;
    } else if (scriptRequested === false) {
      return RequestHandlerCache;
    } else if (scriptRequested === 'html') {
      return RequestHandlerNoData;
    }
  }

  private _handleTransaction<T>(
    promise: JQueryPromise<T>,
    scriptRequested: RequestType,
  ): JQueryPromise<T> {
    const handlerKlass = this._handlerClass(scriptRequested);
    const handler = new handlerKlass(
      promise,
      this.promiseService(),
      this.parserForRequest(scriptRequested),
      this.modelService(),
      this,
    );
    return handler.promise();
  }

  private parserForRequest(scriptRequested: RequestType) {
    if (scriptRequested === false) {
      return new CompoundDocumentParser(this.modelService(), this);
    }
    return new SingleModelParser(this.modelService());
  }

  private dataRequestor(): ServerManager {
    return this._dataRequestor;
  }

  private dataStore(): IDataStore {
    return this._dataStore;
  }

  private promiseService(): PromiseService {
    return this.appService.promiseService;
  }

  private modelService(): IModelService {
    return this.appService.modelService;
  }
}
