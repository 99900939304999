import * as React from 'react';

export interface INavigationHeaderProps {
  menuIsExpanded: boolean;
  toggleExpansion: () => void;
}

export interface INavigationHeaderState {}

export class NavigationHeader extends React.PureComponent<
  INavigationHeaderProps,
  INavigationHeaderState
> {
  public render(): JSX.Element {
    return (
      <header>
        <a className="navbar-brand" href="/">
          SweatRecord
        </a>
        <button type="button" className="navbar-toggle" onClick={this.props.toggleExpansion}>
          <span className="sr-only">Toggle navigation</span>
          <span className="icon-bar" />
          <span className="icon-bar" />
          <span className="icon-bar" />
        </button>
      </header>
    );
  }
}
