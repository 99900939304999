import * as React from 'react';
import { ILocalizationService } from '../Services/ILocalizationService';

export interface ILocalizedComponentProps {}

/**
 * Localized base class for inheritance.
 */
export abstract class LocalizedComponent<
  TProps extends ILocalizedComponentProps,
  TState
> extends React.Component<TProps, TState> {
  public abstract render(): React.ReactNode;

  protected localize(path: string): string {
    return this.context.getValue(path);
  }
}

export const LocalizationContext = React.createContext<ILocalizationService>({} as any);

// tslint:disable-next-line:max-classes-per-file
export abstract class LocalizedPureComponent<
  TProps extends ILocalizedComponentProps,
  TState
> extends React.PureComponent<TProps, TState> {
  protected localize(path: string): string {
    return this.context.getValue(path);
  }
}

LocalizedComponent.contextType = LocalizationContext;
LocalizedPureComponent.contextType = LocalizationContext;
