import * as React from 'react';
import * as _ from 'underscore';
import { Exercise } from '../../../../Models/Exercise';
import { ExerciseGroup } from '../../../../Models/ExerciseGroup';
import { IExerciseHistory } from '../../../../Models/History/IExerciseHistory';
import { HistoryContainer } from '../History/HistoryContainer';
import * as AnimationDefaults from '../Shared/AnimationDefaults';
import { CommandsSection } from '../Shared/CommandsSection';
import { InstructionsContainer } from '../Shared/InstructionsContainer';
import { NoteElement } from '../Shared/NoteElement';
import { ISelectionProps, ISelectionState, Selection } from '../Shared/Selection';
import { TempoDisplay } from '../Shared/TempoDisplay';
import { Title } from '../Shared/Title';
import { ExerciseAttributes } from './ExerciseAttributes';

export interface IExerciseSelectionProps extends ISelectionProps<Exercise> {
  advancedModeEnabled: boolean;
  history: IExerciseHistory[];
  setIndex: number | false;
  isMobile: boolean;
}

export interface IExerciseSelectionState extends ISelectionState {
  reminderEditing: boolean;
  reminderContents: string;
  reminderInitialContents: string;
  reviewInitialContents: string;
  reviewEditing: boolean;
  reviewContents: string;
}

export class ExerciseSelection extends Selection<
  Exercise,
  IExerciseSelectionProps,
  IExerciseSelectionState
> {
  private advancedEnterAnimation = {
    animation: 'transition.slideDownIn',
    display: 'flex',
    duration: AnimationDefaults.defaults.smallAnimationDuration,
  };
  private advancedLeaveAnimation = {
    animation: 'transition.slideUpOut',
    duration: AnimationDefaults.defaults.smallAnimationDuration,
  };

  public constructor(props: IExerciseSelectionProps) {
    super(props);
    const reviewContent = props.model?.note_content;
    const reminderContent = props.model?.definition_note;
    this.state = {
      deleteVisible: false,
      reminderContents: reminderContent || '',
      reminderEditing: false,
      reminderInitialContents: reminderContent,
      reviewContents: reviewContent || '',
      reviewEditing: false,
      reviewInitialContents: reviewContent,
    };
  }

  public static getDerivedStateFromProps(
    nextProps: Readonly<IExerciseSelectionProps>,
    prevState: IExerciseSelectionState,
  ): Partial<IExerciseSelectionState> | null {
    const reminderChanging = nextProps.model?.definition_note !== prevState.reminderInitialContents;
    const reviewChanging = nextProps.model?.note_content !== prevState.reviewInitialContents;
    return {
      reminderInitialContents: reminderChanging
        ? nextProps.model?.definition_note
        : prevState.reminderInitialContents,
      reminderContents: reminderChanging
        ? nextProps.model?.definition_note || ''
        : prevState.reminderContents,
      reviewInitialContents: reviewChanging
        ? nextProps.model?.note_content
        : prevState.reviewInitialContents,
      reviewContents: reviewChanging
        ? nextProps.model?.note_content || ''
        : prevState.reviewContents,
    };
  }

  public render(): JSX.Element {
    if (this.props.isVisible === false) {
      return null;
    }
    if (this.props.model.parent() == null) {
      return null;
    }
    return (
      <div>
        <div>
          <Title title={this.definition().name} labelClass="sv-exercise-name" />
          <ExerciseAttributes
            category={this.definition().category}
            restPeriod={
              this.exerciseGroup().shouldShowRestExercise() && this.exerciseGroup().rest_exercise
            }
            restSet={this.exerciseGroup().shouldShowRestSet() && this.exerciseGroup().rest_set}
            barWeight={this.props.model.effective_bar_weight}
          />
          {_.isEmpty(this.props.model.concentric) ? null : (
            <TempoDisplay
              eccentric={this.props.model.eccentric}
              concentric={this.props.model.concentric}
              rest={this.props.model.rest}
              pause={this.props.model.pause}
            />
          )}
          {_.isEmpty(this.props.model.instructions) ? null : (
            <InstructionsContainer
              label={this.localize('object.exercise.instructions')}
              contents={this.props.model.instructions}
              inputClass="sv-exercise-instructions"
            />
          )}
          <NoteElement
            applyChanges={this.applyReminderChanges}
            label={this.localize('object.exercise_definition.note_content')}
            contents={this.state.reminderContents}
            isEditing={this.state.reminderEditing}
            startEditing={this.startReminderEditing}
            cancelEditing={this.cancelReminderChanges}
            onChange={this.handleReminderChanges}
            inputClass="sv-exercise-reminder"
            containerClass="sv-reminder-container"
          />
          <NoteElement
            applyChanges={this.applyReviewChanges}
            label={this.localize('object.exercise.note_content')}
            contents={this.state.reviewContents}
            isEditing={this.state.reviewEditing}
            startEditing={this.startReviewEditing}
            cancelEditing={this.cancelReviewChanges}
            onChange={this.handleReviewChanges}
            inputClass="sv-exercise-review"
            containerClass="sv-review-container"
          />
        </div>
        <CommandsSection
          viewModel={this.props.viewModel}
          deletePromptVisible={this.state.deleteVisible}
          updateDeleteVisibility={this.updateDeleteVisibility}
          deleteDeniedVisible={false}
          executeDelete={this.executeDelete}
          deleteConfirm={this.localize('mobile_selection_view.delete_exercise_confirm.confirm')}
          deleteCancel={this.localize('mobile_selection_view.delete_exercise_confirm.cancel')}
          deleteWarning={this.localize('mobile_selection_view.delete_exercise_confirm.warning')}
        >
          <button className="xbtn xbtn-primary sv-add-set" onClick={this.handleAddSet}>
            {this.localize('mobile_selection_view.add_set')}
          </button>
          <button
            className="xbtn xbtn-primary sv-change-exercise"
            onClick={this.handleChangeExercise}
          >
            {this.localize('mobile_selection_view.change_exercise')}
          </button>
          <button className="xbtn xbtn-primary sv-history" onClick={this.handleDisplayHistory}>
            {this.localize('mobile_selection_view.show_history')}
          </button>
          {this.props.advancedModeEnabled && (
            <button
              className="xbtn xbtn-primary sv-superset-exercise"
              onClick={this.handleAddSuperSet}
            >
              {this.localize('mobile_selection_view.superset_exercise')}
            </button>
          )}
        </CommandsSection>
        {this.props.isMobile && (
          <HistoryContainer highlightPosition={this.props.setIndex} history={this.props.history} />
        )}
      </div>
    );
  }

  private exerciseGroup(): ExerciseGroup {
    return this.props.model.exerciseGroup();
  }

  private definition() {
    return this.props.model.exercise_definition;
  }

  private handleChangeExercise = (): void => {
    this.props.viewModel.changeExercise();
  };

  private handleAddSuperSet = (): void => {
    this.props.viewModel.addSuperSet();
  };

  private handleDisplayHistory = (): void => {
    this.props.changeSpinnerVisibility(true);
    const onfulfilled = () => this.props.changeSpinnerVisibility(false);
    this.props.viewModel.displayHistoryPanel().then(onfulfilled, onfulfilled);
  };

  private handleAddSet = (): void => {
    this.props.changeSpinnerVisibility(true);
    const promise = this.props.viewModel.addSet();
    promise.always(() => this.props.changeSpinnerVisibility(false));
  };

  private handleReviewChanges = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    this.setState({ reviewContents: event.target.value });
  };

  private handleReminderChanges = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    this.setState({ reminderContents: event.target.value });
  };

  private startReviewEditing = (): void => {
    this.setState({ reviewEditing: true });
  };

  private startReminderEditing = (): void => {
    this.setState({ reminderEditing: true });
  };

  private cancelReviewChanges = (): void => {
    this.setState({ reviewEditing: false, reviewContents: this.props.model.note_content });
  };

  private cancelReminderChanges = (): void => {
    this.setState({ reminderEditing: false, reminderContents: this.props.model.definition_note });
  };

  private applyReminderChanges = (): void => {
    this.applyNoteChanges(this.state.reminderContents, 'definition_note', 'reminderEditing');
  };

  private applyReviewChanges = (): void => {
    this.applyNoteChanges(this.state.reviewContents, 'note_content', 'reviewEditing');
  };

  private applyNoteChanges(
    newValue: string,
    property: keyof Exercise,
    statePropertyName: keyof IExerciseSelectionState,
  ): void {
    this.props.changeSpinnerVisibility(true);
    const promise = this.props.viewModel.updateModelProperty(newValue, property);
    promise
      .always(() => this.props.changeSpinnerVisibility(false))
      .done(() => this.setState(prevState => ({ ...prevState, [statePropertyName]: false })));
  }
}
