import { ExerciseGroup } from './ExerciseGroup';
import { IWorkoutProps } from './IWorkoutProps';
import { Model } from './Model';

export type WorkoutPrivacyStatus = 'public' | 'private';

export class Workout extends Model implements IWorkoutProps {
  public largest_category: DefinitionCategory;
  public _childType: ModelType = 'exercise_group';
  public _childReferenceProperty = 'workout_id';
  public _childCollectionName = 'exercise_groups';
  public name: string;
  public planned_date: string;
  public instructions: string;
  public note_content: string;
  public updated_at: string;
  public status: ModelStatus;
  public links: {
    self: string;
    sharing: string;
  };
  public privacy_status: WorkoutPrivacyStatus;

  public exerciseGroups(): ExerciseGroup[] {
    return this.children() as ExerciseGroup[];
  }
}
