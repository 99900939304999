import * as _ from 'underscore';
import { IApplicationService } from '../Core/IApplicationService';
import { LocalizationService } from './LocalizationService';
import { PromiseService } from './PromiseService';

/**
 * The ViewService type is responsible for providing convenience methods to several view functions, and constructing
 * new view objects.
 */
export class ViewService {
  private DOMService: JQueryStatic;
  private PromiseService: PromiseService;
  private LocalizationService: LocalizationService;
  private _isMobile: boolean;

  /**
   * Constructs a view Service.
   * @param DomService
   * @param promiseService
   * @param localizationService
   * @param applicationService
   */
  constructor(
    DomService: JQueryStatic,
    applicationService: IApplicationService,
    promiseService: PromiseService,
    localizationService: LocalizationService,
  ) {
    this.DOMService = DomService;
    this.PromiseService = promiseService;
    this.LocalizationService = localizationService;
  }

  /**
   * Returns the localization service.
   * @returns {LocalizationService}
   */
  public localization(): LocalizationService {
    return this.LocalizationService;
  }

  /**
   * Returns an empty JQuery promise.
   * @returns {JQueryPromise<any>}
   */
  public emptyPromise(): JQueryDeferred<any> {
    return this.PromiseService.emptyPromise();
  }

  public isMobile(): boolean {
    if (_.isUndefined(this._isMobile)) {
      this._isMobile = window.innerWidth < 570;
    }
    return this._isMobile;
  }
}
