import * as _ from 'underscore';
import { IModelService } from '../../Services/IModelService';
import { CollectionResultSet } from '../CollectionResultSet';
import { IServerCollectionResult } from '../IServerCollectionResult';
import { ICollectionResponseParser } from './ICollectionResponseParser';

/**
 * The CollectionParser handles collection responses.
 * @abstract Subclasses should override the {@link parseObject} function for any special data processing.
 */
export abstract class CollectionParser<T> implements ICollectionResponseParser<T> {
  protected modelService: IModelService;

  constructor(modelService: IModelService) {
    this.modelService = modelService;
  }

  public parse(response: IServerCollectionResult<T>): CollectionResultSet<T> {
    const objects = _.map(response.data, (item: T) => {
      return this.parseObject(item);
    });
    return new CollectionResultSet(objects, response.links.next);
  }

  public abstract parseObject(object: T): T;
}
