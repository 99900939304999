export interface IPromiseService {
  rejectedPromise(): JQueryPromise<any>;

  resolvedPromise(returnObj: any): JQueryPromise<any>;

  emptyPromise(): JQueryDeferred<any>;
}

/**
 * Creates promises for use by functions.
 */
export class PromiseService implements IPromiseService {
  /**
   * Returns an empty promise.
   * @returns {JQueryDeferred<any>} Empty promise.
   */
  private static basePromise(): JQueryDeferred<any> {
    return $.Deferred();
  }

  /**
   * Returns a new, already rejected promise.
   * @returns {JQueryPromise<any>} Rejected promise.
   */
  public rejectedPromise(): JQueryPromise<any> {
    return PromiseService.basePromise()
      .reject()
      .promise();
  }

  /**
   * Returns a new already resolved promise.
   * @returns {JQueryPromise<any>} Resolved promise.
   * @param returnObj - Object to be returned.
   */
  public resolvedPromise(returnObj: any): JQueryPromise<any> {
    return PromiseService.basePromise()
      .resolve(returnObj)
      .promise();
  }

  /**
   * Returns a new, empty promise.
   * @returns {JQueryDeferred<any>} New promise.
   */
  public emptyPromise(): JQueryDeferred<any> {
    return PromiseService.basePromise();
  }
}
