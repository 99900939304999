import * as React from 'react';
import { IPanelViewModelProps } from '../../Panels/IPanelProps';
import { SelectionContainer } from './SelectionContainer';

export interface IMobileSelectionViewContainerProps {
  panels: { [key: string]: IPanelViewModelProps<any, any> };
  isMobile: boolean;
}

interface IMobileSelectionViewContainerState {}

export class MobileSelectionViewContainer extends React.Component<
  IMobileSelectionViewContainerProps,
  IMobileSelectionViewContainerState
> {
  public render(): React.ReactNode {
    if (!this.props.isMobile) {
      return null;
    }
    return Object.values(this.props.panels)
      .filter((panel: IPanelViewModelProps<any, any>) => panel.selectionProps != null)
      .map((panel: IPanelViewModelProps<any, any>) => {
        return (
          <SelectionContainer
            {...panel.selectionProps}
            key={panel.data.id}
            isMobile={this.props.isMobile}
          />
        );
      });
  }
}
