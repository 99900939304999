import * as React from 'react';
import * as _ from 'underscore';
import { Exercise } from '../../../Models/Exercise';
import { ExerciseDefinition } from '../../../Models/ExerciseDefinition';
import { IExerciseHistory } from '../../../Models/History/IExerciseHistory';
import { ILocalizationService } from '../../../Services/ILocalizationService';
import { EventDataDelete, IHistoryViewModel } from '../../../ViewModels/Panels/HistoryViewModel';
import { HistoryFormatter } from '../Helpers/History/HistoryFormatter';
import { IHistoryFormatter } from '../Helpers/History/IHistoryFormatter';
import { AbstractPanelViewContainer } from '../Panels/AbstractPanelViewContainer';
import { IPanelExternalData, IPanelInternalData } from '../Panels/IPanelData';
import { IPanelHeaderActions } from '../Panels/IPanelHeaderActions';
import { IPanelViewModelProps } from '../Panels/IPanelProps';
import { PanelTypes } from '../Panels/PanelTypes';
import {
  ExerciseHistoryModel,
  IHistoryItemActionProps,
  IHistoryItemDataProps,
} from './HistoryItem';

export type IHistoryItemContainerProps = IPanelViewModelProps<
  IHistoryItemDataProps & IHistoryItemActionProps & IHistoryAssignable,
  IHistoryViewModel
>;

export interface IHistoryAssignable {
  assign(history: IHistoryViewModel): void;
  unassign(history: IHistoryViewModel): void;
}

export interface IHistoryItemContainerState {}

export class HistoryItemContainer
  extends AbstractPanelViewContainer<
    IHistoryItemDataProps & IHistoryAssignable,
    {},
    IHistoryItemContainerState,
    IHistoryItemActionProps
  >
  implements IHistoryViewModel {
  private historyFormatter: IHistoryFormatter = new HistoryFormatter();
  public static initialPanelData(
    panelId: string,
    parentViewModelId: string | null = null,
  ): IPanelExternalData {
    return {
      hasFocus: false,
      hasTransactionOverlay: false,
      id: panelId,
      panelType: PanelTypes.History,
      parentPanelId: parentViewModelId,
      title: 'History',
    };
  }

  public static initialPanelProps(
    localizationService: ILocalizationService,
    assignable: IHistoryAssignable | null,
    model: Exercise | ExerciseDefinition,
    setIndex: number | null,
  ): IHistoryItemDataProps & IHistoryAssignable {
    return {
      assign: assignable?.assign,
      exercises: [],
      highlightPosition: setIndex,
      historyType: null,
      model,
      translationService: localizationService,
      unassign: assignable?.unassign,
    };
  }

  public componentDidMount(): void {
    super.componentDidMount();
    $(document).on('sr:object:delete:exercise_definition', this.handleDelete);
    $(document).on('sr:object:delete:exercise', this.handleDelete);
    if (this.props.props.assign) {
      this.props.props.assign(this);
    }

    this.displayHistoryForModel(this.props.props.model, this.props.props.highlightPosition);
  }

  public componentWillUnmount(): void {
    $(document).off('sr:object:delete:exercise_definition', this.handleDelete);
    $(document).off('sr:object:delete:exercise', this.handleDelete);
    if (this.props.props.unassign) {
      this.props.props.unassign(this);
    }
  }

  public focusHistoryPanel(): void {
    this.focusPanel();
  }

  public onDismiss(): void {
    this.removePanel();
  }

  public onRefresh(): void {
    this.refresh();
  }

  public openWorkout(workoutId: string | number, workoutUrl: string): JQueryPromise<any> {
    workoutId = _.isNumber(workoutId) ? workoutId : parseInt(workoutId, 10);
    return this.props.panelManager.openWorkoutWithUrl(workoutId, workoutUrl);
  }

  public displayHistoryForExercise(
    exercise: Exercise,
    setIndex: number | false,
  ): JQueryPromise<any> {
    let promise;
    this.updatePanelData({ hasTransactionOverlay: true });
    promise = exercise.history().all();
    return promise.done((exerciseHistory: IExerciseHistory[]) => {
      this.updatePanelData({ hasTransactionOverlay: false });
      this.updateDisplay(exercise, exerciseHistory, setIndex === false ? null : setIndex);
    });
  }

  public displayHistoryForDefinition(definition: ExerciseDefinition): JQueryPromise<any> {
    let promise;
    this.updatePanelData({ hasTransactionOverlay: true });
    promise = definition.history().all();
    return promise.done((history: IExerciseHistory[]) => {
      this.updatePanelData({ hasTransactionOverlay: false });
      this.updateDisplay(definition, history, null);
      this.focusPanel();
    });
  }

  public refresh(): void {
    this.requestRefresh();
  }

  public requestRefresh(): JQueryPromise<any> {
    let promise;
    this.updatePanelData({ hasTransactionOverlay: true });
    const model = this.props.props.model;
    if (model instanceof ExerciseDefinition) {
      promise = model.history().reload();
      return promise.done(history => {
        this.updatePanelData({ hasTransactionOverlay: false });
        return this.updateDisplay(model, history, this.props.props.highlightPosition);
      });
    }
  }

  public openWorkoutFromURL(url: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.openWorkout(null, url).then(resolve, reject);
    });
  }

  public handleDeleteEvent(e: JQueryEventObject, eventData: EventDataDelete): void {
    if (eventData.object === this.props.props.model) {
      return this.removePanel();
    }
  }

  public displayHistoryForModel(
    model: ExerciseHistoryModel,
    setIndex: number | null,
  ): JQueryPromise<any> {
    if (model instanceof Exercise) {
      return this.displayHistoryForExercise(model, setIndex);
    } else if (model instanceof ExerciseDefinition) {
      return this.displayHistoryForDefinition(model);
    }
  }

  public handleDelete = (event: JQueryEventObject, eventData: EventDataDelete): void => {
    this.handleDeleteEvent(event, eventData);
  };

  protected actionProps(): IHistoryItemActionProps {
    return {
      openWorkoutFromUrl: this.handleOpenWorkoutFromURL,
    };
  }

  protected renderHeaderActions(): IPanelHeaderActions {
    return {
      onDismiss: this.handleDismiss,
      onRefresh: this.handleRefresh,
    };
  }

  protected panelData(): IPanelInternalData {
    return {
      additionalClasses: '',
      type: this.props.props.historyType || 'history',
    };
  }

  private updateDisplay(
    model: Exercise | ExerciseDefinition,
    history: IExerciseHistory[],
    highlightPosition: number | null,
  ) {
    const panelType = model.type === 'exercise' ? 'exercise-history' : 'definition-history';
    let exerciseName;
    exerciseName = model instanceof Exercise ? model.exerciseDefinition().name : model.name;
    const panelName = `${exerciseName} History`;
    const formatted = this.historyFormatter.formatHistory(history);
    this.updateContentProps({ exercises: formatted, highlightPosition, historyType: panelType });
    this.updatePanelData({
      id: this.panelId,
      objectId: model.id.toString(),
      title: panelName,
    });
  }

  private handleOpenWorkoutFromURL = (url: string): Promise<any> => {
    return this.openWorkoutFromURL(url);
  };

  private handleDismiss = (): void => {
    this.onDismiss();
  };

  private handleRefresh = (): void => {
    this.onRefresh();
  };
}
