import * as React from 'react';
import { Exercise as ExerciseModel } from '../../../Models/Exercise';
import { ExerciseDefinition } from '../../../Models/ExerciseDefinition';
import { ILocalizationService } from '../../../Services/ILocalizationService';
import { IExerciseHistoryFormatted } from '../Helpers/History/IExerciseHistoryFormatted';
import { Exercise } from './Exercise';
import DisplayClasses from './PanelViewClasses';

export interface IHistoryItemActionProps {
  openWorkoutFromUrl(url: string): Promise<any>;
}

export type ExerciseHistoryModel = ExerciseModel | ExerciseDefinition;

export interface IHistoryItemDataProps {
  exercises: IExerciseHistoryFormatted[];
  translationService: ILocalizationService;
  highlightPosition: number | null;
  historyType: 'exercise-history' | 'definition-history' | null;
  model: ExerciseHistoryModel;
}

export interface IHistoryItemState {}

export class HistoryItem extends React.PureComponent<
  IHistoryItemDataProps & IHistoryItemActionProps,
  IHistoryItemState
> {
  public render(): JSX.Element {
    if (this.props.exercises) {
      const exercises = this.props.exercises.map(exercise => {
        return (
          <Exercise
            highlightPosition={this.props.highlightPosition}
            displayClasses={DisplayClasses}
            exercise={exercise}
            key={exercise.id}
            openWorkoutFromUrl={this.props.openWorkoutFromUrl}
          />
        );
      });
      return <div>{exercises}</div>;
    } else {
      const translation = this.props.translationService.getValue(
        'edit_panel.exercise_history.never_done',
      );
      return <div>{translation}</div>;
    }
  }
}
