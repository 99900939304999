import * as React from 'react';
import { VelocityTransitionGroup } from 'velocity-react';
import { IPlateItem } from '../../../../Models/IExerciseSet';
import { ILocalizedComponentProps, LocalizedPureComponent } from '../../../LocalizedComponent';
import { PlateItem } from './PlateItem';

export interface IPlatesDisplayProps extends ILocalizedComponentProps {
  plates: IPlateItem[];
  remainder: string;
  barWeight: string;
}

export interface IPlatesDisplayState {}

const animationDuration = 500;

const enterAnimation = {
  animation: 'transition.slideDownIn',
  duration: animationDuration,
};

const leaveAnimation = {
  animation: 'transition.slideDownOut',
  duration: animationDuration,
};

export class PlatesDisplay extends LocalizedPureComponent<
  IPlatesDisplayProps,
  IPlatesDisplayState
> {
  public render(): JSX.Element {
    return (
      <div className="sv-plate-section">
        <div className="sv-section-subtitle">
          {this.localize('mobile_selection_view.exercise_set.plates')}
        </div>
        <VelocityTransitionGroup
          component="div"
          className="sv-horizontal-group sv-plate-content"
          enter={enterAnimation}
          leave={leaveAnimation}
        >
          {this.renderBarWeight()}
          {this.renderPlateEntries()}
        </VelocityTransitionGroup>
      </div>
    );
  }

  private renderBarWeight(): JSX.Element {
    return (
      <div className="sv-plate-bar-weight" key="barWeight">
        <div>{this.props.barWeight}</div>
        <span className="sv-label-text">{this.localize('object.exercise.bar_weight')}</span>
      </div>
    );
  }

  private renderPlateEntries(): JSX.Element[] {
    return this.props.plates.map(plate => {
      return <PlateItem plate={plate} key={plate.weight} />;
    });
  }
}
