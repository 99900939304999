import * as React from 'react';
import * as _ from 'underscore';
import { ExerciseGroup } from '../../../../Models/ExerciseGroup';
import { INewSelectionViewModel } from '../../../../ViewModels/Selection/INewSelectionViewModel';
import { ILocalizedComponentProps, LocalizedPureComponent } from '../../../LocalizedComponent';
import { Title } from '../Shared/Title';
import { ExerciseGroupEntry } from './ExerciseGroupEntry';

export interface IGroupMovementProps extends ILocalizedComponentProps {
  /**
   * The exercise group being moved.
   */
  exerciseGroup: ExerciseGroup;
  viewModel: INewSelectionViewModel;
  changeSpinnerVisibility: (newValue: boolean) => void;
}

export interface IGroupMovementState {
  newGroupPosition: number;
  moveFirst: boolean;
  displayPosition: number;
}

const MOVE_TO_BOTTOM_PATH = 'group_movement.move_to_top';
const APPLY_CHANGES_PATH = 'group_movement.apply';
const EXPLANATION_PATH = 'group_movement.explanation';
const TITLE_PATH = 'group_movement.title';
const POSITION_ADJUSTMENT = 0.5;

/**
 * The GroupMovement component contains all the elements necessary for handling the movement of a group.
 * Since group position values are not guaranteed to be consecutive, this component also contains logic
 * for determining the actual position value to be assigned in the view model.
 */
export class GroupMovement extends LocalizedPureComponent<
  IGroupMovementProps,
  IGroupMovementState
> {
  public constructor(props: IGroupMovementProps, context?: any) {
    super(props, context);
    this.state = {
      displayPosition: props.exerciseGroup.position,
      moveFirst: false,
      newGroupPosition: props.exerciseGroup.position,
    };
  }

  public render(): JSX.Element {
    return (
      <div>
        <Title title={this.localize(TITLE_PATH)} labelClass="" />
        <ul className="groupMovement-list">
          <li onClick={this.handleMoveToTop} className="groupMovement-entry" key="top">
            {this.localize(MOVE_TO_BOTTOM_PATH)}
          </li>
          {this.renderMovementList()}
        </ul>
        <div className="groupMovement-commands">
          <button
            className="groupMovement-button xbtn xbtn-primary"
            onClick={this.handleCompleteClick}
          >
            {this.localize(APPLY_CHANGES_PATH)}
          </button>
        </div>
        <div className="groupMovement-help">{this.localize(EXPLANATION_PATH)}</div>
      </div>
    );
  }

  public componentWillUpdate(
    nextProps: Readonly<IGroupMovementProps>,
    nextState: Readonly<IGroupMovementState>,
    nextContext: any,
  ): void {
    if (this.props.exerciseGroup !== nextProps.exerciseGroup) {
      this.setState({
        displayPosition: nextProps.exerciseGroup.position,
        moveFirst: false,
        newGroupPosition: nextProps.exerciseGroup.position,
      });
    }
  }

  private renderMovementList(): JSX.Element[] {
    const sortedGroups = _.sortBy(this.exerciseGroups(), group => {
      return group === this.props.exerciseGroup ? this.state.displayPosition : group.position;
    });
    return sortedGroups.map((group, index) => {
      const isExerciseGroup = this.props.exerciseGroup === group;
      return (
        <ExerciseGroupEntry
          exerciseGroup={group}
          position={index}
          moveToAction={this.handleMoveBefore}
          isBeingMoved={isExerciseGroup}
          key={group.id}
        />
      );
    });
  }

  private exerciseGroups(): ExerciseGroup[] {
    return this.props.exerciseGroup.workout().exerciseGroups() as ExerciseGroup[];
  }

  private handleCompleteClick = (): void => {
    const newPosition = this.state.moveFirst ? 1 : this.state.newGroupPosition;
    this.props.changeSpinnerVisibility(true);
    this.props.viewModel
      .updateExerciseGroupPosition(this.props.exerciseGroup, newPosition)
      .always(() => this.props.changeSpinnerVisibility(false));
  };

  private handleMoveToTop = (): void => {
    this.setState({ newGroupPosition: 1, moveFirst: true, displayPosition: 0 });
  };

  private handleMoveBefore = (group: ExerciseGroup): void => {
    const newPosition =
      group.position >= this.state.displayPosition ? group.position + 0.5 : group.position - 0.5;
    this.setState({
      displayPosition: newPosition,
      moveFirst: false,
      newGroupPosition: group.position,
    });
  };
}
