import * as React from 'react';
import RandomUUID from '../../../../../Extension/RandomUUID';
import { ILocalizedComponentProps, LocalizedPureComponent } from '../../../../LocalizedComponent';

export interface ITempoFieldProps extends ILocalizedComponentProps {
  value: string;
  labelPath: string;
  isValid: boolean;
  updateValue: (newValue: string) => void;
  propertyName: string;
}

export interface ITempoFieldState {}

export class TempoField extends LocalizedPureComponent<ITempoFieldProps, ITempoFieldState> {
  private UUID: string = RandomUUID();

  public render(): JSX.Element {
    const inputName = `exercise-tempo-${this.props.propertyName}`;
    const inputId = `exercise-tempo-${this.props.propertyName}-${this.UUID}`;
    const inputClass = this.props.isValid ? `tempo-control` : `tempo-control form-field-error`;
    return (
      <div className="form-horizontal-container">
        <label htmlFor={inputId}>{this.localize(this.props.labelPath)}</label>
        <input
          className={inputClass}
          id={inputId}
          name={inputName}
          type="text"
          onChange={this.handleChange}
          value={this.props.value}
        />
      </div>
    );
  }

  private handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.props.updateValue(event.target.value);
  };
}
