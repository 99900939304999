import * as React from 'react';
import { Model } from '../../../Models/Model';
import { IModelService } from '../../../Services/IModelService';
import { ISelectionContainerProps } from '../MobileSelection/Container/SelectionContainer';
import { IPanelData, IPanelInternalData } from './IPanelData';
import { IPanelHeaderActions } from './IPanelHeaderActions';
import {
  IComponentStateManager,
  IPanelProps,
  IPanelViewModelProps,
  IPanelPassthroughProps,
} from './IPanelProps';
import { PanelComponent } from './PanelComponent';

export abstract class AbstractPanelViewContainer<TProps, TViewModel, TState, TActionProps = {}>
  extends React.Component<
    IPanelViewModelProps<TProps & TActionProps, TViewModel> & IPanelPassthroughProps,
    TState
  >
  implements IComponentStateManager {
  protected get panelId(): string {
    return this.props.data.id;
  }

  protected get modelService(): IModelService {
    return this.props.appService.modelService;
  }

  public focusPanel(): void {
    this.props.stateManager.focusPanel(this.panelId);
  }
  public updatePanelData(props: Partial<IPanelData>): void {
    this.props.stateManager.updatePanelData(this.panelId, props);
  }
  public updatePanelPartial<T>(props: Partial<T>): void {
    this.props.stateManager.updatePanelPartial(this.panelId, props);
  }
  public updatePanelSelectionProps(props: Partial<ISelectionContainerProps>): void {
    this.props.stateManager.updatePanelSelectionProps(this.panelId, props);
  }
  public removePanel(): void {
    if (this.props.completePromise.state() === 'pending') {
      this.props.completePromise.reject();
    }
    this.props.stateManager.removeProps(this.panelId);
  }

  public componentDidMount(): void {
    this.props.assignComponent(this);
  }

  public getPanelProps(): IPanelViewModelProps<any, any> {
    return this.props;
  }

  public render(): JSX.Element {
    return <PanelComponent {...this.renderProps()} isMobile={this.props.isMobile} />;
  }

  protected renderProps(): IPanelProps<TProps, TViewModel> & IPanelPassthroughProps {
    return {
      actionProps: this.actionProps(),
      data: this.data(),
      headerActions: this.renderHeaderActions(),
      isMobile: this.props.isMobile,
      onMount: this.props.onMount,
      position: this.props.position,
      props: this.panelProps(),
      selectionProps: this.props.selectionProps,
    };
  }

  protected data(): IPanelData {
    return {
      ...this.props.data,
      ...this.panelData(),
    };
  }

  protected panelProps(): TProps {
    return this.props.props;
  }

  protected abstract actionProps(): TActionProps;

  protected abstract panelData(): IPanelInternalData;

  protected abstract renderHeaderActions(): IPanelHeaderActions;

  protected updateContentProps(props: Partial<TProps>): void {
    this.updatePanelPartial(props);
  }

  protected changeSelection(model: Model): JQueryPromise<any> {
    if (this.props.selectionProps) {
      return this.props.selectionProps.viewModel.changeSelection(model);
    }
  }
  protected displayRecord(type: string, id: string | number, modalDisplay?: boolean): void {
    if (this.props.selectionProps) {
      this.props.selectionProps.viewModel.displayRecord(type, id, modalDisplay);
    }
  }
}
