import * as log from 'loglevel';
import * as React from 'react';
import * as _ from 'underscore';
import { IApplicationService } from '../../Core/IApplicationService';
import { ExerciseDefinition } from '../../Models/ExerciseDefinition';
import { User } from '../../Models/User';
import { LocalizationService } from '../../Services/LocalizationService';
import { ViewService } from '../../Services/ViewService';
import { IWorkoutCopyViewDelegate } from '../../ViewModels/IWorkoutCopyViewDelegate';
import { LocalizationContext } from '../LocalizedComponent';
import Moment = moment.Moment;
import { CopyResult } from './CopyResult';
import { IWorkoutCopyView } from './IWorkoutCopyView';
import { MapResult } from './MapResult';
import { ReactWorkoutCopyView } from './ReactWorkoutCopyView';

export interface IWorkoutCopyViewModelProps {
  viewService: ViewService;
  application: IApplicationService;
  workoutUrl: string;
}

interface IWorkoutCopyViewModelState {
  initialized: boolean;
  copyResult: CopyResult;
  searchResults: ExerciseDefinition[];
}

/**
 * The WorkoutCopyViewModel is responsible for handling the workout copy process.
 */
export class WorkoutCopyViewModel
  extends React.Component<IWorkoutCopyViewModelProps, IWorkoutCopyViewModelState>
  implements IWorkoutCopyViewDelegate {
  private readonly localizationService: LocalizationService;
  private _copyView: IWorkoutCopyView;

  public constructor(props: IWorkoutCopyViewModelProps) {
    super(props);
    this.localizationService = props.viewService.localization();
    this.state = {
      copyResult: null,
      initialized: false,
      searchResults: [],
    };
  }

  public componentDidMount() {
    this.localizationService.localizationReady().done(() => {
      this.setState({ initialized: true });
    });
  }

  public render(): JSX.Element {
    // const component = document.getElementById('reactLocation');
    if (!this.state.initialized) {
      return <div />;
    }
    return (
      <LocalizationContext.Provider value={this.props.application.localizationService}>
        <ReactWorkoutCopyView
          viewService={this.props.viewService}
          viewModel={this}
          ref={this.setCopyViewRef}
        />
      </LocalizationContext.Provider>
    );
  }

  public setCopyViewRef = (view: IWorkoutCopyView) => {
    this._copyView = view;
  };

  public copyView(): IWorkoutCopyView {
    return this._copyView;
  }

  public startCopy() {
    const promise = $.get({
      dataType: 'json',
      url: this.props.workoutUrl,
    });
    promise.done(returnData => {
      this.handleResult(returnData.data);
    });
  }

  public handleResult(result: CopyResult) {
    this.setState({
      copyResult: result,
    });
    _.each(this.state.copyResult.results, function(mapResult: MapResult) {
      if (mapResult.result_type === 'none') {
        mapResult.resolved_type = 'new';
      } else {
        mapResult.resolved_type = 'existing';
        mapResult.resolved_uid = mapResult.result_uid;
      }
    });
    const mappingRequired = _.filter(result.results, (mapping: MapResult) => {
      return mapping.result_type === 'none';
    });
    this.copyView().populateForm(result.workout_name, moment(), mappingRequired);
    this.copyView().displayStartCopyButton(false);
  }

  /**
   * Sets the destination for the supplied origin definition UID.
   * @param originUid - origin definition UUID
   * @param destinationUid - desitinationUID. If null, user is indicating that a new definition should be created.
   */
  public setDestination(originUid: string, destinationUid: string | null) {
    const mapResult: MapResult = { ...this.getMapResult(originUid) };
    let destinationName: string = null;
    if (destinationUid == null) {
      mapResult.resolved_type = 'new';
      mapResult.resolved_uid = null;
    } else {
      const definition: ExerciseDefinition = _.find(this.state.searchResults, function(
        exerciseDefinition: ExerciseDefinition,
      ) {
        return exerciseDefinition.id === destinationUid;
      });
      destinationName = definition.name;
      mapResult.resolved_name = destinationName;
      mapResult.resolved_type = 'existing';
      mapResult.resolved_uid = definition.id.toString();
    }
    const mapResultIndex = _.findIndex(
      this.state.copyResult.results,
      (map: MapResult) => map.origin_uid === originUid,
    );
    this.setState((prevState: IWorkoutCopyViewModelState) => {
      const newResultMap = prevState.copyResult.results.slice();
      newResultMap[mapResultIndex] = mapResult;
      const copyResult: CopyResult = {
        ...prevState.copyResult,
        results: newResultMap,
      };
      return { copyResult };
    });
    this.copyView().assignDestination(mapResult, mapResult.resolved_type, destinationName);
  }

  /**
   * Returns the map result for the provided origin ID.
   * @param originUid
   */
  public getMapResult(originUid: string): MapResult {
    return _.find(this.state.copyResult.results, function(mapResult: MapResult) {
      return mapResult.origin_uid === originUid;
    });
  }

  /**
   * Returns the current user.
   */
  public currentUser(): User {
    return this.props.application.currentUser;
  }

  /**
   * Responds to requests for search results.
   * @param searchText The text to search for.
   */
  public requestSearchResults(searchText: string) {
    const promise = this.currentUser()
      .exercise_definitions()
      .search(searchText);
    promise.done(result => this.handleSearchResults(result.newObjects));
    promise.fail(function(outcome) {
      log.error(outcome);
    });
  }

  public submitCopy(newWorkoutName: string, newWorkoutDate: Moment): Promise<{}> {
    const submitData = {
      data: {
        mappings: this.state.copyResult.results,
        workout_date: moment(newWorkoutDate).toISOString(),
        workout_id: this.state.copyResult.workout_id,
        workout_name: newWorkoutName,
      },
    };
    return new Promise((resolve, reject) => {
      const submitPromise = $.ajax({
        contentType: 'application/json',
        data: JSON.stringify(submitData),
        dataType: 'json',
        method: 'POST',
        url: this.props.workoutUrl,
      });
      submitPromise.then((d, t, s) => this.handleSubmitResponse(d, t, s));
      submitPromise.then(resolve);
      submitPromise
        .fail((j: JQueryXHR, s: string, e: any) => this.handleSubmitFail(j, s, e))
        .fail(reject);
    });
  }

  public handleSubmitResponse(data: any, textStatus: string, jqXHR: JQueryXHR) {
    if (jqXHR.status === 201) {
      this.copyView().removeForm();
      this.copyView().displayStartCopyButton(true);
    } else {
      log.error(data, textStatus);
    }
  }

  public handleSubmitFail(j: JQueryXHR, status: string, errorThrown: any) {
    log.error(j, status, errorThrown);
  }

  /**
   * Responds to search results coming in.
   * @param resultArray
   */
  public handleSearchResults(resultArray: ExerciseDefinition[]) {
    this.setState({
      searchResults: resultArray,
    });
    this.copyView().populateSearchResults(resultArray);
  }
}
