import * as DeepDiff from 'deep-diff';
import * as log from 'loglevel';
import * as _ from 'underscore';
import { DataLayer } from '../Data/DataLayer';
import { DiffService } from '../Services/DiffService';
import { IPromiseService } from '../Services/PromiseService';

interface IModelData {
  [key: string]: any;
}

export interface IModel {
  type: ModelType;
  id: string | number;
  links: {
    self: string;
  };
  updated_at: string;
}

export class Model implements IModel {
  public type: ModelType;
  public id: string | number;
  public relationships: any;
  public links: {
    self: string;
  };
  public updated_at: string;
  protected __diffService: DiffService;
  protected _childType: ModelType;
  protected _parentType: ModelType;
  protected __promiseService: IPromiseService;
  protected __dataLayer: DataLayer;
  protected _savedData: IModelData;
  protected _dataKeyArray: string[];
  protected _childCollectionName: string;
  protected _childReferenceProperty: string;
  protected _parentReferenceProperty: string;
  private _deleted: boolean;

  constructor(
    dataObj: any,
    persistenceObj: DataLayer | null,
    diffService: DiffService,
    promiseService: IPromiseService,
  ) {
    this.__diffService = diffService;
    this.__promiseService = promiseService;
    this.__dataLayer = persistenceObj != null ? persistenceObj : null;
    this._setInitialData(dataObj);
  }

  public data(): IModelData {
    return _.pick(this, this.dataKeys());
  }

  public changes(): any {
    let diff;
    diff = this.changeDiff();
    return this._changedKeys(diff);
  }

  public changeDiff(): DeepDiff.Diff<any>[] {
    const currentData = this.data();
    const savedData = this.getSavedData();
    this.checkedSavedInequality();
    return this.calculateDiff(savedData, currentData);
  }

  public toString(): string {
    return '[Model: ' + this.type + ', ID: ' + this.id + ']';
  }

  public parent(): Model {
    if (this._parentType) {
      return this.dataStore().getData(
        this._parentType,
        (this as any)[this._parentReferenceProperty],
      );
    }
  }

  public siblings(): Model[] {
    let result;
    if (this._parentType) {
      result = this.dataStore().getChildrenData(
        (this as any)[this._parentReferenceProperty],
        this.type,
        this._parentReferenceProperty,
      );
    }
    return _.without(result, this);
  }

  /**
   * Returns a position sorted collection of children models.
   * @returns Model
   */
  public children(): Model[] {
    let result: Model[];
    if (this._childType) {
      result = this.dataStore().getChildrenData(
        this.id,
        this._childType,
        this._childReferenceProperty,
      );
    }
    return _.sortBy(result, 'position');
  }

  public createChild(dataObj: any): JQueryPromise<Model> {
    if (this._childType) {
      return this.createChildObject(this._childType, dataObj, this.childrenURL());
    }
  }

  public createChildObject(type: ModelType, dataObj: any, URL: string): JQueryPromise<Model> {
    return this.dataStore().createObject(dataObj, URL, type, false);
  }

  public reload(): JQueryPromise<Model> {
    if (this.isDeleted()) {
      return this.rejectedPromise();
    }
    return this.dataStore().getObject(this, false);
  }

  public save(overrideChanges?: any): JQueryPromise<any> {
    const scriptRequested = false;
    if (overrideChanges == null) {
      overrideChanges = {};
    }
    if (this.isDeleted()) {
      return this.rejectedPromise();
    }
    const overrideKeys = _.keys(overrideChanges);
    if (overrideKeys.length >= 1) {
      this.update(overrideChanges);
      this.extendPersistedData(overrideKeys);
    }
    const diff = this.changes();
    if (!_.keys(diff).length) {
      return this.resolvedPromise(this);
    }
    this.validate();
    if (this.links && this.links.self != null && this.type != null && diff != null) {
      return this.dataStore()
        .saveObject(this, scriptRequested)
        .fail(() => this.setPersistedData(this.getSavedData()));
    } else {
      return this.rejectedPromise();
    }
  }

  public validate() {}

  public delete(): JQueryPromise<Model> {
    const newPromise = this.promiseService().emptyPromise();
    const permissionPromise = this.canBeDeleted();
    permissionPromise.done(() => {
      return this.dataStore()
        .deleteObject(this)
        .done(() => {
          let deleteEventString;
          deleteEventString = 'sr:object:delete:' + this.type;
          log.info('Triggering Event: ', deleteEventString);
          $(document).trigger(deleteEventString, {
            deleteObject: this,
            object: this,
          });
          return newPromise.resolve();
        });
    });
    permissionPromise.fail(() => {
      return newPromise.reject();
    });
    return newPromise;
  }

  /**
   * Similar to {@link canBeDeleted}, this method returns a promise that checks if the model can be deleted. It
   * resolves with the answer.
   */
  public isDeletePermitted(): JQueryPromise<boolean> {
    return this.promiseService()
      .emptyPromise()
      .resolve(true);
  }

  /**
   * Returns a promise that checks if the model can be deleted, and returns with that result. If deletion is
   * permitted, it resolves. If not, the promise is rejected.
   */
  public canBeDeleted(): JQueryPromise<any> {
    return this.promiseService()
      .emptyPromise()
      .resolve(true);
  }

  public isDeleted(): boolean {
    return this._deleted;
  }

  public childrenURL(): string {
    if (this._childType) {
      return this.relationships[this._childCollectionName].links.self;
    }
  }

  public setPersistedData(dataObj: any): void {
    let storageObj;
    this.dataKeys(dataObj);
    this.updateData(dataObj);
    storageObj = {};
    $.extend(true, storageObj, dataObj);
    this._assignValues(dataObj);
    this._savedData = storageObj;
    this.checkedSavedInequality();
  }

  public update(dataObj: any): void {
    $.extend(this, dataObj);
  }

  protected calculateDiff(referenceData: any, newData: any): DeepDiff.Diff<any>[] {
    return this.diffService().getDiff(referenceData, newData);
  }

  protected getSavedData(): IModelData {
    return this._savedData;
  }

  protected updateData(dataObj: any): void {
    let changeDiff;
    changeDiff = this.calculateDiff(this.getSavedData(), dataObj);
    this._assignValues(dataObj);
    this.triggerUpdate(changeDiff);
  }

  protected dataStore(): DataLayer {
    return this.__dataLayer;
  }

  protected triggerUpdate(changeDiff: DeepDiff.Diff<any>[]): void {
    if (changeDiff != null) {
      if (!this.diffService().isNewDiff(changeDiff)) {
        log.info('Triggering - sr:object:update:' + this.type + ' - ', this.toString());
        $(document).trigger('sr:object:update:' + this.type, {
          changedKeys: this.diffService().changeKeys(changeDiff, 0),
          diff: changeDiff,
          object: this,
          updateObject: this,
        });
      }
    }
  }

  protected promiseService(): IPromiseService {
    return this.__promiseService;
  }

  protected emptyPromise(): JQueryDeferred<any> {
    return this.promiseService().emptyPromise();
  }

  protected resolvedPromise(resolvedObject: any): JQueryPromise<any> {
    return this.promiseService().resolvedPromise(resolvedObject);
  }

  protected rejectedPromise(): JQueryPromise<any> {
    return this.promiseService().rejectedPromise();
  }

  private _changedKeys(diffObj: DeepDiff.Diff<any>[]): any {
    let keysToSelect;
    keysToSelect = this.diffService().changeKeys(diffObj, 1);
    return _.pick(this, keysToSelect);
  }

  /**
   * Gets/sets a list of data keys that are part of the server data. Should not include any client specific data.
   * @param dataObj Optional. If provides, sets the data keys using the object.
   * @returns Array of data keys that can be saved to the server.
   * @private
   */
  private dataKeys(dataObj?: any): string[] {
    let result: string[];
    let badKeys: string[];
    if (dataObj != null) {
      this._dataKeyArray = _.keys(dataObj);
    }
    result = this._dataKeyArray;
    badKeys = _.filter(result, function(entry: string): boolean {
      return entry[0] === '_';
    });
    if (badKeys.length > 0) {
      log.warn('Bad Keys Included', badKeys);
    }
    return result;
  }

  private checkedSavedInequality(): void {
    let currentData;
    let savedData;
    currentData = this.data();
    savedData = this.getSavedData();
    if (
      currentData.performance_specifications &&
      currentData.performance_specifications === savedData.performance_specifications
    ) {
      log.error('Saved Data Performance is same as Current Data');
    }
  }

  private extendPersistedData(keys: string[]) {
    return _.forEach(keys, value => {
      return (this._savedData[value] = '');
    });
  }

  private _assignValues(dataObj: any) {
    $.extend(true, this, dataObj);
    return this._copyObjects(dataObj);
  }

  private _copyObjects(dataObj: any) {
    let arrayNames;
    let data;
    let objNames;
    let results;
    arrayNames = ['performance_specifications', 'intensity_parameter_value', 'configurations'];
    objNames = ['plates'];
    for (const name of arrayNames) {
      if (dataObj[name]) {
        data = dataObj[name];
        (this as any)[name] = _.map(data, _.clone);
      }
    }
    results = [];
    for (const name of objNames) {
      // tslint:disable-next-line
      results.push(
        // tslint:disable-next-line:ban-comma-operator
        dataObj[name] ? ((data = dataObj[name]), ((this as any)[name] = _.clone(data))) : void 0,
      );
    }
    return results;
  }

  private _setInitialData(dataObj: any): void {
    this.dataKeys(dataObj);
    this._assignValues(dataObj);
    this._savedData = dataObj;
    this.checkedSavedInequality();
  }

  private diffService(): DiffService {
    return this.__diffService;
  }
}
