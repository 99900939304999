import { Model } from '../../Models/Model';
import { CollectionResultSet } from '../CollectionResultSet';
import { IServerResult } from '../IServerResult';
import { ServerRequestHandler } from './ServerRequestHandler';
/**
 * Parsers the response as models and stores it in the data lyer.
 */
export class RequestHandlerRecordCollection<T extends Model> extends ServerRequestHandler<T> {
  protected doneRequest(response: IServerResult<T>) {
    const parsedResponse = this._responseParser.parse(response);
    this._objectManager.storeCollectionResultSet((parsedResponse as any) as CollectionResultSet<T>);
    return this._promise.resolve(parsedResponse);
  }
}
