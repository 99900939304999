import * as React from 'react';
import { SetGroup } from '../../../../Models/SetGroup';
import { IWorkoutHoverChangeDelegate } from '../../../../ViewModels/Panels/IWorkoutViewModel';
import { ExerciseSetContainer } from './ExerciseSetContainer';
import { HoverButtons } from './HoverButtons';
import { IModelComponentProps, ModelComponent } from './ModelComponent';

export interface ISetGroupContainerProps extends IModelComponentProps<SetGroup> {
  delegate: IWorkoutHoverChangeDelegate;
  isMobile: boolean;
}

export interface ISetGroupContainerState {
  hasHover: boolean;
}

export class SetGroupContainer extends ModelComponent<
  SetGroup,
  ISetGroupContainerProps,
  ISetGroupContainerState
> {
  public constructor(props: ISetGroupContainerProps, context?: any) {
    super(props);
    this.state = {
      hasHover: false,
    };
  }

  private get hasMultipleSegments(): boolean {
    return this.props.model.exerciseSets().length > 1;
  }

  public render(): JSX.Element {
    const setGroupClasses = this.hasMultipleSegments
      ? 'set_group group_base multiple-sets'
      : 'set_group group_base';
    return (
      <div
        className={setGroupClasses}
        data-object-id={this.props.model.id}
        onClick={this.handleClick}
        data-record="set_group"
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <section className="exercise_sets">{this.renderExerciseSets()}</section>
        <section className="sg-second-header">
          <div className="rest-period">
            <i /> {this.props.model.rest_period}
          </div>
        </section>

        {this.state.hasHover && !this.props.isMobile && (
          <HoverButtons
            model={this.props.model}
            onCopy={this.handleCopyClick}
            onDelete={this.handleDeleteClick}
          />
        )}
      </div>
    );
  }

  private handleMouseEnter = () => {
    if (!this.props.isMobile) {
      this.setState({ hasHover: true });
    }
  };

  private handleMouseLeave = () => {
    if (!this.props.isMobile) {
      this.setState({ hasHover: false });
    }
  };

  private handleCopyClick = (): void => {
    this.props.delegate.copySetGroup(this.props.model);
  };

  private handleDeleteClick = (): void => {
    this.props.delegate.deleteModel(this.props.model);
  };

  private handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (!this.hasMultipleSegments) {
      this.props.delegate.selectModel(this.props.model.children()[0]);
    }
  };

  private renderExerciseSets(): JSX.Element[] {
    return this.props.model.exerciseSets().map(es => {
      return <ExerciseSetContainer model={es} key={es.id} delegate={this.props.delegate} />;
    });
  }
}
