import * as React from 'react';
import { ExerciseSet } from '../../../../Models/ExerciseSet';
import { IWorkoutHoverChangeDelegate } from '../../../../ViewModels/Panels/IWorkoutViewModel';
import { ExerciseSetFormatter } from '../../Helpers/History/ExerciseSetFormatter';
import { IExerciseSetFormatter } from '../../Helpers/History/IExerciseSetFormatter';
import { IModelComponentProps, ModelComponent } from './ModelComponent';

export interface IExerciseSetContainerProps extends IModelComponentProps<ExerciseSet> {
  delegate: IWorkoutHoverChangeDelegate;
}

export interface IExerciseSetContainerState {}

export class ExerciseSetContainer extends ModelComponent<
  ExerciseSet,
  IExerciseSetContainerProps,
  IExerciseSetContainerState
> {
  private setFormatter: IExerciseSetFormatter = new ExerciseSetFormatter();

  public render(): JSX.Element {
    const exercise_set = this.props.model;
    const difficultyClassName = `difficulty-${exercise_set.difficulty}-icon`;
    return (
      <div
        className="exercise_set wpv-exercise-set"
        data-object-id={this.props.model.id}
        data-record="exercise_set"
        onClick={this.handleClick}
      >
        <div className="wpv-primary-values">
          <section className="sg-status-header">
            <i className={difficultyClassName} />
          </section>
          <div className="wpv-intensity-value intensity exercise-set-item">
            {this.renderFirstConfig()}
          </div>
          <div className="wpv-specifications">{this.renderSpecifications()}</div>
        </div>
        <ul className="wpv-parameters">{this.renderParameters()}</ul>
      </div>
    );
  }

  private renderFirstConfig(): JSX.Element {
    const configurations = this.props.model.configurations;
    if (configurations.length === 0) {
      return <div className="wpv-intensity-value intensity exercise-set-item" />;
    }
    const firstConfig = configurations[0];
    const intensityValue = `${firstConfig.value} ${firstConfig.unit_entry}`;
    return (
      <div className="wpv-intensity-value intensity exercise-set-item">
        <span>{intensityValue}</span>
        {firstConfig.name && <div className="wpv-intensity-name">{firstConfig.name}</div>}
      </div>
    );
  }

  private handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    this.props.delegate.selectModel(this.props.model);
  };

  private renderParameters(): JSX.Element[] {
    return this.props.model.secondaryConfigurations().map((param, index: number) => {
      return (
        <li className="wpv-parameter" key={index}>
          {param.value} {param.unit_entry} {param.name}
        </li>
      );
    });
  }

  private renderSpecifications(): JSX.Element[] {
    return this.props.model.performance_specifications.map((spec, index: number) => {
      const formattedSpec = this.setFormatter.formatSpecification(spec);
      return (
        <div className="wpv-specification" key={index}>
          <div className="wpv-spec-target">{formattedSpec.target}</div>
          <div className="wpv-spec-unit">{formattedSpec.unit}</div>
          <div className="wpv-spec-actual">{formattedSpec.actual}</div>
        </div>
      );
    });
  }
}
