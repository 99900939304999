import * as React from 'react';
import { Workout } from '../../../../../Models/Workout';
import { ValueValidator } from '../../../Helpers/ValueValidator';
import { DateTimePicker } from '../DateTimePicker';
import { FormField } from '../FormField';
import { FormTextArea } from '../FormTextArea';
import { IObjectSectionProps, ObjectSection } from '../ObjectSection';
import { SectionWrapper } from '../SectionWrapper';

export interface IWorkoutSectionProps extends IObjectSectionProps<Workout> {}

export interface IWorkoutSectionState {
  name: string;
  date: string;
  instructions: string;
  review: string;
}

export class WorkoutSection extends ObjectSection<
  Workout,
  IWorkoutSectionProps,
  IWorkoutSectionState
> {
  private nameValidator = ValueValidator.lengthValidator(1, 140, () => this.state.name);
  private instructionsValidator = ValueValidator.lengthValidator(
    0,
    2000,
    () => this.state.instructions,
  );
  private reviewValidator = ValueValidator.lengthValidator(0, 2000, () => this.state.review);

  public constructor(props: IWorkoutSectionProps) {
    super(props);
    const model = props.model;
    this.state = {
      date: model.planned_date,
      instructions: model.instructions || '',
      name: model.name,
      review: model.note_content || '',
    };
  }

  private get isValid(): boolean {
    return (
      this.instructionsValidator.isValid() &&
      this.nameValidator.isValid() &&
      this.reviewValidator.isValid()
    );
  }

  public render(): JSX.Element {
    return (
      <div>
        <SectionWrapper iconClass="workout-name-icon">
          <FormField
            isValid={this.nameValidator.isValid()}
            value={this.state.name}
            propertyName="name"
            modelName="workout"
            labelPath="object.workout.name"
            placeholder=""
            updateAction={this.updateName}
            valueError={this.nameValidator.error()}
          />
        </SectionWrapper>
        <SectionWrapper iconClass="workout-date-icon">
          <DateTimePicker value={this.state.date} onChange={this.updateDate} />
        </SectionWrapper>
        <SectionWrapper iconClass="instruction-icon">
          <FormTextArea
            value={this.state.instructions}
            valueError={this.instructionsValidator.error()}
            isValid={this.instructionsValidator.isValid()}
            updateAction={this.updateInstructions}
            placeholder=""
            labelPath="object.workout.instructions"
            modelName="workout"
            propertyName="instructions"
          />
        </SectionWrapper>
        <SectionWrapper iconClass="workout-note-icon">
          <FormTextArea
            value={this.state.review}
            valueError={this.reviewValidator.error()}
            isValid={this.reviewValidator.isValid()}
            updateAction={this.updateReview}
            placeholder=""
            labelPath="object.workout.note_content"
            modelName="workout"
            propertyName="review"
          />
        </SectionWrapper>
      </div>
    );
  }

  protected updateFormData() {
    const updateData = [
      this.constructUpdate('workout', 'note_content', 'review'),
      this.constructUpdate('workout', 'instructions', 'instructions'),
      this.constructUpdate('workout', 'name', 'name'),
      this.constructUpdate('workout', 'planned_date', 'date'),
    ];
    this.props.updateFormData(updateData);
    this.props.updateValidity(this.isValid);
  }

  private updateName = (newValue: string): void => {
    this.setState({ name: newValue });
  };

  private updateDate = (newValue: string): void => {
    this.setState({ date: newValue });
  };

  private updateInstructions = (newValue: string): void => {
    this.setState({ instructions: newValue });
  };

  private updateReview = (newValue: string): void => {
    this.setState({ review: newValue });
  };
}
