import * as _ from 'underscore';
import { IApplicationService } from '../../Core/IApplicationService';
import { randomUUID } from '../../Extension/RandomUUID';
import { Exercise } from '../../Models/Exercise';
import { ExerciseDefinition } from '../../Models/ExerciseDefinition';
import { ExerciseGroup } from '../../Models/ExerciseGroup';
import { Model } from '../../Models/Model';
import { User } from '../../Models/User';
import { Workout } from '../../Models/Workout';
import { ExerciseHistoryModel } from '../../Views/Journal/History/HistoryItem';
import {
  HistoryItemContainer,
  IHistoryAssignable,
} from '../../Views/Journal/History/HistoryItemContainer';
import { ExerciseListContainer } from '../../Views/Journal/Panels/DefinitionList/ExerciseListContainer';
import { DefinitionSelectionViewContainer } from '../../Views/Journal/Panels/DefinitionSelection/DefinitionSelectionViewContainer';
import { ObjectFormContainer } from '../../Views/Journal/Panels/EditObject/ObjectFormContainer';
import { GuideContainer } from '../../Views/Journal/Panels/Guide/GuideContainer';
import { IPanelExternalData } from '../../Views/Journal/Panels/IPanelData';
import { IPanelViewModelProps } from '../../Views/Journal/Panels/IPanelProps';
import { NewWorkoutContainer } from '../../Views/Journal/Panels/NewWorkout/NewWorkoutContainer';
import { WorkoutListContainer } from '../../Views/Journal/Panels/SessionList/WorkoutListContainer';
import { WorkoutViewModelComponent } from '../../Views/Journal/Panels/Workouts/WorkoutViewModelComponent';
import { IPanelStateManager } from '../IPanelStateManager';
import { SelectionModes } from '../Panels/IDefinitionSelectionViewModel';
import { INewSelectionViewModel } from '../Selection/INewSelectionViewModel';
import { NewSelectionViewModel } from '../Selection/NewSelectionViewModel';
import { IPanelManagerViewModel, IPanelPromise } from './IPanelManagerViewModel';

const definitionListViewModelId = 'definition-list';
const workoutListViewModelId = 'workout-list';
const helpPanelId = 'help';

export class PanelManager implements IPanelManagerViewModel {
  protected appService: IApplicationService;
  private stateManager: IPanelStateManager;
  private sharedSelectionViewModel: INewSelectionViewModel | null;

  public constructor(
    stateManager: IPanelStateManager,
    applicationService: IApplicationService,
    sharedSelectionViewModel: INewSelectionViewModel | null,
  ) {
    this.stateManager = stateManager;
    this.appService = applicationService;
    this.sharedSelectionViewModel = sharedSelectionViewModel;
  }

  private get maxPosition(): number {
    return _.max(this.stateManager.getAllPanelProps(), panel => panel.position).position;
  }

  public focusPanel(panelId: string): void {
    this.stateManager.focusPanel(panelId);
  }

  public closePanel(panelId: string): void {
    this.stateManager.removeProps(panelId);
  }

  public openHelpPanel(): void {
    if (this.stateManager.isPanelOpen(helpPanelId)) {
      return this.stateManager.focusPanel(helpPanelId);
    }
    const position = this.maxPosition + 1;
    const props = GuideContainer.initialPanelProps();
    const data = GuideContainer.initialPanelData(helpPanelId);
    const deferred = $.Deferred();
    this.assignViewModel(data, props, position, helpPanelId, () => deferred.resolve());
    deferred.then(() => this.focusPanel(helpPanelId));
  }

  public openWorkoutList(): void {
    if (this.stateManager.isPanelOpen(workoutListViewModelId)) {
      return this.stateManager.focusPanel(workoutListViewModelId);
    }
    const svm = this.newSelectionViewModel(workoutListViewModelId);
    const mountDeferred = $.Deferred();
    const props = WorkoutListContainer.initialPanelProps(this.currentUser().workouts());
    const data = WorkoutListContainer.initialPanelData(workoutListViewModelId);
    this.assignSelectionViewModel(svm, data, props, 0, workoutListViewModelId, () =>
      mountDeferred.resolve(),
    );
  }

  public openDefinitionList(): void {
    if (this.stateManager.isPanelOpen(definitionListViewModelId)) {
      return this.stateManager.focusPanel(definitionListViewModelId);
    }
    const svm = this.newSelectionViewModel(definitionListViewModelId);
    const position = this.maxPosition + 1;
    const data = ExerciseListContainer.initialPanelData(definitionListViewModelId);
    const props = ExerciseListContainer.initialPanelProps(
      this.currentUser().exercise_definitions(),
    );
    const deferred = $.Deferred();
    this.assignSelectionViewModel(svm, data, props, position, definitionListViewModelId, () =>
      deferred.resolve(),
    );
    deferred.then(() => this.focusPanel(definitionListViewModelId));
  }

  public openWorkout(workout: Workout): JQueryPromise<any> {
    const workoutPanelId = `workout-${workout.id}`;
    if (this.stateManager.isPanelOpen(workoutPanelId)) {
      this.stateManager.focusPanel(workoutPanelId);
      return $.Deferred().resolve();
    }
    const svm = this.newSelectionViewModel(workoutPanelId);
    const position = this.maxPosition + 1;
    const props = WorkoutViewModelComponent.initialPanelProps(
      this.appService.localizationService,
      workout,
    );
    const data = WorkoutViewModelComponent.initialPanelData(workoutPanelId, workout);
    const mountDeferred = $.Deferred();
    this.assignSelectionViewModel(svm, data, props, position, workoutPanelId, () => {
      mountDeferred.resolve();
    });
    mountDeferred.then(() => {
      this.focusPanel(workoutPanelId);
    });
    return $.Deferred().resolve();
  }

  public openWorkoutWithUrl(workoutId: number, workoutUrl: string): JQueryPromise<any> {
    const workout = this.appService.modelService.getModel({
      id: workoutId,
      type: 'workout',
    }) as Workout;
    if (workout != null) {
      return this.openWorkout(workout);
    } else if (workoutUrl != null) {
      const promise = this.appService.dataLayer.getObject(workoutUrl, false);
      promise.done((w: Workout) => this.openWorkout(w));
      return promise;
    }
    return this.appService.promiseService.rejectedPromise();
  }

  public openDefinitionHistory(definition: ExerciseDefinition): Promise<any> {
    const vm = this.openHistoryPanel(null, definition, null, null);
    return new Promise((resolve, reject) => {
      return vm.mount.then(() => {
        this.focusPanel(vm.panelId);
        resolve();
      });
    });
  }

  public openHistoryPanel(
    panelId: string | null,
    model: ExerciseHistoryModel,
    setIndex: number | null,
    assignable: IHistoryAssignable | null,
  ): IPanelPromise {
    const id = `history-${randomUUID()}`;
    const position = this.maxPosition + 1;
    const props = HistoryItemContainer.initialPanelProps(
      this.appService.localizationService,
      assignable,
      model,
      setIndex,
    );
    const data = HistoryItemContainer.initialPanelData(id, panelId);
    const deferred = $.Deferred();
    const newProps = this.assignViewModel(data, props, position, id, () => deferred.resolve());
    return { completed: newProps.completePromise, mount: deferred, panelId: newProps.data.id };
  }

  public openObjectPanel(
    object: Model,
    contextRecords: any,
    parentViewModelId: string | null,
  ): IPanelPromise<any> {
    const id = `object-${randomUUID()}`;
    const position = this.maxPosition + 1;
    const panelProps = ObjectFormContainer.initialPanelProps(contextRecords, object);
    const panelData = ObjectFormContainer.initialPanelData(id, object, parentViewModelId);
    const mountDeferred = $.Deferred();
    const newProps = this.assignViewModel(panelData, panelProps, position, id, () =>
      mountDeferred.resolve(),
    );
    mountDeferred.then(() => this.stateManager.focusPanel(id));
    return { completed: newProps.completePromise, mount: mountDeferred, panelId: newProps.data.id };
  }

  public openDefinitionSelectorPanel(
    selectedRecord: Exercise | ExerciseGroup | Workout,
    mode: SelectionModes,
    parentViewModelId: string | null,
  ): IPanelPromise<any> {
    const panelId = `definition-select-${randomUUID()}`;
    const position = this.maxPosition + 1;
    const props = DefinitionSelectionViewContainer.initialPanelProps(
      this.appService.localizationService,
      mode,
      selectedRecord,
    );
    const panelData = DefinitionSelectionViewContainer.initialPanelData(
      panelId,
      mode,
      parentViewModelId,
    );
    const deferred = $.Deferred();
    const newProps = this.assignViewModel(panelData, props, position, panelId, () =>
      deferred.resolve(),
    );
    deferred.then(() => {
      this.focusPanel(panelId);
    });
    return { completed: newProps.completePromise, mount: deferred, panelId: newProps.data.id };
  }

  public openNewWorkoutPanel(parentViewModelId: string, workoutToCopy: Workout): IPanelPromise {
    const panelId = randomUUID();
    const position = this.maxPosition + 1;
    const props = NewWorkoutContainer.initialPanelProps(workoutToCopy);
    const data = NewWorkoutContainer.initialPanelData(panelId, parentViewModelId);
    const deferred = $.Deferred();
    const newProps = this.assignViewModel(data, props, position, panelId, () => deferred.resolve());
    deferred.then(() => this.focusPanel(panelId));
    return {
      completed: newProps.completePromise,
      mount: deferred,
      panelId: newProps.data.id,
    };
  }

  public currentUser(): User {
    return this.appService.currentUser;
  }

  public isMobile(): boolean {
    return this.stateManager.isMobile();
  }

  public isCurrentUserSet(): boolean {
    return this.appService.currentUser != null;
  }

  protected assignViewModel<TProps>(
    panelData: IPanelExternalData,
    panelProps: TProps,
    position: number,
    panelId: string,
    onMount: () => void,
  ): IPanelViewModelProps<TProps, any> {
    const props2: IPanelViewModelProps<TProps, any> = {
      appService: this.appService,
      assignComponent: () => {},
      completePromise: $.Deferred(),
      data: panelData,
      onMount,
      panelManager: this,
      position,
      props: panelProps,
      stateManager: this.stateManager,
      viewModel: this,
    };
    this.stateManager.addProps<TProps>(panelId, props2);
    return props2;
  }

  protected assignSelectionViewModel<TProps>(
    svm: INewSelectionViewModel,
    panelData: IPanelExternalData,
    panelProps: TProps,
    position: number,
    panelId: string,
    onMount: () => void,
  ): void {
    const props: IPanelViewModelProps<any, any> = {
      appService: this.appService,
      assignComponent: () => {},
      completePromise: $.Deferred(),
      data: panelData,
      onMount,
      panelManager: this,
      position,
      props: panelProps,
      selectionProps: svm.initialSelectionProps(),
      stateManager: this.stateManager,
      viewModel: this,
    };
    this.stateManager.addProps(panelId, props);
  }

  protected newSelectionViewModel(panelId: string): INewSelectionViewModel {
    return new NewSelectionViewModel(this, null, this.appService, this.stateManager, panelId);
  }
}
