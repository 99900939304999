import * as React from 'react';
import * as _ from 'underscore';
import { Model } from '../../../../Models/Model';
import { INewSelectionViewModel } from '../../../../ViewModels/Selection/INewSelectionViewModel';
import './selection.scss';
import { LocalizationContext } from '../../../LocalizedComponent';

export interface ISelectionProps<TModel extends Model> {
  viewModel: INewSelectionViewModel;
  model: TModel;
  isVisible: boolean;
  changeSpinnerVisibility: (newValue: boolean) => void;
  isSpinnerVisible: boolean;
}

export interface ISelectionState {
  deleteVisible: boolean;
}

export abstract class Selection<
  TModel extends Model,
  TProps extends ISelectionProps<TModel>,
  TState extends ISelectionState
> extends React.Component<TProps, TState> {
  protected recordUpdatedAt: string;

  protected set overlayPresent(newValue: boolean) {
    this.props.changeSpinnerVisibility(newValue);
  }

  protected get viewModel(): INewSelectionViewModel {
    return this.props.viewModel;
  }

  public abstract render(): JSX.Element;

  public shouldComponentUpdate(
    nextProps: Readonly<TProps>,
    nextState: Readonly<TState>,
    nextContext: any,
  ): boolean {
    return (
      this.recordUpdatedAt !== nextProps.model.updated_at ||
      !_.isEqual(this.state, nextState) ||
      !_.isEqual(nextProps, this.props)
    );
  }
  public componentDidUpdate(prevProps: Readonly<TProps>, prevState: Readonly<TState>) {
    this.recordUpdatedAt = this.props.model.updated_at;
  }

  protected localize(path: string): string {
    return this.context.getValue(path);
  }

  protected updateDeleteVisibility = (newValue: boolean): void => {
    this.setState({ deleteVisible: newValue });
  };
  protected executeDelete = (): void => {
    if (this.state.deleteVisible !== true) {
      return;
    }
    this.props.changeSpinnerVisibility(true);
    this.setState({ deleteVisible: false });
    const promise = this.props.viewModel.deleteObject();
    promise.always(() => this.props.changeSpinnerVisibility(false));
  };
}

Selection.contextType = LocalizationContext;
