import * as React from 'react';

export interface IGuideContentProps {
  content: string;
}

export interface IGuideContentState {}

export class GuideContent extends React.PureComponent<IGuideContentProps, IGuideContentState> {
  public render(): JSX.Element {
    return <div dangerouslySetInnerHTML={{ __html: this.props.content }} />;
  }
}
