import { ViewService } from '../Services/ViewService';
import { IViewModel } from '../ViewModels/IViewModel';
import { INamedCollection } from '../ViewModels/ViewModel';
import { IView } from './IView';

/**
 * The view class handles UI logic for view components.
 * Usage:
 * * Override _templateID property with the Handlebars template name.
 * * Attach any listeners necessary by overriding the attachListeners() function.
 * * Detach the listeners in detachListeners()
 * * Attach any child views with the addChildView function.
 * * Call attachListeners in constructor.
 * * Assign template name to _templateID property.
 */
export abstract class View<TViewModel extends IViewModel> implements IView {
  protected _viewModel: TViewModel;
  protected _$Element: JQuery;
  protected _viewService: ViewService;
  protected _views: INamedCollection<IView>;

  public constructor(
    _viewModel: TViewModel,
    _$Element: JQuery,
    _viewService: ViewService,
    initializeImmediate: boolean = true,
  ) {
    this._viewModel = _viewModel;
    this._$Element = _$Element;
    this._viewService = _viewService;
    this._views = {};
    if (initializeImmediate === true) {
      this.attachListeners();
    }
  }

  /**
   *  Overriden by subclassses. Should be called in constructor.
   */
  public attachListeners() {}

  /**
   * Called when the view is being destroyed.
   */
  public detachListeners() {}

  /**
   * Called to initiate the view creation process.
   */
  public create() {}

  public view(): JQuery {
    return this.callProp<JQuery>(this._$Element);
  }

  public viewModel(): TViewModel {
    return this._viewModel;
  }

  public viewService(): ViewService {
    return this._viewService;
  }

  public destroy(): void {
    this.detachListeners();
    this.view().remove();
  }

  public addChildView(viewID: string, viewObject: IView): void {
    this._views[viewID] = viewObject;
  }

  public childView(viewID: string): IView {
    return this._views[viewID];
  }

  public localize(path: string) {
    return this.viewService()
      .localization()
      .getValue(path);
  }

  protected callProp<T>(prop: any): T {
    if (prop instanceof Function) {
      return prop();
    } else {
      return prop;
    }
  }
}
