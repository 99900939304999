import * as React from 'react';
import { Exercise } from '../../../../Models/Exercise';
import { IWorkoutHoverChangeDelegate } from '../../../../ViewModels/Panels/IWorkoutViewModel';
import { HoverButtons } from './HoverButtons';
import { IModelComponentProps, ModelComponent } from './ModelComponent';
import { SetGroupContainer } from './SetGroupContainer';

export interface IExerciseContainerProps extends IModelComponentProps<Exercise> {
  delegate: IWorkoutHoverChangeDelegate;
  isMobile: boolean;
}

export interface IExerciseContainerState {
  hasHover: boolean;
}

export class ExerciseContainer extends ModelComponent<
  Exercise,
  IExerciseContainerProps,
  IExerciseContainerState
> {
  public constructor(props: IExerciseContainerProps, context?: any) {
    super(props, context);
    this.state = { hasHover: false };
  }

  public render(): JSX.Element {
    const exercise = this.props.model;
    const exerciseDefinition = exercise.exerciseDefinition();
    // In Rare Situations, Exercises May Exist Without a Definition.
    if (exerciseDefinition == null) {
      return null;
    }
    return (
      <div
        className="exercise group_base"
        data-current-status={exercise.status}
        data-definition="<%= exercise.exercise_definition_uuid %>"
        data-object-id={exercise.id}
        data-record="exercise"
        data-category={exerciseDefinition.category}
      >
        <div
          className="exercise_header exercise-bar"
          onClick={this.handleClick}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        >
          <div className="e-primary-header">
            <div className="main-data">
              <header className="exercise-definition-name">
                <i /> {exerciseDefinition.name}
              </header>
              <div className="attributes">{this.tempoDisplay()}</div>
            </div>
            {exercise.definition_note && (
              <div className="exercise-item definition-note">
                <span>
                  <i />
                </span>
                <span className="content">{exercise.definition_note}</span>
              </div>
            )}
            {exercise.instructions && (
              <div className="exercise-item instructions">
                <span>
                  <i className="instruction-icon" />
                </span>
                <span className="content">{exercise.instructions}</span>
              </div>
            )}
            {exercise.note_content && (
              <div className="exercise-item note-content">
                <span>
                  <i />
                </span>
                <span className="content">{exercise.note_content}</span>
              </div>
            )}
          </div>
          <div className="e-status-header exercise-handle">
            <div className="difficulty">
              <i />
              <span />
            </div>
          </div>
          {this.state.hasHover && !this.props.isMobile && (
            <HoverButtons model={this.props.model} onAddSet={this.handleAddSetClick} />
          )}
        </div>
        <div className="set_groups">{this.renderSetGroups()}</div>
      </div>
    );
  }

  private handleMouseEnter = (): void => {
    if (!this.props.isMobile) {
      this.setState({ hasHover: true });
    }
  };

  private handleMouseLeave = (): void => {
    if (!this.props.isMobile) {
      this.setState({ hasHover: false });
    }
  };

  private handleAddSetClick = (): void => {
    this.props.delegate.addSetGroup(this.props.model);
  };

  private handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    this.props.delegate.selectModel(this.props.model);
  };

  private tempoDisplay(): JSX.Element {
    const ex = this.props.model;
    if (ex.concentric) {
      const formatted = `${ex.concentric}/${ex.pause}/${ex.eccentric}/${ex.rest}`;
      return (
        <div className="tempo exercise-item read-only">
          <span className="item-label">
            <i />{' '}
          </span>
          <div className="tempo_data">{formatted}</div>
        </div>
      );
    }
    return null;
  }

  private renderSetGroups(): JSX.Element[] {
    return this.props.model.setGroups().map(setGroup => {
      return (
        <SetGroupContainer
          model={setGroup}
          key={setGroup.id}
          isMobile={this.props.isMobile}
          delegate={this.props.delegate}
        />
      );
    });
  }
}
