import * as React from 'react';
import { ExerciseGroup } from '../../../../Models/ExerciseGroup';
import { IWorkoutHoverChangeDelegate } from '../../../../ViewModels/Panels/IWorkoutViewModel';
import { ExerciseContainer } from './ExerciseContainer';
import { IModelComponentProps, ModelComponent } from './ModelComponent';

export interface IExerciseGroupContainerProps extends IModelComponentProps<ExerciseGroup> {
  /**
   * Zero-index position of this group. Cannot use group position as values are not expected to be consecutive.
   */
  position: number;
  delegate: IWorkoutHoverChangeDelegate;
  isMobile: boolean;
}

export interface IExerciseGroupContainerState {}

export class ExerciseGroupContainer extends ModelComponent<
  ExerciseGroup,
  IExerciseGroupContainerProps,
  IExerciseGroupContainerState
> {
  private get showRestSet(): boolean {
    return this.props.model.shouldShowRestSet();
  }

  private get showRestExercise(): boolean {
    return this.props.model.shouldShowRestExercise();
  }
  public render(): JSX.Element {
    const exercise_group = this.props.model;
    return (
      <div
        className="exercise_group group_base"
        data-object-id={exercise_group.id}
        data-record="exercise_group"
      >
        {this.renderHeader()}
        <div id={`exercise_group_${exercise_group.id}`} className="exercises">
          {this.renderExercises()}
        </div>
      </div>
    );
  }

  private renderExercises(): JSX.Element[] {
    return this.props.model.exercises().map(ex => {
      return (
        <ExerciseContainer
          model={ex}
          key={ex.id}
          isMobile={this.props.isMobile}
          delegate={this.props.delegate}
        />
      );
    });
  }

  private renderHeader(): JSX.Element {
    const exercise_group = this.props.model;
    return (
      <div className="exercise-group-header exercise-group-handle">
        {this.renderGroupTitle()}
        <div className="eg-second-header">
          {this.showRestSet && (
            <div className="exercise-item rest-set">
              <span className="item-label">
                <i />
              </span>
              {exercise_group.rest_set}
            </div>
          )}
          {this.showRestExercise && (
            <div className="exercise-item rest-exercise">
              <span className="item-label">
                <i />
              </span>
              {exercise_group.rest_exercise}
            </div>
          )}
        </div>
      </div>
    );
  }

  private renderGroupTitle(): JSX.Element {
    const title = this.props.model.children().length > 1 ? 'Super-Set Group' : 'Group';
    return (
      <header className="eg-primary-header" onClick={this.handleHeaderClick}>
        {title} <span className="exercise_group_position">{this.props.position + 1}</span>
      </header>
    );
  }

  private handleHeaderClick = (): void => {
    this.props.delegate.selectModel(this.props.model);
  };
}
