import * as React from 'react';
import { ILocalizationService } from '../../../../Services/ILocalizationService';
import { FormField } from '../EditObject/FormField';
import { SectionWrapper } from '../EditObject/SectionWrapper';
import { CategorySelector } from './CategorySelector';
import { IConfigurationEntry } from './IConfigurationEntry';
import { ImplementSelector } from './ImplementSelector';

export interface INewDefinitionFormValues {
  category: string;
  implement: string;
  sided: 'true' | 'false';
  name: string;
  reminder: string;
}

export interface INewDefinitionFormProps {
  localizationService: ILocalizationService;
  categories: IConfigurationEntry[];
  implementList: IConfigurationEntry[];
  sidedOptions: IConfigurationEntry[];
  updateAction: (value: Partial<INewDefinitionFormValues>) => void;
  currentCategory: string;
  currentImplement: string;
  currentSided: string;
  currentName: string;
  isValidName: boolean;
  nameError: string;
}

export interface INewDefinitionFormState {}

export class NewDefinitionForm extends React.Component<
  INewDefinitionFormProps,
  INewDefinitionFormState
> {
  public render(): JSX.Element {
    return (
      <div className="new-exercise-selector definition-selector-group" id="new-exercise-selector">
        <SectionWrapper>
          <FormField
            value={this.props.currentName}
            valueError={this.props.nameError}
            isValid={this.props.isValidName}
            labelPath="object.exercise_definition.name"
            placeholder=""
            propertyName="name"
            modelName="name"
            updateAction={this.handleNameChange}
          />
        </SectionWrapper>
        {this.renderCategorySelector()}
        {this.renderImplementSelector()}
        {this.renderSidedSelector()}
        {this.renderNoteTextArea()}
      </div>
    );
  }

  private handleNameChange = (name: string): void => {
    this.props.updateAction({ name });
  };

  private renderNoteTextArea(): JSX.Element {
    return (
      <div className="form-group-container">
        <div className="form-group-icon">
          <i className="exercise-definition-reminder-icon" />
        </div>
        <div className="form-group-field">
          <label htmlFor="exercise_definition_note_content">
            {this.props.localizationService.getValue('object.exercise_definition.note_content')}
          </label>
          <textarea
            id="exercise_definition_note_content"
            maxLength={2000}
            name="exercise_definition[note_content]"
            onChange={this.handleEvent}
          />
          <div className="error-container" />
        </div>
      </div>
    );
  }

  private handleEvent = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    this.props.updateAction({ reminder: event.target.value });
  };

  private renderSidedSelector(): JSX.Element {
    const sidedOptions = this.props.sidedOptions.map((option, index) => {
      const inputId = `definition-radio-sided-${option.key}`;
      const isSelected = this.props.currentSided === option.key;
      const className = `radio-button definition-sided-${option.key}`;
      const onChange = () => this.props.updateAction({ sided: option.key as any });
      return (
        <div className="radio-button" key={option.key}>
          <input
            type="radio"
            className="radio-button"
            name="exercise_definition[sided]"
            id={inputId}
            checked={isSelected}
            value={option.key}
            onChange={onChange}
          />
          <label htmlFor={inputId} className={className}>
            <i />
            <span>{option.title}</span>
          </label>
        </div>
      );
    });
    return (
      <div className="form-group-container">
        <div className="form-group-icon" />
        <div className="form-group-field">
          <label htmlFor="category">
            {this.props.localizationService.getValue('object.exercise_definition.sided')}
          </label>
          <div className="radio-button-group">{sidedOptions}</div>
        </div>
      </div>
    );
  }

  private renderImplementSelector(): JSX.Element {
    return (
      <ImplementSelector
        currentValue={this.props.currentImplement}
        options={this.props.implementList}
        sectionLabel={this.props.localizationService.getValue(
          'object.exercise_definition.implement',
        )}
        updateAction={this.props.updateAction}
      />
    );
  }

  private renderCategorySelector(): JSX.Element {
    return (
      <CategorySelector
        options={this.props.categories}
        currentSelection={this.props.currentCategory}
        updateAction={this.props.updateAction}
        propertyName="category"
        sectionLabel={this.props.localizationService.getValue(
          'object.exercise_definition.category',
        )}
      />
    );
  }
}
