import { IModelService } from '../../Services/IModelService';
import { IServerResult } from '../IServerResult';
import { IResponseParser } from './IResponseParser';

/**
 * The ModelResponseParser abstract class parses server responses.
 *
 * @abstract Subclasses should override the {@link parse} method.
 */
export abstract class ModelResponseParser<T> implements IResponseParser<T> {
  protected modelService: IModelService;

  constructor(modelService: IModelService) {
    this.modelService = modelService;
  }

  public abstract parse(response: IServerResult<T>): T;
}
