import * as React from 'react';
import { Model } from '../../../../Models/Model';
import { IFormChangeData } from '../../../../ViewModels/Panels/IObjectPanelViewModel';
import {
  ILocalizedComponentProps,
  LocalizationContext,
  LocalizedPureComponent,
} from '../../../LocalizedComponent';

export interface IObjectSectionProps<T extends Model> extends ILocalizedComponentProps {
  model: T;
  updateValidity: (isValid: boolean) => void;
  updateFormData: (newData: IFormChangeData[]) => void;
}

export interface IObjectSectionState {}

export abstract class ObjectSection<
  T extends Model,
  TProps extends IObjectSectionProps<T>,
  TState extends IObjectSectionState
> extends LocalizedPureComponent<TProps, TState> {
  public componentDidUpdate(
    prevProps: Readonly<TProps>,
    prevState: Readonly<TState>,
    prevContext: any,
  ): void {
    this.updateFormData();
  }

  public abstract render(): JSX.Element;

  protected abstract updateFormData(): void;

  protected constructUpdate(
    model: string,
    attribute: string,
    statePropName: keyof TState,
  ): IFormChangeData {
    return { attribute, type: model, value: this.state[statePropName] };
  }
}

ObjectSection.contextType = LocalizationContext;
