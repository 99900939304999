import * as React from 'react';
import * as _ from 'underscore';
import { RecordCollection } from '../../../../Models/Collections/RecordCollection';
import { User } from '../../../../Models/User';
import { Workout } from '../../../../Models/Workout';
import { IWorkoutListViewModel } from '../../../../ViewModels/Panels/IWorkoutListViewModel';
import { WorkoutListGroup } from '../../../../ViewModels/Panels/WorkoutListGroup';
import { AbstractPanelViewContainer } from '../AbstractPanelViewContainer';
import { IPanelExternalData, IPanelInternalData } from '../IPanelData';
import { IPanelHeaderActions } from '../IPanelHeaderActions';
import { IPanelHeaderCategoryFilterState } from '../IPanelHeaderCategoryFilterState';
import { IPanelViewModelProps } from '../IPanelProps';
import { PanelTypes } from '../PanelTypes';
import { IListProps, IWorkoutListActionProps } from './List';

const categories = ['strength', 'flexibility', 'endurance'];

export type IWorkoutListContainerProps = IPanelViewModelProps<
  IListProps & IWorkoutListActionProps,
  IWorkoutListViewModel
>;

export interface IWorkoutListContainerState {}

export class WorkoutListContainer
  extends AbstractPanelViewContainer<
    IListProps,
    {},
    IWorkoutListContainerState,
    IWorkoutListActionProps
  >
  implements IWorkoutListViewModel {
  // private workoutCollection: RecordCollection<Workout, User>;
  private searchText: string = '';
  private categoryFilters: IPanelHeaderCategoryFilterState = {
    endurance: false,
    flexibility: false,
    strength: false,
  };
  private workoutGroupCodeCache: Dictionary<string> = {};

  private timeCache = {
    endOfLastMonth: null as any,
    endOfLastWeek: null as any,
    endOfMonthBeforeLast: null as any,
    endOfNextWeek: null as any,
    endOfThisWeek: null as any,
  };

  public static initialPanelProps(workoutCollection: RecordCollection<Workout, User>): IListProps {
    return {
      collection: workoutCollection,
      hasMoreAvailable: false,
      workoutGroups: [],
    };
  }

  public static initialPanelData(panelId: string): IPanelExternalData {
    return {
      hasFocus: false,
      hasTransactionOverlay: false,
      id: panelId,
      panelType: PanelTypes.WorkoutList,
      parentPanelId: null,
      title: 'Sessions',
    };
  }

  public componentDidMount(): void {
    super.componentDidMount();
    this.refreshList();
    $(document).on('sr:object:update', this.handleChange);
    $(document).on('sr:object:delete:workout', this.handleChange);
  }

  public componentWillUnmount(): void {
    $(document).off('sr:object:update', this.handleChange);
    $(document).off('sr:object:delete:workout', this.handleChange);
  }

  public onClickAddWorkout(): void {
    this.addWorkout();
  }

  public refreshList(): void {
    this.refresh();
  }

  public updateSearch(newSearch: string): void {
    this.search(newSearch);
  }

  public onOpenWorkout(workout: Workout): Promise<any> {
    return new Promise((resolve, reject) => {
      this.openWorkout(workout.id as number).then(resolve, reject);
    });
  }

  public onSelectWorkout(workout: Workout): void {
    this.selectWorkout(workout.id as number);
  }

  public onRequestMore(): void {
    this.requestMore();
  }

  // public setWorkoutCollection(newCollection: any): JQueryPromise<any> {
  //   this.requestInProgress = true;
  //   this.workoutCollection = newCollection;
  //   this.updatePanelData({ hasTransactionOverlay: true });
  //   return this.workoutCollection.more().done(() => {
  //     this.displayWorkouts();
  //     this.updatePanelData({ hasTransactionOverlay: false });
  //     return (this.requestInProgress = false);
  //   });
  // }

  public addWorkout() {
    this.props.panelManager.openNewWorkoutPanel(this.panelId, null);
  }

  public openWorkout(workoutId: number): JQueryPromise<any> {
    return this.props.panelManager.openWorkoutWithUrl(workoutId, null);
  }

  public selectWorkout(workoutId: number) {
    this.displayRecord('workout', workoutId, true);
  }

  public refresh() {
    this.requestRefresh();
  }

  public updateFilters(newValue: IPanelHeaderCategoryFilterState) {
    this.categoryFilters = newValue;
    this.displayWorkouts();
  }

  public search(newValue: string) {
    this.searchText = newValue;
    this.displayWorkouts();
  }

  public requestRefresh() {
    this.updateContentProps({ workoutGroups: [], hasMoreAvailable: false });
    this.updatePanelData({ hasTransactionOverlay: true });
    return this.props.props.collection.reload().done(completedReload => {
      this.updatePanelData({ hasTransactionOverlay: false });
      this.displayWorkouts();
    });
  }

  public requestMore(): JQueryPromise<any> {
    return this.props.props.collection.more().done(newWorkoutSet => {
      this.displayWorkouts();
    });
  }

  public populateWorkouts(workouts: Workout[]) {
    workouts = this.filterWorkouts(workouts);
    this.calculateTimeIntervals();
    const groupedWorkouts = _.groupBy(workouts, workout => {
      return this.getWorkoutGroupCode(workout);
    });
    const workoutGroups = _.map(groupedWorkouts, (value: Workout[], key, object) => {
      const newGroup = this.getWorkoutGroup(value[0]);
      newGroup.workouts = _.sortBy(value, (w: Workout) =>
        moment(w.planned_date).valueOf(),
      ).reverse();
      return newGroup;
    });
    const sortedGroups = _.sortBy(workoutGroups, workoutGroup => {
      return -workoutGroup.momentValue();
    });
    _.forEach(sortedGroups, (listGroup, index) => {
      listGroup.position = index;
    });
    this.updateContentProps({
      hasMoreAvailable: this.props.props.collection.isMoreAvailable(),
      workoutGroups: sortedGroups,
    });
  }

  public onChange(): void {
    this.displayWorkouts();
  }

  protected actionProps(): IWorkoutListActionProps {
    return {
      addAction: this.handleAddWorkout,
      moreAction: this.handleMoreAction,
      openAction: this.handleOpenWorkout,
      selectAction: this.handleSelectWorkout,
    };
  }

  protected panelData(): IPanelInternalData {
    return {
      additionalClasses: null,
      type: 'workout-list',
    };
  }

  protected renderHeaderActions(): IPanelHeaderActions {
    return {
      onFilter: this.onFilter,
      onRefresh: this.onRefresh,
      onSearch: this.onSearch,
    };
  }

  private displayWorkouts() {
    this.populateWorkouts(this.props.props.collection.members());
  }

  private getWorkoutGroupCode(workout: Workout): string {
    const plannedDate = workout.planned_date;
    if (!this.workoutGroupCodeCache[plannedDate]) {
      this.workoutGroupCodeCache[plannedDate] = this.calculateWorkoutGroupCode(plannedDate);
    }
    return this.workoutGroupCodeCache[plannedDate];
  }

  private calculateTimeIntervals() {
    const endOfThisWeek = moment().endOf('week');
    const endOfNextWeek = endOfThisWeek.clone().add(1, 'week');
    const endOfLastWeek = endOfThisWeek.clone().subtract(1, 'week');
    const endOfLastMonth = moment()
      .endOf('month')
      .subtract(1, 'month');
    const endOfMonthBeforeLast = moment()
      .endOf('month')
      .subtract(2, 'month');
    this.timeCache = {
      endOfLastMonth,
      endOfLastWeek,
      endOfMonthBeforeLast,
      endOfNextWeek,
      endOfThisWeek,
    };
  }

  private calculateWorkoutGroupCode(date: string): string {
    const workoutDate = moment(date);
    const endOfThisWeek = moment().endOf('week');
    const endOfNextWeek = endOfThisWeek.clone().add(1, 'week');
    const endOfLastWeek = endOfThisWeek.clone().subtract(1, 'week');
    const endOfLastMonth = moment()
      .endOf('month')
      .subtract(1, 'month');
    const endOfMonthBeforeLast = moment()
      .endOf('month')
      .subtract(2, 'month');
    if (workoutDate.isAfter(endOfNextWeek)) {
      return 'relative/future';
    } else if (workoutDate.isAfter(endOfThisWeek)) {
      return 'relative/next-week';
    } else if (workoutDate.isAfter(endOfLastWeek)) {
      return 'relative/this-week';
    } else if (workoutDate.isAfter(endOfLastMonth)) {
      return 'relative/this-month';
    } else if (workoutDate.isAfter(endOfMonthBeforeLast)) {
      return 'relative/last-month';
    } else {
      return 'absolute' + workoutDate.format('YYYY-MM');
    }
  }

  private getWorkoutGroup(workout: Workout): WorkoutListGroup {
    const newGroup: WorkoutListGroup = new WorkoutListGroup();
    newGroup.localizationService = this.props.appService.localizationService;
    const endOfThisWeek = moment().endOf('week');
    const endOfNextWeek = endOfThisWeek.clone().add(1, 'week');
    const endOfLastWeek = endOfThisWeek.clone().subtract(1, 'week');
    const endOfLastMonth = moment()
      .endOf('month')
      .subtract(1, 'month');
    const endOfMonthBeforeLast = moment()
      .endOf('month')
      .subtract(2, 'month');
    const workoutDate = moment(workout.planned_date);
    if (workoutDate.isAfter(endOfNextWeek)) {
      _.assign(newGroup, { type: 'relative', period: 'future' });
    } else if (workoutDate.isAfter(endOfThisWeek)) {
      _.assign(newGroup, { type: 'relative', period: 'next-week' });
    } else if (workoutDate.isAfter(endOfLastWeek)) {
      _.assign(newGroup, { type: 'relative', period: 'this-week' });
    } else if (workoutDate.isAfter(endOfLastMonth)) {
      _.assign(newGroup, { type: 'relative', period: 'this-month' });
    } else if (workoutDate.isAfter(endOfMonthBeforeLast)) {
      _.assign(newGroup, { type: 'relative', period: 'last-month' });
    } else {
      _.assign(newGroup, { type: 'absolute', period: workoutDate.format('YYYY-MM') });
    }
    return newGroup;
  }

  private filterWorkouts(workouts: Workout[]): Workout[] {
    const strength = this.categoryFilters.strength;
    const flex = this.categoryFilters.flexibility;
    const endurance = this.categoryFilters.endurance;
    const showAll = _.all([strength, flex, endurance], v => v === false);
    if (_.isEmpty(this.searchText) && showAll) {
      return workouts;
    }
    return _.select(workouts, w => this.includeWorkout(w));
  }

  private includeWorkout(workout: Workout): boolean {
    return this.matchesName(workout) && this.matchesCategories(workout);
  }

  private matchesName(workout: Workout): boolean {
    if (_.isEmpty(this.searchText)) {
      return true;
    }
    return workout.name.indexOf(this.searchText) >= 0;
  }
  private matchesCategories(workout: Workout): boolean {
    const c = this.categoryFilters;
    if (c.flexibility === c.endurance && c.endurance === c.strength) {
      return true;
    }
    const workoutCategory = workout.largest_category;
    return _.any(categories, cat => (c as any)[cat] && workoutCategory === cat);
  }

  private handleAddWorkout = (): void => this.onClickAddWorkout();
  private handleMoreAction = (): void => this.onRequestMore();
  private handleOpenWorkout = (workout: Workout): Promise<any> => this.onOpenWorkout(workout);
  private handleSelectWorkout = (workout: Workout): void => this.onSelectWorkout(workout);

  private handleChange = (): void => this.onChange();

  private onRefresh = (): void => this.refreshList();

  private onFilter = (newValue: IPanelHeaderCategoryFilterState): void => {
    this.updateFilters(newValue);
  };

  private onSearch = (newValue: string): void => {
    this.updateSearch(newValue);
  };
}
