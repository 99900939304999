import * as React from 'react';
import { ILocalizationService } from '../../../Services/ILocalizationService';
import { IPanelManagerViewModel } from '../../../ViewModels/PanelManager/IPanelManagerViewModel';
import { IPanelViewModelProps } from '../Panels/IPanelProps';
import { buildMenu } from './buildMenu';
import { Menu } from './Menus/index';
import { NavigationHeader } from './NavigationHeader';
import Dictionary = _.Dictionary;

export interface INavigationProps {
  panels: Dictionary<IPanelViewModelProps<any, any>>;
  localizationService: ILocalizationService;
  panelManager: IPanelManagerViewModel;
}

export interface INavigationState {
  menuIsExpanded: boolean;
}

export class Navigation extends React.PureComponent<INavigationProps, INavigationState> {
  public constructor(props: INavigationProps, context?: any) {
    super(props, context);
    this.state = {
      menuIsExpanded: false,
    };
  }

  public render(): JSX.Element {
    const menuWrapperClass = this.state.menuIsExpanded ? 'display-contents' : null;
    const builtMenu = buildMenu(this.props.panels, this.props.localizationService, {
      handleClose: this.handleClosePanel,
      handleFocus: this.handleFocusPanel,
      handleOpenDefinition: this.handleOpenDefinitionList,
      handleOpenHelp: this.handleOpenHelpClick,
    });
    return (
      <nav className="navbar-sr navbar-journal" role="navigation">
        <NavigationHeader
          menuIsExpanded={this.state.menuIsExpanded}
          toggleExpansion={this.handleMenuClick}
        />
        <div id="menu-wrapper" className={menuWrapperClass}>
          <Menu menuConfig={builtMenu} />
        </div>
      </nav>
    );
  }

  private handleOpenDefinitionList = (): void => {
    this.props.panelManager.openDefinitionList();
  };

  private handleClosePanel = (panelId: string) => {
    this.props.panelManager.closePanel(panelId);
  };

  private handleFocusPanel = (panelId: string) => {
    this.props.panelManager.focusPanel(panelId);
  };

  private handleOpenHelpClick = (): void => {
    this.props.panelManager.openHelpPanel();
  };

  private handleMenuClick = (): void => {
    this.setState({ menuIsExpanded: !this.state.menuIsExpanded });
  };
}
