import * as React from 'react';

export interface IToggleButtonProps {
  baseClasses: string;
  isActive: boolean;
  handleClick: () => void;
  filterCategory?: string;
  activeClass?: string;
}

export interface IToggleButtonState {}

export class ToggleButton extends React.PureComponent<IToggleButtonProps, IToggleButtonState> {
  public render(): JSX.Element {
    const activeClass = this.props.isActive ? this.props.activeClass || 'filter-active' : '';
    const classes = `panel-header-item ${this.props.baseClasses} ${activeClass}`;
    return (
      <button
        className={classes}
        onClick={this.props.handleClick}
        data-filter-category={this.props.filterCategory}
      />
    );
  }
}
