import * as React from 'react';

export interface IOverlayProps {}

export interface IOverlayState {}

export class Overlay extends React.PureComponent<IOverlayProps, IOverlayState> {
  public render(): JSX.Element {
    return (
      <div className="sv-update-overlay">
        <i className="sv-update-spinner" />
      </div>
    );
  }
}
