import * as React from 'react';
import { LocalizedComponent } from '../LocalizedComponent';
import { FormGroupField } from './FormGroupField';
import { UserProfileAttributes } from './UserProfileAttributes';

const ExplainTag: React.FunctionComponent<{}> = (props, context) => {
  return (
    <p className="explainer">
      <i />
      {props.children}
    </p>
  );
};

export interface IUserProfileFormProps {
  disableSubmitButton: boolean;
  userRestSet: string;
  userRestExercise: string;
  userBarbellWeight: string;
  userDumbbellWeight: string;
  userOlympicBarbellWeight: string;
  onUpdateField(fieldName: UserProfileAttributes, newValue: string): void;
  onPasswordSubmit(currentPassword: string, newPass: string, confirmPass: string): void;
}

interface IUserProfileFormState {
  userCurrentPassword: string;
  currentPasswordErrors: string | null;
  userNewPassword: string;
  newPasswordErrors: string | null;
  userConfirmNewPassword: string;
  confirmPasswordErrors: string | null;
  validPassword: boolean;
}

export class UserProfileForm extends LocalizedComponent<
  IUserProfileFormProps,
  IUserProfileFormState
> {
  constructor(props: IUserProfileFormProps, context: any) {
    super(props, context);
    this.state = {
      confirmPasswordErrors: null,
      currentPasswordErrors: null,
      newPasswordErrors: null,
      userConfirmNewPassword: '',
      userCurrentPassword: '',
      userNewPassword: '',
      validPassword: true,
    };
  }

  public render(): React.ReactNode {
    const {
      userBarbellWeight,
      userDumbbellWeight,
      userOlympicBarbellWeight,
      userRestExercise,
      userRestSet,
    } = this.props;
    const { userNewPassword, userCurrentPassword, userConfirmNewPassword } = this.state;
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <h2>Defaults</h2>
          </div>
        </div>
        <div className="user-profile row">
          <div className="col-md-4 timing">
            <h3>
              <i /> Rest Periods
            </h3>
            <ExplainTag>
              These rest periods will be prepopulated into new exercises you create.
            </ExplainTag>
            <FormGroupField
              name="user[rest_set]"
              id="user-rest-set"
              label={this.localize('object.user.rest_set')}
              onChange={this.handleRestSetChange}
              groupField={true}
              value={userRestSet}
            />
            <FormGroupField
              name="user[rest_exercise]"
              id="user-rest-exercise"
              label={this.localize('object.user.rest_exercise')}
              onChange={this.handleRestExerciseChange}
              groupField={true}
              value={userRestExercise}
            />
          </div>
          <div className="col-md-4 weights">
            <h3>
              <i /> Bar Weights
            </h3>
            <ExplainTag>
              These are the default bar weights for the different types of bars. If you don't enter
              a value, we'll use standard defaults (45 lbs for Barbell and Olympic Barbell, none for
              Dumbbells).
            </ExplainTag>
            <FormGroupField
              name={'user[bar_weight_barbell]'}
              id={'user-barbell-weight'}
              label={this.localize('object.user.bar_weight_barbell')}
              onChange={this.handleBarbellWeightChange}
              groupField={true}
              value={userBarbellWeight}
            />
            <FormGroupField
              name={'user[bar_weight_dumbell]'}
              id={'user-dumbbell-weight'}
              label={this.localize('object.user.bar_weight_dumbbell')}
              onChange={this.handleDumbbellWeightChange}
              groupField={true}
              value={userDumbbellWeight}
            />
            <FormGroupField
              name={'user[bar_weight_olympic_barbell]'}
              id={'user-olympic-barbell-weight'}
              label={this.localize('object.user.bar_weight_olympic')}
              onChange={this.handleOlympicWeightChange}
              groupField={true}
              value={userOlympicBarbellWeight}
            />
          </div>
          <div className="col-md-4 password">
            <h3>Password Change</h3>
            <ExplainTag>You can change your password here.</ExplainTag>
            <form id="password-change" onSubmit={this.handlePasswordSubmit}>
              <FormGroupField
                name={'user[current_password]'}
                id={'user-current-password'}
                label={'Current Password'}
                onChange={this.handleCurrentPasswordChange}
                password={true}
                value={userCurrentPassword}
                required={true}
                updateEveryChange={true}
              />
              <FormGroupField
                name={'user[password]'}
                id={'user-new-password'}
                label={'New Password'}
                onChange={this.handleNewPasswordChange}
                password={true}
                required={true}
                minLength={8}
                maxLength={128}
                value={userNewPassword}
                updateEveryChange={true}
              />
              <FormGroupField
                name={'user[password_confirmation]'}
                id={'user-password-confirmation'}
                label={'Password Confirmation'}
                onChange={this.handleConfirmPasswordChange}
                password={true}
                required={true}
                minLength={8}
                maxLength={128}
                value={userConfirmNewPassword}
                updateEveryChange={true}
              />
              <div className="form-group">{this.renderPasswordSubmit()}</div>
            </form>
          </div>
        </div>
      </div>
    );
  }
  private renderPasswordSubmit(): React.ReactNode {
    const classes = `xbtn xbtn-primary ${
      this.props.disableSubmitButton || !this.state.validPassword ? 'disabled' : ''
    }`;
    return (
      <button type="submit" className={classes} onClick={this.handlePasswordSubmit}>
        Change Password
      </button>
    );
  }

  private handleRestSetChange = (newText: string) => {
    this.props.onUpdateField('rest_set', newText);
  };
  private handleRestExerciseChange = (newText: string) => {
    this.props.onUpdateField('rest_exercise', newText);
  };
  private handleBarbellWeightChange = (newText: string) => {
    this.props.onUpdateField('barbell_weight', newText);
  };
  private handleDumbbellWeightChange = (newText: string) => {
    this.props.onUpdateField('dumbbell_weight', newText);
  };
  private handleOlympicWeightChange = (newText: string) => {
    this.props.onUpdateField('olympic_barbell_weight', newText);
  };
  private handleCurrentPasswordChange = (newText: string) => {
    this.submitPasswordUpdate({ userCurrentPassword: newText });
  };
  private handleNewPasswordChange = (newText: string) => {
    this.submitPasswordUpdate({ userNewPassword: newText });
  };
  private handleConfirmPasswordChange = (newText: string) => {
    this.submitPasswordUpdate({ userConfirmNewPassword: newText });
  };
  private handlePasswordSubmit = (
    event: React.FormEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>,
  ): void => {
    event.preventDefault();
    event.stopPropagation();
    const { userConfirmNewPassword, userCurrentPassword, userNewPassword } = this.state;
    this.props.onPasswordSubmit(userCurrentPassword, userNewPassword, userConfirmNewPassword);
  };
  private submitPasswordUpdate(statePartial: Partial<IUserProfileFormState>): void {
    this.setState(prevState => {
      const updatedState = { ...prevState, ...statePartial };
      updatedState.newPasswordErrors =
        updatedState.userNewPassword.length < 8 || updatedState.userNewPassword.length > 128
          ? 'Password must be anywhere from 8 to 128 characters long.'
          : null;
      updatedState.confirmPasswordErrors =
        updatedState.userNewPassword !== updatedState.userConfirmNewPassword
          ? 'Password confirmation must match new password.'
          : null;
      updatedState.validPassword =
        updatedState.newPasswordErrors == null &&
        updatedState.confirmPasswordErrors == null &&
        updatedState.currentPasswordErrors == null;
      return updatedState;
    });
  }
}
