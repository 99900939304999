import Parsley from '../Extension/ParsleyExtensions';
import ExtendString from '../Extension/ToTitleCase';
import registerSlideEffect from '../Extension/VelocityEffects';
import { DateTimePickerCustom } from './DateTimePickerCustom';

export default function loadExtensions() {
  Parsley();
  ExtendString();
  registerSlideEffect();
  DateTimePickerCustom.setDateFormatter();
}
