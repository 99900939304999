import * as React from 'react';
import { IConfigurationEntry } from './IConfigurationEntry';
import { INewDefinitionFormValues } from './NewDefinitionForm';

export interface IImplementSelectorProps {
  options: IConfigurationEntry[];
  currentValue: string;
  sectionLabel: string;
  updateAction: (values: Partial<INewDefinitionFormValues>) => void;
}

export interface IImplementSelectorState {}

export class ImplementSelector extends React.Component<
  IImplementSelectorProps,
  IImplementSelectorState
> {
  public render(): JSX.Element {
    const implementChoices = this.props.options.map(tool => {
      return (
        <option value={tool.key} key={tool.key}>
          {tool.title}
        </option>
      );
    });
    return (
      <div className="form-group-container">
        <div className="form-group-icon" />
        <div className="form-group-field">
          <label htmlFor="exercise_definition[implement]">{this.props.sectionLabel}</label>
          <select
            name="exercise_definition[implement]"
            className="form-control"
            value={this.props.currentValue}
            onChange={this.handleChange}
          >
            {implementChoices}
          </select>
        </div>
      </div>
    );
  }

  private handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.props.updateAction({ implement: event.target.value });
  };
}
