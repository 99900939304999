import Dictionary = _.Dictionary;
import * as _ from 'underscore';
import { ILocalizationService } from '../../Services/ILocalizationService';
import { IConfigurationEntry } from '../Journal/Panels/DefinitionSelection/IConfigurationEntry';

export function convertConfiguration(
  path: string,
  localization: ILocalizationService,
): IConfigurationEntry[] {
  const options: { [key: string]: { title: string } } = localization.getValue(path) as any;
  return _.map(options, (value, key) => {
    return { key, title: value.title };
  });
}

export function convertConfigurationObject(
  object: Dictionary<{ title: string }>,
): IConfigurationEntry[] {
  return _.map(object, (value, key) => {
    return { key, title: value.title };
  });
}

export default convertConfiguration;
