import * as React from 'react';
import { VelocityTransitionGroup } from 'velocity-react';
import { INewSelectionViewModel } from '../../../../ViewModels/Selection/INewSelectionViewModel';
import { ILocalizedComponentProps, LocalizedPureComponent } from '../../../LocalizedComponent';

export interface ICommandsSectionProps extends ILocalizedComponentProps {
  viewModel: INewSelectionViewModel;
  deletePromptVisible: boolean;
  deleteDeniedVisible: boolean;
  updateDeleteVisibility: (newVisibility: boolean) => void;
  executeDelete: () => void;
  deleteWarning: string;
  deleteConfirm: string;
  deleteCancel: string;
  deleteNotPermitted?: string;
}

export interface ICommandsSectionState {}

export class CommandsSection extends LocalizedPureComponent<
  ICommandsSectionProps,
  ICommandsSectionState
> {
  public constructor(props: ICommandsSectionProps) {
    super(props);
  }

  public render(): JSX.Element {
    return (
      <div className="msv-section sv-command-container">
        <div className="sv-section-commands">
          <button
            className="xbtn xbtn-primary sv-edit"
            onClick={() => this.props.viewModel.openObjectPanel()}
          >
            {this.localize('mobile_selection_view.edit')}
          </button>
          <button
            className="xbtn xbtn-primary sv-delete"
            onClick={() => this.props.updateDeleteVisibility(true)}
          >
            {this.localize('mobile_selection_view.delete')}
          </button>
          {this.props.children}
        </div>
        <VelocityTransitionGroup
          enter={{ animation: 'transition.slideDownIn', display: 'flex' }}
          leave={{ animation: 'transition.slideUpOut' }}
        >
          {this.deletePrompt()}
        </VelocityTransitionGroup>
      </div>
    );
  }

  private deletePrompt(): JSX.Element {
    if (this.props.deleteDeniedVisible === true) {
      return (
        <div className="sv-section-delete-not-allowed">
          <div className="sv-delete-warning">
            <i className="sv-delete-warning-icon" />
          </div>
          <div>{this.props.deleteNotPermitted}</div>
          <div className="sv-section-delete-commands">
            <button
              className="xbtn xbtn-primary sv-delete-cancel"
              onClick={() => this.props.updateDeleteVisibility(false)}
            >
              {this.props.deleteCancel}
            </button>
          </div>
        </div>
      );
    } else if (this.props.deletePromptVisible === true) {
      return (
        <div className="sv-section-delete-confirm">
          <div className="sv-delete-warning">
            <i className="sv-delete-warning-icon" />
          </div>
          <div>{this.props.deleteWarning}</div>
          <div className="sv-section-delete-commands">
            <button
              className="xbtn xbtn-primary sv-delete-cancel"
              onClick={() => this.props.updateDeleteVisibility(false)}
            >
              {this.props.deleteCancel}
            </button>
            <button
              className="xbtn xbtn-danger sv-delete-confirm"
              onClick={this.props.executeDelete}
            >
              {this.props.deleteConfirm}
            </button>
          </div>
        </div>
      );
    }
    return null;
  }
}
