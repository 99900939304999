import { IConfiguration } from '../../../../../Models/IConfiguration';
import { IPerformanceSpecification } from '../../../../../Models/IPerformanceSpecification';

/**
 * Creates new instances of Intensity Parameters and Performance Specifications.
 */
export class ObjectCreator {
  public static specification(): IPerformanceSpecification {
    return {
      actual_value: null,
      target_lower_value: null,
      target_type: 'specific',
      target_value: 10,
      unit_entry: 'count',
      unit_kind: 'counting',
    };
  }

  public static parameter(): IConfiguration {
    return {
      name: '',
      unit_entry: '',
      unit_kind: 'custom',
      value: 1,
    };
  }
}
