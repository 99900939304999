import { DefinitionCollection } from './Collections/DefinitionCollection';
import { RecordCollection } from './Collections/RecordCollection';
import { Model } from './Model';

export interface IUserRelationships {
  exercise_definitions: {
    links: {
      self: string;
    };
  };
  workouts: {
    links: {
      self: string;
    };
  };
}

export class User extends Model {
  public rest_set: string;
  public rest_exercise: string;
  public barbell_weight: string;
  public olympic_barbell_weight: string;
  public dumbbell_weight: string;
  public preferred_unit: 'lbs' | 'kg';
  public relationships: IUserRelationships;
  public _childTypes = ['exercise_definition', 'workout'];
  private _workoutCollection: any;
  private _exerciseDefinitionCollection: DefinitionCollection;

  public delete(): JQueryPromise<Model> {
    throw new Error('User.Delete - User cannot be deleted.');
  }

  public canBeDeleted() {
    return this.promiseService()
      .emptyPromise()
      .resolve(false);
  }

  public workouts() {
    let _ref;
    return (_ref = this._workoutCollection) != null
      ? _ref
      : (this._workoutCollection = new RecordCollection(
          this.relationships.workouts.links.self,
          this.dataStore(),
          this,
          'workout',
          this.promiseService(),
        ));
  }

  /**
   * Provides the exercise definition collection.
   * @return {DefinitionCollection}
   */
  public exercise_definitions(): DefinitionCollection {
    let _ref;
    return (_ref = this._exerciseDefinitionCollection) != null
      ? _ref
      : (this._exerciseDefinitionCollection = new DefinitionCollection(
          this.relationships.exercise_definitions.links.self,
          this.dataStore(),
          this,
          'exercise_definition',
          this.promiseService(),
        ));
  }
}
