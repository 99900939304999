import * as React from 'react';
import { IExerciseDefinition } from '../../Models/IExerciseDefinition';
import { ILocalizedComponentProps, LocalizedPureComponent } from '../LocalizedComponent';
import { DestinationSelector } from './DestinationSelector';
import { IWorkoutCopyFormDelegate } from './IWorkoutCopyFormDelegate';
import { MapResult } from './MapResult';

export interface IImportDefinitionRowProps extends ILocalizedComponentProps {
  mapResult: MapResult;
  isSelected: boolean;
  searchResults: IExerciseDefinition[];
  selectedDefinition: IExerciseDefinition;
  delegate: IWorkoutCopyFormDelegate;
  searchEntry: string;
}

export interface IImportDefinitionRowState {}

const CHANGE_BUTTON_PATH = 'copy_workout_link.mapping_change_button';
const USE_EXISTING_PATH = 'copy_workout_link.mapping_import_existing';
const CREATE_NEW_PATH = 'copy_workout_link.mapping_import_new_html';

export class ImportDefinitionRow extends LocalizedPureComponent<
  IImportDefinitionRowProps,
  IImportDefinitionRowState
> {
  public render(): JSX.Element {
    return (
      <div className="definition-mapping" data-object-id={this.props.mapResult.origin_uid}>
        <div className="mapping-origin">
          <div className="mapping-origin-exercise">{this.props.mapResult.origin_name}</div>
          {!this.props.isSelected && (
            <div
              className="xbtn xbtn-small xbtn-primary change-destination"
              onClick={this.handleSelectMappingClick}
            >
              {this.localize(CHANGE_BUTTON_PATH)}
            </div>
          )}
        </div>
        <div className="mapping-destination">
          {this.props.mapResult.resolved_type === 'existing'
            ? this.renderCopyExisting()
            : this.renderImportNew()}
        </div>
        {this.props.isSelected && (
          <DestinationSelector
            searchText={this.props.searchEntry}
            searchResults={this.props.searchResults}
            delegate={this.props.delegate}
            selectedDefinition={this.props.selectedDefinition}
          />
        )}
      </div>
    );
  }

  private renderCopyExisting(): JSX.Element {
    return (
      <div className="mapping-destination-exercise">
        {this.localize(USE_EXISTING_PATH)}
        <strong>{this.props.mapResult.resolved_name}</strong>.
      </div>
    );
  }

  private renderImportNew(): JSX.Element {
    const s = this.localize(CREATE_NEW_PATH);
    return <div className="mapping-destination-exercise" dangerouslySetInnerHTML={{ __html: s }} />;
  }

  private handleSelectMappingClick = (): void => {
    this.props.delegate.handleSelectMapping(this.props.mapResult);
  };
}
