import * as log from 'loglevel';
import * as _ from 'underscore';
import { ModelFragment } from '../Core/GlobalVariables';
import { IApplicationService } from '../Core/IApplicationService';
import { IDataLayer } from '../Data/IDataLayer';
import { IDataStore } from '../Data/IDataStore';
import { Factory } from '../Models/Constructor';
import { Exercise } from '../Models/Exercise';
import { ExerciseDefinition } from '../Models/ExerciseDefinition';
import { ExerciseGroup } from '../Models/ExerciseGroup';
import { ExerciseSet } from '../Models/ExerciseSet';
import { IExerciseDefinition } from '../Models/IExerciseDefinition';
import { Model } from '../Models/Model';
import { SetGroup } from '../Models/SetGroup';
import { User } from '../Models/User';
import { Workout } from '../Models/Workout';
import { IExerciseQuickSetOptions } from '../ViewModels/Panels/IDefinitionSelectionViewModel';
import { IModelService } from './IModelService';

export interface INewExerciseOptions {
  quick_set_attributes?: any;
  previous_exercise_id?: string | number;
}

export type IdValue = string | number;

/**
 * The ModelService is responsible for handling most creation operations on models.
 */
export class ModelService implements IModelService {
  private _newestObject: { type: string; id: string | number };
  private dataStore: IDataStore;
  private applicationService: IApplicationService;

  constructor(dataStore: any, applicationService: IApplicationService) {
    this.dataStore = dataStore;
    this.applicationService = applicationService;
  }

  private get dataLayer(): IDataLayer {
    return this.applicationService.dataLayer;
  }

  //noinspection JSMethodCanBeStatic
  /**
   * Returns a new model object that has not been persisted.
   * @param dataObject
   * @returns {Model}
   */
  public createObject<T extends Model>(dataObject: any): T {
    return Factory(
      dataObject,
      this.dataLayer,
      window.SR.diffService,
      window.SR.promiseService,
    ) as T;
  }

  /**
   * Sets the newest object.
   * @param fragment Object fragment.
   */
  public setNewestObject(fragment: ModelFragment): void {
    this._newestObject = { type: fragment.type, id: fragment.id };
  }

  /**
   * Returns the newest object.
   * @returns {{type: string, id: any}}
   */
  public newestObject(): ModelFragment {
    return _.clone(this._newestObject) as ModelFragment;
  }

  /**
   * Clears the newest object.
   */
  public clearNewestObject(): void {
    this._newestObject = { type: null, id: null };
  }

  /**
   * Creates a new model and returns a promise completing when it is finished.
   * @param parentModel The parent model.
   * @param dataObject The attributes to apply to the new model.
   * @param withScript - The script option to use.
   * @returns {JQueryPromise<Model>} The promise returning when the model has been persisted.
   */
  public addObject<T>(parentModel: Model, dataObject: any, withScript?: any): JQueryPromise<T> {
    const transaction = parentModel.createChild(dataObject);
    transaction.done(this.handleAddObjectDone);
    transaction.fail(() => {
      log.error('Failure creating new object');
    });
    return transaction as any;
  }

  /**
   * Reloads the set groups belonging to the supplied exercise.
   * @param exercise The exercise to reload.
   * @returns {any} Promise returning when the request is complete.
   */
  public reloadChildrenSetGroups(exercise: Exercise): JQueryPromise<any> {
    const setGroups =
      (exercise && exercise.relationships && exercise.relationships.set_groups) || undefined;
    if (setGroups) {
      return this.dataLayer.loadRecordCollection(setGroups.links.self);
    } else {
      log.error('Exercise does not have set groups links', exercise.relationships);
    }
  }

  /**
   * Reloads the set groups belonging to the supplied exerciseGroup.
   * @param exerciseGroup The exerciseGroup to reload.
   * @returns {any} Promise returning when the request is complete.
   */
  public reloadChildrenExercises(exerciseGroup: ExerciseGroup): JQueryPromise<any> {
    const exercises =
      (exerciseGroup && exerciseGroup.relationships && exerciseGroup.relationships.exercises) ||
      undefined;
    if (exercises) {
      return this.dataLayer.loadRecordCollection(exercises.links.self);
    } else {
      log.error('Exercise Group does not have exercises links', exerciseGroup.relationships);
    }
  }

  /**
   * Asks the model service to reload the selected record in display mode.
   * @param model Model to be reloaded.
   */
  public reloadRecord(model: Model): JQueryPromise<any> {
    return model.reload();
  }

  /**
   * Reloads the set groups belonging to the supplied workout.
   * @param workout The workout to reload.
   * @returns {any} Promise returning when the request is complete.
   */
  public reloadChildrenExerciseGroups(workout: Workout): JQueryPromise<any> {
    const exerciseGroups =
      (workout && workout.relationships && workout.relationships.exercises) || undefined;
    if (exerciseGroups) {
      return this.dataLayer.loadRecordCollection(exerciseGroups.links.self);
    } else {
      log.error('Workout does not have exerciseGroups links', workout.relationships);
    }
  }

  //noinspection JSMethodCanBeStatic
  /**
   * Creates a new workout for the attached user.
   * @param user User getting the new workout.
   * @param newWorkoutData Attributes for the new workout.
   * @param copiedWorkout Workout to copy. Optional.
   * @returns {JQueryPromise<Workout>}
   */
  public addWorkout(
    user: User,
    newWorkoutData: any,
    copiedWorkout: Workout,
  ): JQueryPromise<Workout> {
    const workoutData = _.clone(newWorkoutData);
    if (copiedWorkout) {
      workoutData.copy = copiedWorkout.id;
    }
    return user.workouts().create(workoutData, false);
  }

  //noinspection JSMethodCanBeStatic
  /**
   * Creates a new exercise definition for the attached user.
   * @param {User} user - User object under which the new definition will be created.
   * @param {Object} newDefinitionData - User provided attributes for the new defintion.
   */
  public addDefinition(
    user: User,
    newDefinitionData: IExerciseDefinition,
  ): JQueryPromise<ExerciseDefinition> {
    return user.exercise_definitions().create(newDefinitionData);
  }

  /**
   * Creates a new exercise under the provided exercise group, with the provided definition ID.
   * @param exerciseGroup - The exercise group receiving the new exercise.
   * @param definitionId - The definition ID to be attached to the new exercise.
   * @param newExercise - The quick set options to be used in creating the new exercise.
   */
  public addExercise(
    exerciseGroup: ExerciseGroup,
    definitionId: string,
    newExercise: INewExerciseOptions,
  ): JQueryPromise<Exercise> {
    const exerciseAttributes: {
      exercise_definition_uuid: string;
      quick_set_attributes?: IExerciseQuickSetOptions;
    } = { exercise_definition_uuid: definitionId };
    if (newExercise != null) {
      _.assign(exerciseAttributes, newExercise);
    }
    return this.addObject(exerciseGroup, exerciseAttributes);
  }

  /**
   * Creates a new exercise group and exercise under the provided workout, with the provided definition ID.
   * @param workout - The workout receiving the new exercise group.
   * @param definitionId - The definition ID to be attached to the new exercise.
   * @param newExercise - The quick set options to use in creating the exercise in the exercise_group.
   */
  public addExerciseGroup(
    workout: Workout,
    definitionId: string,
    newExercise: INewExerciseOptions,
  ): JQueryPromise<ExerciseGroup> {
    const exerciseAttributes: any = { exercise_definition_uuid: definitionId };
    if (newExercise != null) {
      _.assign(exerciseAttributes, newExercise);
    }
    return this.addObject(workout, { exercises_attributes: [exerciseAttributes] });
  }

  /**
   * Copies a set group under the provided exercise.
   * @param exercise - The exercise receiving the new set group.
   * @param setGroupIdToCopy - The set group ID of the set group to be copied.
   */
  public copySet(exercise: Exercise, setGroupIdToCopy: IdValue): JQueryPromise<SetGroup> {
    return this.addObject(exercise, { copy: setGroupIdToCopy });
  }

  /**
   * Adds an empty set group to the provided exercise.
   */
  public addSetGroup(exercise: Exercise): JQueryPromise<SetGroup> {
    const maxPosition = _.max(exercise.setGroups().map(sg => sg.position));
    return this.addObject(exercise, { position: maxPosition + 1 });
  }

  /**
   * Copies the provided exerciseSet and adds it after the existing one in the parent set group.
   * @param {ExerciseSet} exerciseSet - Exercise Set object to copy.
   */
  public copyExerciseSet(exerciseSet: ExerciseSet): JQueryPromise<ExerciseSet> {
    const setGroup = exerciseSet.parent();
    return this.addObject(setGroup, { copy: exerciseSet.id });
  }

  /**
   * Creates an empty service group with the supplied attributes.
   * @param {Workout} workout - The workout receiving the new exercise group.
   * @param {*} exerciseGroupAttributes - The attributes to apply to the new exercise group.
   */
  public createEmptyExerciseGroup(
    workout: Workout,
    exerciseGroupAttributes: any,
  ): JQueryPromise<ExerciseGroup> {
    return this.addObject(workout, exerciseGroupAttributes, false);
  }

  /**
   * Deletes a model given its type and ID.
   * @param {{type: string, id: string, number}} typeAndId - type and ID of the model to delete.
   */
  public deleteModelById(typeAndId: ModelFragment): JQueryPromise<Model> {
    const model = this.getModel(typeAndId);
    return model.delete();
  }

  /**
   * Retrieves the model with the provided request object.
   * @param {{type: string, id: string, number}} typeAndId - type and ID of the model to retrieve.
   */
  public getModel(typeAndId: ModelFragment): Model {
    return this.dataStore.getData(typeAndId.type, typeAndId.id);
  }

  private handleAddObjectDone = (): void => {
    const newestObject = this.newestObject();
    if (newestObject != null) {
      const triggerString = 'sr:object:created:#{newestObject.type}';
      log.debug('Triggering ', triggerString);
      $(document).trigger(triggerString, { id: newestObject.id, type: newestObject.type });
    }
  };
}
