import { IConfiguration } from '../../../../Models/IConfiguration';
import { IncrementTypes } from './IncrementTypes';

export class ValueIncrementer {
  public static allowedChanges(config: IConfiguration): IncrementTypes[] {
    return [IncrementTypes.Increment, IncrementTypes.Decrement];
  }

  public static valueForIncrement(config: IConfiguration, increment: IncrementTypes): number {
    const multiplier = increment === IncrementTypes.Increment ? 1 : -1;
    return this.incrementInterval(config.unit_kind, config.unit_entry) * multiplier + config.value;
  }

  private static incrementInterval(unitKind: SpecificationUnitKind, unitEntry: string): number {
    switch (unitKind) {
      case 'mass':
        return this.massIncrementInterval(unitEntry);
      case 'time':
        return 5;
      default:
        return 1;
    }
  }

  private static massIncrementInterval(unitEntry: string): number {
    switch (unitEntry) {
      case 'lbs':
        return 5;
      case 'kg':
        return 5;
      default:
        return 1;
    }
  }
}
