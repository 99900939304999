import * as log from 'loglevel';
import * as React from 'react';
import * as _ from 'underscore';
import { IApplicationService } from '../../Core/IApplicationService';
import { ILocalizationService } from '../../Services/ILocalizationService';
import { IPanelStateManager } from '../../ViewModels/IPanelStateManager';
import { IPanelManagerViewModel } from '../../ViewModels/PanelManager/IPanelManagerViewModel';
import { PanelManager } from '../../ViewModels/PanelManager/PanelManager';
import { ILocalizedComponentProps, LocalizationContext } from '../LocalizedComponent';
import { MobileSelectionViewContainer } from './MobileSelection/Container/MobileSelectionViewContainer';
import { ISelectionContainerProps } from './MobileSelection/Container/SelectionContainer';
import { Navigation } from './Navigations/Navigation';
import { IPanelData } from './Panels/IPanelData';
import { IPanelViewModelProps } from './Panels/IPanelProps';
import { PanelContainer } from './Panels/PanelContainer';
import Dictionary = _.Dictionary;

export interface IApplicationRootProps extends ILocalizedComponentProps {
  appService: IApplicationService;
  localization: ILocalizationService;
}

export interface IApplicationRootState {
  panels: Dictionary<IPanelViewModelProps<any, any>>;
  selectionProps?: ISelectionContainerProps;
  isMobile: boolean;
}

const MINIMUM_WIDTH_FOR_DESKTOP = 570;

function getMobileStatus(): boolean {
  return window.innerWidth < MINIMUM_WIDTH_FOR_DESKTOP;
}

/**
 * The ApplicationRoot initializes the panels and menus, and is the root of the application display.
 */
export class ApplicationRoot
  extends React.PureComponent<IApplicationRootProps, IApplicationRootState>
  implements IPanelStateManager {
  private panelManager: IPanelManagerViewModel;

  public constructor(props: IApplicationRootProps, context?: any) {
    super(props, context);
    this.state = {
      isMobile: getMobileStatus(),
      panels: {},
    };
  }

  public updateSharedSelectionProps<T>(props: Partial<ISelectionContainerProps>): void {
    log.error('ApplicationRoot.updateSharedSelectionProps');
  }

  public componentDidMount(): void {
    this.panelManager = this.constructPanelManager();
    this.panelManager.openWorkoutList();
    window.addEventListener('resize', this.handleResizeEvent, { passive: true });
  }

  public componentWillUnmount(): void {
    super.componentWillUnmount();
    window.removeEventListener('resize', this.handleResizeEvent);
  }

  public redraw(): void {
    const cloned = _.mapObject(this.state.panels, (val, key) => {
      return _.clone(val);
    });
    this.setState({ panels: cloned });
  }

  public updateProps<T, T2 = any>(id: string, props: Partial<IPanelViewModelProps<T, T2>>): void {
    this.setState(prevState => {
      if (prevState.panels[id] == null) {
        log.warn(`ApplicationRoot.updateProps - update for missing panel "${id}"`);
        return null;
      }
      const currentProps = prevState.panels[id];
      const newPanel = { ...currentProps, props };
      log.debug('ApplicationRoot.updateProps', id, newPanel, props);
      return { panels: { ...prevState.panels, [id]: newPanel } };
    });
  }

  public addProps<T, T2 = any>(id: string, props: IPanelViewModelProps<T, T2>): void {
    this.setState(prevState => {
      return {
        panels: { ...prevState.panels, [id]: props },
      };
    });
  }

  public removeProps(id: string): void {
    this.setState(prevState => {
      const panelsToRemove = [id];
      _.values(prevState.panels).forEach((value: IPanelViewModelProps<any, any>) => {
        if (value.data.parentPanelId === id) {
          panelsToRemove.push(value.data.id);
        }
      });
      log.debug('ApplicationRoot.removeProps - dropping panels', JSON.stringify(panelsToRemove));
      return {
        panels: _.omit(prevState.panels, ...panelsToRemove),
      };
    });
  }

  public render(): JSX.Element {
    log.debug('ApplicationRoot.render', this.state);
    return (
      <LocalizationContext.Provider value={this.props.localization}>
        <div className="panel-layout">
          <Navigation
            localizationService={this.props.localization}
            panelManager={this.panelManager}
            panels={this.state.panels}
          />
          <PanelContainer panels={this.state.panels} isMobile={this.state.isMobile} />
          <MobileSelectionViewContainer panels={this.state.panels} isMobile={this.state.isMobile} />
        </div>
      </LocalizationContext.Provider>
    );
  }

  public focusPanel(panelId: string): void {
    this.setState((prevState: IApplicationRootState) => {
      return {
        panels: _.mapObject<IPanelViewModelProps<any, any>>(prevState.panels, (val, key) => {
          return {
            ...val,
            data: { ...val.data, hasFocus: key === panelId },
          };
        }),
      };
    });
    if (!this.state.isMobile) {
      const panel = $(`.panel-item[data-panel="${panelId}"]`);
      panel.velocity('scroll', { axis: 'X', container: panel.parent() });
    }
  }

  public isPanelOpen(panelId: string): boolean {
    return this.state.panels[panelId] != null;
  }

  public getPanelProps<T, T2 = any>(panelId: string): IPanelViewModelProps<T, T2> {
    return this.state.panels[panelId];
  }

  public getAllPanelProps(): IPanelViewModelProps<any, any>[] {
    return _.values(this.state.panels);
  }

  public getSharedSelectionProps(): ISelectionContainerProps {
    return this.state.selectionProps;
  }

  public updatePanelData<TProps>(panelId: string, data: Partial<IPanelData>): void {
    this.setState(prevState => {
      if (prevState.panels[panelId] == null) {
        log.warn(`ApplicationRoot.updatePanelData - update for missing panel "${panelId}"`);
        return null;
      }
      const panel = prevState.panels[panelId];
      const currentData = panel.data;
      const newPanel = { ...panel, data: { ...currentData, ...data } };
      log.debug('ApplicationRoot.updatePanelData', panelId, newPanel, data);
      return {
        panels: { ...prevState.panels, [panelId]: newPanel },
      };
    });
  }

  public updatePanelPartial<T>(panelId: string, props: Partial<T>): void {
    this.setState(prevState => {
      if (prevState.panels[panelId] == null) {
        log.warn(`ApplicationRoot.updatePanelPartial - update for missing panel "${panelId}"`);
        return null;
      }
      const panel = prevState.panels[panelId];
      const currentProps = panel.props;
      const newPanel = { ...panel, props: { ...currentProps, ...(props as object) } };
      log.debug('ApplicationRoot.updatePanelPartial', panelId, newPanel, props);
      return {
        panels: {
          ...prevState.panels,
          [panelId]: newPanel,
        },
      };
    });
  }

  public updatePanelProps<TProps, TViewModel = any>(
    panelId: string,
    props: Partial<IPanelViewModelProps<TProps, TViewModel>>,
  ): void {
    this.setState(prevState => {
      if (prevState.panels[panelId] == null) {
        log.warn(`ApplicationRoot.updatePanelProps - update for missing panel "${panelId}"`);
        return null;
      }
      const panel: IPanelViewModelProps<any, any> = prevState.panels[panelId];
      const newPanel = { ...panel, ...props };
      log.debug('ApplicationRoot.updatePanelProps', panelId, newPanel, props);
      return {
        panels: {
          ...prevState.panels,
          [panelId]: newPanel,
        },
      };
    });
  }

  public updatePanelSelectionProps(
    panelId: string,
    props: Partial<ISelectionContainerProps>,
  ): void {
    this.setState(prevState => {
      if (prevState.panels[panelId] == null) {
        // log.warn(`ApplicationRoot.updatePanelSelectionProps - update for missing panel "${panelId}"`);
        return null;
      }
      const panel: IPanelViewModelProps<any, any> = prevState.panels[panelId];
      const currentProps = panel.selectionProps;
      const newPanel: IPanelViewModelProps<any, any> = {
        ...panel,
        selectionProps: { ...currentProps, ...props },
      };
      log.debug('ApplicationRoot.updatePanelSelectionProps', panelId, newPanel, props);
      return {
        panels: {
          ...prevState.panels,
          [panelId]: newPanel,
        },
      };
    });
  }

  public isMobile(): boolean {
    return this.state.isMobile;
  }

  private handleResizeEvent = (event: Event): void => {
    if (this.state.isMobile !== getMobileStatus()) {
      this.setState({ isMobile: getMobileStatus() });
    }
  };

  private constructPanelManager(): IPanelManagerViewModel {
    return new PanelManager(this, this.props.appService, null);
  }
}
