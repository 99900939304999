import { User } from '../Models/User';
import { UserProfileViewModel } from '../ViewModels/UserProfileViewModel';
import { View } from './View';
export enum ButtonOptions {
  Ready,
  Invalid,
  Pending,
}

export class UserProfileView extends View<UserProfileViewModel> {
  public attachListeners() {
    this.view()
      .find('#user-rest-set')
      .change(e => this.handleFieldChange(e, 'rest_set'));
    this.view()
      .find('#user-rest-exercise')
      .change(e => this.handleFieldChange(e, 'rest_exercise'));
    this.view()
      .find('#user-barbell-weight')
      .change(e => this.handleFieldChange(e, 'barbell_weight'));
    this.view()
      .find('#user-dumbbell-weight')
      .change(e => this.handleFieldChange(e, 'dumbbell_weight'));
    this.view()
      .find('#user-olympic-barbell-weight')
      .change(e => this.handleFieldChange(e, 'olympic_barbell_weight'));
    this.form().on('submit', e => this.handleSubmit(e));
    this.form()
      .parsley()
      .on('field:validated', () => this.configureFormStatus());
  }

  /**
   * Populates the form with the user data.
   * @param user User object containing the data to update.
   */
  public populateUserData(user: User): void {
    this.populateField(user, 'barbell_weight', '#user-barbell-weight');
    this.populateField(user, 'olympic_barbell_weight', '#user-olympic-barbell-weight');
    this.populateField(user, 'dumbbell_weight', '#user-dumbbell-weight');
    this.populateField(user, 'rest_set', '#user-rest-set');
    this.populateField(user, 'rest_exercise', '#user-rest-exercise');
  }

  public setButtonState(state: ButtonOptions) {
    if (state === ButtonOptions.Ready) {
      this.submitButton().removeClass('disabled');
    } else if (state === ButtonOptions.Pending) {
      this.submitButton().addClass('disabled');
    } else if (state === ButtonOptions.Invalid) {
      this.submitButton().addClass('disabled');
    }
  }

  public clearFields() {}

  private populateField(user: User, attributeName: any, fieldSelector: string) {
    this.view()
      .find(fieldSelector)
      .val((user as any)[attributeName]);
  }

  private handleSubmit(event: JQueryEventObject): void {
    event.preventDefault();
    event.stopPropagation();
    // tslint:disable-next-line:no-object-literal-type-assertion
    if (!(this.form().parsley({} as parsley.FormOptions) as parsley.Form).isValid()) {
      return;
    }
    this.viewModel().changePassword(
      this.currentPasswordField().val(),
      this.newPasswordField().val(),
      this.passwordConfirmField().val(),
    );
  }

  private submitButton(): JQuery {
    return this.view().find('button');
  }

  private currentPasswordField(): JQuery {
    return this.view().find('#user-current-password');
  }

  private newPasswordField(): JQuery {
    return this.view().find('#user-new-password');
  }

  private passwordConfirmField(): JQuery {
    return this.view().find('#user-password-confirmation');
  }

  private form(): JQuery {
    return this.view().find('#password-change');
  }

  private handleFieldChange(event: JQueryInputEventObject, attributeName: any) {
    const value = (event.target as HTMLInputElement).value;
    this.viewModel().updateModelAttribute(attributeName, value);
  }

  private configureFormStatus() {
    const valid = this.form()
      // tslint:disable-next-line:no-object-literal-type-assertion
      .parsley({} as parsley.FormOptions)
      .isValid();
    const desiredState = valid ? ButtonOptions.Ready : ButtonOptions.Invalid;
    this.setButtonState(desiredState);
  }
}
