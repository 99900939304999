import * as React from 'react';
import * as _ from 'underscore';
import { MaximumWorkoutNameLength } from '../../ApplicationConfiguration';
import { IExerciseDefinition } from '../../Models/IExerciseDefinition';
import { ValueValidator } from '../Journal/Helpers/ValueValidator';
import { DateTimePicker } from '../Journal/Panels/EditObject/DateTimePicker';
import { FormField } from '../Journal/Panels/EditObject/FormField';
import { SectionWrapper } from '../Journal/Panels/EditObject/SectionWrapper';
import { ILocalizedComponentProps, LocalizedPureComponent } from '../LocalizedComponent';
import { IWorkoutCopyFormDelegate } from './IWorkoutCopyFormDelegate';
import { MappingDialog } from './MappingDialog';
import { MapResult } from './MapResult';

export interface IWorkoutCopyDialogProps extends ILocalizedComponentProps {
  workoutName: string;
  workoutDate: string;
  mappingRequired: MapResult[];
  selectedMapping: MapResult | null;
  searchResults: IExerciseDefinition[];
  searchEntry: string;
  selectedDefinition: IExerciseDefinition | null;
  delegate: IWorkoutCopyFormDelegate;
}

export interface IWorkoutCopyDialogState {
  inProgress: boolean;
}

const WORKOUT_NAME_PATH = 'workout_links.show.attributes.workout.name';
const COPY_WORKOUT_BUTTON_PATH = 'copy_workout_link.submit_button';

export class WorkoutCopyDialog extends LocalizedPureComponent<
  IWorkoutCopyDialogProps,
  IWorkoutCopyDialogState
> {
  private dialogMounted: boolean;
  private nameValidator = ValueValidator.lengthValidator(
    1,
    MaximumWorkoutNameLength,
    () => this.props.workoutName,
  );

  public constructor(props: IWorkoutCopyDialogProps, context?: any) {
    super(props, context);
    this.dialogMounted = false;
    this.state = {
      inProgress: false,
    };
  }

  private get isValid(): boolean {
    return this.nameValidator.isValid();
  }

  public componentDidMount(): void {
    this.dialogMounted = true;
  }

  public componentWillUnmount(): void {
    this.dialogMounted = false;
  }

  public render(): JSX.Element {
    return (
      <form className="workout-copy">
        <SectionWrapper>
          <FormField
            value={this.props.workoutName}
            valueError={this.nameValidator.error()}
            modelName="workout"
            isValid={this.nameValidator.isValid()}
            updateAction={this.props.delegate.updateName}
            propertyName="name"
            placeholder=""
            labelPath={WORKOUT_NAME_PATH}
          />
        </SectionWrapper>
        <SectionWrapper>
          <DateTimePicker
            value={this.props.workoutDate}
            onChange={this.props.delegate.updateDate}
          />
        </SectionWrapper>
        {!_.isEmpty(this.props.mappingRequired) && (
          <MappingDialog
            mappings={this.props.mappingRequired}
            searchResults={this.props.searchResults}
            selectedDefinition={this.props.selectedDefinition}
            searchEntry={this.props.searchEntry}
            selectedItem={this.props.selectedMapping}
            delegate={this.props.delegate}
          />
        )}
        <div className="form-group-container pull-right">{this.renderSubmitButton()}</div>
      </form>
    );
  }

  private renderSubmitButton(): JSX.Element {
    let classes = 'xbtn xbtn-primary submit-copy';
    if (!this.isValid) {
      classes += ' disabled';
    }
    return (
      <button className={classes} onClick={this.handleSubmitClick}>
        <i className="fa fa-copy" />
        &nbsp;{this.localize(COPY_WORKOUT_BUTTON_PATH)}
      </button>
    );
  }

  private handleSubmitClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    if (!this.isValid) {
      return;
    }
    this.setState({ inProgress: true });
    const handle = () => {
      if (!this.dialogMounted) {
        return;
      }
      this.setState({ inProgress: false });
    };
    this.props.delegate.submit().then(handle, handle);
  };
}
