import * as React from 'react';
import * as _ from 'underscore';
import { ExerciseSet } from '../../../../Models/ExerciseSet';
import { Model } from '../../../../Models/Model';
import { SetGroup } from '../../../../Models/SetGroup';
import {
  IFormChangeData,
  IObjectPanelViewModel,
} from '../../../../ViewModels/Panels/IObjectPanelViewModel';
import { AbstractPanelViewContainer } from '../AbstractPanelViewContainer';
import { IPanelExternalData, IPanelInternalData } from '../IPanelData';
import { IPanelHeaderActions } from '../IPanelHeaderActions';
import { IPanelViewModelProps } from '../IPanelProps';
import { PanelTypes } from '../PanelTypes';
import { IObjectFormActionProps, IObjectFormProps } from './ObjectForm';

export type IObjectFormContainerProps = IPanelViewModelProps<
  IObjectFormProps & IObjectFormActionProps,
  IObjectPanelViewModel
>;

export interface IObjectFormContainerState {}

export class ObjectFormContainer
  extends AbstractPanelViewContainer<
    IObjectFormProps,
    {},
    IObjectFormContainerState,
    IObjectFormActionProps
  >
  implements IObjectPanelViewModel {
  public static initialPanelData(
    viewModelId: string,
    selectedRecord: Model,
    parentlViewModelId: string = null,
  ): IPanelExternalData {
    return {
      hasFocus: false,
      hasTransactionOverlay: false,
      id: viewModelId,
      objectId: selectedRecord.id.toString(),
      panelType: PanelTypes.Object,
      parentPanelId: parentlViewModelId,
      title: this.panelTitle(selectedRecord),
    };
  }

  public static initialPanelProps(contextRecords: any, model: Model): IObjectFormProps {
    return {
      contextRecords,
      model,
    };
  }

  private static panelTitle(selectedRecord: Model): string {
    switch (selectedRecord.type) {
      case 'workout':
        return 'Edit Workout';
      case 'exercise_set':
        return 'Edit Set';
      case 'exercise':
      case 'exercise_definition':
        return 'Edit Exercise';
      default:
        return 'Edit Object';
    }
  }

  public closeObjectPanel(): void {
    this.removePanel();
  }

  public applyChanges(formChangeData: IFormChangeData[]): JQueryPromise<any> {
    const existingRecords: any = this.props.props.contextRecords;
    let exerciseSetChanges: any;
    if (this.props.props.model instanceof ExerciseSet) {
      exerciseSetChanges = this.updateSetGroupRecords(formChangeData);
    } else {
      this.updateRecords(formChangeData);
    }
    let promises = _.map(existingRecords, function(record) {
      if (record instanceof SetGroup) {
        return record.save(exerciseSetChanges);
      } else if (!(record instanceof ExerciseSet) && record instanceof Model) {
        return record.save();
      }
    });
    promises = _.compact(promises);
    return $.when.apply(this, promises).done(() => {
      this.props.completePromise.resolve();
      return this.removePanel();
    });
  }

  protected actionProps(): IObjectFormActionProps {
    return {
      viewModel: this,
    };
  }

  protected panelData(): IPanelInternalData {
    return {
      additionalClasses: null,
      type: 'object',
    };
  }

  protected renderHeaderActions(): IPanelHeaderActions {
    return {
      onDismiss: this.onDismiss,
    };
  }

  protected panelProps(): IObjectFormProps {
    return {
      contextRecords: this.props.props.contextRecords,
      model: this.props.props.model,
    };
  }

  private onDismiss = (): void => {
    this.removePanel();
  };

  private updateSetGroupRecords(formChangeData: IFormChangeData[]) {
    let exerciseSetChanges: IFormChangeData[];
    let setGroup: SetGroup;
    let updateObject: any;
    const existingRecords: any = this.props.props.contextRecords;
    setGroup = existingRecords.set_group;
    _.each(formChangeData, changeData => {
      if (changeData.type === 'set_group') {
        return ((setGroup as any)[changeData.attribute] = changeData.value);
      }
    });
    exerciseSetChanges = _.filter(formChangeData, function(changeData) {
      return changeData.type === 'exercise_set';
    });
    updateObject = {
      id: existingRecords.exercise_set.id,
    };
    _.each(exerciseSetChanges, change => {
      if (change.attribute === 'difficulty') {
        updateObject.status = change.value === 'not_set' ? 'planned' : 'completed';
      }
      return (updateObject[change.attribute] = change.value);
    });
    return {
      exercise_sets_attributes: [updateObject],
    };
  }

  private updateRecords(formChangeData: IFormChangeData[]) {
    const existingRecords: any = this.props.props.contextRecords;
    return _.each(formChangeData, function(changeData) {
      return (existingRecords[changeData.type][changeData.attribute] = changeData.value);
    });
  }
}
