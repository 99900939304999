import * as React from 'react';
import * as _ from 'underscore';
import { IConfiguration } from '../../../../Models/IConfiguration';
import { IPlateItem } from '../../../../Models/IExerciseSet';
import { IExerciseTempo } from '../../../../Models/IExerciseTempo';
import { ILocalizedComponentProps, LocalizedPureComponent } from '../../../LocalizedComponent';
import { TempoDisplay } from '../Shared/TempoDisplay';
import { IncrementerControl } from './IncrementerControl';
import { IncrementTypes } from './IncrementTypes';
import { IntensityParameterList } from './IntensityParameterList';
import { PlatesDisplay } from './PlatesDisplay';

export interface IPlateData {
  plates: IPlateItem[];
  remainder: string;
  barWeight: string;
}

export interface IIntensitySectionProps extends ILocalizedComponentProps {
  secondaryConfigurations: IConfiguration[];
  prediction: string;
  incrementPermitted: boolean;
  decrementPermitted: boolean;
  currentValue: string | number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onIncrementClick: (incrementAction: IncrementTypes) => void;
  onIncrementerBlur: () => void;
  intensityValueLabel: string;
  plateData: IPlateData;
  tempoData: IExerciseTempo;
}

export interface IIntensitySectionState {}

const incrementerControlClass = 'sv-intensity-primary';
const incrementerLabelClass = 'sv-intensity-name';
const incrementerInputclass = 'sv-intensity-value';

export class IntensitySection extends LocalizedPureComponent<
  IIntensitySectionProps,
  IIntensitySectionState
> {
  public render(): JSX.Element {
    return (
      <div className="msv-section sv-intensity-section">
        <div className="msvSectionTitle">{this.localize('object.exercise_set.intensity')}</div>
        <IncrementerControl
          incrementPermitted={this.props.incrementPermitted}
          decrementPermitted={this.props.decrementPermitted}
          onIncrementClick={this.props.onIncrementClick}
          onChange={this.props.onChange}
          currentValue={this.props.currentValue}
          inputClass={incrementerInputclass}
          containerClass={incrementerControlClass}
          labelClass={incrementerLabelClass}
          isEnabled={true}
          label={this.props.intensityValueLabel}
          onBlurOrSubmit={this.props.onIncrementerBlur}
        />
        <IntensityParameterList parameters={this.props.secondaryConfigurations} />
        {this.props.plateData && (
          <PlatesDisplay
            plates={this.props.plateData.plates}
            barWeight={this.props.plateData.barWeight}
            remainder={this.props.plateData.remainder}
          />
        )}
        {this.props.tempoData && !_.isEmpty(this.props.tempoData.concentric) && (
          <TempoDisplay
            eccentric={this.props.tempoData.eccentric}
            concentric={this.props.tempoData.concentric}
            rest={this.props.tempoData.rest}
            pause={this.props.tempoData.pause}
          />
        )}
        {this.props.prediction && (
          <div className="sv-prediction-section">
            <div className="sv-section-subtitle">
              {this.localize('mobile_selection_view.exercise_set.prediction')}
            </div>
            <div className="sv-horizontal-group sv-prediction-content">{this.props.prediction}</div>
          </div>
        )}
      </div>
    );
  }
}
