import * as log from 'loglevel';
import * as _ from 'underscore';
import { IApplicationService } from '../../Core/IApplicationService';
import { Exercise } from '../../Models/Exercise';
import { ExerciseDefinition } from '../../Models/ExerciseDefinition';
import { ExerciseGroup } from '../../Models/ExerciseGroup';
import { ExerciseSet } from '../../Models/ExerciseSet';
import { IConfiguration } from '../../Models/IConfiguration';
import { IPerformanceSpecification } from '../../Models/IPerformanceSpecification';
import { Model } from '../../Models/Model';
import { SetGroup } from '../../Models/SetGroup';
import { Workout } from '../../Models/Workout';
import { IModelService } from '../../Services/IModelService';
import { IPromiseService } from '../../Services/PromiseService';
import { ExerciseHistoryModel } from '../../Views/Journal/History/HistoryItem';
import { IHistoryAssignable } from '../../Views/Journal/History/HistoryItemContainer';
import { INavigationSectionDataProps } from '../../Views/Journal/MobileSelection/Container/NavigationSection';
import { ISelectionContainerProps } from '../../Views/Journal/MobileSelection/Container/SelectionContainer';
import { IPanelStateManager } from '../IPanelStateManager';
import { IViewModel } from '../IViewModel';
import { IPanelManagerViewModel, IPanelPromise } from '../PanelManager/IPanelManagerViewModel';
import { IHistoryViewModel } from '../Panels/HistoryViewModel';
import { SelectionModes } from '../Panels/IDefinitionSelectionViewModel';
import { IWorkoutViewModel } from '../Panels/IWorkoutViewModel';
import { IManager } from '../WorkoutNavigation/IManager';
import { Manager } from '../WorkoutNavigation/Manager';
import { INewSelectionViewModel } from './INewSelectionViewModel';
import { ISelectionNavigationDisplay } from './ISelectionNavigationDisplay';

export class NewSelectionViewModel
  implements INewSelectionViewModel, ISelectionNavigationDisplay, IHistoryAssignable {
  public currentModel: Model;
  public panelId: string | null;
  protected panelStateManager: IPanelStateManager;
  protected readonly _modelService: IModelService;
  protected _panelManager: IPanelManagerViewModel;
  protected application: IApplicationService;
  protected historyViewModel: IHistoryViewModel;
  private currentWorkoutView: IWorkoutViewModel;
  private navigationManager: IManager = new Manager(this);
  private navigationData: INavigationSectionDataProps = {
    currentPosition: null,
    nextDisplay: '',
    numberOfSets: null,
    previousDisplay: '',
  };

  constructor(
    panelManager: IPanelManagerViewModel,
    parentViewModel: IViewModel,
    app: IApplicationService,
    stateManager: IPanelStateManager,
    panelId: string,
  ) {
    this._modelService = app.modelService;
    this._panelManager = panelManager;
    this.application = app;
    this.panelStateManager = stateManager;
    this.panelId = panelId;
  }

  public onNextClick = (): void => {
    this.goNextItem();
  };

  public onPreviousClick = (): void => {
    this.goPreviousItem();
  };

  public initialSelectionProps(): ISelectionContainerProps {
    return {
      history: [],
      isVisible: false,
      model: null,
      navigationData: this.navigationData,
      setIndex: false,
      updateVisibility: newValue => this.updateViewProps({ isVisible: newValue }),
      viewModel: this,
    };
  }

  public updateExerciseGroupPosition(
    group: ExerciseGroup,
    newPosition: number,
  ): JQueryPromise<ExerciseGroup> {
    group.position = newPosition;
    const promise = group.save();
    return promise.done(() => {
      group.workout().reload();
      this.updateViewProps({ isVisible: false });
    });
  }

  // <editor-fold desc="ISelectionNavigationDisplay Methods">
  public updateNextItemDisplay(displayText: string): void {
    const newNavigation = _.clone(this.navigationData);
    newNavigation.nextDisplay = displayText;
    this.navigationData = newNavigation;
    this.updateViewProps({ navigationData: newNavigation });
  }

  public updatePreviousItemDisplay(displayText: string): void {
    const newNavigation = _.clone(this.navigationData);
    newNavigation.previousDisplay = displayText;
    this.navigationData = newNavigation;
    this.updateViewProps({ navigationData: newNavigation });
  }

  public updateSetProgress(currentPosition: number, numberOfSets: number): void;
  public updateSetProgress(clear: 'clear'): void;
  public updateSetProgress(currentPosition: any, numberOfSets?: any) {
    const newNavigation = _.clone(this.navigationData);
    newNavigation.numberOfSets = numberOfSets;
    newNavigation.currentPosition = currentPosition;
    this.navigationData = newNavigation;
    this.updateViewProps({ navigationData: newNavigation });
  }

  // </editor-fold>

  public assign = (history: IHistoryViewModel): void => {
    log.debug('NewSelectionViewModel.assign', history);
    this.historyViewModel = history;
  };

  public unassign = (history: IHistoryViewModel): void => {
    log.debug('NewSelectionViewModel.unassign', history);
    if (this.historyViewModel === history) {
      this.historyViewModel = null;
    }
  };

  // <editor-fold desc="ISelectionNavigationHandler Methods">
  /**
   * Instructs the handler to go to the next item in the list.
   */
  public goNextItem(): JQueryPromise<any> {
    return this.navigationManager.goNext();
  }

  /**
   * Instructs the handler to go to the previous item in the list.
   */
  public goPreviousItem(): JQueryPromise<any> {
    return this.navigationManager.goPrevious();
  }

  // </editor-fold>

  /**
   * Displays a History panel.
   * @returns {JQueryPromise<any>} Promsie resolving once the history panel has been displayed.
   */
  public displayHistoryPanel(): Promise<any> {
    // const hadHistory: boolean = historyViewModel != null;
    let modelToShow: ExerciseHistoryModel;
    let setIndex: number | null = null;
    if (this.currentModel instanceof Exercise || this.currentModel instanceof ExerciseSet) {
      modelToShow = this.getExercise(this.currentModel);
      setIndex = this.getSetIndex(this.currentModel);
    } else if (this.currentModel instanceof ExerciseDefinition) {
      modelToShow = this.currentModel;
    }
    if (this.historyViewModel == null) {
      return new Promise((resolve, reject) => {
        this.panelManager()
          .openHistoryPanel(this.panelId, modelToShow, setIndex, this)
          .mount.then((...args: any[]) => {
            this.handleHistoryDisplay(this.historyViewModel);
            resolve();
          })
          .fail((...args: any[]) => {
            reject();
          });
      });
    }
    return new Promise<any>((resolve, reject) => {
      this.historyViewModel.displayHistoryForModel(modelToShow, setIndex).then(
        () => {
          resolve();
        },
        () => {
          reject();
        },
      );
    });
  }

  public hideHistory(): void {
    const historyViewModel = this.historyViewModel;
    if (historyViewModel) {
      historyViewModel.onDismiss();
    }
  }

  public addSuperSet(): JQueryPromise<Exercise> {
    const exercise = this.currentModel;
    if (!(exercise instanceof Exercise)) {
      log.error('Add Super Set Called without Exercise');
      return;
    }
    const exerciseGroup = exercise.exerciseGroup();
    this.operateDefinitionSelectionViewModel(exerciseGroup, 'add-exercise');
  }

  /**
   * Copies an exercise segment.
   * @returns Returns a promise resolving with the new segment.
   */
  public addSegment(): JQueryPromise<ExerciseSet> {
    const exerciseSet = this.currentModel;
    if (!(exerciseSet instanceof ExerciseSet)) {
      log.error('Add Segment Called without Exercise Set');
      return this.application.promiseService.rejectedPromise();
    }
    const promise = this.modelService().copyExerciseSet(exerciseSet);
    promise.done(() => this.updateViewProps({ isVisible: false }));
    return promise;
  }

  /**
   * Deletes the selected ExerciseSet
   * @returns Returns a promise resolving when the record has been deleted.
   */
  public deleteSegment(): JQueryPromise<ExerciseSet> {
    const exerciseSet = this.currentModel;
    if (!(exerciseSet instanceof ExerciseSet)) {
      log.error('Delete Segment Called Without Exercise Set');
      return this.promiseService().rejectedPromise();
    }
    if (exerciseSet.siblings().length === 0) {
      log.error('Delete Segment Called on Only Child Exercise Set');
      return this.promiseService().rejectedPromise();
    }
    const promise = exerciseSet.delete().done(() => {
      this.updateViewProps({ isVisible: false });
    });
    return promise as JQueryPromise<ExerciseSet>;
  }

  /**
   * Instructs the view model to copy the currently selected {@link ExerciseSet}
   * @returns Promise resolving when set has been copied.
   */
  public copySet(): JQueryPromise<SetGroup> {
    if (this.currentModel instanceof ExerciseSet) {
      const setGroup = this.currentModel.parent() as SetGroup;
      const exercise = setGroup.parent() as Exercise;
      const promise = this.modelService().copySet(exercise, setGroup.id);
      promise.done(() => this.updateViewProps({ isVisible: false }));
      return promise;
    } else {
      log.error('Copy Set called without Set selected');
    }
  }

  public updateDifficulty(newDifficulty: string): JQueryPromise<Model> {
    if (this.currentModel instanceof ExerciseSet) {
      const group: ExerciseSet = this.currentModel;
      group.update({ difficulty: newDifficulty });
      return group.save().done(() => this.displayModel(this.currentModel));
    }
    return null;
  }

  public addSet(): JQueryPromise<SetGroup> {
    if (this.currentModel instanceof Exercise) {
      const promise = this.modelService().addSetGroup(this.currentModel);
      promise.done(() => this.updateViewProps({ isVisible: false }));
      return promise;
    }
  }

  public changeExercise(): JQueryPromise<Exercise> {
    const exercise = this.currentModel;
    if (!(exercise instanceof Exercise)) {
      log.error('Change Exercise Called without Exercise Selected');
      return;
    }
    const mode = 'change-exercise';
    return this.operateDefinitionSelectionViewModel(exercise, mode);
  }

  /**
   * Changes the selection.
   * @param model
   * @returns {JQueryDeferred<T>} Promise that resolves once complete.
   */
  public changeSelection(model: Model): JQueryPromise<any> {
    this.currentModel = model;
    this.displayModel(model);
    if (model.type === 'workout') {
      this.currentModel.reload();
    }
    this.handleModelChangeOrUpdate();
    this.handleNavigationChange();
    return $.Deferred().resolve();
  }

  public displayRecord(
    type: ModelType,
    id: string | number,
    modalDisplay = false,
    requestingWorkoutView?: IWorkoutViewModel,
  ) {
    const model: Model = this.modelService().getModel({ id, type });
    this.currentWorkoutView = requestingWorkoutView;
    if (modalDisplay === true) {
      this.changeSelection(model);
    }
  }

  public deleteObject(): JQueryPromise<Model> {
    const currentModel = this.getModelToDelete(this.currentModel);
    const deletePromise = currentModel.delete();
    deletePromise.done(() => {
      this.updateViewProps({ model: null, isVisible: false });
      this.hideHistory();
    });
    return deletePromise;
  }

  public updateView() {}

  public selectedRecord(): Model {
    return this.currentModel;
  }

  /**
   * Updates the note content on the model.
   * @param newNoteContent New note content.
   * @return Promise that resolves with the updated model once updated.
   */
  public updateNoteContent(newNoteContent: string): JQueryPromise<Model> {
    return this.updateModelProperty(newNoteContent, 'note_content');
  }

  /**
   * Handles changes to a specification identified by its index.
   * @param specificationIndex
   * @param fieldUpdated
   * @param newValue
   */
  public updateSpecification(
    specificationIndex: number,
    fieldUpdated: keyof IPerformanceSpecification,
    newValue: number,
  ): JQueryPromise<Model> {
    const currentModel = this.currentModel;
    if (!(currentModel instanceof ExerciseSet)) {
      log.error('Invalid Model, Current Model is A', currentModel);
      return null;
    }
    const exerciseSet = currentModel;
    const specification = exerciseSet.performance_specifications[specificationIndex];
    (specification[fieldUpdated] as any) = newValue;
    const savePromise = exerciseSet.save();
    savePromise.always(() => {
      this.displayModel(this.currentModel);
    });

    return savePromise;
  }

  public updateConfiguration(
    index: number,
    fieldUpdated: keyof IConfiguration,
    newValue: number,
  ): JQueryPromise<ExerciseSet> {
    const model = this.currentModel;
    if (!(this.currentModel instanceof ExerciseSet)) {
      log.error('Invalid Model, Current Model is ', model);
      return this.promiseService().rejectedPromise();
    }
    const es = model as ExerciseSet;
    const config = es.configurations[index];
    (config[fieldUpdated] as any) = newValue;
    const savePromise = es.save();
    savePromise.always(() => {
      this.displayModel(this.currentModel);
    });
    return savePromise;
  }

  public incrementSpecification(
    specificationIndex: number,
    fieldUpdated: 'target_value' | 'actual_value',
    incrementType: 'increment' | 'decrement',
  ): JQueryPromise<Model> {
    const currentModel = this.currentModel;
    if (!(currentModel instanceof ExerciseSet)) {
      log.error('Invalid Model, Current Model is', currentModel);
      return;
    }
    const exerciseSet: ExerciseSet = currentModel;
    const specification = exerciseSet.performance_specifications[specificationIndex];
    const currentValue = specification[fieldUpdated];
    const increment = incrementType === 'increment' ? 1 : -1;
    specification[fieldUpdated] = currentValue + increment;
    const savePromise = exerciseSet.save();
    savePromise.done((obj: Model) => {
      this.displayModel(this.currentModel);
    });
  }

  /**
   * Updates any model property with the supplied name and value.
   * @param newValue Any value valid for the property.
   * @param propertyName The property to update.
   * @return A promise resolving when the change has been completed.
   */
  public updateModelProperty(newValue: any, propertyName: string): JQueryPromise<Model> {
    const updateObj: Dictionary<any> = {};
    updateObj[propertyName] = newValue;
    this.currentModel.update(updateObj);
    const promise = this.currentModel.save();
    promise.always(() => {
      if (this.getSelectionProps().isVisible === true) {
        return this.displayModel(this.currentModel);
      }
    });
    return promise;
  }

  public openObjectPanel(): void {
    const contextRecords = this.getContextRecords(this.currentModel);
    const vm = this.panelManager().openObjectPanel(this.currentModel, contextRecords, this.panelId);
    vm.completed.always(() => {
      return this.displayModel(this.currentModel);
    });
    this.handleOverlayPanelDisplayed();
  }

  public openCopyWorkout(): void {
    const vm = this.panelManager().openNewWorkoutPanel(this.panelId, this.currentModel as Workout);
    vm.completed
      .done(() => this.updateViewProps({ isVisible: false }))
      .fail(() => this.updateViewProps({ isVisible: true }));
    this.handleOverlayPanelDisplayed();
  }

  /**
   * Called after the history is displayed through {@link displayHistoryPanel}
   * @param historyViewModel
   */
  protected handleHistoryDisplay(historyViewModel: IHistoryViewModel): void {
    if (this.isMobile()) {
      historyViewModel.focusHistoryPanel();
      this.handleOverlayPanelDisplayed();
    } else {
      historyViewModel.focusHistoryPanel();
    }
  }

  /**
   * Subclass should update with handling required when a model is changed or updated.
   */
  protected handleModelChangeOrUpdate(): void {
    if (this.isMobile()) {
      this.displayHistoryInSelection();
    } else {
      if (_.contains(['exercise', 'exercise_set', 'exercise_definition'], this.currentModel.type)) {
        this.displayHistoryPanel();
      }
    }
  }

  protected handleOverlayPanelDisplayed(): void {
    if (this.isMobile()) {
      this.updateViewProps({ isVisible: false });
    }
  }

  protected displayModel(model: Model): void {
    this.updateViewProps({ model, isVisible: true });
  }

  /**
   * Gets the Set Index value for the provided Model.
   * @param model
   * @returns False if Exercise, Position index otherwise.
   */
  protected getSetIndex(model: Exercise | ExerciseSet): number | null {
    if (model instanceof Exercise) {
      return null;
    }
    const setGroups = model
      .setGroup()
      .exercise()
      .children() as SetGroup[];
    return setGroups.indexOf(model.setGroup());
  }

  /**
   * Gets the Exercise for the provided model.
   * @param model Model to retrieve the Exercise for.
   * @returns
   */
  protected getExercise(model: Exercise | ExerciseSet): Exercise {
    if (model instanceof Exercise) {
      return model;
    }
    return model.setGroup().exercise();
  }

  protected updateViewProps(props: Partial<ISelectionContainerProps>): void {
    if (this.panelId != null) {
      this.panelStateManager.updatePanelSelectionProps(this.panelId, props);
    } else {
      this.panelStateManager.updateSharedSelectionProps(props);
    }
  }

  /**
   * Called when the selected exercise' definition has changed (or a new exercise has been added).
   * Added so that mobile selection view models can avoid displaying the history panel.
   */
  protected handleDefinitionSelection(): void {
    if (!this.isMobile()) {
      this.displayHistoryPanel();
    }
  }

  protected panelManager(): IPanelManagerViewModel {
    return this._panelManager;
  }

  protected modelService(): IModelService {
    return this._modelService;
  }

  protected promiseService(): IPromiseService {
    return this.application.promiseService;
  }

  private getSelectionProps(): ISelectionContainerProps {
    let result;
    result =
      this.panelId == null
        ? this.panelStateManager.getSharedSelectionProps()
        : this.panelStateManager.getPanelProps(this.panelId).selectionProps;
    if (result == null) {
      log.warn('no selection props available', this.panelId || 'no panel ID');
    }
    return result;
  }

  private operateDefinitionSelectionViewModel(
    exercise: Exercise | ExerciseGroup,
    mode: SelectionModes,
  ) {
    const viewModel = this.createDefinitionSelectionViewModel(exercise, mode);
    viewModel.completed
      .fail(() => {
        this.displayModel(this.currentModel);
      })
      .done(() => {
        this.updateViewProps({ isVisible: false });
        this.handleDefinitionSelection();
      });
    this.handleOverlayPanelDisplayed();
    return viewModel.completed;
  }

  private createDefinitionSelectionViewModel(
    exercise: Exercise | ExerciseGroup,
    mode: SelectionModes,
  ): IPanelPromise<any> {
    return this.panelManager().openDefinitionSelectorPanel(exercise, mode, this.panelId);
  }

  private getModelToDelete(model: Model): Model {
    if (model instanceof ExerciseSet) {
      return this.getSetGroupToDelete(model);
    } else if (model instanceof Exercise) {
      return this.getExerciseToDelete(model);
    }
    return model;
  }

  private getSetGroupToDelete(exerciseSet: ExerciseSet): Model {
    const setGroup: SetGroup = exerciseSet.setGroup();
    const isLastSet: boolean = setGroup.children().length === 1;
    return isLastSet ? setGroup : exerciseSet;
  }

  private getExerciseToDelete(exercise: Exercise): Model {
    const exerciseGroup: ExerciseGroup = exercise.parent() as ExerciseGroup;
    const isLastExercise: boolean = exerciseGroup.children().length === 1;
    return isLastExercise ? exerciseGroup : exercise;
  }

  private getContextRecords(model: Model) {
    const result: any = {};
    result[model.type] = model;
    switch (model.type) {
      case 'exercise':
        result.exercise_group = model.parent();
        break;
      case 'exercise_set':
        result.set_group = model.parent();
        break;
      default:
    }
    return result;
  }

  private handleNavigationChange(): void {
    const model = this.currentModel;
    if (this.isExerciseSet(model)) {
      const workout: Workout = model.workout();
      this.navigationManager.populateList(workout);
      this.navigationManager.setCurrentItem(model);
    } else {
      this.navigationManager.clearNavigation();
    }
  }
  private isExerciseSet(model: Model): model is ExerciseSet {
    return model.type === 'exercise_set';
  }

  private isMobile(): boolean {
    return this._panelManager.isMobile();
  }

  private displayHistoryInSelection(): void {
    if (this.currentModel instanceof ExerciseDefinition) {
      const historyPromise = this.currentModel.history().all();
      historyPromise.done(members =>
        this.updateViewProps({
          history: members,
          setIndex: false,
        }),
      );
    } else if (this.currentModel instanceof ExerciseSet || this.currentModel instanceof Exercise) {
      const exercise = this.getExercise(this.currentModel);
      const historyPromise = exercise.history().all();
      const setIndex = this.getSetIndex(this.currentModel);
      historyPromise.done(members => this.updateViewProps({ history: members, setIndex }));
    }
  }
}
