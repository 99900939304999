import * as React from 'react';
import { ILocalizationService } from '../../../../Services/ILocalizationService';
import { DefinitionSelectionMode } from './DefinitionSelectionMode';

export interface IModeTabsProps {
  localizationService: ILocalizationService;
  selectionMode: DefinitionSelectionMode;
  onChangeMode: (newMode: DefinitionSelectionMode) => void;
}

export interface IModeTabsState {}

const SELECTOR_OPEN_CLASS = ' selector-open';

export class ModeTabs extends React.PureComponent<IModeTabsProps, IModeTabsState> {
  public render(): JSX.Element {
    let existingTabClasses = 'definition-selector-mode-option existing-mode';
    let newTabClasses = 'definition-selector-mode-option new-mode';
    if (this.props.selectionMode === DefinitionSelectionMode.Existing) {
      existingTabClasses += SELECTOR_OPEN_CLASS;
    } else {
      newTabClasses += SELECTOR_OPEN_CLASS;
    }
    return (
      <ul className="definition-selector-mode">
        <li
          data-help="definition_select.existing_tab"
          onClick={this.handleExistingClick}
          className={existingTabClasses}
        >
          {this.props.localizationService.getValue('definitions.select.existing_exercise_tab')}
        </li>
        <li
          className={newTabClasses}
          onClick={this.handleNewClick}
          data-help="definition_select.new_tab"
        >
          {this.props.localizationService.getValue('definitions.select.new_exercise_tab')}
        </li>
      </ul>
    );
  }

  private handleExistingClick = (): void => {
    this.props.onChangeMode(DefinitionSelectionMode.Existing);
  };

  private handleNewClick = (): void => {
    this.props.onChangeMode(DefinitionSelectionMode.New);
  };
}
