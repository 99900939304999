import * as React from 'react';
import * as _ from 'underscore';
import { Exercise } from '../../../../../Models/Exercise';
import { IExerciseTempo } from '../../../../../Models/IExerciseTempo';
import { ValueValidator } from '../../../Helpers/ValueValidator';
import { FormField } from '../FormField';
import { FormTextArea } from '../FormTextArea';
import { IObjectSectionProps, ObjectSection } from '../ObjectSection';
import { SectionWrapper } from '../SectionWrapper';
import { TempoComponent } from './TempoComponent';

export interface IExerciseSectionProps extends IObjectSectionProps<Exercise> {
  showBarWeight: boolean;
  showRestBetweenExercises: boolean;
  showRestBetweenSets: boolean;
}

export interface IExerciseSectionState {
  restBetweenExercises: string;
  restBetweenSets: string;
  status: ModelStatus;
  instructions: string;
  review: string;
  barWeight: string;
  tempoEccentric: string;
  tempoConcentric: string;
  tempoRest: string;
  tempoPause: string;
}

export class ExerciseSection extends ObjectSection<
  Exercise,
  IExerciseSectionProps,
  IExerciseSectionState
> {
  private restSetValidator = ValueValidator.durationValidator(() => this.state.restBetweenSets);
  private restExerciseValidator = ValueValidator.durationValidator(
    () => this.state.restBetweenExercises,
  );
  private barWeightValidator = ValueValidator.weightValidator(() => this.state.barWeight);
  private instructionsValidator = ValueValidator.lengthValidator(
    0,
    2000,
    () => this.state.instructions,
  );
  private reviewValidator = ValueValidator.lengthValidator(0, 2000, () => this.state.review);
  private concentricValidator = ValueValidator.tempoValueValidator(
    () => this.state.tempoConcentric,
  );
  private eccentricValidator = ValueValidator.tempoValueValidator(() => this.state.tempoEccentric);
  private pauseValidator = ValueValidator.tempoValueValidator(() => this.state.tempoPause);
  private restValidator = ValueValidator.tempoValueValidator(() => this.state.tempoRest);

  public constructor(props: IExerciseSectionProps) {
    super(props);
    const exerciseGroup = props.model.exerciseGroup();
    const exercise = props.model;
    this.state = {
      barWeight: exercise.bar_weight || '',
      instructions: exercise.instructions || '',
      restBetweenExercises: exerciseGroup.rest_exercise || '',
      restBetweenSets: exerciseGroup.rest_set || '',
      review: exercise.note_content || '',
      status: exercise.status,
      tempoConcentric: exercise.concentric || '',
      tempoEccentric: exercise.eccentric || '',
      tempoPause: exercise.pause || '',
      tempoRest: exercise.rest || '',
    };
  }

  private get tempoValidators(): ValueValidator<string>[] {
    return [
      this.concentricValidator,
      this.eccentricValidator,
      this.pauseValidator,
      this.restValidator,
    ];
  }

  private get isTempoValid(): boolean {
    const validators = this.tempoValidators;
    return _.all(validators, validator => validator.isValid());
  }

  private get tempoError(): string {
    const errorStrings = _.map(this.tempoValidators, v => v.error());
    return _.uniq(_.compact(errorStrings)).join('');
  }

  public render(): JSX.Element {
    return (
      <div>
        {this.props.showRestBetweenSets && (
          <SectionWrapper iconClass="exercise-group-rest-set-icon">
            <FormField
              value={this.state.restBetweenSets}
              valueError={this.restSetValidator.error()}
              updateAction={this.updateRestBetweenSets}
              isValid={this.restSetValidator.isValid()}
              propertyName="rest_set"
              modelName="exercise_group"
              placeholder=""
              labelPath="object.exercise_group.rest_set"
            />
          </SectionWrapper>
        )}
        {this.props.showRestBetweenExercises && (
          <SectionWrapper iconClass="exercise-group-rest-exercise-icon">
            <FormField
              value={this.state.restBetweenExercises}
              valueError={this.restExerciseValidator.error()}
              updateAction={this.updateRestBetweenExercises}
              placeholder=""
              modelName="exercise_group"
              propertyName="rest_exercise"
              labelPath="object.exercise_group.rest_exercise"
              isValid={this.restExerciseValidator.isValid()}
            />
          </SectionWrapper>
        )}
        {this.props.showBarWeight && (
          <SectionWrapper iconClass="exercise-bar-weight-icon">
            <FormField
              value={this.state.barWeight}
              isValid={this.barWeightValidator.isValid()}
              propertyName="bar_weight"
              modelName="exercise"
              placeholder=""
              labelPath="object.exercise.bar_weight"
              updateAction={this.updateBarWeight}
              valueError={this.barWeightValidator.error()}
            />
          </SectionWrapper>
        )}
        <SectionWrapper>
          <FormTextArea
            value={this.state.instructions}
            isValid={this.instructionsValidator.isValid()}
            valueError={this.instructionsValidator.error()}
            labelPath="object.exercise.instructions"
            updateAction={this.updateInstructions}
            placeholder=""
            modelName="exercise"
            propertyName="instructions"
          />
        </SectionWrapper>
        <SectionWrapper>
          <FormTextArea
            value={this.state.review}
            isValid={this.reviewValidator.isValid()}
            valueError={this.reviewValidator.error()}
            labelPath="object.exercise.note_content"
            updateAction={this.updateReviewContents}
            placeholder=""
            modelName="exercise"
            propertyName="note_content"
          />
        </SectionWrapper>
        <SectionWrapper>
          <TempoComponent
            eccentric={this.state.tempoEccentric}
            eccentricValid={this.eccentricValidator.isValid()}
            concentricValid={this.concentricValidator.isValid()}
            concentric={this.state.tempoConcentric}
            rest={this.state.tempoRest}
            restValid={this.restValidator.isValid()}
            pauseValid={this.pauseValidator.isValid()}
            pause={this.state.tempoPause}
            updateValue={this.updateTempo}
          />
          {!this.isTempoValid && <div className="field-error">{this.tempoError}</div>}
        </SectionWrapper>
      </div>
    );
  }

  protected updateFormData(): void {
    const updateData = [
      this.constructUpdate('exercise', 'note_content', 'review'),
      this.constructUpdate('exercise', 'instructions', 'instructions'),
      this.constructUpdate('exercise', 'eccentric', 'tempoEccentric'),
      this.constructUpdate('exercise', 'concentric', 'tempoConcentric'),
      this.constructUpdate('exercise', 'rest', 'tempoRest'),
      this.constructUpdate('exercise', 'pause', 'tempoPause'),
      this.constructUpdate('exercise', 'bar_weight', 'barWeight'),
      this.constructUpdate('exercise_group', 'rest_set', 'restBetweenSets'),
      this.constructUpdate('exercise_group', 'rest_exercise', 'restBetweenExercises'),
    ];
    this.props.updateFormData(updateData);
    this.props.updateValidity(this.isValid());
  }

  private isValid(): boolean {
    const barWeightValid = !this.props.showBarWeight || this.barWeightValidator.isValid();
    const restSetValid = !this.props.showRestBetweenSets || this.restSetValidator.isValid();
    const restExerciseValid =
      !this.props.showRestBetweenExercises || this.restExerciseValidator.isValid();
    const instructionsValid = this.instructionsValidator.isValid();
    const reviewValid = this.reviewValidator.isValid();
    return _.all([
      barWeightValid,
      restSetValid,
      restExerciseValid,
      instructionsValid,
      reviewValid,
      this.isTempoValid,
    ]);
  }

  private updateBarWeight = (newValue: string): void => {
    this.setState({ barWeight: newValue });
  };

  private updateRestBetweenSets = (newValue: string): void => {
    this.setState({ restBetweenSets: newValue });
  };

  private updateRestBetweenExercises = (newValue: string): void => {
    this.setState({ restBetweenExercises: newValue });
  };

  private updateInstructions = (newValue: string): void => {
    this.setState({ instructions: newValue });
  };

  private updateReviewContents = (newValue: string): void => {
    this.setState({ review: newValue });
  };

  private updateTempo = (newValue: IExerciseTempo): void => {
    this.setState({
      tempoConcentric: newValue.concentric,
      tempoEccentric: newValue.eccentric,
      tempoPause: newValue.pause,
      tempoRest: newValue.rest,
    });
  };
}
