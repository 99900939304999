import * as React from 'react';

export interface IReadOnlyContainerProps {
  inputClass: string;
  labelClass: string;
  label: string;
  contents: string;
}

export interface IReadOnlyContainerState {}

export class ReadOnlyContainer extends React.PureComponent<
  IReadOnlyContainerProps,
  IReadOnlyContainerState
> {
  public render(): JSX.Element {
    const classes = `sv-field ${this.props.inputClass}`;
    const labelClasses = `sv-textbox ${this.props.labelClass}`;
    return (
      <label className={labelClasses}>
        <span className="sv-label-text">{this.props.label}</span>
        <div className={classes}>{this.props.contents}</div>
      </label>
    );
  }
}
