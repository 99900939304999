import * as React from 'react';
import { IExercise } from '../../../../Models/IExercise';
import { ILocalizedComponentProps, LocalizedPureComponent } from '../../../LocalizedComponent';

export interface IExerciseListProps extends ILocalizedComponentProps {
  exercises: IExercise[];
}

export interface IExerciseListState {}

const EXERCISE_LIST_PATH = 'mobile_selection_view.workout.exercises_title';

/**
 * The ExerciseList renders a list of exercises for the Workout Selection view.
 */
export class ExerciseList extends LocalizedPureComponent<IExerciseListProps, IExerciseListState> {
  public render(): JSX.Element {
    const exercises = this.props.exercises.map(e => {
      const ed = e.exerciseDefinition();
      const categoryIconClassName = `category-${ed.category}-icon`;
      return (
        <li className="wes-entry" key={e.id}>
          <i className={categoryIconClassName} /> {ed.name}
        </li>
      );
    });
    return (
      <div className="msv-section">
        <div className="msvSectionTitle">{this.localize(EXERCISE_LIST_PATH)}</div>
        <ul className="wes-list">{exercises}</ul>
      </div>
    );
  }
}
