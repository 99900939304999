import * as React from 'react';
import { ExerciseDefinition } from '../../../../Models/ExerciseDefinition';
import { IExerciseHistory } from '../../../../Models/History/IExerciseHistory';
import { HistoryContainer } from '../History/HistoryContainer';
import { CommandsSection } from '../Shared/CommandsSection';
import { NoteElement } from '../Shared/NoteElement';
import {
  ISelectionProps,
  ISelectionState,
  Selection as ObjectSelection,
} from '../Shared/Selection';
import { Title } from '../Shared/Title';
import { DefinitionAttributes } from './DefinitionAttributes';

export interface IDefinitionSelectionProps extends ISelectionProps<ExerciseDefinition> {
  history: IExerciseHistory[];
  historyButtonVisible: boolean;
}

export interface IDefinitionSelectionState extends ISelectionState {
  deleteDeniedVisible: boolean;
  reminderEditing: boolean;
  reminderContents: string;
}

export class DefinitionSelection extends ObjectSelection<
  ExerciseDefinition,
  IDefinitionSelectionProps,
  IDefinitionSelectionState
> {
  public constructor(props: IDefinitionSelectionProps) {
    super(props);
    this.state = {
      deleteDeniedVisible: false,
      deleteVisible: false,
      reminderContents: props.model.note_content,
      reminderEditing: false,
    };
  }

  public componentWillReceiveProps(
    nextProps: Readonly<IDefinitionSelectionProps>,
    nextContext: any,
  ): void {
    const updateObject: Partial<IDefinitionSelectionState> = {
      reminderContents: nextProps.model.note_content,
    };
    if (nextProps.isVisible === false) {
      updateObject.reminderEditing = false;
      updateObject.deleteDeniedVisible = false;
      updateObject.deleteVisible = false;
    }
    this.setState(updateObject as any);
  }

  public render(): JSX.Element {
    return (
      <div>
        <div className="sv-section">
          <Title title={this.props.model.name} labelClass="sv-definition-name" />
          <DefinitionAttributes
            implement={this.props.model.implement}
            category={this.props.model.category}
            sided={this.props.model.sided}
          />
          <NoteElement
            inputClass="sv-definition-reminder"
            onChange={this.handleReminderChanges}
            label={this.localize('object.exercise_definition.note_content')}
            contents={this.state.reminderContents}
            isEditing={this.state.reminderEditing}
            startEditing={this.startReminderEditing}
            cancelEditing={this.cancelReminderEditing}
            applyChanges={this.applyReminderChanges}
            containerClass="sv-reminder-container"
          />
          <CommandsSection
            viewModel={this.props.viewModel}
            deletePromptVisible={this.state.deleteVisible}
            deleteDeniedVisible={this.state.deleteDeniedVisible}
            updateDeleteVisibility={this.updateDeleteVisible}
            executeDelete={this.executeDelete}
            deleteWarning={this.localize('mobile_selection_view.delete_definition_confirm.warning')}
            deleteConfirm={this.localize('mobile_selection_view.delete_definition_confirm.confirm')}
            deleteCancel={this.localize('mobile_selection_view.delete_definition_confirm.cancel')}
            deleteNotPermitted={this.localize(
              'mobile_selection_view.delete_definition_not_permitted.message',
            )}
          >
            {this.props.historyButtonVisible && (
              <button className="xbtn xbtn-primary sv-history" onClick={this.handleDisplayHistory}>
                {this.localize('mobile_selection_view.show_history')}
              </button>
            )}
          </CommandsSection>

          {this.props.history && (
            <HistoryContainer history={this.props.history} highlightPosition={false} />
          )}
        </div>
      </div>
    );
  }

  protected updateDeleteVisible = (newValue: boolean) => {
    if (newValue === this.isVisible()) {
      return;
    } else if (newValue === false) {
      this.setState({ deleteVisible: false, deleteDeniedVisible: false });
    } else {
      this.props.changeSpinnerVisibility(true);
      const deletePromise = this.props.model.isDeletePermitted();
      deletePromise.done((deletePermitted: boolean) => {
        this.props.changeSpinnerVisibility(false);
        deletePermitted
          ? this.setState({ deleteVisible: true })
          : this.setState({ deleteDeniedVisible: true });
      });
    }
  };

  private handleDisplayHistory = (): void => {
    this.props.changeSpinnerVisibility(true);
    const done = () => this.props.changeSpinnerVisibility(false);
    this.props.viewModel.displayHistoryPanel().then(done, done);
  };

  private isVisible(): boolean {
    return this.state.deleteVisible || this.state.deleteDeniedVisible;
  }

  private handleReminderChanges = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    this.setState({ reminderContents: event.target.value });
  };

  private applyReminderChanges = (): void => {
    this.props.changeSpinnerVisibility(true);
    const promise = this.props.viewModel.updateModelProperty(
      this.state.reminderContents,
      'note_content',
    );
    promise
      .always(() => this.props.changeSpinnerVisibility(false))
      .done(() => this.setState({ reminderEditing: false }));
  };

  private cancelReminderEditing = (): void => {
    if (this.state.reminderEditing === false) {
      return;
    }
    this.setState({ reminderEditing: false, reminderContents: this.props.model.note_content });
  };

  private startReminderEditing = (): void => {
    if (this.state.reminderEditing) {
      return;
    }
    this.setState({ reminderEditing: true });
  };
}
