import * as moment from 'moment';

const intervalStart = moment().subtract(6, 'days');
const intervalEnd = moment().add(6, 'days');

export class FormatDate {
  public static relative(date: string): string {
    const plannedDate = moment(date);
    if (plannedDate.isBetween(intervalStart, intervalEnd)) {
      return plannedDate.calendar();
    }
    return plannedDate.format('llll');
  }
}
