import * as React from 'react';
import { RecordCollection } from '../../../../Models/Collections/RecordCollection';
import { User } from '../../../../Models/User';
import { Workout } from '../../../../Models/Workout';
import { IWorkoutListGroup } from '../../../../ViewModels/Panels/WorkoutListGroup';
import { ILocalizedComponentProps, LocalizedPureComponent } from '../../../LocalizedComponent';
import { Group } from './Group';

export interface IWorkoutListActionProps {
  addAction: () => void;
  moreAction: () => void;
  openAction: (workout: Workout) => Promise<any>;
  selectAction: (workout: Workout) => void;
}

export interface IListProps extends ILocalizedComponentProps {
  workoutGroups: IWorkoutListGroup[];
  hasMoreAvailable: boolean;
  collection: RecordCollection<Workout, User>;
}

interface IListState {}

const ADD_WORKOUT_LABEL_PATH = 'inline.add_workout';
const GET_MORE_WORKOUTS_LABEL_PATH = 'inline.get_more_workouts';

export class List extends LocalizedPureComponent<IListProps & IWorkoutListActionProps, IListState> {
  public render(): JSX.Element {
    return (
      <div>
        <button
          className="add-workout ia-action new-workout"
          data-action="add-workout"
          data-module="workoutList"
          onClick={this.props.addAction}
        >
          <div className="command-icon">
            <div className="difficulty">
              <i />
              <span />
            </div>
          </div>
          <div className="main-section">
            <i />
            {this.localize(ADD_WORKOUT_LABEL_PATH)}
          </div>
        </button>
        <div className="workout-lists">{this.renderGroups()}</div>
        {this.props.hasMoreAvailable && (
          <button
            className="get-more ia-action"
            data-action="get-more"
            data-module="workoutList"
            onClick={this.props.moreAction}
          >
            <div className="command-icon">
              <div className="difficulty">
                <i />
                <span />
              </div>
            </div>
            <div className="main-section">
              <i />
              {this.localize(GET_MORE_WORKOUTS_LABEL_PATH)}
            </div>
          </button>
        )}
      </div>
    );
  }

  private renderGroups(): JSX.Element[] {
    return this.props.workoutGroups.map(group => {
      return (
        <Group
          groupEntries={group.workouts}
          title={group.title}
          groupCode={group.period}
          key={group.period}
          openAction={this.props.openAction}
          selectAction={this.props.selectAction}
        />
      );
    });
  }
}
