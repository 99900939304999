import * as React from 'react';
import { Workout } from '../../../../Models/Workout';
import { Item } from './Item';

interface IGroupProps {
  title: string;
  groupCode: string;
  groupEntries: Workout[];
  openAction: (workout: Workout) => Promise<any>;
  selectAction: (workout: Workout) => void;
}

interface IGroupState {}

export class Group extends React.PureComponent<IGroupProps, IGroupState> {
  public render(): JSX.Element {
    return (
      <div className="workout-list" data-list-group={this.props.groupCode}>
        <div className="list-group-header">{this.props.title}</div>
        <div className="list-group-contents">{this.renderItems()}</div>
      </div>
    );
  }

  private renderItems(): JSX.Element[] {
    return this.props.groupEntries.map(workout => {
      return (
        <Item
          key={workout.id}
          largestCategory={workout.largest_category}
          name={workout.name}
          status={workout.status}
          id={workout.id}
          plannedDate={workout.planned_date}
          onSelect={this.props.selectAction}
          onOpen={this.props.openAction}
          workout={workout}
        />
      );
    });
  }
}
