import { Exercise } from './Exercise';
import { ExerciseSet } from './ExerciseSet';
import { IWorkoutDescendant } from './IWorkoutDescendant';
import { Model } from './Model';
import { Workout } from './Workout';

export class SetGroup extends Model implements IWorkoutDescendant {
  public position: number;
  public rest_period: string;
  public _childType: ModelType = 'exercise_set';
  public _childReferenceProperty = 'set_group_id';
  public _childCollectionName = 'exercise_sets';
  public _parentType: ModelType = 'exercise';
  public _parentReferenceProperty = 'exercise_id';

  public workout(): Workout {
    return this.exercise().workout();
  }

  /**
   * Returns a position-ordered array of this model's exercise sets.
   * @returns {ExerciseSet[]}
   */
  public exerciseSets(): ExerciseSet[] {
    return this.children() as ExerciseSet[];
  }

  public exercise(): Exercise {
    return this.parent() as Exercise;
  }
}
