import * as _ from 'underscore';
import { Workout } from '../../../Models/Workout';
import Dictionary = _.Dictionary;
import { ILocalizationService } from '../../../Services/ILocalizationService';
import { IPanelProps, IPanelViewModelProps } from '../Panels/IPanelProps';
import { IMenuConfiguration } from './Menus/IMenuConfiguration';
import { IMenuEntryProps } from './Menus/IMenuEntryProps';

export const EXERCISE_DEFINITON_PANEL_ID = 'definition-list';
export const HELP_PANEL_ID = 'help';
export const WORKOUT_LIST_ID = 'workout-list';
export const FIXED_LIST_CLASSES = 'menu-fixed-items';
export const VARIABLE_LIST_CLASSES = 'menu-variable-items';
export const SECONDARY_LIST_CLASSES = 'menu-secondary-items';

interface IMenuActions {
  handleOpenDefinition(): void;
  handleOpenHelp(): void;
  handleFocus(panelId: string): void;
  handleClose(panelId: string): void;
}

type PanelProps = Dictionary<IPanelViewModelProps<any, any>>;

function buildWorkoutList(
  panel: IPanelViewModelProps<any, any>,
  ls: ILocalizationService,
  menuActions: IMenuActions,
): IMenuEntryProps {
  return {
    additionalClasses: 'open-workout-list',
    clickAction: () => menuActions.handleFocus(WORKOUT_LIST_ID),
    dismissAction: null,
    isDismissable: false,
    isOpen: true,
    panelId: WORKOUT_LIST_ID,
    panelObjectId: null,
    panelTitle: ls.getValue('menu.workout_list'),
    panelType: WORKOUT_LIST_ID,
  };
}

function buildExerciseList(
  panel: IPanelViewModelProps<any, any>,
  ls: ILocalizationService,
  menuActions: IMenuActions,
): IMenuEntryProps {
  const panelTitle = ls.getValue('menu.definition_list');
  const clickAction =
    panel == null
      ? menuActions.handleOpenDefinition
      : () => menuActions.handleFocus(EXERCISE_DEFINITON_PANEL_ID);
  const dismissAction =
    panel == null ? null : () => menuActions.handleClose(EXERCISE_DEFINITON_PANEL_ID);
  return {
    additionalClasses: 'open-definition-list',
    clickAction,
    dismissAction,
    isDismissable: true,
    isOpen: panel != null,
    panelId: EXERCISE_DEFINITON_PANEL_ID,
    panelObjectId: null,
    panelTitle,
    panelType: EXERCISE_DEFINITON_PANEL_ID,
  };
}

function buildFixedItems(
  panels: PanelProps,
  ls: ILocalizationService,
  menuActions: IMenuActions,
): IMenuEntryProps[] {
  return [
    buildWorkoutList(panels[WORKOUT_LIST_ID], ls, menuActions),
    buildExerciseList(panels[EXERCISE_DEFINITON_PANEL_ID], ls, menuActions),
  ];
}

function buildHelpItem(
  panel: IPanelViewModelProps<any, any>,
  ls: ILocalizationService,
  menuActions: IMenuActions,
): IMenuEntryProps {
  const clickAction =
    panel == null ? menuActions.handleOpenHelp : () => menuActions.handleFocus(HELP_PANEL_ID);
  const dismissAction = panel == null ? null : () => menuActions.handleClose(HELP_PANEL_ID);
  return {
    additionalClasses: '',
    clickAction,
    dismissAction,
    isDismissable: true,
    isOpen: panel != null,
    panelId: HELP_PANEL_ID,
    panelObjectId: null,
    panelTitle: ls.getValue('menu.help_panel'),
    panelType: HELP_PANEL_ID,
  };
}

function buildSecondaryItems(
  panels: PanelProps,
  ls: ILocalizationService,
  menuActions: IMenuActions,
): IMenuEntryProps[] {
  return [buildHelpItem(panels.help, ls, menuActions)];
}

function buildVariableItem(panel: IPanelProps<any>, menuActions: IMenuActions): IMenuEntryProps {
  const clickAction = () => menuActions.handleFocus(panel.data.id);
  const dismissAction = () => menuActions.handleClose(panel.data.id);
  const model = panel.props.model;
  const workout = model instanceof Workout ? model : null;
  return {
    additionalClasses: '',
    clickAction,
    dismissAction,
    isDismissable: true,
    isOpen: true,
    panelId: panel.data.id,
    panelObjectId: panel.data.objectId,
    panelTitle: panel.data.title,
    panelType: panel.data.type,
    workout,
  };
}

function buildVariableItems(
  panels: PanelProps,
  ls: ILocalizationService,
  menuActions: IMenuActions,
): IMenuEntryProps[] {
  const variablePanels = _.omit(
    panels,
    WORKOUT_LIST_ID,
    EXERCISE_DEFINITON_PANEL_ID,
    HELP_PANEL_ID,
  );
  const sortedItems = _.values(variablePanels).sort((a: IPanelProps<any>, b: IPanelProps<any>) => {
    return a.position - b.position;
  });
  return sortedItems.map(item => buildVariableItem(item, menuActions));
}

export function buildMenu(
  panels: PanelProps,
  localizationService: ILocalizationService,
  menuActions: IMenuActions,
): IMenuConfiguration {
  return {
    fixedClass: FIXED_LIST_CLASSES,
    fixedItems: buildFixedItems(panels, localizationService, menuActions),
    secondaryClass: SECONDARY_LIST_CLASSES,
    secondaryItems: buildSecondaryItems(panels, localizationService, menuActions),
    variableClass: VARIABLE_LIST_CLASSES,
    variableItems: buildVariableItems(panels, localizationService, menuActions),
  };
}
