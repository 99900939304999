import * as React from 'react';
import { VelocityComponent } from 'velocity-react';

require('./SpecificationEntry.scss');

export interface ISpecificationEntryProps {
  targetValue: string;
  actualValue: string;
  targetSelectable: boolean;
  actualSelectable: boolean;
  unit: string;
  specificationIndex: number;
  selectAction: (index: number, value: 'target' | 'actual') => void;
  isSelected: 'target' | 'actual' | null;
}

export interface ISpecificationEntryState {}

const selectedAnimation = 'callout.pulse';
const selectedDuration = 250;

const targetBaseClasses = 'sv-parameter sv-parameter-target';
const actualBaseClasses = 'sv-parameter sv-parameter-actual';
const selectableClass = ' sv-parameter-selectable';
const targetSelectableClasses = targetBaseClasses + selectableClass;
const actualSelectableClasses = actualBaseClasses + selectableClass;
const selectedClass = ' sv-parameter-selected';

export class SpecificationEntry extends React.PureComponent<
  ISpecificationEntryProps,
  ISpecificationEntryState
> {
  private targetComponent: VelocityComponent;
  private actualComponent: VelocityComponent;

  public render(): JSX.Element {
    const targetClasses = this.getTargetClasses();
    const actualClasses = this.getActualClasses();
    return (
      <div className="msvSpecificationEntry">
        <VelocityComponent
          ref={input => (this.targetComponent = input)}
          animation={selectedAnimation}
          duration={selectedDuration}
        >
          <div className={targetClasses} onClick={this.handleTargetClick}>
            {this.props.targetValue}
          </div>
        </VelocityComponent>
        <div className="sv-parameter-unit">{this.props.unit}</div>
        <VelocityComponent
          animation={
            this.props.actualSelectable ? 'transition.slideRightIn' : 'transition.slideRightOut'
          }
          duration={500}
          display={'block'}
        >
          <VelocityComponent
            ref={input => (this.actualComponent = input)}
            animation={selectedAnimation}
            duration={selectedDuration}
          >
            <div className={actualClasses} onClick={this.handleActualClick}>
              {this.props.actualValue}
            </div>
          </VelocityComponent>
        </VelocityComponent>
      </div>
    );
  }

  public componentWillReceiveProps(
    nextProps: Readonly<ISpecificationEntryProps>,
    nextContext: any,
  ): void {
    if (this.isTargetChanging(nextProps)) {
      this.targetComponent.runAnimation();
    } else if (this.isActualChanging(nextProps)) {
      this.actualComponent.runAnimation();
    }
  }

  private isTargetChanging(nextProps: Readonly<ISpecificationEntryProps>): boolean {
    return (
      (nextProps.isSelected === 'target' && this.props.isSelected !== 'target') ||
      (nextProps.isSelected === 'target' && nextProps.targetValue !== this.props.targetValue)
    );
  }

  private isActualChanging(nextProps: Readonly<ISpecificationEntryProps>): boolean {
    return (
      (nextProps.isSelected === 'actual' && this.props.isSelected !== 'actual') ||
      (nextProps.isSelected === 'actual' && nextProps.actualValue !== this.props.actualValue)
    );
  }

  private getActualClasses(): string {
    const actualClasses = actualSelectableClasses; // Left In Selectable State to Animate State Changes
    return this.props.isSelected === 'actual' ? actualClasses + selectedClass : actualClasses;
  }

  private getTargetClasses(): string {
    const targetClasses = this.props.targetSelectable ? targetSelectableClasses : targetBaseClasses;
    return this.props.isSelected === 'target' ? targetClasses + selectedClass : targetClasses;
  }

  private handleTargetClick = (): void => {
    this.props.selectAction(this.props.specificationIndex, 'target');
  };

  private handleActualClick = (): void => {
    if (this.props.actualSelectable) {
      this.props.selectAction(this.props.specificationIndex, 'actual');
    }
  };
}
