import * as React from 'react';
import { LocalizedPureComponent } from '../../../LocalizedComponent';

export interface ITempoDisplayProps {
  eccentric: string;
  concentric: string;
  rest: string;
  pause: string;
}

export interface ITempoDisplayState {}

export class TempoDisplay extends LocalizedPureComponent<ITempoDisplayProps, ITempoDisplayState> {
  public render(): JSX.Element {
    return (
      <div className="sv-tempo-container">
        <div className="sv-section-subtitle">
          {this.localize('configuration.exercise.tempo.title')}
        </div>
        <div className="sv-horizontal-group">
          <div>
            <div className="sv-exercise-tempo-eccentric sv-field">{this.props.eccentric}</div>
            <span className="sv-label-text">
              {this.localize('configuration.exercise.tempo.eccentric.title')}
            </span>
          </div>
          <div>
            <div className="sv-exercise-tempo-concentric sv-field">{this.props.concentric}</div>
            <span className="sv-label-text">
              {this.localize('configuration.exercise.tempo.concentric.title')}
            </span>
          </div>
          <div>
            <div className="sv-exercise-tempo-rest sv-field">{this.props.rest}</div>
            <span className="sv-label-text">
              {this.localize('configuration.exercise.tempo.rest.title')}
            </span>
          </div>
          <div>
            <div className="sv-exercise-tempo-pause sv-field">{this.props.pause}</div>
            <span className="sv-label-text">
              {this.localize('configuration.exercise.tempo.pause.title')}
            </span>
          </div>
        </div>
      </div>
    );
  }
}
