import { CollectionResultSet } from '../../Data/CollectionResultSet';
import { Model } from '../Model';
import { Collection } from './Collection';

export class RecordCollection<T, TParent extends Model> extends Collection<TParent, T> {
  public members(): T[] {
    // TODO: Figure out typing for this. I'm not actually returning an array.
    return this._dataLayer.getAllObjects(this.type) as any;
  }

  public more() {
    const newPromise = this._promiseService.emptyPromise();
    if (this.isMoreAvailable()) {
      const dataPromise = this._dataLayer.loadRecordCollection(this._moreURL);
      dataPromise.done(collectionSet => {
        this.updateCollection(collectionSet);
        return newPromise.resolve(collectionSet.newObjects);
      });
      dataPromise.fail(() => {
        return newPromise.reject;
      });
    } else {
      newPromise.resolve([]);
    }
    return newPromise;
  }

  public reload() {
    this._moreURL = this._baseURL;
    const newPromise = this._promiseService.emptyPromise();
    const dataPromise = this._dataLayer.refreshRecordCollection(this._moreURL, this.type);
    dataPromise.done(collectionSet => {
      this.updateCollection(collectionSet);
      newPromise.resolve(collectionSet.newObjects);
    });
    dataPromise.fail(() => {
      newPromise.reject();
    });
    return newPromise;
  }

  /**
   * Creates a child object.
   * @param {Object} dataObject - Object containing the attributes for the new object.
   */
  public create(dataObject: any): JQueryPromise<any> {
    if (this.type === 'exercise-history' || this.type === 'definition-history') {
      return $.Deferred().reject();
    }
    return this._parentObject.createChildObject(this.type, dataObject, this._baseURL);
  }

  protected updateCollection(collectionSet: CollectionResultSet<T>) {
    return (this._moreURL = collectionSet.moreURL);
  }
}
