import * as _ from 'underscore';
import { Exercise } from '../../Models/Exercise';
import { ExerciseGroup } from '../../Models/ExerciseGroup';
import { ExerciseSet } from '../../Models/ExerciseSet';
import { SetGroup } from '../../Models/SetGroup';
import { Workout } from '../../Models/Workout';
import { IComposer } from './IComposer';

/**
 * The Composer class orders a workout's exercise sets into a list of sets to complete.
 */
export class Composer implements IComposer {
  private static setGroupIterator: _.ListIterator<SetGroup, ExerciseSet[]> = function(setGroup) {
    return setGroup.exerciseSets();
  };

  public createNavigationList(workout: Workout): ExerciseSet[] {
    const exerciseSetsNested: ExerciseSet[][] = _.map(
      workout.exerciseGroups(),
      (group: ExerciseGroup): ExerciseSet[] => {
        if (group.exercises().length > 1) {
          return this.createSuperSetList(group);
        } else {
          return this.createSimpleGroupList(group);
        }
      },
    );
    return _.flatten(exerciseSetsNested);
  }

  private createSimpleGroupList(group: ExerciseGroup): ExerciseSet[] {
    const exerciseIterator: _.ListIterator<Exercise, ExerciseSet[]> = function(exercise: Exercise) {
      const interim = _.map(exercise.setGroups(), Composer.setGroupIterator);
      return _.flatten(interim);
    };
    const nestedExercises: ExerciseSet[][] = _.map(group.exercises(), exerciseIterator);
    return _.flatten(nestedExercises);
  }

  private createSuperSetList(group: ExerciseGroup): ExerciseSet[] {
    const numberOfSets: number[] = _.map(group.exercises(), function(exercise): number {
      return exercise.setGroups().length;
    });
    const maxNumberOfSets: number = _.max(numberOfSets);
    const exercises: Exercise[] = group.exercises();
    const results: ExerciseSet[] = [];
    for (let roundNumber = 0; roundNumber < maxNumberOfSets; roundNumber++) {
      _.forEach(exercises, function(exercise) {
        const setGroup = exercise.setGroups()[roundNumber];
        if (setGroup) {
          results.push(...setGroup.exerciseSets());
        }
      });
    }
    return results;
  }
}
