import * as React from 'react';
import * as _ from 'underscore';
import Dictionary = _.Dictionary;
import {
  MaximumConfigurations,
  MaximumSpecifications,
} from '../../../../../ApplicationConfiguration';
import { ExerciseSet } from '../../../../../Models/ExerciseSet';
import { IConfiguration } from '../../../../../Models/IConfiguration';
import { IPerformanceSpecification } from '../../../../../Models/IPerformanceSpecification';
import convertConfiguration, {
  convertConfigurationObject,
} from '../../../../Utilities/ConvertConfiguration';
import { ValueValidator } from '../../../Helpers/ValueValidator';
import { IConfigurationEntry } from '../../DefinitionSelection/IConfigurationEntry';
import { FormField } from '../FormField';
import { IObjectSectionProps, ObjectSection } from '../ObjectSection';
import { RadioButtonControl } from '../RadioButtonControl';
import { SectionWrapper } from '../SectionWrapper';
import { ConfigurationSection } from './ConfigurationSection';
import { DataEntryMode } from './DataEntryMode';
import { IConfigurationObject } from './IConfigurationObject';
import { IExerciseSectionEntry } from './IExerciseSectionEntry';
import { ModePicker } from './ModePicker';
import { ObjectCreator } from './ObjectCreator';
import { SpecificationFieldSection } from './SpecificationFieldSection';

export interface IExerciseSetSectionProps extends IObjectSectionProps<ExerciseSet> {
  showRestPeriod: boolean;
}

export interface IExerciseSetSectionState {
  difficulty: ModelDifficulty;
  specifications: IExerciseSectionEntry<IPerformanceSpecification>[];
  configurations: IExerciseSectionEntry<IConfiguration>[];
  restPeriod: string;
  mode: DataEntryMode;
}

const MAXIMUM_SPECIFICATION_COUNT = MaximumSpecifications;
const MAXIMUM_PARAMETER_COUNT = MaximumConfigurations;

export class ExerciseSetSection extends ObjectSection<
  ExerciseSet,
  IExerciseSetSectionProps,
  IExerciseSetSectionState
> {
  private _configurationObject: IConfigurationObject;
  private _difficultyOptions: IConfigurationEntry[];
  private restPeriodValidator = ValueValidator.durationValidator(() => this.state.restPeriod);

  public constructor(props: IExerciseSetSectionProps) {
    super(props);
    const exerciseSet = props.model;
    const setGroup = exerciseSet.setGroup();
    this.state = {
      configurations: _.map(exerciseSet.configurations, param => ({
        isValid: true,
        object: Object.freeze(_.clone(param)),
      })),
      difficulty: exerciseSet.difficulty,
      mode: DataEntryMode.Input,
      restPeriod: setGroup.rest_period || '',
      specifications: _.map(exerciseSet.performance_specifications, spec => {
        return { object: Object.freeze(_.clone(spec)), isValid: true };
      }),
    };
  }

  private get difficultyOptions(): IConfigurationEntry[] {
    if (this._difficultyOptions == null) {
      this._difficultyOptions = convertConfiguration(
        'configuration.exercise_set.difficulty',
        this.context,
      );
    }
    return this._difficultyOptions;
  }

  // <editor-fold desc="Intensity Section Methods and Properties">
  private get canAddIntensity(): boolean {
    return this.state.configurations.length < MAXIMUM_PARAMETER_COUNT;
  }

  // </editor-fold>

  // <editor-fold desc="Specifications Methods">
  private get canAddSpecification(): boolean {
    return this.state.specifications.length < MAXIMUM_SPECIFICATION_COUNT;
  }

  private get isActualPermitted(): boolean {
    return this.state.difficulty !== 'not_set';
  }

  // </editor-fold>

  private get configurationObject(): IConfigurationObject {
    if (this._configurationObject == null) {
      const unitKinds = convertConfiguration('configuration.specification.unit_kind', this.context);
      const unitEntries = this.unitEntries;
      const targetTypes = convertConfiguration(
        'configuration.specification.target_type',
        this.context,
      );
      this._configurationObject = { unitKinds, unitEntries, targetTypes };
    }
    return this._configurationObject;
  }

  private get unitEntries(): Dictionary<IConfigurationEntry[]> {
    const unitEntriesMap = this.localize('configuration.specification.unit_entry');
    return _.mapObject(unitEntriesMap, (value: any, key: string) => {
      return convertConfigurationObject(value);
    });
  }

  public render(): JSX.Element {
    return (
      <div>
        <SectionWrapper iconClass="difficulty-icon">
          <RadioButtonControl
            options={this.difficultyOptions}
            propertyName="difficulty"
            modelName="set_group"
            updateAction={this.updateDifficulty}
            currentValue={this.state.difficulty}
          />
        </SectionWrapper>
        {this.props.showRestPeriod && (
          <SectionWrapper iconClass="set-group-rest-period-icon">
            <FormField
              value={this.state.restPeriod}
              updateAction={this.updateRestPeriod}
              modelName="set_group"
              propertyName="rest_period"
              placeholder=""
              isValid={this.restPeriodValidator.isValid()}
              valueError={this.restPeriodValidator.error()}
              labelPath="object.set_group.rest_period"
            />
          </SectionWrapper>
        )}
        <ModePicker mode={this.state.mode} updateMode={this.updateMode} />
        <ConfigurationSection
          updateValidity={this.updateIntensityValidity}
          canAddParameter={this.canAddIntensity}
          addParameter={this.addParameter}
          updateParameter={this.updateParameter}
          removeParameter={this.removeParameter}
          mode={this.state.mode}
          configObject={this.configurationObject}
          parameters={this.state.configurations}
        />
        <SpecificationFieldSection
          updateValidity={this.updateSpecificationValidity}
          canAddSpecification={this.canAddSpecification}
          addSpecification={this.addSpecification}
          updateSpecification={this.updateSpecification}
          removeSpecification={this.removeSpecification}
          configObject={this.configurationObject}
          specifications={this.state.specifications}
          isActualPermitted={this.isActualPermitted}
          updateMode={this.updateMode}
          mode={this.state.mode}
        />
      </div>
    );
  }

  protected updateFormData() {
    const updates = [
      this.constructUpdate('set_group', 'rest_period', 'restPeriod'),
      this.constructUpdate('exercise_set', 'difficulty', 'difficulty'),
      {
        attribute: 'performance_specifications',
        type: 'exercise_set',
        value: _.map(this.state.specifications, entry => {
          return entry.object;
        }),
      },
      {
        attribute: 'configurations',
        type: 'exercise_set',
        value: _.map(this.state.configurations, entry => {
          return entry.object;
        }),
      },
    ];
    this.props.updateFormData(updates);
    const allSpecsValid = _.all(this.state.specifications, specWrapper => specWrapper.isValid);
    const allParamsValid = _.all(this.state.configurations, paramWrapper => paramWrapper.isValid);
    const restValid = this.restPeriodValidator.isValid();
    this.props.updateValidity(_.all([allSpecsValid, allParamsValid, restValid]));
  }

  private updateMode = (newmode: DataEntryMode): void => {
    this.setState({ mode: newmode });
  };

  private updateRestPeriod = (newValue: string): void => {
    this.setState({ restPeriod: newValue });
  };

  private updateDifficulty = (newValue: ModelDifficulty): void => {
    this.setState({ difficulty: newValue });
  };

  private updateIntensityValidity = (index: number, isValid: boolean): void => {
    const newArray = this.cloneParameters();
    newArray[index].isValid = isValid;
    this.setState({ configurations: newArray });
  };

  private updateParameter = (newValue: IConfiguration, index: number): void => {
    const newArray = this.cloneParameters();
    newArray[index].object = _.assign({}, newValue);
    this.setState({ configurations: newArray });
  };

  private removeParameter = (index: number): void => {
    const newArray = this.cloneParameters();
    newArray.splice(index, 1);
    this.setState({ configurations: newArray });
  };

  private addParameter = (): void => {
    const newArray = this.cloneParameters();
    newArray.push({ object: ObjectCreator.parameter(), isValid: true });
    this.setState({ configurations: newArray });
  };

  private cloneParameters(): IExerciseSectionEntry<IConfiguration>[] {
    return _.clone(this.state.configurations);
  }

  private updateSpecificationValidity = (isValid: boolean, index: number): void => {
    const newArray = this.cloneSpecifications();
    newArray[index].isValid = isValid;
    this.setState({ specifications: newArray });
  };

  private updateSpecification = (newValue: IPerformanceSpecification, index: number): void => {
    const newArray = this.cloneSpecifications();
    newArray[index].object = newValue;
    this.setState({ specifications: newArray });
  };

  private removeSpecification = (index: number): void => {
    const newArray = this.cloneSpecifications();
    newArray.splice(index, 1);
    this.setState({ specifications: newArray });
  };

  private addSpecification = (): void => {
    const newArray = this.cloneSpecifications();
    newArray.push({ object: ObjectCreator.specification(), isValid: false });
    this.setState({ specifications: newArray });
  };

  private cloneSpecifications(): IExerciseSectionEntry<IPerformanceSpecification>[] {
    return _.clone(this.state.specifications);
  }
}
