import * as React from 'react';
// tslint:disable-next-line:no-duplicate-imports
import { FormEvent } from 'react';
import * as _ from 'underscore';
import { Exercise } from '../../../../Models/Exercise';
import { ExerciseGroup } from '../../../../Models/ExerciseGroup';
import { IExerciseHistory } from '../../../../Models/History/IExerciseHistory';
import { IExerciseHistoryBase } from '../../../../Models/History/IExerciseHistoryBase';
import { IExerciseDefinition } from '../../../../Models/IExerciseDefinition';
import { Workout } from '../../../../Models/Workout';
import { ILocalizationService } from '../../../../Services/ILocalizationService';
import {
  IDefinitionSelectionViewModel,
  IExerciseQuickSetOptions,
  SelectionModes,
} from '../../../../ViewModels/Panels/IDefinitionSelectionViewModel';
import { ValueValidator } from '../../Helpers/ValueValidator';
import { IPanelPassthroughProps } from '../IPanelProps';
import { DefinitionSelectionMode } from './DefinitionSelectionMode';
import {
  IExistingDefinitionActionProps,
  IExistingDefinitionDataProps,
} from './IExistingDefinitionProps';
import { INewDefinitionProps } from './INewDefinitionProps';
import { IQuickSetSettings } from './IQuickSetSettings';
import { ModeTabs } from './ModeTabs';
import { INewDefinitionFormValues, NewDefinitionForm } from './NewDefinitionForm';
import { NewExerciseConfiguration, NewExerciseContentMode } from './NewExerciseConfiguration';
import { SelectionList } from './SelectionList';
import { SubmitButton } from './SubmitButton';
import { SubmitStatus } from './SubmitStatus';

export interface IDefinitionSelectionContainerProps {
  selectionMode: DefinitionSelectionMode;
  targetType: SelectionModes;
  localization: ILocalizationService;
  existingDefinitionDataProps: IExistingDefinitionDataProps;
  newDefinitionProps: INewDefinitionProps;
  newExerciseContentMode: NewExerciseContentMode;
  previousExercise: IExerciseHistory[];
  quickSetProps: IQuickSetSettings;
  selectedExercise: IExerciseHistoryBase;
  targetRecord: Exercise | ExerciseGroup | Workout;
}

export interface IDefinitionSelectionContainerActionProps {
  existingDefinitionActionProps: IExistingDefinitionActionProps;
  viewModel: IDefinitionSelectionViewModel;
}

export interface ISelectionContainerState {
  inProgress: boolean;
  currentCategory: string;
  currentImplement: string;
  currentSided: 'true' | 'false';
  name: string;
  reminder: string;
}

const MAXIMUM_NAME_LENGTH = 140;

export class DefinitionSelectionContainer extends React.Component<
  IDefinitionSelectionContainerProps &
    IDefinitionSelectionContainerActionProps &
    IPanelPassthroughProps,
  ISelectionContainerState
> {
  private quickSetCountValidator: ValueValidator<number> = ValueValidator.rangeValidator(
    1,
    20,
    () => this.props.quickSetProps.quickSetCount,
  );
  private quickSetIntensityValidator: ValueValidator<
    string
  > = ValueValidator.intensityValueValidator(() => this.props.quickSetProps.quickSetIntensity);
  private quickSetTargetValidator: ValueValidator<string> = ValueValidator.targetValueValidator(
    () => this.props.quickSetProps.quickSetTarget,
  );
  private nameValidator: ValueValidator<string> = ValueValidator.lengthValidator(
    1,
    MAXIMUM_NAME_LENGTH,
    () => this.state.name,
  );
  private selectionValidator: ValueValidator<any> = new ValueValidator(
    (value: IExerciseDefinition) => _.isObject(value),
    `Must select an Exercise`,
    () => this.props.existingDefinitionDataProps.selectedDefinition,
  );

  constructor(
    props: IDefinitionSelectionContainerProps &
      IDefinitionSelectionContainerActionProps &
      IPanelPassthroughProps,
  ) {
    super(props);
    this.state = {
      currentCategory: 'strength',
      currentImplement: 'barbell',
      currentSided: 'true',
      inProgress: false,
      name: '',
      reminder: '',
    };
  }

  private get buttonStatus(): SubmitStatus {
    if (this.state.inProgress) {
      return SubmitStatus.Pending;
    }
    return this.isValid() ? SubmitStatus.Valid : SubmitStatus.Invalid;
  }

  public render(): JSX.Element {
    return (
      <div className="definition-selection">
        <ModeTabs
          localizationService={this.props.localization}
          selectionMode={this.props.selectionMode}
          onChangeMode={this.onChangeMode}
        />
        <form id="definition-selector-form" onSubmit={this.handleSubmitAction}>
          {this.props.selectionMode === DefinitionSelectionMode.Existing && (
            <SelectionList
              onChangeSearchAction={this.onChangeSearch}
              isMobile={this.props.isMobile}
              onSelectEntryAction={
                this.props.existingDefinitionActionProps.onSelectDefinitionAction
              }
              definitions={this.props.existingDefinitionDataProps.definitionList}
              selectedDefinition={this.props.existingDefinitionDataProps.selectedDefinition}
            />
          )}
          {this.props.selectionMode === DefinitionSelectionMode.New && (
            <NewDefinitionForm
              localizationService={this.props.localization}
              categories={this.props.newDefinitionProps.categories}
              implementList={this.props.newDefinitionProps.implementList}
              sidedOptions={this.props.newDefinitionProps.sidedList}
              updateAction={this.handleNewDefinitionUpdate}
              currentSided={this.state.currentSided}
              currentImplement={this.state.currentImplement}
              currentCategory={this.state.currentCategory}
              currentName={this.state.name}
              isValidName={this.isNewValid()}
              nameError={this.nameError()}
            />
          )}
          {this.props.newExerciseContentMode !== NewExerciseContentMode.NotPermitted && (
            <NewExerciseConfiguration
              quickSetProps={this.props.quickSetProps}
              contentMode={this.props.newExerciseContentMode}
              selectedExercise={this.props.selectedExercise}
              selectPreviousExercise={this.selectPreviousExercise}
              selectionMode={this.props.selectionMode}
              handleQuickSetUpdate={this.handleQuickSetUpdate}
              changeContentMode={this.changeContentMode}
              previousExercises={this.props.previousExercise}
            />
          )}
          {this.renderSubmitButton()}
        </form>
      </div>
    );
  }

  private onChangeMode = (newMode: DefinitionSelectionMode) => {
    this.props.viewModel.updateMode(newMode);
  };

  private onChangeSearch = (newSearch: string) => {
    this.setState({ name: newSearch });
    this.props.existingDefinitionActionProps.onChangeSearchAction(newSearch);
  };

  private changeContentMode = (newMode: NewExerciseContentMode): void => {
    this.props.viewModel.updateContentMode(newMode);
    if (newMode !== NewExerciseContentMode.CopyPrevious) {
      this.props.viewModel.selectNoPreviousExercise();
    }
  };

  private selectPreviousExercise = (ex: IExerciseHistoryBase): void => {
    this.props.viewModel.selectPreviousExercise(ex);
  };

  private handleNewDefinitionUpdate = (values: Partial<INewDefinitionFormValues>) => {
    if (values.category) {
      this.setState({ currentCategory: values.category });
    }
    if (values.implement) {
      this.setState({ currentImplement: values.implement });
    }
    if (_.has(values, 'name')) {
      this.setState({ name: values.name });
    }
    if (values.reminder) {
      this.setState({ reminder: values.reminder });
    }
    if (values.sided) {
      this.setState({ currentSided: values.sided });
    }
  };

  private isNewValid(): boolean {
    return this.nameValidator.isValid();
  }

  private nameError(): string {
    return this.nameValidator.error();
  }

  private isExistingValid(): boolean {
    return this.selectionValidator.isValid();
  }

  private isQuickSetValid(): boolean {
    if (this.props.newExerciseContentMode === NewExerciseContentMode.QuickSet) {
      const isValidCount = this.quickSetCountValidator.isValid();
      const isValidIntensity = this.quickSetIntensityValidator.isValid();
      const isValidTarget = this.quickSetTargetValidator.isValid();
      return isValidCount && isValidIntensity && isValidTarget;
    } else {
      return true;
    }
  }

  private handleQuickSetUpdate = (count: number, intensity: string, target: string) => {
    this.props.viewModel.updateQuickSetValues({
      intensityValue: intensity,
      numberOfSets: count,
      targetValue: target,
    });
  };

  private renderSubmitButton(): JSX.Element {
    const buttonLabel = this.props.localization.getValue('inline.select_exercise_definition');
    return (
      <div className="definition-selector-commands">
        <SubmitButton
          submitAction={this.handleSubmitAction}
          status={this.buttonStatus}
          label={buttonLabel}
        />
      </div>
    );
  }

  private isValid(): boolean {
    return (
      (this.props.selectionMode === DefinitionSelectionMode.Existing
        ? this.isExistingValid()
        : this.isNewValid()) && this.isQuickSetValid()
    );
  }

  private handleSubmitAction = (
    event: FormEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    if (!this.isValid() || this.state.inProgress) {
      return;
    }
    let quickSet: IExerciseQuickSetOptions = null;
    let previousExerciseId: IExerciseHistoryBase = null;
    if (this.props.newExerciseContentMode === NewExerciseContentMode.QuickSet) {
      quickSet = {
        intensity_value: this.props.quickSetProps.quickSetIntensity,
        number_of_sets: this.props.quickSetProps.quickSetCount,
        target_value: this.props.quickSetProps.quickSetTarget,
      };
    } else if (this.props.newExerciseContentMode === NewExerciseContentMode.CopyPrevious) {
      previousExerciseId = this.props.selectedExercise;
    }
    const view = this.props.viewModel;
    this.setState({ inProgress: true });
    let promise;
    if (this.props.selectionMode === DefinitionSelectionMode.Existing) {
      promise = view.completeChange(quickSet, previousExerciseId);
    } else {
      const newDefinitionData: IExerciseDefinition = {
        category: this.state.currentCategory as DefinitionCategory,
        implement: this.state.currentImplement,
        name: this.state.name,
        note_content: this.state.reminder,
        sided: this.state.currentSided,
      };
      promise = view.createDefinition(newDefinitionData, quickSet);
    }
    /**
     * Returns on fail only as setState is otherwise called after panel is dismissed.
     */
    promise.fail(() => this.setState({ inProgress: false }));
  };
}
