import * as DeepDiff from 'deep-diff';
import * as _ from 'underscore';

export class DiffService {
  /**
   * Compares the objects and returns them in a DiffSet.
   */
  public compare(previousObj?: object, newObj?: object): DiffSet {
    return new DiffSet(previousObj, newObj, this);
  }

  /**
   * Returns whether this is a new diff or not.
   */
  public isNewDiff(changeDiffData: Array<DeepDiff.Diff<any>>): boolean {
    return _.all(changeDiffData, function(diff: DeepDiff.Diff<any>) {
      return diff.kind === 'N';
    });
  }

  /**
   * Returns a list of the changed keys,
   * @param diffArray The array of diff changes.
   * @param depth The depth of keys to look at.
   * @return Array of keys.
   */
  public changeKeys(diffArray: Array<DeepDiff.Diff<any>>, depth = 0): string[] {
    let initialResult: string[];
    initialResult = _.map(diffArray, function(diff: DeepDiff.Diff<any>) {
      const diffPath = diff.path;
      let changePath: string[];
      changePath = depth > 0 ? _.first(diffPath, depth) : diffPath;
      if (diff.kind !== 'N') {
        return changePath.join('.');
      }
    });
    return _.uniq(initialResult);
  }

  /**
   * Returns a diff of objects.
   */
  public getDiff(previousObj: object, newObj: object): Array<DeepDiff.Diff<any>> {
    return DeepDiff.diff(previousObj, newObj);
  }
}

/**
 * The DiffSet class provides access to information about the diff generated between two objects.
 */
// tslint:disable-next-line:max-classes-per-file
class DiffSet {
  public diffArray: Array<DeepDiff.Diff<any>>;
  public isNewDiff: boolean;
  public changeKeys: string[];
  private _previousObj: object;
  private _newObj: object;

  constructor(previousObj: object, newObj: object, diffService: DiffService) {
    this._previousObj = previousObj;
    this._newObj = newObj;
    this.diffArray = diffService.getDiff(previousObj, newObj);
    this.isNewDiff = diffService.isNewDiff(this.diffArray);
    this.changeKeys = diffService.changeKeys(this.diffArray);
  }
}
