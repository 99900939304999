import * as React from 'react';
import { VelocityComponent } from 'velocity-react';
import './IncrementerControl.scss';
import { IncrementTypes } from './IncrementTypes';

export interface IIncrementerControlExternalProps {
  incrementPermitted: boolean;
  decrementPermitted: boolean;
  currentValue: string | number;
  label: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onIncrementClick: (incrementAction: IncrementTypes) => void;
  onBlurOrSubmit: () => void;
  isEnabled: boolean;
}

export interface IIncrementerControlProps extends IIncrementerControlExternalProps {
  /**
   * Additional classes for the container.
   */
  containerClass: string;
  /**
   * Additional classes for the input element.
   */
  inputClass: string;
  /**
   * Additional classes for the label element.
   */
  labelClass: string;
}

export interface IIncrementerControlState {}

const animationDuration = 250;
const animationPlugin = 'callout.pulse';

export class IncrementerControl extends React.PureComponent<
  IIncrementerControlProps,
  IIncrementerControlState
> {
  private velocityDecrement: VelocityComponent;
  private velocityIncrement: VelocityComponent;
  private velocityValue: VelocityComponent;

  public render(): JSX.Element {
    const containerClasses = this.props.containerClass + ' msvIncrementer';
    const inputClasses = this.props.inputClass + ' sv-large-field sv-entry msvIncrementer-field';
    const labelClasses = this.props.labelClass + ' msvIncrementer-label';
    return (
      <div className={containerClasses}>
        <VelocityComponent
          animation={animationPlugin}
          ref={elem => (this.velocityDecrement = elem)}
          duration={animationDuration}
        >
          <button
            className="sv-decrement msvIncrementer-button xbtn xbtn-primary"
            onClick={this.handleDecrementClick}
            disabled={!this.props.decrementPermitted}
          >
            <i className="decrement-icon" />
          </button>
        </VelocityComponent>
        <VelocityComponent
          animation={animationPlugin}
          ref={elem => (this.velocityValue = elem)}
          duration={animationDuration}
        >
          <label className={labelClasses}>
            <input
              type="text"
              className={inputClasses}
              value={this.props.currentValue}
              disabled={!this.props.isEnabled}
              onChange={this.props.onChange}
              onBlur={this.props.onBlurOrSubmit}
            />
            <div className="msvIncrementer-titleText">{this.props.label}</div>
          </label>
        </VelocityComponent>
        <VelocityComponent
          animation={animationPlugin}
          ref={elem => (this.velocityIncrement = elem)}
          duration={animationDuration}
        >
          <button
            className="sv-increment msvIncrementer-button xbtn xbtn-primary"
            onClick={this.handleIncrementClick}
            disabled={!this.props.incrementPermitted}
          >
            <i className="increment-icon" />
          </button>
        </VelocityComponent>
      </div>
    );
  }

  private handleDecrementClick = () => {
    this.handleIncrement(IncrementTypes.Decrement);
  };

  private handleIncrementClick = () => {
    this.handleIncrement(IncrementTypes.Increment);
  };

  private handleIncrement = (incrementAction: IncrementTypes): void => {
    const velComponent =
      incrementAction === IncrementTypes.Increment
        ? this.velocityIncrement
        : this.velocityDecrement;
    velComponent.runAnimation();
    this.props.onIncrementClick(incrementAction);
  };
}
