import * as _ from 'underscore';
import { IExerciseHistory } from '../../../../Models/History/IExerciseHistory';
import { IExerciseSetHistory } from '../../../../Models/History/IExerciseSetHistory';
import { ISetGroupHistory } from '../../../../Models/History/ISetGroupHistory';
import { ExerciseSetFormatter } from './ExerciseSetFormatter';
import { IExerciseHistoryFormatted } from './IExerciseHistoryFormatted';
import { IExerciseSetFormatted } from './IExerciseSetFormatted';
import { IExerciseSetFormatter } from './IExerciseSetFormatter';
import { IHistoryFormatter } from './IHistoryFormatter';
import { ISetGroupHistoryFormatted } from './ISetGroupHistoryFormatted';

/**
 * The HistoryFormatter class is responsible for formatting exercise history objects for rendering.
 */
export class HistoryFormatter implements IHistoryFormatter {
  private formatter: IExerciseSetFormatter = new ExerciseSetFormatter();

  public formatHistory(history: IExerciseHistory[]): IExerciseHistoryFormatted[] {
    return _.map(
      history,
      (exercise: IExerciseHistory): IExerciseHistoryFormatted => {
        const results = {
          date: exercise.date,
          id: exercise.id,
          links: exercise.links,
          note_content: exercise.note_content,
          rest_set: exercise.rest_set,
          set_groups: this.formatSetGroups(exercise.set_groups),
          status: exercise.status,
          super_set_exercises: exercise.super_set_exercises,
          tempo: exercise.tempo,
          workout_id: exercise.workout_id,
          workout_name: exercise.workout_name,
          workout_url: exercise.links.workout,
        };
        return results;
      },
    );
  }

  private formatSetGroups(setGroups: ISetGroupHistory[]): ISetGroupHistoryFormatted[] {
    return _.map(
      setGroups,
      (setGroup: ISetGroupHistory): ISetGroupHistoryFormatted => {
        return {
          ...setGroup,
          exercise_sets: this.formatExerciseSet(setGroup.exercise_sets),
        };
      },
    );
  }

  private formatExerciseSet(sets: IExerciseSetHistory[]): IExerciseSetFormatted[] {
    return _.map(
      sets,
      (set: IExerciseSetHistory): IExerciseSetFormatted => {
        return this.formatter.formatExerciseSet(set);
      },
    );
  }
}
