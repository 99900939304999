import * as React from 'react';
import { IExerciseDefinition } from '../../Models/IExerciseDefinition';
import { ILocalizedComponentProps, LocalizedPureComponent } from '../LocalizedComponent';
import { IWorkoutCopyFormDelegate } from './IWorkoutCopyFormDelegate';

export interface IDestinationSelectorProps extends ILocalizedComponentProps {
  searchResults: IExerciseDefinition[];
  selectedDefinition: IExerciseDefinition;
  searchText: string;
  delegate: IWorkoutCopyFormDelegate;
}

export interface IDestinationSelectorState {}

const SELECT_CANCEL_BUTTON_PATH = 'copy_workout_link.select_cancel_button';
const SELECT_NEW_BUTTON_PATH = 'copy_workout_link.select_new_button';
const SELECT_EXISTING_BUTTON_PATH = 'copy_workout_link.select_existing_button';

export class DestinationSelector extends LocalizedPureComponent<
  IDestinationSelectorProps,
  IDestinationSelectorState
> {
  public render(): JSX.Element {
    return (
      <div className="select-destination">
        <input
          className="form-field search-definition-name"
          name="definition_name"
          value={this.props.searchText}
          onChange={this.handleSearchChange}
        />
        <ul className="search-results">{this.renderSearchResults()}</ul>
        <div className="select-destination-submit">
          <div className="xbtn xbtn-warning select-cancel" onClick={this.handleCancelClick}>
            {this.localize(SELECT_CANCEL_BUTTON_PATH)}
          </div>
          <div className="xbtn xbtn-primary select-new" onClick={this.handleSelectNewClick}>
            {this.localize(SELECT_NEW_BUTTON_PATH)}
          </div>
          {this.renderSelectExistingButton()}
        </div>
      </div>
    );
  }

  private renderSelectExistingButton(): JSX.Element {
    let className = 'xbtn xbtn-primary select-existing';
    const isDisabled = this.props.selectedDefinition == null;
    if (isDisabled) {
      className += ' disabled';
    }
    return (
      <button className={className} disabled={isDisabled} onClick={this.handleSelectExistingClick}>
        {this.localize(SELECT_EXISTING_BUTTON_PATH)}
      </button>
    );
  }

  private renderSearchResults(): JSX.Element[] {
    return this.props.searchResults.map(sr => this.renderSearchResult(sr));
  }

  private renderSearchResult(definition: IExerciseDefinition): JSX.Element {
    const isSelected = definition === this.props.selectedDefinition;
    let className =
      'exercise-definition-selection-item exercise_definition exercise-definition-format';
    if (isSelected) {
      className += ' selected-definition';
    }
    return (
      <li
        className={className}
        onClick={() => this.props.delegate.handleSelectSearchResult(definition)}
        data-object-id={definition.id}
        data-record="exercise_definition"
        data-category={definition.category}
        key={definition.id}
      >
        <div className="ed-status-header">
          <div className="difficulty">
            <i />
            <span />
          </div>
        </div>
        <div className="ed-primary-header">
          <div className="definition-name">
            {definition.user_id && <i className="definition-ownership" />}
            <span data-record="exercise_definition" data-attribute="name" className="name-base">
              <i />
              <div className="static_value">{definition.name}</div>
            </span>
          </div>
        </div>
        <div className="ed-second-header ed-select-exercise">
          <div className="content">
            <i />
            <span />
          </div>
        </div>
      </li>
    );
  }

  private handleSelectNewClick = (): void => {
    this.props.delegate.handleUpdateMapping(null);
  };

  private handleCancelClick = (): void => {
    this.props.delegate.handleSelectMapping(null);
  };

  private handleSelectExistingClick = (): void => {
    this.props.delegate.handleUpdateMapping(this.props.selectedDefinition);
  };

  private handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.props.delegate.handleSearchEntry(event.target.value);
  };
}
