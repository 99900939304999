import * as log from 'loglevel';
import * as React from 'react';
import * as _ from 'underscore';
import { IExerciseDefinition } from '../../Models/IExerciseDefinition';
import { ViewService } from '../../Services/ViewService';
import { IWorkoutCopyViewDelegate } from '../../ViewModels/IWorkoutCopyViewDelegate';
import { ILocalizedComponentProps, LocalizedPureComponent } from '../LocalizedComponent';
import { IWorkoutCopyFormDelegate } from './IWorkoutCopyFormDelegate';
import { IWorkoutCopyView } from './IWorkoutCopyView';
import { MapResult } from './MapResult';
import { CopyState, WorkoutCopyContainer } from './WorkoutCopyContainer';

export interface IReactWorkoutCopyViewProps extends ILocalizedComponentProps {
  viewService: ViewService;
  viewModel: IWorkoutCopyViewDelegate;
}

interface IReactWorkoutCopyState {
  name: string;
  date: string;
  searchResults: IExerciseDefinition[];
  mappings: MapResult[];
  searchEntry: string;
  copyState: CopyState;
  selectedMapping: MapResult;
  selectedDefinition: IExerciseDefinition | null;
}

export class ReactWorkoutCopyView
  extends LocalizedPureComponent<IReactWorkoutCopyViewProps, IReactWorkoutCopyState>
  implements IWorkoutCopyView, IWorkoutCopyFormDelegate {
  private searchQueryFunc: number = 0;

  constructor(props: IReactWorkoutCopyViewProps) {
    super(props);
    this.state = {
      copyState: CopyState.NotStarted,
      date: '',
      mappings: [],
      name: '',
      searchEntry: '',
      searchResults: [],
      selectedDefinition: null,
      selectedMapping: null,
    };
  }

  // <editor-fold desc="IWorkoutCopyFormDelegate Methods">

  public startCopying(): void {
    this.props.viewModel.startCopy();
  }

  public updateName = (newValue: string): void => {
    this.setState({ name: newValue });
  };

  public updateDate = (newValue: string): void => {
    this.setState({ date: newValue });
  };

  public handleSearchEntry(inputString: string): void {
    if (inputString === this.state.searchEntry) {
      return;
    } else {
      this.setState({
        searchEntry: inputString,
      });
    }
    if (this.searchQueryFunc != null) {
      window.clearTimeout(this.searchQueryFunc);
    }
    const queryFunc = () => {
      this.setState({ selectedDefinition: null });
      this.props.viewModel.requestSearchResults(inputString);
    };
    this.searchQueryFunc = window.setTimeout(queryFunc, 300);
  }

  public handleSelectMapping(newMapping: MapResult | null): void {
    if (newMapping !== this.state.selectedMapping) {
      this.setState({
        searchEntry: '',
        searchResults: [],
        selectedDefinition: null,
        selectedMapping: newMapping,
      });
    }
  }

  public handleSelectSearchResult(newDefinition: IExerciseDefinition | null): void {
    this.setState({ selectedDefinition: newDefinition });
  }

  public handleUpdateMapping(newDestination: IExerciseDefinition | null): void {
    const newDestinationId = newDestination ? newDestination.id.toString() : null;
    this.props.viewModel.setDestination(this.state.selectedMapping.origin_uid, newDestinationId);
    this.setState({
      searchEntry: '',
      searchResults: [],
      selectedDefinition: null,
      selectedMapping: null,
    });
  }

  public submit(): Promise<any> {
    return this.props.viewModel.submitCopy(this.state.name, moment(this.state.date));
  }

  // </editor-fold>

  // <editor-fold desc="IWorkoutCopyView">
  public displayStartCopyButton(display: boolean): void {
    this.setState({
      copyState: display ? CopyState.NotStarted : CopyState.InProgress,
    });
  }

  public populateForm(name: string, date: moment.Moment, mappingRequired: MapResult[]) {
    this.setState({
      copyState: CopyState.InProgress,
      date: date.toISOString(),
      mappings: mappingRequired,
      name,
    });
  }

  public assignDestination(mapResult: MapResult, destinationType: any, destinationName: string) {
    const existingMapResult = _.findIndex(
      this.state.mappings,
      (map: MapResult) => map.origin_uid === mapResult.origin_uid,
    );
    this.setState(state => {
      const mappings = state.mappings.slice();
      mappings.splice(existingMapResult, 1, mapResult);
      return {
        mappings,
      };
    });
  }

  public removeForm(): void {
    log.warn('Received Remove Form');
  }

  public populateSearchResults(resultArray: IExerciseDefinition[]): void {
    this.setState({
      searchResults: resultArray,
    });
  }

  // </editor-fold>

  public render(): JSX.Element {
    // const component = document.getElementById('reactLocation');
    // const props: IWorkoutCopyContainerProps = {
    //   copyState: this.copyState,
    //   delegate: this,
    //   localization: this.localizationService,
    //   mappingRequired: this.mappings,
    //   searchEntry: this.searchEntry,
    //   searchResults: this.searchResults,
    //   selectedDefinition: this.selectedDefinition,
    //   selectedMapping: this.selectedMapping,
    //   workoutDate: this.date,
    //   workoutName: this.name,
    // };
    return (
      <WorkoutCopyContainer
        workoutName={this.state.name}
        workoutDate={this.state.date}
        mappingRequired={this.state.mappings}
        searchResults={this.state.searchResults}
        searchEntry={this.state.searchEntry}
        copyState={this.state.copyState}
        delegate={this}
        selectedMapping={this.state.selectedMapping}
        selectedDefinition={this.state.selectedDefinition}
      />
    );
  }
}
