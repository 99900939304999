import * as log from 'loglevel';

const animationDefaults: JQuerySlickOptions = {
  arrows: false,
  autoplay: true,
  autoplaySpeed: 5000,
  dots: true,
};

/**
 * The CarouselManager is used on the homepage to link together a feature list and a carousel gallery.
 */
export class CarouselManager {
  private static currentListItemClass = 'hp-slickCurrent';
  private carouselElement: JQuery;
  private itemList: JQuery;

  /**
   *
   * @param carouselElement - The carousel container element.
   * @param itemList - The list element containing the list items.
   */
  constructor(carouselElement: JQuery, itemList: JQuery) {
    this.carouselElement = carouselElement.slick(animationDefaults);
    this.itemList = itemList;
    this.configureChangeEvents();
    this.handleInit();
  }

  public static prepare(elementSelector: string, featureListSelector: string): CarouselManager {
    const itemList = $(featureListSelector);
    const element = $(elementSelector);
    if (element.length == null || element.length === 0) {
      throw new Error('No Element Provided');
    }
    if (itemList.length == null || itemList.length === 0) {
      throw new Error('No Item List Provided');
    }
    const featureListCount = itemList.children().length;
    const slideCount = element.children().length;
    if (featureListCount !== slideCount) {
      log.warn(`Inconsistent List Count: Slides ${slideCount}, List Items ${featureListCount}`);
    }
    return new CarouselManager(element, itemList);
  }

  private configureChangeEvents(): void {
    this.carouselElement.on(
      'beforeChange',
      (event: JQueryEventObject, slick: JQuery, currentSlide: number, nextSlide: number) => {
        return this.handleChange(event, slick, currentSlide, nextSlide);
      },
    );
    this.itemList.find('li').each((index: number, elem: Element) => {
      $(elem).click(e => this.handleItemClick(index));
    });
  }

  private handleItemClick(index: number): void {
    this.carouselElement.slick('slickGoTo', index);
  }

  private handleChange(
    event: JQueryEventObject,
    slick: JQuery,
    currentSlide: number,
    nextSlide: number,
  ): void {
    this.getListItem(currentSlide).removeClass(CarouselManager.currentListItemClass);
    this.getListItem(nextSlide).addClass(CarouselManager.currentListItemClass);
  }

  private handleInit(): void {
    this.getListItem(0).addClass(CarouselManager.currentListItemClass);
  }

  private getListItem(itemNumber: number): JQuery {
    return $(this.itemList.find('li').get(itemNumber));
  }
}
