/* tslint:disable:max-classes-per-file */
import * as _ from 'underscore';
import { AjaxRequestDataType, AjaxRequestMethod, IAjaxObject } from './IAjaxObject';

export class AjaxObject implements IAjaxObject {
  public data: {};
  public url: string;
  public type: AjaxRequestMethod;
  public dataType: AjaxRequestDataType;

  public isValid(): boolean {
    return (
      _.isString(this.url) &&
      _.contains(['PATCH', 'POST', 'DELETE', 'GET'], this.type) &&
      _.contains(['JSON', 'script', 'HTML'], this.dataType)
    );
  }
}

const VERSION_2_HEADER = { 'X-SR-Version': '2' };
Object.freeze(VERSION_2_HEADER);

export class AjaxObjectGetHTML extends AjaxObject {
  constructor(url: string, data: {}) {
    super();
    this.url = url;
    this.data = data;
    this.type = 'GET';
    this.dataType = 'HTML';
  }
}

export class AjaxObjectGetScript extends AjaxObject {
  constructor(url: string, data: {}) {
    super();
    this.url = url;
    this.data = data;
    this.type = 'GET';
    this.dataType = 'script';
  }
}

export class AjaxObjectGetJSON extends AjaxObject {
  public readonly type = 'GET';
  public readonly dataType = 'JSON';
  public headers = VERSION_2_HEADER;

  constructor(url: string, data: {}) {
    super();
    this.url = url;
    this.data = data;
  }
}

export class AjaxObjectDeleteJSON extends AjaxObject {
  public headers = VERSION_2_HEADER;

  constructor(url: string, data: {}) {
    super();
    this.url = url;
    this.data = data;
    this.type = 'DELETE';
    this.dataType = 'JSON';
  }
}

export class AjaxObjectUpdateJSON extends AjaxObject {
  public headers = VERSION_2_HEADER;

  constructor(url: string, data: {}) {
    super();
    this.url = url;
    this.data = data;
    this.type = 'PATCH';
    this.dataType = 'JSON';
  }
}

export class AjaxObjectCreateJSON extends AjaxObject {
  public headers = VERSION_2_HEADER;

  constructor(url: string, data: {}) {
    super();
    this.url = url;
    this.data = data;
    this.type = 'POST';
    this.dataType = 'JSON';
  }
}

export class AjaxObjectUpdateScript extends AjaxObject {
  constructor(url: string, data: {}) {
    super();
    this.url = url;
    this.data = data;
    this.type = 'PATCH';
    this.dataType = 'script';
  }
}

export class AjaxObjectCreateScript extends AjaxObject {
  constructor(url: string, data: {}) {
    super();
    this.url = url;
    this.data = data;
    this.type = 'POST';
    this.dataType = 'script';
  }
}
